/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'shared/components/Button';
import Logo from 'assets/logos/ACE-logo_final-White.png';
import {
  BatteryIcon,
  DurationIcon,
  ModalCloseIcon,
  NumberIcon,
  PassThresholdIcon,
  TerminateLeaveIcon,
  TimeLimitIcon,
} from 'shared/components/Icons';
import { bindDuration } from 'utils/appHelpers';

export const QuizTerminateModal = ({
  isOpen,
  onClose,
  onTerminate,
  lesson,
  currentAttempt,
  passedDuration = 0,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={e => e.stopPropagation()}>
        <div className='modal-body'>
          <img src={Logo} alt='logo' />
          <Button onClick={onClose} className='btn-transparent close-button p-0'>
            <ModalCloseIcon />
          </Button>
          <p className='main-desc'>
            It is a long established fact that a reader will be distracted by the readable content
            of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
            more-or-less normal distribution of letters
          </p>
          <div className='content'>
            <div className='first-block'>
              <h6 className='title'>Before closing the quiz</h6>
              <p className='description'>
                You need to terminate quiz before you leave. This will be considered as failed
                attempt.
              </p>
            </div>
            <div className='second-block'>
              <h6 className='title'>Overview</h6>
              <p className='description'>
                It is a long established fact that a reader will be disastracted by the
              </p>
              <div className='details-block'>
                <div className='left-block'>
                  <div className='row'>
                    <div className='name-part'>
                      <NumberIcon />
                      <span>Number Of Questions</span>
                    </div>
                    <span>{lesson.steps.length}</span>
                  </div>
                  <div className='row danger'>
                    <div className='name-part'>
                      <BatteryIcon color='#EB4D4D' />
                      <span>Number Of attempts</span>
                    </div>
                    <span>
                      {currentAttempt || 1}/{lesson.attempts}
                    </span>
                  </div>
                  <div className='row'>
                    <div className='name-part'>
                      <PassThresholdIcon />
                      <span>Pass Threshold</span>
                    </div>
                    <span>{lesson.passingThreshold}%</span>
                  </div>
                </div>
                <div className='vertical-border' />
                <div className='right-block'>
                  <div className='row'>
                    <div className='name-part'>
                      <DurationIcon />
                      <span>Duration</span>
                    </div>
                    <span>
                      {lesson.timeLimit ? `~${(passedDuration / 60).toFixed(1)} min` : 'No limit'}
                    </span>
                  </div>
                  <div className='row'>
                    <div className='name-part'>
                      <TimeLimitIcon />
                      <span>Time Limitation</span>
                    </div>
                    <span>
                      {lesson.timeLimit
                        ? `${bindDuration(passedDuration)}/${lesson.timeLimit} min`
                        : 'No Limit'}
                    </span>
                  </div>
                  <div className='row'>
                    <div className='name-part'>
                      <TerminateLeaveIcon />
                      <span>Terminate on Leave</span>
                    </div>
                    <span>{lesson.terminateOnLeave ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='btns-container'>
            <Button className='btn-blue btn-cancel' onClick={onClose}>
              Cancel
            </Button>
            <Button
              className='btn-blue btn-start'
              onClick={() => {
                onTerminate();
                onClose();
              }}
            >
              Terminate
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root'),
  );
};
