import React from 'react';
import ServiceAndMaintenanceImg from 'assets/service-and-maintenance.png';

const ServiceAndMaintenance = () => {
  return (
    <div className='service-and-maintenance-container'>
      <img className='service-img' alt='Service and Maintenance' src={ServiceAndMaintenanceImg} />
      <h2 className='title'>Maintenance in Progress</h2>
      <span className='minor-txt'>Please come back soon</span>
    </div>
  );
};

export default ServiceAndMaintenance;
