import React, { useEffect, useState } from 'react';
import PublicWrapper from 'shared/components/layouts/PublicWrapper';
import Loading from 'shared/components/Loading';
import { Api } from 'utils/connectors';
import CoursePreview from './CoursePreview';
import logo from 'assets/ace-logo-tsp.png';
import TypeVIdeo from 'app/Main/routes/CourseView/components/TypeVIdeo';
import TypePDF from 'app/Main/routes/CourseView/components/TypePDF';
import TypeCases from 'app/Main/routes/CourseView/components/TypeCases';
import TypeImage from 'app/Main/routes/CourseView/components/TypeImage';
import { courseSubsStatuses, getError, isMobileDevice } from 'utils/appHelpers';
import { formatSubscription } from 'utils/formatHelpers';
import { useSnackbar } from 'notistack';
import { connect, useSelector } from 'react-redux';
import { getCourses } from 'app/Main/routes/Courses/actions';

const viewsComponents = {
  1: TypeVIdeo,
  2: TypePDF,
  3: TypeCases,
  4: TypeImage,
};

const PublicCourseView = ({ match, history, location, getCourses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const courseIdOrSharedToken = match.params?.id;
  const isCourseIdShareToken = isNaN(courseIdOrSharedToken);
  const needGetCourses = location.state?.needGetCourses;
  const [course, setCourse] = useState();
  const [subscription, setSubscription] = useState(null);

  const userId = useSelector(item => item?.account?.id);

  const fetchSubs = async () => {
    try {
      const subs = course?.subscriptionPlans && course?.subscriptionPlans[0];
      if (subs) {
        const { data } = await Api.get(`subscription/getsubscription/${subs.subscriptionId}`);
        setSubscription(formatSubscription(data.data));
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const autoEnroll = async () => {
    try {
      await Api.post('/subscription/subscribe', {
        subscriptionId: subscription?.id,
        userId,
        courseId: course?.id,
      });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getSharedCourseData = async () => {
    try {
      const { data } = await Api.get(`/courses/shared/course/${courseIdOrSharedToken}`);
      sessionStorage.setItem('courseSharedToken', courseIdOrSharedToken);
      setCourse(data.data);
    } catch (err) {
      history.push('/no-web-access');
    }
  };

  const getPublicCourseData = async () => {
    try {
      const { data } = await Api.get(`/courses/getcourse/${courseIdOrSharedToken}`);
      setCourse(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/allcourses');
    }
  };

  const onCasePlayClick = () => {};

  useEffect(() => {
    isCourseIdShareToken ? getSharedCourseData() : getPublicCourseData();
  }, [courseIdOrSharedToken]);

  useEffect(() => {
    if (course) {
      fetchSubs();
    }
  }, [course]);

  useEffect(() => {
    if (
      course?.isMarketingMaterial &&
      !courseSubsStatuses(course?.userCourse?.userCourseStatus, 'active')
    ) {
      autoEnroll();
    }
  }, [subscription]);

  useEffect(() => {
    if (needGetCourses) getCourses();
  }, [needGetCourses]);

  const LessonView =
    course?.courseLessons?.[0]?.lesson &&
    viewsComponents[(course?.courseLessons?.[0]?.lesson?.contentType)];

  if (course?.isMarketingMaterial && isMobileDevice()) {
    return (
      <div className='marketing-course-mobile-view'>
        <div className='marketing-header d-flex p-0 align-items-center'>
          <div className='event-nav'>
            <div className='container d-flex justify-content-between'>
              <img src={logo} height='60' alt='logo' />
            </div>
          </div>
        </div>
        {!!course?.courseLessons?.[0]?.lesson && (
          <LessonView
            course={course}
            lesson={course?.courseLessons?.[0]?.lesson}
            onCasePlayClick={onCasePlayClick}
          />
        )}
      </div>
    );
  }

  return (
    <PublicWrapper
      hasNotNav={location.pathname.startsWith('/marketing-courses') && isMobileDevice()}
    >
      {!course ? <Loading classView='mt-5' /> : <CoursePreview course={course} />}
    </PublicWrapper>
  );
};

const mapStateToProps = state => ({
  courses: state.courses,
});

const mapDispatchToProps = {
  getCourses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicCourseView);
