import React, { useState } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import ButtonLoading from 'shared/components/ButtonLoading';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { connect } from 'react-redux';
import { getCourses } from 'app/Main/routes/Courses/actions';

const ExpiredView = ({ course, getCourses }) => {
  const [fetch, setFetch] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const sentRequest = async () => {
    try {
      setFetch(true);
      await Api.post(`/subscription/extendlicense/${course.subscriptionId}`);
      await getCourses();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const isSent = course.userSubscriptionStatus === 6;

  return (
    <div className='p-4 expired-screen'>
      <p className='line-1-text'>Usage period has expired</p>
      {!isSent && (
        <>
          <p className='line-2-text'>You can request to extend access by one additional period.</p>
          <p className='line-3-text'>
            A request will be sent to “{course.name}” subscription administrator
          </p>
          <ButtonLine onClick={sentRequest} disabled={fetch} className='px-3'>
            {fetch ? <ButtonLoading /> : 'Send'}
          </ButtonLine>
        </>
      )}
      {isSent && (
        <>
          <p className='line-2-text'>Your request to extend usage period has been sent.</p>
          <p className='line-3-text'>
            You will receive a notification as soon as course administrator changes your access
            period.
          </p>
        </>
      )}
    </div>
  );
};

export default connect(
  null,
  { getCourses },
)(ExpiredView);
