import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Api } from 'utils/connectors';
import { getCourses } from 'app/Main/routes/Courses/actions';
import { useSnackbar } from 'notistack';
import { getError, isMobileDevice } from 'utils/appHelpers';
import { CircleFillIcon } from 'shared/components/Icons';
import ResponsiveImage from 'shared/ResponsiveImage';

const TypeImage = ({ lesson, getCourses, params, course }) => {
  const { enqueueSnackbar } = useSnackbar();
  const updateLesson = async () => {
    try {
      const body = {
        lessonId: Number(params?.lessonId) || lesson?.id,
        completed: 1,
        courseId: course.id,
      };
      await Api.post('/courses/updateuserlesson', body);
      getCourses();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    updateLesson();
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className={`video-area flex-1 d-flex justify-content-center ${course?.isMarketingMaterial &&
        isMobileDevice() &&
        'flex-column'}`}
    >
      <ResponsiveImage imgUrl={lesson.contentUrl || lesson.url} height='100%' alt='lesson' />
      {course?.isMarketingMaterial && isMobileDevice() && (
        <div className='mobile-marketing-lesson-name-container'>
          <div className='name-block'>
            <CircleFillIcon />
            <div className='text-block'>
              <h2 className='title'>{lesson.title}</h2>
              <p className='description'>{lesson.info}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  null,
  { getCourses },
)(TypeImage);
