/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { HOST } from 'configs';
import React from 'react';

const Print = () => {
  return (
    <div>
      <a
        href='#'
        download
        href={HOST.API.CONTENT + '/content/dicom_cheatsheet.pdf'}
        target='_blank'
        rel='noopener noreferrer'
      >
        Click here to open printable PDF help file in a new browser tab.
      </a>
    </div>
  );
};

export default Print;
