import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
import Loading from 'shared/components/Loading';
import { generateViewerData } from './utils';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { steps as initialSteps, leftOverlayViews, rightOverlayViews, tools } from './configs';
import ControlArea from './components/ControlArea';
import StepsArea from './components/StepsArea';
import HelpModal from './components/HelpModal';
import CanvasView from './components/CanvasView';
import QuestionaryModal from './components/QuestionaryModal';
import ExplanationModal from './components/ExplanationModal';
import HeatmapModal from './components/HeatmapModal';
import CanvasOverlay from './components/CanvasOverlay';
import { OVERLAY_VIEW_KEY_BY_IDX } from './constants';

const LATERALITY_BY_POSITION = {
  right: 'R',
  left: 'L',
};

const STEP_VIEWS_OVERLAYS_BY_LATERALITY = {
  L: leftOverlayViews,
  R: rightOverlayViews,
};

const TypeCasesPlay = ({ lesson, fullData }) => {
  const params = useParams();
  const { id: courseId } = params;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const viewerOptions = useSelector(state => state.viewerOptions);
  const [fetch, setFetch] = useState();
  const [activeCase, setActiveCase] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [course, setCourse] = useState(fullData);
  const [selectedPoint, setSelectedPoint] = useState({});
  const [tempSelectedPoint, setTempSelectedPoint] = useState(null);
  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayData, setOverlayData] = useState(null);
  const [selectedViewLaterality, setSelectedViewLaterality] = useState(null);
  const [selectedDicomIndex, setSelectedDicomIndex] = useState(null);
  const [mainSliceIndex, setMainSliceIndex] = useState(0);
  const [sideSliceIndex, setSideSliceIndex] = useState(0);
  const [overlayViewsLoading, setOverlayViewsLoading] = useState({ 0: false, 1: false });
  const [overlayStackControlByIdx, setOverlayStackControlByIdx] = useState({
    0: undefined,
    1: undefined,
  });
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const lessonCases = lesson && (lesson.cases || lesson.activeCases);

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  const handelActiveToolChange = tool => {
    setActiveTool(tool);
  };

  const handleClickCloseAssociations = () => {
    setTempSelectedPoint(null);
    setSelectedPoint({});

    document.querySelectorAll('.viewport-element').forEach(el => {
      const selectTool = cornerstoneTools.getToolForElement(el, 'SelectTool');
      if (selectTool && typeof selectTool.clearSelection === 'function') {
        selectTool.clearSelection(el);
      }
    });
  };

  const stepChangeHandler = async step => {
    await handleClickCloseAssociations();
    const isModal = step === 9 || step === 10 || step === 11;
    if (isModal) setActiveTool({});
    if (!completedSteps.includes(step) && !isModal) {
      await updateAndAddCompletedStep(step);
      completedSteps.push(step);
    }
    setActiveStep(step);
  };

  const updateAndAddCompletedStep = async step => {
    const { caseId } = lessonCases[activeCase];
    await updateDicomStepData(step, caseId);
    completedSteps.push(step);
  };

  const getCases = async () => {
    try {
      if (!lessonCases || !lessonCases.length) return;
      const { caseId, caseUniqueId } = lessonCases[activeCase];
      const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
      const { data } = await Api.get(endpoint);
      const cases = generateViewerData(data.data, lesson);
      setCourse({ title: lesson.title, lesson, cases });
      if (!cases[0].completed_steps.includes(0)) {
        cases[0].completed_steps.push(0);
        updateDicomStepData(0);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onCaseChange = async value => {
    try {
      if (!course.cases[value]) {
        setFetch(true);
        const { caseId, caseUniqueId } = lessonCases[value];
        const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
        const { data } = await Api.get(endpoint);
        const caseData = generateViewerData(data.data, lesson);
        const cases = [...course.cases, ...caseData];
        if (!cases[value].completed_steps.includes(0)) {
          cases[value].completed_steps.push(0);
          updateDicomStepData(0, caseId);
        }
        setCourse({ ...course, cases });
        setActiveCase(value);
        setFetch(false);
      } else {
        setActiveCase(value);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const updateFinding = async body => {
    await Api.post('/dicom/savedicomvectordata', body);
  };

  const updateDicomStepData = async (step, id) => {
    const caseId = id;
    const body = {
      lessonId: lesson.id,
      completed: 1,
      step: step + 1,
      caseId: Number(caseId),
      courseId: courseId || course?.id,
    };
    await Api.post('/courses/updateuserlesson', body);
  };

  const handleOpenOverlay = (newOverlayData, newCanvasViewKey, newCaseViewPosition, newIdx) => {
    const isSameOverlay =
      overlayData?.handles?.end?.id === newOverlayData?.handles?.end?.id &&
      selectedDicomIndex === newIdx &&
      selectedViewLaterality === LATERALITY_BY_POSITION[newCaseViewPosition];

    // If the overlay is the same, do not update state to prevent re-render and infinite loop
    if (showOverlay && isSameOverlay) {
      return;
    }
    setSelectedViewLaterality(LATERALITY_BY_POSITION[newCaseViewPosition]);
    setOverlayData(newOverlayData);
    setSelectedDicomIndex(newIdx);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setOverlayData(null);
  };

  useEffect(() => {
    fullScreenChange();
    if (!course) getCases();
    //eslint-disable-next-line
  }, []);

  // THIS MAYBE NEEDED TO REMOVE THEN
  useEffect(() => {
    if (!showOverlay) {
      setOverlayData(null);
      setSelectedViewLaterality(null);
      setSelectedDicomIndex(null);
    }
  }, [showOverlay]);

  if (!course || fetch) return <Loading className='mt-5' />;

  let steps = [...initialSteps];
  const selectedCase = lessonCases?.[activeCase];

  if (!!course.isOpened || !selectedCase?.hasHeatmap) {
    // remove questionary and heatmaps
    const excludeNames = course.isOpened
      ? ['question', 'heatmap']
      : !selectedCase?.hasHeatmap
      ? ['heatmap']
      : [];
    steps = steps.filter(item => !excludeNames.includes(item.name));
  }

  const isModal = steps[activeStep].type === 'modal';
  const caseViews = course.cases[activeCase].views;
  const completedSteps = course.cases[activeCase].completed_steps;
  const stepViews = steps[activeStep].views;
  const stepViewsData = Object.values(stepViews);
  const viewsCount = Object.values(stepViews).length;

  const sideDicomIndex = selectedDicomIndex === 1 ? 0 : 1;
  const defaultTypeKey = '3DQ';

  const findCorrelationIndex = typeKey =>
    caseViews?.[
      `${selectedViewLaterality}${
        OVERLAY_VIEW_KEY_BY_IDX?.[selectedDicomIndex]
      }_${typeKey?.toUpperCase()}`
    ]?.images?.findIndex(slice =>
      slice.geniusAIDataList.some(gaid => {
        if (overlayData?.handles?.end?.designator) {
          return gaid?.designator === overlayData?.handles?.end?.designator;
        } else {
          return gaid?.id === overlayData?.handles?.end?.id;
        }
      }),
    );

  const correlation3DQIndex = findCorrelationIndex('3DQ');
  const correlationTomoIndex = findCorrelationIndex('TOMO');

  const correlationTypeKey =
    correlation3DQIndex !== -1 ? '3DQ' : correlationTomoIndex !== -1 ? 'tomo' : defaultTypeKey;
  const correlationIndex =
    correlationTypeKey === 'tomo' ? correlationTomoIndex : correlation3DQIndex;

  const findSideDicomCorrelationIndex = typeKey => {
    const sideDicomImages =
      caseViews?.[
        `${selectedViewLaterality}${
          OVERLAY_VIEW_KEY_BY_IDX?.[sideDicomIndex]
        }_${typeKey?.toUpperCase()}`
      ]?.images;
    return sideDicomImages?.findIndex(slice =>
      slice.geniusAIDataList.some(
        gaid => gaid?.designator === overlayData?.handles?.end?.designator,
      ),
    );
  };

  // Manual Switch for GAID2
  const manualMainCorrelationTypeKey = overlayStackControlByIdx[selectedDicomIndex];
  const manualSideCorrelationTypeKey = overlayStackControlByIdx[sideDicomIndex];
  const manualMainCorrelationIndex = findCorrelationIndex(manualMainCorrelationTypeKey);
  const manualSideCorrelationIndex = findSideDicomCorrelationIndex(manualSideCorrelationTypeKey);

  const manualMainCorrelationSlice =
    manualMainCorrelationIndex !== -1 && imagesLoaded ? manualMainCorrelationIndex : null;
  // Made side correlation to be in the same slice as main when not found
  const manualSideCorrelationSlice = imagesLoaded
    ? manualSideCorrelationIndex !== -1
      ? manualSideCorrelationIndex
      : manualMainCorrelationSlice ?? correlationIndex
    : null;

  const sideIdx = findSideDicomCorrelationIndex(correlationTypeKey);
  const sideDicomCorrelationIndex = sideIdx === -1 ? correlationIndex : sideIdx;

  // If manually changed recalculate the differences
  const currentMainCorrelationSlice =
    typeof manualMainCorrelationSlice === 'number' ? manualMainCorrelationSlice : correlationIndex;
  const currentSideCorrelationSlice =
    typeof manualSideCorrelationSlice === 'number'
      ? manualSideCorrelationSlice
      : sideDicomCorrelationIndex;

  const slicesDifference = currentMainCorrelationSlice - currentSideCorrelationSlice;

  const gaid2SlicesConfigs = {
    mainSliceIndex,
    sideSliceIndex,
    slicesDifference,
    overlayData,
    imagesLoaded,
    overlayStackControlByIdx,
    overlayViewsLoading,
    setOverlayViewsLoading,
    setMainSliceIndex,
    setSideSliceIndex,
    setOverlayStackControlByIdx,
    setImagesLoaded,
  };

  return (
    <>
      <div className='viewer d-flex flex-column flex-fill'>
        <ControlArea
          course={course}
          fullScreen={fullScreen}
          activeTool={activeTool}
          setActiveTool={handelActiveToolChange}
          history={history}
          viewerOptions={viewerOptions}
          tools={tools}
        />
        <div
          className={`viewer-area has-steps-and-header d-flex ${fullScreen ? 'fullscreen' : ''}`}
          key={`${activeCase}_${activeStep}`}
        >
          {!isModal &&
            stepViewsData.map((item, index) => {
              return (
                <CanvasView
                  key={item.key + index}
                  dataTomo={caseViews[`${item.key}_TOMO`]}
                  data2D={caseViews[`${item.key}_2D`]}
                  data3DQ={caseViews[`${item.key}_3DQ`]}
                  step={item}
                  caseViews={caseViews}
                  allSteps={steps}
                  activeTool={activeTool}
                  setActiveTool={handelActiveToolChange}
                  fullScreen={fullScreen}
                  index={index}
                  viewsCount={viewsCount}
                  updateFinding={updateFinding}
                  viewerOptions={viewerOptions}
                  setActiveStep={setActiveStep}
                  selectedPoint={selectedPoint}
                  setSelectedPoint={setSelectedPoint}
                  tempSelectedPoint={tempSelectedPoint}
                  setTempSelectedPoint={setTempSelectedPoint}
                  handleClickClose={handleClickCloseAssociations}
                  openOverlay={handleOpenOverlay}
                  showOverlay={showOverlay}
                  canvasViewKey={item.key}
                  position={item.position}
                  correlationIndex={
                    index === selectedDicomIndex ? correlationIndex : sideDicomCorrelationIndex
                  }
                  sideDicomCorrelationIndex={sideDicomCorrelationIndex}
                />
              );
            })}
          {showOverlay && (
            <CanvasOverlay
              isLoading={overlayViewsLoading?.[0] || overlayViewsLoading?.[1]}
              fullScreen={fullScreen}
              activeCase={activeCase}
              activeStep={activeStep}
              onClose={handleCloseOverlay}
            >
              {!!selectedViewLaterality &&
                Object.values(STEP_VIEWS_OVERLAYS_BY_LATERALITY[selectedViewLaterality]).map(
                  (item, index) => {
                    const currentCorrelationIndex =
                      index === selectedDicomIndex
                        ? manualMainCorrelationSlice ?? correlationIndex
                        : manualSideCorrelationSlice ?? sideDicomCorrelationIndex;
                    const defaultStackControl =
                      index === selectedDicomIndex
                        ? manualMainCorrelationTypeKey || correlationTypeKey
                        : manualSideCorrelationTypeKey || correlationTypeKey;
                    return (
                      <CanvasView
                        key={item.key + index + 'overlay'}
                        dataTomo={
                          caseViews[
                            `${selectedViewLaterality}${OVERLAY_VIEW_KEY_BY_IDX[index]}_TOMO`
                          ]
                        }
                        data2D={
                          caseViews[`${selectedViewLaterality}${OVERLAY_VIEW_KEY_BY_IDX[index]}_2D`]
                        }
                        data3DQ={
                          caseViews[
                            `${selectedViewLaterality}${OVERLAY_VIEW_KEY_BY_IDX[index]}_3DQ`
                          ]
                        }
                        step={item}
                        caseViews={caseViews}
                        allSteps={steps}
                        activeTool={activeTool}
                        setActiveTool={handelActiveToolChange}
                        fullScreen={fullScreen}
                        index={index}
                        viewsCount={viewsCount}
                        updateFinding={updateFinding}
                        viewerOptions={viewerOptions}
                        setActiveStep={setActiveStep}
                        selectedPoint={selectedPoint}
                        setSelectedPoint={setSelectedPoint}
                        tempSelectedPoint={tempSelectedPoint}
                        setTempSelectedPoint={setTempSelectedPoint}
                        handleClickClose={handleClickCloseAssociations}
                        openOverlay={handleOpenOverlay}
                        showOverlay={showOverlay}
                        canvasViewKey={item.key}
                        position={item.position}
                        defaultStackControl={defaultStackControl}
                        correlationIndex={currentCorrelationIndex}
                        sideDicomCorrelationIndex={sideDicomCorrelationIndex}
                        isPrimaryDicom={index === selectedDicomIndex}
                        isGaid2View
                        {...gaid2SlicesConfigs}
                      />
                    );
                  },
                )}
            </CanvasOverlay>
          )}
          {isModal && (
            <>
              {steps[activeStep].name === 'question' && (
                <QuestionaryModal
                  lesson={lesson}
                  changeStep={stepChangeHandler}
                  completedSteps={completedSteps}
                  step={steps[activeStep]}
                  isOpened={course.isOpened}
                  caseId={selectedCase.caseId}
                />
              )}
              {steps[activeStep].name === 'explanator' && (
                <ExplanationModal
                  isDisabled={false}
                  updateStep={updateAndAddCompletedStep}
                  caseData={selectedCase}
                  caseId={selectedCase.caseId}
                  caseViews={caseViews}
                />
              )}
              {steps[activeStep].name === 'heatmap' && (
                <HeatmapModal
                  updateStep={updateAndAddCompletedStep}
                  isDisabled={completedSteps.length < steps[activeStep].disabled_no_active}
                  caseId={selectedCase.caseId}
                  lesson={lesson}
                  caseViews={caseViews}
                  isOpened={course.isOpened}
                />
              )}
            </>
          )}
        </div>
        <StepsArea
          cases={fullData?.cases || lessonCases}
          steps={steps}
          completedSteps={completedSteps}
          activeCase={activeCase}
          changeActiveCase={onCaseChange}
          activeStep={activeStep}
          changeActiveStep={stepChangeHandler}
          setActiveStep={setActiveStep}
          onHelpModalOpen={setOpenHelpModal}
          history={history}
        />
        {openHelpModal && <HelpModal onModalClose={() => setOpenHelpModal(false)} />}
      </div>
    </>
  );
};

export default TypeCasesPlay;
