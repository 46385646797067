import React, { memo } from 'react';

const Button = ({
  children,
  disabled,
  type = 'button',
  className = '',
  onClick,
  disabledClassName,
}) => (
  <button
    className={`btn ${className} ${disabled && disabledClassName}`}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    <span>{children}</span>
  </button>
);

export default memo(Button);
