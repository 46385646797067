import { createReducer } from 'utils/reduxHelpers';
import { Api, setAuthToken } from 'utils/connectors';
import { getLocalToken, removeLocalToken, saveLocalToken } from 'utils/tokenHelpers';

import { parseJson, getImageUrl, courseSubsStatuses, getErrorCode } from 'utils/appHelpers';
import { clearPermissions, setUserAppPermissions } from 'utils/permissionHelper';
import { saveToStore } from 'utils/storeHelpers';
import { PLATFORM } from 'configs/constants';
import { HOST } from 'configs';
import uuidv4 from 'app/Main/routes/Viewer/plugins/util/uuidv4';

const ON_AUTH_SATE = '@@auth/ON_AUTH_SATE';
const ON_LOGIN = '@@auth/ON_LOGIN';
export const ON_LOGOUT = '@@auth/ON_LOGOUT';

export const onAuthState = payload => ({ type: ON_AUTH_SATE, payload });
const onLogin = payload => ({ type: ON_LOGIN, payload });
const onLogout = () => ({ type: ON_LOGOUT });

const isAuthHandlers = {
  [ON_AUTH_SATE]: (state, action) => {
    return action.payload;
  },
};

const accountHandlers = {
  [ON_LOGIN]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

export const clearForLogout = dispatch => {
  dispatch(onLogout());
  removeLocalToken();
  dispatch(onAuthState(0));
  setAuthToken('', Api);
  clearPermissions();
};

// InvalidToken = 101,
// SessionExpired = 102,
// InvalidSessionUser = 103,
// NotActiveSession = 104,
// InvalidSession = 105

const errorMessage = {
  102: 'Our session is expired. Log in again to continue using the app.',
};

const setProfileSubscriptions = data => {
  if (!data || !data.length) return [];
  const result = [];
  data.forEach(item => {
    if (courseSubsStatuses(item.userSubscriptionStatus, 'active')) {
      const courses = item.subscription.courses;
      const course = courses && courses[0] && courses[0].course;
      result.push({
        id: item.id,
        startDate: item.startDate,
        endDate: item.endDate,
        progress: item.progress,
        name: item.subscription.title,
        courseId: course ? course.id : null,
        expirable: course ? course.expirable : undefined,
        isOpened: course ? course.isOpened : undefined,
      });
    }
  });

  return result;
};

const setProfileConnectionTest = data => {
  if (!data || !data.length) return [];
  return data.map(item => {
    const test = parseJson(item.testData);
    const { resolution, latency, bandwidth, browser, os } = test;
    return {
      ip: item.hostAddress || '-',
      browser,
      os,
      screen: {
        resolution,
        type: 'resolution',
        value: resolution ? resolution.screen : '-',
      },
      window: {
        resolution,
        type: 'resolution',
        value: resolution ? resolution.window : '-',
      },
      latency: {
        latency,
        type: 'latency',
        value: latency ? `Average: ${latency.avg}, Min: ${latency.min}, Max: ${latency.max}` : '-',
      },
      bandwidth: {
        bandwidth,
        type: 'bandwidth',
        value: bandwidth ? `received ${bandwidth.received} MB in ${bandwidth.duration}s` : '-',
      },
    };
  });
};

const setProfileEvent = events => {
  if (!events || !events.length) return null;
  return { ...events[0].publicEvent };
};

const getProfile = async () => {
  try {
    const { data } = await Api.post('/auth/getprofile');
    const profile = data && data.data ? data.data : false;
    if (profile) {
      setUserAppPermissions(profile.userType, profile.roles);
      profile.imageUrl = getImageUrl(profile.imageUrl);
      profile.subscriptions = setProfileSubscriptions(profile.userSubscriptions);
      profile.connections = setProfileConnectionTest(profile.connectionTests);
      profile.event = setProfileEvent(profile.userEvents);
      return profile;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const getAndUpdateProfile = () => async dispatch => {
  try {
    const res = await getProfile();
    await dispatch(onLogin(res));
  } catch (err) {
    clearForLogout(dispatch);
  }
};

const setVisitorState = async dispatch => {
  try {
    const { data } = await Api.post('/auth/visit', {
      visitorId: uuidv4(),
    });
    saveLocalToken(data.data.token);
    setAuthToken(data.data.token, Api);
    const user = await getProfile();
    // Keep user in store
    await dispatch(onLogin(user));
    // Set Aut State 2 - is login state (Account)
    dispatch(onAuthState(0));
  } catch (err) {
    // Set Aut State 0 - is logout state (Activation)
    dispatch(onAuthState(0));
    // Set logout
    clearForLogout(dispatch);
  }
};

export const getAuthState = enqueueSnackbar => async dispatch => {
  let token = getLocalToken();
  const url = new URL(window.location.href);
  const isAuthLink = url.pathname.includes('auth/');
  const isKolAuth = url.pathname.includes('grant/kol');
  const kolToken = isKolAuth && url.searchParams.get('t');
  const userRedirectUrl = !isAuthLink && !url.pathname.includes('login/') ? url.pathname : false;

  try {
    await Api.get('/auth/authorize-origin');
  } catch (err) {
    // TEMPORARY HIDDEN FOR FURTHER IMPLEMENTATION
    // if (err?.response?.status === 503 || err.message === 'Network Error') {
    //   dispatch(onAuthState(-2));
    // } else {
    dispatch(onAuthState(-1));
    // }
    return;
  }

  if (!token && userRedirectUrl) {
    saveToStore('redirectUrl', userRedirectUrl);
  }

  if (isKolAuth && kolToken) {
    token = kolToken;
    saveLocalToken(token);
  }

  if (token && !isAuthLink) {
    // set common headers
    setAuthToken(token, Api);

    try {
      const user = await getProfile();
      // Keep user in store
      await dispatch(onLogin(user));
      // Set Aut State 2 - is login state (Account)
      dispatch(onAuthState(2));
    } catch (err) {
      const { code } = getErrorCode(err);
      if (code === 102) enqueueSnackbar(errorMessage[102], { variant: 'error' });
      await setVisitorState(dispatch);
    }
  }
  // Set Aut State 0 - is logout state (Activation)
  else {
    await setVisitorState(dispatch);
  }
};

export const loginUser = ({ data }, history, forwardUrl = '/home') => async dispatch => {
  try {
    const { token, platform } = data;
    saveLocalToken(token);
    setAuthToken(token, Api);
    if (platform === PLATFORM.admin) {
      // Redirect to admin here
      window.open(`${HOST.API.ADMIN_DOMAIN}/grant/kol?t=${token}`, '_self');
    } else if (platform === PLATFORM.hcb) {
      history.push('/no-web-access');
      removeLocalToken();
      return;
    }
    // Set User Profile
    const user = await getProfile();
    await dispatch(onLogin(user));
    // Set Aut State 2 - is login state (Account)
    dispatch(onAuthState(2));
    history.push(forwardUrl);
  } catch (err) {
    // Set Aut State 0 - is logout state (Activation)
    dispatch(onAuthState(0));
    // Set logout
    clearForLogout(dispatch);
  }
};

export const logout = () => async dispatch => {
  try {
    await Api.post('/auth/logout');
    clearPermissions();
    await setVisitorState(dispatch);
  } catch (err) {
    clearForLogout(dispatch);
  }
};

export const isAuthInitialState = null;
export const accountInitialState = null;

export const isAuthReducer = createReducer(isAuthInitialState, isAuthHandlers);
export const accountReducer = createReducer(accountInitialState, accountHandlers);
