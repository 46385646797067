/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { getDefaultType } from '../../../configs';
import CanvasView from '../../CanvasView';
import ControlArea from '../../ControlArea';
import { answerTools } from '../configs';
import step1Icon from 'assets/answers/step1.png';
import step2Icon from 'assets/answers/step2.png';
import step3Icon from 'assets/answers/step3.png';

const mock = [step1Icon, step2Icon, step3Icon];

const AnswerViewer = ({ data, mergedSteps, activeStep, setActiveStep }) => {
  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(!!document.fullscreenElement);

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  useEffect(() => {
    fullScreenChange();
    //eslint-disable-next-line
  }, []);

  if (!mergedSteps || !mergedSteps[activeStep]) {
    return (
      <div className='text-white p-5 text-center w-100'>
        No answers frame provided for this case
      </div>
    );
  }

  const activeStepData = mergedSteps[activeStep];

  const activeStepIsImage = activeStepData && activeStepData.stepType === 'image';
  const stepViews = mergedSteps[activeStep].views;
  const stepViewsData = Object.keys(stepViews);
  const viewsCount = Object.values(stepViews).length;

  const viewSizesForced = () => {
    return {
      width: (window.innerWidth - (fullScreen ? 548 : 240 + 530)) / viewsCount,
      height: window.innerHeight - 370,
    };
  };

  const getImage = index => {
    try {
      const step = mergedSteps[index];
      const { key, dataType, imageIndex = 0 } = step.views[Object.keys(step.views)[0]];
      const dicomTypeData = {
        tomo: data[`${key}_TOMO`],
        '2D': data[`${key}_2D`],
        '3DQ': data[`${key}_3DQ`],
      };

      const control = getDefaultType(dataType, dicomTypeData);
      const dicomData = dicomTypeData[control];
      return step.stepType === 'image' ? step.views.image : dicomData.images[imageIndex].url;
    } catch (err) {
      return mock[index];
    }
  };

  return (
    <div className='flex-fill'>
      <div className='viewer d-flex flex-column flex-fill pr-3'>
        <ControlArea
          fullScreen={fullScreen}
          activeTool={activeTool}
          setActiveTool={tool => setActiveTool(tool)}
          tools={answerTools}
          disabled={activeStepIsImage}
        />
        <div className={`viewer-area d-flex ${fullScreen ? 'fullscreen' : ''}`}>
          {stepViewsData.map((key, index) => {
            const item = stepViews[key];
            if (activeStepIsImage) {
              return (
                <div
                  key={item.key + index}
                  style={{ height: viewSizesForced().height }}
                  className='text-center'
                >
                  <img src={item} alt='img' style={{ maxHeight: '100%' }} />;
                </div>
              );
            }
            return (
              <CanvasView
                key={item.key + index}
                dataTomo={data[`${item.key}_TOMO`]}
                data2D={data[`${item.key}_2D`]}
                data3DQ={data[`${item.key}_3DQ`]}
                step={item}
                activeTool={activeTool}
                setActiveTool={tool => setActiveTool(tool)}
                fullScreen={fullScreen}
                viewsCount={viewsCount}
                viewSizesForced={viewSizesForced}
                currentImageIndex={item.imageIndex}
              />
            );
          })}
        </div>
        <div className='answer-viewer-steps'>
          <div className='d-flex image-list justify-content-between'>
            {[0, 1, 2].map(index => {
              return (
                <div
                  key={`${index}`}
                  onClick={() => setActiveStep(index)}
                  className={`pointer flex-fill ${activeStep === index ? 'active' : ''}`}
                >
                  <img src={getImage(index)} alt='img' role='presentation' />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerViewer;
