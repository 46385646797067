import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import LeftLayout from 'app/Product/components/LeftLayout';
import Button from 'shared/components/Button';
import RightLayout from 'app/Product/components/RightLayout';
import useQuery from 'shared/hooks/useQuery';
import { TOKEN_RESPONSE_CODES } from 'app/Auth/routes/Register/configs';

const ResetPassword = ({ match, history, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { urlPath } = match.params;
  const queryParams = useQuery();
  const { description } = location?.state || {};

  const { entity, spec = '' } = queryParams;

  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [email, setEmail] = useState('');
  const [isCodeExpired, setIsCodeExpired] = useState(false);

  const leftLayoutDetails = {
    entity: entity,
    entityForwardPageKey: 'reset-password',
    description:
      description ||
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever ",
  };

  const rightLayoutDetails = {
    footerForwardUrl: `/product/${urlPath}/register?entity=${entity}&spec=${spec}`,
    question: 'Don’t have an account yet?',
    buttonText: 'Sign Up',
  };

  const handleChange = ({ target }) => setEmail(target.value);

  const handleSubmit = async () => {
    try {
      setFetch(true);

      await Api.post('/auth/forgot/', {
        email,
      });
      history.push({
        pathname: `/product/${urlPath}/verify`,
        search: `?entity=${entity}&spec=${spec}`,
        state: { resetUserEmail: email },
      });
    } catch (err) {
      if (err.response.data.data === TOKEN_RESPONSE_CODES.expiredConfirmationCode) {
        setIsCodeExpired(true);
      } else {
        setError(getError(err));
      }
    }
    setFetch(false);
  };

  return (
    <div className='product-sign-container d-flex flex-column min-vh-100'>
      <div className='flex-1 d-flex'>
        <LeftLayout {...leftLayoutDetails} />
        <RightLayout {...rightLayoutDetails}>
          <div className='form-block'>
            <div className='form-title-block'>
              <h5 className='desc-title'>Verify Your Email</h5>
              <p className='desc-subtitle'>Please enter the verification code sent to your email</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='d-flex flex-column'>
                <InputGroup
                  type='email'
                  name='email'
                  onChange={handleChange}
                  className='focus-bottom-border input-item'
                  placeholder='Email*'
                  value={email}
                  required
                />
                {error && <div className='error-text'>{error}</div>}
                {isCodeExpired && <div className='error-text'>The code is expired.</div>}
              </div>
              <div className='form-btns-container'>
                <Button
                  onClick={() => history.goBack()}
                  className='btn-outline-blue form-button w-100'
                >
                  Back
                </Button>
                <Button
                  onClick={handleSubmit}
                  className='btn-blue-dark-redesigned form-button w-100'
                  type='submit'
                  disabled={!email || fetch}
                  disabledClassName='btn-disabled-gray'
                >
                  Send
                </Button>
              </div>
            </form>
          </div>
        </RightLayout>
      </div>
    </div>
  );
};

export default ResetPassword;
