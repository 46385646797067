import React, { useState, useEffect } from 'react';
import Button from 'shared/components/Button';
import InputGroup from 'shared/components/InputGroup';
import Select from 'shared/components/Select';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { specialty, countries, degrees } from 'app/Auth/routes/Register/configs';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { getAndUpdateProfile } from 'app/Auth/actions';

const Settings = ({ data, getAndUpdateProfile, isEventUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const profile = data.learnerProfile || {};
  const [fetch, setFecth] = useState(false);
  const [isEdit, toggleEdit] = useState(false);
  const [info, setInfo] = useState({
    firstName: data.firstName || '',
    lastName: data.lastName || '',
    email: data.email || '',
    imageUrl: data.imageUrl || '',
    countryId: data.countryId || '',
  });

  const [learner, setLearner] = useState({
    speciality: profile.speciality || '',
    phone: profile.phone || '',
    city: profile.city || '',
    state: profile.state || '',
    address: profile.address || '',
    zipCode: profile.zipCode || '',
    prefferedNameOnCertificate: profile.prefferedNameOnCertificate || '',
    institution: profile.institution || '',
    arrtNumber: profile.arrtNumber || '',
    degree: profile.degree || '',
  });

  const handleInfoChange = ({ target }) => {
    setInfo({ ...info, [target.name]: target.value });
  };

  const handleLearnerChange = ({ target }) => {
    setLearner({ ...learner, [target.name]: target.value });
  };

  const onSubmitHandle = async () => {
    try {
      setFecth(true);
      const body = { userId: data.id, ...info, learnerProfile: learner };
      await Api.post('/auth/edituser', body);
      await getAndUpdateProfile();
      enqueueSnackbar('Successfully updated', { variant: 'success' });
      toggleEdit(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFecth(false);
    }
  };

  const findFromList = (id, list) => {
    const item = list.find(v => v.id === Number(id));
    return item ? item.name : '-';
  };

  useEffect(() => {
    getAndUpdateProfile();
  }, [getAndUpdateProfile]);

  return (
    <>
      <ul className='list-default list-settings'>
        <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
          <div className='col-4 weight-900'>Name:</div>
          <div className='col weight-100'>
            {isEdit ? (
              <InputGroup
                type='text'
                name='firstName'
                value={info.firstName}
                onChange={handleInfoChange}
              />
            ) : (
              data.firstName || '-'
            )}
          </div>
        </li>
        <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
          <div className='col-4 weight-900'>Surname:</div>
          <div className='col weight-100'>
            {isEdit ? (
              <InputGroup
                type='text'
                name='lastName'
                value={info.lastName}
                onChange={handleInfoChange}
              />
            ) : (
              data.lastName || '-'
            )}
          </div>
        </li>
        {!isEventUser && (
          <>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>Profession:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <Select
                    name='speciality'
                    className='form-control'
                    value={learner.speciality}
                    items={specialty}
                    onChange={handleLearnerChange}
                    required
                  />
                ) : (
                  profile.speciality || '-'
                )}
              </div>
            </li>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>Degree:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <Select
                    name='degree'
                    className='form-control'
                    value={learner.degree}
                    items={degrees}
                    onChange={handleLearnerChange}
                    required
                  />
                ) : (
                  profile.degree || '-'
                )}
              </div>
            </li>
          </>
        )}
        <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
          <div className='col-4 weight-900'>Email:</div>
          <div className='col weight-100'>
            {isEdit ? (
              <InputGroup
                type='email'
                name='email'
                value={info.email}
                onChange={handleInfoChange}
                disabled
              />
            ) : (
              data.email || '-'
            )}
          </div>
        </li>
        {!isEventUser && (
          <>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>Phone:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <InputGroup
                    type='text'
                    name='phone'
                    value={learner.phone}
                    onChange={handleLearnerChange}
                  />
                ) : (
                  profile.phone || '-'
                )}
              </div>
            </li>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>Address:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <InputGroup
                    type='text'
                    name='address'
                    value={learner.address}
                    onChange={handleLearnerChange}
                  />
                ) : (
                  profile.address || '-'
                )}
              </div>
            </li>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>City:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <InputGroup
                    type='text'
                    name='city'
                    value={learner.city}
                    onChange={handleLearnerChange}
                  />
                ) : (
                  profile.city || '-'
                )}
              </div>
            </li>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>State/Province:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <InputGroup
                    type='text'
                    name='state'
                    value={learner.state}
                    onChange={handleLearnerChange}
                  />
                ) : (
                  profile.state || '-'
                )}
              </div>
            </li>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>Country:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <Select
                    name='countryId'
                    className='form-control'
                    value={info.countryId}
                    items={countries}
                    onChange={handleInfoChange}
                    required
                  />
                ) : (
                  findFromList(info.countryId, countries)
                )}
              </div>
            </li>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>ZIP Code:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <InputGroup
                    type='number'
                    name='zipCode'
                    value={learner.zipCode}
                    onChange={handleLearnerChange}
                  />
                ) : (
                  profile.zipCode || '-'
                )}
              </div>
            </li>
            <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
              <div className='col-4 weight-900'>Name on Certificate:</div>
              <div className='col weight-100'>
                {isEdit ? (
                  <InputGroup
                    type='text'
                    name='prefferedNameOnCertificate'
                    value={learner.prefferedNameOnCertificate}
                    onChange={handleLearnerChange}
                  />
                ) : (
                  profile.prefferedNameOnCertificate || '-'
                )}
              </div>
            </li>
          </>
        )}
        <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
          <div className='col-4 weight-900'>Hospital/Clinic:</div>
          <div className='col weight-100'>
            {isEdit ? (
              <InputGroup
                type='text'
                name='institution'
                value={learner.institution}
                onChange={handleLearnerChange}
              />
            ) : (
              profile.institution || '-'
            )}
          </div>
        </li>
        <li className={`d-flex align-items-center px-0 p-2 ${isEdit ? 'is-edit' : ''}`}>
          <div className='col-4 weight-900'>Technologist ARRT</div>
          <div className='col weight-100'>
            {isEdit ? (
              <InputGroup
                type='text'
                name='arrtNumber'
                value={learner.arrtNumber}
                onChange={handleLearnerChange}
              />
            ) : (
              profile.arrtNumber || '-'
            )}
          </div>
        </li>
      </ul>
      <div className='text-right mt-2'>
        {isEdit ? (
          <>
            <Button disabled={fetch} className='btn-blue mr-3' onClick={() => toggleEdit(false)}>
              Cancel
            </Button>
            <Button disabled={fetch} className='btn-blue' onClick={onSubmitHandle}>
              Save
            </Button>
          </>
        ) : (
          <Button className='btn-blue' onClick={() => toggleEdit(true)}>
            Edit
          </Button>
        )}
      </div>
    </>
  );
};

export default connect(
  null,
  { getAndUpdateProfile },
)(Settings);
