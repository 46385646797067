/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import ButtonLine from 'shared/components/ButtonLine';
import { IconInfo } from 'shared/components/Icons';
import { onOpenExternalUrl } from 'utils/appHelpers';

const InfoStep = ({ onNextClick }) => {
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [checkConfirm2, setCheckConfirm2] = useState(false);
  return (
    <div className='reg-block d-flex flex-column justify-content-between mt-5'>
      <div className='head-part d-flex align-items-center px-4'>
        <IconInfo /> <p className='m-0 ml-3'>Important Information</p>
      </div>
      <div className='content-part px-4 py-3 flex-fill'>
        <div className='d-flex'>
          <div className='flex-1 pl-2'>
            <p className='stronger mt-2 mb-3'>
              Upon activation of your registration, you will receive an e-mail to confirm your new
              account. Please open that email and verify, before logging in for the first time.
            </p>
            <p className='stronger my-2'>Accessing the ACE Portal:</p>
            <ul className='m-0 pl-3'>
              <li>
                Hardware:
                <ul className='m-0 pl-3'>
                  <li>Standard laptop, desktop computer or iPad</li>
                  <li>Resolution of at least 1366×768</li>
                </ul>
              </li>
              <li>
                Internet Connection:
                <ul className='m-0 pl-3'>
                  <li>No firewall blocking ACE domain (hologicace.com)</li>
                  <li>Bandwidth 7 MBits/s connection</li>
                </ul>
              </li>
              <li>
                Browser:
                <ul className='m-0 pl-3'>
                  <li>
                    Google Chrome (min version <b>79.0.3945</b>)
                  </li>
                  <li>
                    Mozilla Firefox (min version <b>78.0</b>)
                  </li>
                  <li>
                    Safari (Mac) (min version <b>13</b>)
                  </li>
                  <li>
                    Microsoft Edge (min version <b>79.0.309</b>)
                  </li>
                  <li>Internet Explorer is no longer supported</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='bottom-part reg-form-footer d-flex align-items-center justify-content-between'>
        <div className='d-flex flex-column'>
          <p className='checkbox-area d-flex mb-1 mt-1'>
            <input
              id='agreementCheckbox'
              type='checkbox'
              checked={checkConfirm}
              onChange={e => setCheckConfirm(e.target.checked)}
            />
            <label htmlFor='agreementCheckbox' className='ml-2 mb-0 font-weight-bold'>
              I agree to{' '}
              <a
                href='https://www.hologic.com/privacy-policy'
                onClick={onOpenExternalUrl.bind(null, 'https://www.hologic.com/privacy-policy')}
                target='_blank'
              >
                <u>User License Agreement</u>
              </a>
            </label>
          </p>
          <p className='checkbox-area d-flex m-0 mt-1'>
            <input
              id='agreementCheckbox2'
              type='checkbox'
              checked={checkConfirm2}
              onChange={e => setCheckConfirm2(e.target.checked)}
            />
            <label htmlFor='agreementCheckbox2' className='ml-2 mb-0 font-weight-bold'>
              I understand not all products or technologies featured are <br /> available in all
              markets.
            </label>
          </p>
        </div>
        <ButtonLine
          onClick={onNextClick}
          className='p-0'
          disabled={!checkConfirm || !checkConfirm2}
        >
          Next
        </ButtonLine>
      </div>
    </div>
  );
};

export default InfoStep;
