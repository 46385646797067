import React from 'react';
import Select from 'shared/components/Select';

const FeedSelect = ({ data, onChange, answers, isDisabled, isAnswered }) => {
  const items = data.feedbackItems.map(item => ({ name: item.label, id: item.label }));

  const handleChnage = e => {
    onChange({ id: data.id, value: e.target.value });
  };

  return (
    <li className='d-flex align-items-center'>
      <div className='col-6 pl-0'>
        <label className='m-0'>{data.question}</label>
      </div>
      <div className='input-group p-0'>
        {!isAnswered ? (
          <Select
            className='border-0 p-0'
            selectClassName='m-0'
            name='select'
            value={answers[data.id]}
            items={items}
            onChange={handleChnage}
            disabled={isDisabled}
            label='Select option'
            required
          />
        ) : (
          answers[data.id]
        )}
      </div>
    </li>
  );
};

export default FeedSelect;
