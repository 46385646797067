/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/umd/rangeslider.min.css';
import { IconAiPlay, IconPlayFill, IconSliceNext, IconSlicePrev } from 'shared/components/Icons';
import Loading from 'shared/components/Loading';
import pause from 'assets/pause.svg';
import speedIcon from 'assets/speed.svg';
import { getImagesPrePostSlices, getImagesSortedSlices, getPrimarySlices } from './PrePostSlices';
import debounce from 'lodash.debounce';

const stackWords = {
  CC: { top: 'H', bottom: 'F' },
  MLO: { top: 'M', bottom: 'L' },
};

const speeds = [
  { name: 'Low', value: 160 },
  { name: 'Medium', value: 80 },
  { name: 'High', value: 40 },
];

const modes = [
  { name: 'Genius AI Mode', value: 'genius' },
  { name: 'Normal Mode', value: 'normal' },
];

const StackControl = ({
  cornerstoneTools,
  element,
  cornerstone,
  imageType,
  setActiveTool,
  className,
  images,
  play,
  setPlay,
  isGenuine,
  defaultSliceIndex = 0,
  isGaid2View,
  slicesDifference,
  mainSliceIndex,
  setMainSliceIndex = () => {},
  sideSliceIndex,
  setSideSliceIndex = () => {},
  isPrimaryDicom,
  initGenuines,
  stackControl,
  setOverlayViewsLoading,
  index,
  overlayData,
  onImagesLoaded = () => {},
}) => {
  const stackEl = useRef();
  const [speed, setSpeed] = useState(80);
  const [mode, setMode] = useState(isGaid2View ? 'genius' : 'normal');
  const [fetch, setFetch] = useState(false);
  const isAIMode = mode === 'genius';

  const playIndex = useRef({ index: 0, increase: true, images: undefined });
  const scale = useRef(0);
  const playInterval = useRef();
  const lastInitializedSlice = useRef(null); // Track the last slice that was initialized with Genuines
  const debouncedInitGenuines = useRef(
    debounce(() => {
      if (lastInitializedSlice.current !== playIndex.current.index) {
        initGenuines();
        lastInitializedSlice.current = playIndex.current.index;
      }
    }, 200),
  ).current;

  const primarySlices = isAIMode ? getPrimarySlices(images) : [];
  const allPrePostSlices = isAIMode ? getImagesPrePostSlices(images) : [];
  const allSortedSlices = isAIMode ? getImagesSortedSlices(images) : [];
  const hasNextSlice = primarySlices.indexOf(playIndex.current.index) < primarySlices.length - 1;
  const hasPrevSlice = primarySlices.indexOf(playIndex.current.index) !== 0;

  const getStackData = () => {
    const stackData = cornerstoneTools.getToolState(element, 'stack');
    if (stackData) {
      const { currentImageIdIndex, imageIds } = stackData.data[0];
      return { currentImageIdIndex, imageIds };
    } else {
      return {};
    }
  };

  const { imageIds } = getStackData();

  const changeHandle = index => {
    if (fetch || play) return;
    if (!playIndex.current.images) {
      if (!isGaid2View) {
        loadAllImages();
      }
      return;
    }

    playIndex.current.index = index;
    scale.current = index;

    let displayIndex = index;
    if (isGaid2View) {
      // Calculate displayIndex for UI purposes only when isGaid2View is true
      const maxIndex = images.length - 1;
      displayIndex = index < 0 ? 0 : index > maxIndex ? maxIndex : index;
    }

    changeStackImage(playIndex.current.images, displayIndex);

    // Sync slices based on the current view
    if (isPrimaryDicom) {
      if (mainSliceIndex !== index && index !== -1) {
        setMainSliceIndex(index);
        // Update side slice only if it's not already in sync
        if (sideSliceIndex !== index - slicesDifference) {
          setSideSliceIndex(index - slicesDifference);
        }
      }
    } else {
      if (sideSliceIndex !== index) {
        setSideSliceIndex(index);
        // Update main slice only if it's not already in sync
        if (mainSliceIndex !== index + slicesDifference) {
          setMainSliceIndex(index + slicesDifference);
        }
      }
    }
  };

  const changeStackImage = (images, index) => {
    let displayIndex = index;
    if (isGaid2View) {
      const maxIndex = images.length - 1;
      displayIndex = index < 0 ? 0 : index > maxIndex ? maxIndex : index;
    }

    if (images[displayIndex] !== undefined) {
      const viewport = cornerstone.getViewport(element);
      const stackData = cornerstoneTools.getToolState(element, 'stack');
      stackData.data[0].currentImageIdIndex = displayIndex;
      cornerstone.displayImage(element, images[displayIndex], viewport);

      // Reinitialize and apply the GenuineTool state
      debouncedInitGenuines();
    }
  };

  const playStack = images => {
    try {
      let { index, increase } = playIndex.current;
      index += increase ? 1 : -1;
      if (!imageIds[index]) {
        increase = !increase;
        index += increase ? 2 : -2;
      }
      changeStackImage(images, index);
      playIndex.current.index = index;
      playIndex.current.increase = increase;
    } catch (err) {
      console.log(err);
    }
  };

  const playStackSlices = images => {
    try {
      let { index, increase } = playIndex.current;
      const activeSlice = allSortedSlices.findIndex(i => i.includes(index));
      const nearPrimary = activeSlice !== -1 ? allSortedSlices[activeSlice] : allSortedSlices[0];
      const sliceImages = [...(nearPrimary || [])].sort((a, b) => a - b);
      const currentIndex = sliceImages.indexOf(index);
      index = sliceImages[currentIndex === -1 ? 0 : currentIndex + (increase ? 1 : -1)];
      if (!imageIds[index]) {
        increase = !increase;
        index = sliceImages[currentIndex === -1 ? 0 : currentIndex + (increase ? 1 : -1)];
      }
      // Update both main and side slices
      if (isPrimaryDicom) {
        setMainSliceIndex(index);
        setSideSliceIndex(index - slicesDifference);
      } else {
        setSideSliceIndex(index);
        setMainSliceIndex(index + slicesDifference);
      }

      changeStackImage(images, index);
      playIndex.current.index = index;
      playIndex.current.increase = increase;
    } catch (err) {
      console.log(err);
    }
  };

  const setPlayInterval = (speed, changedToAIMode) => {
    if (changedToAIMode || isAIMode) {
      playInterval.current = setInterval(playStackSlices, speed, playIndex.current.images);
    } else {
      playInterval.current = setInterval(playStack, speed, playIndex.current.images);
    }
  };

  const loadAllImages = async play => {
    setFetch(true);
    if (!playIndex.current.images) {
      const { imageIds } = getStackData();
      const imageLoads = imageIds.map(async item => await cornerstone.loadAndCacheImage(item));
      const images = await Promise.all(imageLoads);
      playIndex.current.images = images;
    }
    setFetch(false);

    // Initialize the slices only after images are loaded
    if (isGaid2View) {
      if (isPrimaryDicom) {
        setMainSliceIndex(defaultSliceIndex);
        setSideSliceIndex(defaultSliceIndex - slicesDifference);
      } else {
        setSideSliceIndex(defaultSliceIndex);
        setMainSliceIndex(defaultSliceIndex + slicesDifference);
      }
    }

    if (play) setPlayInterval(speed);
  };

  const loadNewImages = async () => {
    const stackData = cornerstoneTools.getToolState(element, 'stack');
    let { imageIds } = stackData ? stackData.data[0] : {};

    // If imageIds are undefined or not updated, reinitialize them
    if (!imageIds || imageIds.length === 0 || imageIds !== images.map(img => img.url)) {
      imageIds = images.map(img => img.url); // Get fresh image URLs
      cornerstoneTools.addToolState(element, 'stack', { imageIds, currentImageIdIndex: 0 }); // Update the stack state with new images
      playIndex.current = { index: 0, increase: true, images: undefined }; // Reset playIndex for fresh images
    }

    setOverlayViewsLoading(prev => ({ ...prev, [index]: true }));

    const newImages = []; // Temporary storage for new images

    try {
      for (let i = 0; i < imageIds.length; i++) {
        const image = await cornerstone.loadAndCacheImage(imageIds[i]); // Wait to load and cache each image
        newImages.push(image);
      }

      playIndex.current.images = newImages;
      onImagesLoaded(true); // Notify parent component that images are fully loaded
    } catch (error) {
      console.error('Error loading images:', error);
    } finally {
      setOverlayViewsLoading(prev => ({ ...prev, [index]: false }));
    }
  };

  useEffect(() => {
    if (isGaid2View) {
      loadNewImages();
    }

    return () => {
      playIndex.current = { index: 0, increase: true, images: undefined };
      scale.current = 0;
    };
  }, [imageIds, stackControl, isGaid2View, index]);

  useEffect(() => {
    if (isGaid2View) {
      const initializeStack = async () => {
        await loadAllImages();
        changeHandle(defaultSliceIndex);
      };

      initializeStack();
    }
  }, [defaultSliceIndex, isGaid2View, isPrimaryDicom]);

  const onElementScroll = e => {
    e.preventDefault();

    if (!playIndex.current.images) {
      if (fetch || play) return;
      loadAllImages();
      return;
    }

    const { imageIds } = getStackData();
    scale.current += e.deltaY * -0.01;
    scale.current = Math.min(Math.max(0, scale.current), imageIds.length);

    if (playIndex.current.index !== Math.floor(scale.current)) {
      changeHandle(Math.floor(scale.current));
    }
  };

  const onChangeStart = () => {
    // setActiveTool({ type: 'remove' });
  };

  const handleUserKeyPress = event => {
    const { keyCode } = event;
    if (keyCode === 32)
      setPlay(p => {
        return !p;
      });
  };

  const stopPlayerUnmount = () => {
    window.removeEventListener('keydown', handleUserKeyPress);
    setPlay(false);
    clearInterval(playInterval.current);
    playIndex.current = { index: 0, increase: true, images: undefined };
  };

  const onSpeedChange = async value => {
    setSpeed(value);
    if (play) {
      clearInterval(playInterval.current);
      setPlayInterval(value);
    }
  };

  const onPlayerModeChanges = value => {
    setMode(value);
    if (play) {
      clearInterval(playInterval.current);
      setPlayInterval(speed, value === 'genius');
    }
  };

  const renderLines = length => {
    const lines = [];
    for (let index = length - 1; index >= 0; index--) {
      const hasGenuine = images?.[index]?.geniusAIDataList?.length > 0;
      const hasPrimarySlice =
        hasGenuine || (hasGenuine && (!!images?.[index]?.postSlices || images?.[index]?.preSlices));
      const hasPrePostSlice = !hasPrimarySlice && allPrePostSlices.includes(index);

      // THIS USED WHEN MAIN SLICE - SLICES DIFFERENCE IS OUT OF RANGE OF SIDE SLICE TO DISPLAY MAX OR 0 BUT KEEP CONFIGS SAME
      const displayIndex = isGaid2View
        ? index < 0
          ? 0
          : index >= length
          ? length - 1
          : index
        : index;

      lines.push(
        <span
          key={displayIndex}
          className={`line ${hasGenuine ? 'has-point' : ''} ${
            hasPrimarySlice ? 'has-primary-slice' : ''
          } ${hasPrePostSlice ? 'has-prepost-slice' : ''}`}
        />,
      );
    }
    return lines;
  };

  const onMovePrimarySlice = isNext => {
    setPlay(false);
    // Get the current slice index
    const currentIndex = playIndex.current.index;
    if (isNext) {
      // Find the nearest next primary slice
      const nextPrimarySlice = primarySlices.find(slice => slice > currentIndex);
      if (nextPrimarySlice !== undefined) {
        changeHandle(nextPrimarySlice);
      }
    } else {
      // Find the nearest previous primary slice
      const prevPrimarySlices = primarySlices.filter(slice => slice < currentIndex);
      const prevPrimarySlice = prevPrimarySlices[prevPrimarySlices.length - 1]; // Get the last one (closest previous)

      if (prevPrimarySlice !== undefined) {
        changeHandle(prevPrimarySlice);
      }
    }
  };

  useEffect(() => {
    if (isGaid2View) {
      if (isPrimaryDicom) {
        changeHandle(mainSliceIndex);
      } else {
        changeHandle(sideSliceIndex);
      }
    }
  }, [isGaid2View, isPrimaryDicom, mainSliceIndex, sideSliceIndex]);

  useEffect(() => {
    playIndex.current = { index: 0, increase: true, images: undefined };
    scale.current = 0;
    //eslint-disable-next-line
  }, [stackControl]);

  useEffect(() => {
    if (play) {
      element.onwheel = null;
      loadAllImages(true);
    } else {
      scale.current = playIndex.current.index;
      element.onwheel = onElementScroll;
      clearInterval(playInterval.current);
    }
    //eslint-disable-next-line
  }, [play]);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      stopPlayerUnmount();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      images.map(item => cornerstone.loadAndCacheImage(item.url));
    }, 100);
    stopPlayerUnmount();
    //eslint-disable-next-line
  }, []);

  if (!imageIds?.length) return <Loading />;

  return (
    <>
      <div
        className={`stack-control ${fetch ? 'is-loading' : ''} ${
          isAIMode ? 'genius-ai-mode' : ''
        } ${className || ''}`}
        ref={stackEl}
      >
        <div className='stack-lines d-flex flex-column justify-content-around'>
          {imageIds?.length && renderLines(imageIds.length)}
        </div>
        <div className='settings-btn'>
          <img src={speedIcon} alt='speed' className='speed-icon' />
          <div className='settings-options d-flex align-items-center'>
            {speeds.map(item => (
              <button
                key={item.value}
                className={`btn btn-sm btn-block ${speed === item.value ? 'active' : ''}`}
                onClick={() => onSpeedChange(item.value)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <div className='settings-btn'>
          <button
            className='btn play-btn pb-1'
            disabled={fetch}
            onClick={e => {
              setPlay(p => !p);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {fetch ? (
              <Loading className={' '} classSpinner='spinner-border-sm text-white' />
            ) : play ? (
              <img className='mr-1' src={pause} alt='pause' width='15' />
            ) : (
              <IconPlayFill color='#fff' />
            )}
          </button>
          {!play && isGenuine && (
            <div className='settings-options pt-0 d-flex align-items-center'>
              {modes.map(item => (
                <button
                  key={item.value}
                  className={`btn btn-sm btn-block text-nowrap ${
                    mode === item.value ? 'active' : ''
                  }`}
                  onClick={() => onPlayerModeChanges(item.value)}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className='stack-word'>{stackWords[imageType].top}</div>
        <Slider
          min={0}
          max={imageIds.length - 1}
          value={
            isGaid2View
              ? Math.min(Math.max(getStackData().currentImageIdIndex, 0), imageIds.length - 1)
              : Number(getStackData().currentImageIdIndex)
          }
          onChange={changeHandle}
          onChangeStart={onChangeStart}
          orientation='vertical'
          tooltip={false}
          handleLabel={`${
            isGaid2View
              ? Math.min(Math.max(getStackData().currentImageIdIndex, 0), imageIds.length - 1) + 1
              : getStackData().currentImageIdIndex + 1
          }`}
        />
        <div className='stack-word'>{stackWords[imageType].bottom}</div>
        <div className='stack-num'>
          {Number(getStackData().currentImageIdIndex) + 1}/{imageIds.length}
        </div>
        {isAIMode && (
          <div className='d-flex flex-column stack-movie-buttons'>
            <button
              className='btn stack-next'
              disabled={!primarySlices.length || !hasNextSlice || fetch}
              onClick={onMovePrimarySlice.bind(null, true)}
            >
              <IconSliceNext />
            </button>
            <button
              className='btn stack-prev'
              disabled={!primarySlices.length || !hasPrevSlice || fetch}
              onClick={onMovePrimarySlice.bind(null, false)}
            >
              <IconSlicePrev />
            </button>
          </div>
        )}
        <div className='stack-tooltip'>Buffering in Process</div>
      </div>
      {play && isAIMode && (
        <div className='align-items-center d-flex playing-mode-alert text-nowrap'>
          <IconAiPlay /> <span className='ml-2'>Genius AI Mode</span>
        </div>
      )}
    </>
  );
};

export default StackControl;
