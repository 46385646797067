/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconArrowRight } from 'shared/components/Icons';
import { courseSubsStatuses, getEndOfSubs, getEventUrl } from 'utils/appHelpers';
import ProgressBar from 'shared/components/ProgressBar';
import { hasAccess } from 'utils/permissionHelper';
import { useSelector } from 'react-redux';

const SubscriptionStatus = ({ subscriptions }) => {
  const history = useHistory();
  const courses = useSelector(state => state.courses);
  const all = courses.filter(item => courseSubsStatuses(item.status, 'active'));
  const path = hasAccess('event_user') ? getEventUrl() : '';

  const goToProgress = (id, e) => {
    e.preventDefault();
    history.push(`${path}/courses/progress/${id}`, { from: 'account' });
  };

  return (
    <div className='blue-box px-1 mb-3 d-flex flex-column justify-content-between'>
      <div className='d-flex'>
        <div className='col-3'>
          <p>Course:</p>
        </div>
        <div className='col-3 p-0'>
          <p>Expiration:</p>
        </div>
        <div className='col-4 text-center'>
          <p>Progress:</p>
        </div>
        <div className='col-2' />
      </div>
      {all.map(item => {
        return (
          <React.Fragment key={item.id}>
            <div className='d-flex mb-2'>
              <div className='col-3'>
                <span className='info d-block'>{item.name}</span>
              </div>
              <div className='col-3 p-0'>
                <span className='info d-block'>{getEndOfSubs(item.endDate, item.expirable)}</span>
              </div>
              <div className='col-4'>
                {!item.isOpened ? (
                  <p className='weight-100 m-0 d-flex align-items-center'>
                    <span className='mr-2 col-2 p-0'>{item.progress}%</span>
                    <ProgressBar progress={item.progress || 0} />
                  </p>
                ) : (
                  <p className='text-center'>
                    <span className='info'>No Progress Available</span>
                  </p>
                )}
              </div>
              <div className='col-2 small text-right'>
                <a hreg='#' className='pointer link' onClick={goToProgress.bind(null, item.id)}>
                  More Info <IconArrowRight />
                </a>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SubscriptionStatus;
