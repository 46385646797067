/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Button from 'shared/components/Button';
import { IconSearch } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

const Entities = ({ form, setForm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [entities, setEntities] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [forwardUrl, setForwardUrl] = useState('');

  const filteredEntities =
    entities?.length &&
    entities.filter(entity => entity.name.toLowerCase().includes(search.toLowerCase()));

  const addOrUpdateParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    return url.pathname + url.search;
  };

  const handleSelectEntity = entity => {
    setForm({ ...form, entity });
    setSelectedEntity(entity);
    setForwardUrl(addOrUpdateParam('entity', entity.name));
  };

  const getEntities = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/common/entities`, { params });
      setEntities(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getEntities();
  }, []);

  return (
    <div className='entities-section'>
      <h6 className='title'>Select Your Company</h6>
      <div className='content-block'>
        <span className='regular-txt'>Select entity</span>
        <div className='search-block entity-search-block w-100'>
          <input
            type='text'
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            className='form-control has-icon-left w-100'
            placeholder='Search'
          />
          <IconSearch className='left-icon' color='#91939A' />
        </div>
        <div className='entity-items-block'>
          {filteredEntities?.length ? (
            filteredEntities.map((entity, idx) => {
              return (
                <div
                  onClick={() => handleSelectEntity(entity)}
                  key={idx}
                  className={`entity-item ${form?.entity?.id === entity?.id && 'active'}`}
                >
                  {entity.imageUrl ? (
                    <img className='banner' alt={entity.name} src={entity.imageUrl} />
                  ) : (
                    <div className='no-img-banner'>
                      <h6 className='first-letter'>{entity?.name?.[0]}</h6>
                      <p className='name'>{entity.name}</p>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className='no-result-block'>
              <p>No Result</p>
            </div>
          )}
        </div>
      </div>
      <Link to={forwardUrl}>
        <Button
          className='btn-blue-dark-redesigned form-button'
          disabled={!selectedEntity}
          disabledClassName='btn-disabled-gray'
        >
          Next
        </Button>
      </Link>
    </div>
  );
};

export default Entities;
