import React from 'react';
import CaseLeftIcon from 'assets/arrows/caseLeft.svg';
import CaseRightIcon from 'assets/arrows/caseRight.svg';

const Switcher = ({ name, previews, next, disabled, current, allCount, highlightNext }) => {
  return (
    <div className='switcher d-flex align-items-center'>
      <button className='btn case-btn' onClick={previews} disabled={current - 1 < 0 || disabled}>
        <img src={CaseLeftIcon} alt='icon' className='sw-icon' />
      </button>
      <p className='text-nowrap'>
        {name} <span className='text-light-blue'>{current + 1}</span> of {allCount}
      </p>
      <button
        className={`btn case-btn ${highlightNext ? 'highlighted' : ''}`}
        onClick={next}
        disabled={current + 2 > allCount || disabled}
      >
        <img src={CaseRightIcon} alt='icon' className='sw-icon' />
      </button>
    </div>
  );
};

export default Switcher;
