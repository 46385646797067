import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PageTitle from 'shared/components/PageTitle';

const initialPermission = {
  public_view: true,
};

export let userPermissions = {
  ...initialPermission,
};

// const types = {
//   0: 'Admin',
//   1: 'Learner',
//   2: 'Doctor',
//   3: 'User',
//   4: 'DemoLearner',
//   5: 'KOL',
//   6: 'EventVisitor',
// };

export const setUserAppPermissions = (type, roles) => {
  userPermissions = {};
  const hasKOL = roles.find(role => role.title === 'KOL');
  const hasAdminAccess = roles.find(role =>
    role.rolePermissions.some(perm => perm.permissionItem.name.includes('Admin')),
  );
  if (hasKOL) userPermissions['kol_user'] = true;
  if (hasAdminAccess) userPermissions['admin_access'] = true;

  const isVisitorUser = roles.find(role => role.title === 'Visitor');
  if (isVisitorUser) {
    userPermissions['visitor_user'] = true;
  }

  const hasEventUser = roles.find(role => role.title === 'Event Visitor');
  if (hasEventUser) {
    userPermissions['event_user'] = true;
  } else {
    userPermissions['vip_user'] = true;
  }
};

export const hasAccess = name => {
  const isEventsView = window.location.pathname.includes('events/');
  if (name === 'event_user') return isEventsView;
  if (name === 'vip_user') return !isEventsView;
  return !!userPermissions[name];
};

export const hasAccessAny = permissionsArray => {
  let isAllowed = false;
  permissionsArray.forEach(access => {
    if (!isAllowed) {
      isAllowed = hasAccess(access);
    }
  });
  return isAllowed;
};

export const PRoute = props => {
  if (props.access && !hasAccess(props.access)) return <Redirect to='/' />;
  return <WithTitleRoute {...props} />;
};

export const clearPermissions = () => {
  userPermissions = {
    ...initialPermission,
  };
};

export const WithTitleRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <PageTitle match={props.match} />
          <Component {...props} />
        </>
      )}
    />
  );
};
