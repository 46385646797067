import React, { useEffect } from 'react';
import CertificateHeader from './components/CertificateHeader';
import certificate from 'assets/certificate.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAndUpdateProfile } from '../../../Auth/actions/index';

const UserCertificate = ({ history }) => {
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const data = account.userCertificates || [];

  useEffect(() => {
    dispatch(getAndUpdateProfile());
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <CertificateHeader history={history} />
      {!data.length && (
        <h4 className='text-center py-5 certificate-page'>
          Certificate will be available after completion of the course.
        </h4>
      )}
      {!!data.length && (
        <div className='p-3 d-flex'>
          {data.map(item => (
            <div key={item.courseId} className='p-2 certificate-item mr-3'>
              <div className='hover-item'>
                <a
                  className='down-icon'
                  href={item.certificateUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                  download='Certificate'
                >
                  Download
                </a>
                <span>{item.title}</span>
              </div>
              <img width='250' src={certificate} alt='certificate' />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserCertificate;
