import { LESSON_STATUSES } from 'configs/constants';
import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { IconCourseLock, IconPlay } from 'shared/components/Icons';
import { Api } from 'utils/connectors';

const SubMenuCourses = ({ courses, location, path }) => {
  const [activeCourse, setActiveCourse] = useState();
  const openCourses = courses?.filter(item => item.status === LESSON_STATUSES.inProgress);

  const isNavActive = (id, match) => {
    if (!match) return false;
    setActiveCourse(id);
    return true;
  };

  const handleLinkClick = (active, e) => {
    if (!active) e.preventDefault();
  };

  const getFirtsLessonPath = course => {
    if (course.status !== 1) return `${path}/allcourses/details/${course.id}`;
    const firstLesson = course.lessons[0];
    return `${path}/courses/${course.id}/${firstLesson.type}/${firstLesson.id}`;
  };

  useEffect(() => {
    const id = location.pathname.split('/')[2];
    setActiveCourse(Number(id));
  }, [location.pathname]);

  return (
    <ul className='sub-menu courses mt-2'>
      {openCourses &&
        openCourses.map((item, index) => {
          const coursePath = `${path}/courses/${item.id}`;
          const isCourseUnlocked = item.status !== LESSON_STATUSES.notStarted;
          const isActive = item.id === activeCourse && isCourseUnlocked;
          const Icon = isCourseUnlocked ? IconPlay : IconCourseLock;
          const isAllLessonsPassed = item.lessons
            .slice(0, item.lessons.length - 2)
            .every(l => l.status === 1);
          return (
            <li key={index}>
              <NavLink
                onClick={() => Api.get(`/courses/check-to-enroll/${item.id}`)}
                className={!isCourseUnlocked && 'not-allowed'}
                to={getFirtsLessonPath(item)}
                isActive={isNavActive.bind(null, item.id)}
              >
                <Icon className='d-inline align-baseline mr-1' /> {item.name}
              </NavLink>
              {isActive && (
                <ul>
                  {item.lessons &&
                    item.lessons.map((lesson, index) => {
                      const isLessonUnlocked =
                        lesson.status === LESSON_STATUSES.inProgress ||
                        lesson.status === LESSON_STATUSES.completed;
                      return (
                        <li key={lesson.id + index}>
                          <NavLink
                            className={!isLessonUnlocked && 'not-allowed'}
                            to={`${coursePath}/${lesson.type}/${lesson.id}`}
                            activeClassName='active'
                            onClick={handleLinkClick.bind(null, isLessonUnlocked)}
                          >
                            {lesson.name}
                          </NavLink>
                        </li>
                      );
                    })}
                </ul>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default withRouter(SubMenuCourses);
