import React, { useState, useEffect, useRef } from 'react';
import { bindDuration } from 'utils/appHelpers';

export const CountDown = ({
  timeLimit,
  questionTimeLimit,
  question,
  pingToBackend,
  passedDuration,
  onTimeEnded,
  isModalOpen,
  isQuestionTimer,
  passedSecondsRef,
  passedSecondsOnQuestionRef,
}) => {
  const [leftSeconds, setLeftSeconds] = useState(timeLimit * 60 - passedDuration);
  const [leftSecondsOnQuestion, setLeftSecondsOnQuestion] = useState(
    questionTimeLimit * 60 - question.duration,
  );

  const timerIdRef = useRef(null);

  const startInterval = () => {
    timerIdRef.current = setInterval(async () => {
      if (!isQuestionTimer) {
        passedSecondsRef.current += 1;
        setLeftSeconds(prev => prev - 1);
        if (passedSecondsRef.current % 10 === 0) {
          pingToBackend({
            passedSeconds: passedSecondsRef.current,
            passedSecondsOnQuestion: passedSecondsOnQuestionRef.current,
          });
        }
      }

      if (questionTimeLimit && passedSecondsOnQuestionRef.current !== questionTimeLimit * 60) {
        if (isQuestionTimer) {
          passedSecondsOnQuestionRef.current += 1;
          setLeftSecondsOnQuestion(prev => prev - 1);
          if (passedSecondsOnQuestionRef.current % 10 === 0) {
            pingToBackend({
              passedSeconds: passedSecondsRef.current,
              passedSecondsOnQuestion: passedSecondsOnQuestionRef.current,
            });
          }
        }
      }
    }, 1000);
  };

  useEffect(() => {
    if (timeLimit && !isModalOpen) {
      startInterval();
    } else {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }

    return () => {
      clearInterval(timerIdRef.current);
    };
  }, [timeLimit, leftSeconds, onTimeEnded, pingToBackend, isModalOpen]);

  useEffect(() => {
    passedSecondsOnQuestionRef.current = 0;
  }, [question.id]);

  return (
    <>
      {!!timeLimit && !isQuestionTimer && (
        <div className='countdown'>{bindDuration(leftSeconds)}</div>
      )}
      {!!questionTimeLimit && isQuestionTimer && (
        <div className='countdown'>{bindDuration(leftSecondsOnQuestion)}</div>
      )}
    </>
  );
};
