import React, { useEffect, useState, memo } from 'react';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setDiagnosticData } from '../../actions';
import SpinnerLoading from './../SpinnerLoading';
import Feedback from './../Feedback';
import ViewResult from './ViewResult';
import { MAX_WAIT_TIME } from '../../constants';

const threshold = 3; // This is the minimal threshold to count MIN, MAX and AVG
const repeatLimit = 2;
const limit = threshold + repeatLimit;

// Latency
const settings = {
  optimal: 5000,
  minimal: 5000,
  timeout: MAX_WAIT_TIME,
};

const requestUrl = '/common/test-latency';

const LatencyTest = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [aborted, setAborted] = useState(false);

  const checkNetworkConnection = () => {
    if (result) return;

    const promises = [];

    for (let i = 1; i <= limit; i++) {
      promises.push(
        new Promise((resolve, reject) => {
          const start = performance.now();
          const timeoutId = setTimeout(() => {
            resolve(settings.timeout);
          }, settings.timeout);

          Api.get(requestUrl, { timeout: settings.timeout })
            .then(res => {
              clearTimeout(timeoutId);
              const millis = performance.now() - start;
              resolve(Math.round(millis % 60000));
            })
            .catch(err => {
              clearTimeout(timeoutId);
              reject(err);
            });
        }),
      );
    }

    Promise.allSettled(promises)
      .then(results => {
        const successfulResults = results.filter(r => r.status === 'fulfilled').map(r => r.value);
        const allTimedOut = successfulResults.every(res => res === MAX_WAIT_TIME);

        if (allTimedOut) throw new Error('All latency tests failed or timed out.');

        if (successfulResults.length > 0 && !allTimedOut) {
          // Process successful results
          let sorted = [...successfulResults];
          sorted.sort((a, b) => a - b);
          const avg = Math.round(sorted.reduce((a, b) => a + b) / sorted.length);
          const min = sorted[0];
          const max = sorted[sorted.length - 1];
          dispatch(
            setDiagnosticData({ latency: { avg, min, max, success: avg <= settings.optimal } }),
          );
          setResult({ avg, min, max, success: avg <= settings.optimal });
        }
      })
      .catch(err => {
        // Handle errors if all promises are rejected
        dispatch(setDiagnosticData({ failed: true, latencyTimedOut: true }));
        setAborted(true);
        setResult({ success: false });
        dispatch(setDiagnosticData({ latency: { success: false } }));
        enqueueSnackbar(getError(err), { variant: 'error' });
      });
  };

  useEffect(() => {
    checkNetworkConnection();
  }, [result]);

  return (
    <>
      <div className='box'>
        <p className='m-0'>
          <span className='weight-600 mr-2'>Latency:</span>
          <span className='weight-100'>
            {result ? <ViewResult data={result} /> : <SpinnerLoading />}
          </span>
        </p>
      </div>
      <Feedback
        data={result}
        optimalSettings={settings.optimal}
        minimalSettings={settings.minimal}
        resultKey='avg'
        successText='The latency is in normal range.'
        failText={
          <>
            The latency is too high for optimal viewing performance.
            <br />
            Hint: Try other internet connection.
          </>
        }
        aborted={aborted}
      />
    </>
  );
};

export default memo(LatencyTest);
