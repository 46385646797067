/* eslint-disable consistent-return */
import * as cornerstone from 'cornerstone-core';
import uuidv4 from './../util/uuidv4.js';
import triggerEvent from './../util/triggerEvent.js';

/**
 * Implements an imageId specific tool state management strategy.  This means that
 * Measurements data is tied to a specific imageId and only visible for enabled elements
 * That are displaying that imageId.
 * @public
 * @constructor newImageIdSpecificToolStateManager
 * @memberof StateManagement
 *
 * @returns {Object} An imageIdSpecificToolStateManager instance.
 */
function newImageIdSpecificToolStateManager() {
  let toolState = {};

  // Here we add tool state, this is done by tools as well
  // As modules that restore saved state

  function saveImageIdToolState(imageId) {
    return toolState[imageId];
  }

  function restoreImageIdToolState(imageId, imageIdToolState) {
    toolState[imageId] = imageIdToolState;
  }

  function saveToolState() {
    return toolState;
  }

  function restoreToolState(savedToolState) {
    toolState = savedToolState;
  }

  // Here we add tool state, this is done by tools as well
  // As modules that restore saved state
  function addElementToolState(element, toolType, data) {
    const enabledElement = cornerstone.getEnabledElement(element);

    // If we don't have an image for this element exit early
    if (!enabledElement.image) {
      return;
    }
    addImageIdToolState(enabledElement.image.imageId, toolType, data);
  }

  function addImageIdToolState(imageId, toolType, data) {
    // If we don't have any tool state for this imageId, add an empty object
    if (toolState.hasOwnProperty(imageId) === false) {
      toolState[imageId] = {};
    }

    const imageIdToolState = toolState[imageId];

    // If we don't have tool state for this type of tool, add an empty object
    if (imageIdToolState.hasOwnProperty(toolType) === false) {
      imageIdToolState[toolType] = {
        data: [],
      };
    }

    const toolData = imageIdToolState[toolType];

    // Finally, add this new tool to the state
    toolData.data.push(data);
  }

  function getElementToolState(element, toolType) {
    const enabledElement = cornerstone.getEnabledElement(element);

    // If the element does not have an image return undefined.
    if (!enabledElement.image) {
      return;
    }

    return getImageIdToolState(enabledElement.image.imageId, toolType);
  }

  // Here you can get state - used by tools as well as modules
  // That save state persistently
  function getImageIdToolState(imageId, toolType) {
    // If we don't have any tool state for this imageId, return undefined
    if (toolState.hasOwnProperty(imageId) === false) {
      return;
    }

    const imageIdToolState = toolState[imageId];

    // If we don't have tool state for this type of tool, return undefined
    if (imageIdToolState.hasOwnProperty(toolType) === false) {
      return;
    }

    return imageIdToolState[toolType];
  }

  // Clears all tool data from this toolStateManager.
  function clearElementToolState(element) {
    const enabledElement = cornerstone.getEnabledElement(element);

    if (!enabledElement.image) {
      return;
    }
    clearImageIdToolState(enabledElement.image.imageId);
  }

  function clearImageIdToolState(imageId) {
    if (toolState.hasOwnProperty(imageId) === false) {
      return;
    }

    delete toolState[imageId];
  }

  return {
    get: getElementToolState,
    add: addElementToolState,
    clear: clearElementToolState,
    getImageIdToolState,
    addImageIdToolState,
    clearImageIdToolState,
    saveImageIdToolState,
    restoreImageIdToolState,
    saveToolState,
    restoreToolState,
    toolState,
  };
}

// A global imageIdSpecificToolStateManager - the most common case is to share state between all
// Visible enabled images
const globalImageIdSpecificToolStateManager = newImageIdSpecificToolStateManager();

/**
 * Returns the toolstate for a specific element.
 * @public
 * @function getElementToolStateManager
 *
 * @param  {HTMLElement} element The element.
 * @returns {Object} The toolState.
 */
function getElementToolStateManager(element) {
  const enabledElement = cornerstone.getEnabledElement(element);
  // If the enabledElement has no toolStateManager, create a default one for it
  // NOTE: This makes state management element specific

  if (enabledElement.toolStateManager === undefined) {
    enabledElement.toolStateManager = globalImageIdSpecificToolStateManager;
  }

  return enabledElement.toolStateManager;
}

/**
 * Adds tool state to the toolStateManager, this is done by tools as well
 * as modules that restore saved state.
 * @public
 * @method addToolState
 *
 * @param  {HTMLElement} element  The element.
 * @param  {string} toolType      The toolType of the state.
 * @param  {Object} measurementData The data to store in the state.
 * @returns {undefined}
 */
function addToolState(element, toolType, measurementData) {
  const toolStateManager = getElementToolStateManager(element);

  measurementData.uuid = measurementData.uuid || uuidv4();
  toolStateManager.add(element, toolType, measurementData);

  const eventType = 'cornerstonetoolsmeasurementremoved';
  const eventData = {
    toolType,
    element,
    measurementData,
  };

  triggerEvent(element, eventType, eventData);
}

/**
 * Returns tool specific state of an element. Used by tools as well as modules
 * that save state persistently
 * @export
 * @public
 * @method
 * @name getToolState
 *
 * @param  {HTMLElement} element The element.
 * @param  {string} toolType The toolType of the state.
 * @returns {Object}          The element's state for the given toolType.
 */
function getToolState(element, toolType) {
  const toolStateManager = getElementToolStateManager(element);

  return toolStateManager.get(element, toolType);
}

/**
 * Removes specific tool state from the toolStateManager.
 * @public
 * @method removeToolState
 *
 * @param  {HTMLElement} element  The element.
 * @param  {string} toolType      The toolType of the state.
 * @param  {Object} data          The data to remove from the toolStateManager.
 * @returns {undefined}
 */
function removeToolState(element, toolType, data) {
  const toolStateManager = getElementToolStateManager(element);
  const toolData = toolStateManager.get(element, toolType);

  if (!toolData || !toolData.data || !toolData.data.length) {
    return;
  }

  // Find this tool data
  let indexOfData = -1;

  for (let i = 0; i < toolData.data.length; i++) {
    if (toolData.data[i] === data) {
      indexOfData = i;
    }
  }

  if (indexOfData !== -1) {
    toolData.data.splice(indexOfData, 1);

    const eventType = 'cornerstonetoolsmeasurementremoved';
    const eventData = {
      toolType,
      element,
      measurementData: data,
    };

    triggerEvent(element, eventType, eventData);
  }
}

export { getToolState, addToolState, removeToolState };
