import React from 'react';
import AuthorsDropdown from './AuthorsDropdown';
import BackButton from 'shared/BackButton';

const HeaderSection = ({ course, hasBack, onBackClick }) => {
  return (
    <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between'>
      <div className='d-flex align-items-center'>
        <div className='course-name pr-4 text-two-line d-flex align-items-center'>
          {hasBack && <BackButton onClick={onBackClick} className='mr-2' />}
          {course.title}
        </div>
        {!course?.isMarketingMaterial && (
          <div className='align-items-center course-authors d-flex pl-4'>
            <AuthorsDropdown data={course.courseAuthors} />
          </div>
        )}
      </div>
    </div>
  );
};
export default HeaderSection;
