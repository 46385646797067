import React from 'react';

const EmptyView = ({ text, Icon }) => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <div>
        <Icon />
      </div>
      <div className='mt-2 fz-20'>{text}</div>
    </div>
  );
};

export default EmptyView;
