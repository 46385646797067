// Load vendors
import axios from 'axios';
import store from 'redux/store';

// Load configs
import { HOST } from 'configs/host';
import { getEventId, getEventUrlName } from './appHelpers';
import { hasAccess } from './permissionHelper';
import { clearForLogout, getAuthState, onAuthState } from 'app/Auth/actions';
import { SnackbarUtils } from './snackbarUtils';

// Set config defaults when creating the instance
export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  // THIS HEADERS IS IMPORTANT SEE THE URL ABOVE
  headers: { common: {} },
});

export const setAuthToken = (authToken, api) => {
  api.defaults.headers.common[HOST.API.AUTH_HEADER] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};

Api.interceptors.request.use(
  request => {
    if (hasAccess('event_user') && getEventId()) {
      request.headers.common.eventId = getEventId();
    }
    if (hasAccess('event_user') && getEventUrlName()) {
      request.headers.common.event = getEventUrlName();
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(null, async error => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      clearForLogout(store.dispatch);
      await store.dispatch(getAuthState(SnackbarUtils.enqueueSnackbar));
    }
    // TEMPORARY HIDDEN FOR FURTHER IMPLEMENTATION
    // if (error.response.status === 503) {
    //   await store.dispatch(onAuthState(-2));
    // }
  }
  // if (error.message === 'Network Error' || error.message?.includes('503')) {
  //   await store.dispatch(onAuthState(-2));
  // }

  return Promise.reject(error);
});
