import React from 'react';
import ReactDOM from 'react-dom';

const OfflineModal = ({ isOpen, countdown }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className='modal-overlay'>
      <div className='modal-content offline-content'>
        <h6 className='offline-warning'>You are offline!</h6>
        <p className='offline-description'>
          You'll be redirected as time will end unless you come back online.
        </p>
        <div className='offline-countdown'>{countdown}</div>
      </div>
    </div>,
    document.getElementById('modal-root'),
  );
};

export default OfflineModal;
