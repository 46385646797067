import React, { useEffect, useState, useRef } from 'react';
import { IMAGE_RESIZE_WIDTHS } from 'configs/constants';
import { HOST } from 'configs';

export const ResponsiveBackgroundImage = ({
  imgUrl,
  sizes = IMAGE_RESIZE_WIDTHS.default,
  children,
  className,
  style,
}) => {
  const containerRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [isUpdatingImgSrc, setIsUpdatingImgSrc] = useState(false);

  const getClosestHigherWidth = parentWidth => {
    for (let size of sizes) {
      if (parentWidth <= size) {
        return size;
      }
    }
    return 1001;
  };

  const getNewImageUrl = (url, width) => {
    if (width > 1000) {
      return url;
    }

    const contentDomain = HOST.API.CONTENT;
    const baseUrlPattern = new RegExp(`^${contentDomain}/`);
    const baseUrlMatch = url.match(baseUrlPattern);

    if (!baseUrlMatch) return url;

    const baseUrl = baseUrlMatch[0];
    const pathAndFile = url.slice(baseUrl.length);
    if (!pathAndFile) return url;
    const [path, file] = pathAndFile.split(/\/(?=[^\/]*$)/);
    if (!file) return url;
    const [filename, extension] = file.split('.');

    const newFilename = `${filename}@${Math.round(width)}x`;
    return `${baseUrl}${path}/${newFilename}.${extension}`;
  };

  const updateBackgroundImage = () => {
    setIsUpdatingImgSrc(true);
    if (containerRef.current && imgUrl) {
      const parentWidth = containerRef.current.offsetWidth;
      const closestWidth = getClosestHigherWidth(parentWidth);
      const newBackgroundImage = getNewImageUrl(imgUrl, closestWidth);

      if (newBackgroundImage !== imgUrl) {
        preloadImage(
          newBackgroundImage,
          () => {
            setBackgroundImage(newBackgroundImage);
            setIsUpdatingImgSrc(false);
          },
          () => {
            setBackgroundImage(imgUrl); // Fallback to original if the new src fails to load
            setIsUpdatingImgSrc(false);
          },
        );
      } else {
        setBackgroundImage(imgUrl);
        setIsUpdatingImgSrc(false);
      }
    }
  };

  const preloadImage = (src, onLoad, onError) => {
    const img = new Image();
    img.src = src;
    img.onload = onLoad;
    img.onerror = onError;
  };

  useEffect(() => {
    updateBackgroundImage();
  }, [imgUrl, sizes]);

  return (
    <div
      ref={containerRef}
      className={className}
      style={{ ...style, backgroundImage: `url(${backgroundImage})` }}
    >
      {isUpdatingImgSrc ? null : children}
    </div>
  );
};
