import React, { useState, useEffect } from 'react';
import { Api } from 'utils/connectors';
import InputGroup from 'shared/components/InputGroup';
import ButtonLine from 'shared/components/ButtonLine';
import { IconLock } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';

const ChangePasswordModal = ({ email, onClose }) => {
  const [fetch, setFetch] = useState(false);
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState({
    curPassword: '',
    newPassword: '',
    cnfPassword: '',
  });
  const [backError, setBackError] = useState(false);
  const [errors, setErrors] = useState({
    curPassword: null,
    newPassword: null,
  });

  const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

  const requestForPasswordChange = async () => {
    try {
      setFetch(true);
      const body = { email, curPassword: form.curPassword, newPassword: form.newPassword };
      await Api.post(`/auth/changepassword`, body);
      setSuccess(true);
    } catch (err) {
      setBackError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  const validateSubmission = e => {
    e.preventDefault();
    const { curPassword, newPassword, cnfPassword } = form;
    const newErrors = JSON.parse(JSON.stringify(errors));
    if (!curPassword) {
      newErrors.curPassword = 'Enter current password!';
    } else if (curPassword && newErrors.curPassword) {
      newErrors.curPassword = null;
    } else if (!newPassword || !cnfPassword || newPassword !== cnfPassword) {
      newErrors.newPassword = 'Password Missmatch!';
    } else if (newPassword && cnfPassword && newPassword === cnfPassword && newErrors.newPassword) {
      newErrors.newPassword = null;
    }
    setErrors(newErrors);
  };

  const handleSubmit = () => {
    const { curPassword, newPassword, cnfPassword } = form;
    const existingErrors = !!Object.values(errors).join('').length;
    if (curPassword && newPassword && cnfPassword && !existingErrors) {
      requestForPasswordChange();
    }
  };

  useEffect(handleSubmit, [errors]);

  return (
    <div className='password-modal d-flex justify-content-center align-items-start'>
      <div className='modal-content'>
        <div className='modal-header d-flex justify-content-end'>
          <span className='modal-close' onClick={onClose} role='presentation' />
        </div>
        <div className='modal-body'>
          {!success ? (
            <>
              <h4 className='text-center'>Change Password</h4>
              <p className='mb-5 text-center'>Please enter your current password</p>
              <form onSubmit={validateSubmission}>
                <div className='password-input d-flex mb-5'>
                  <div className='pr-3 pl-2 pass-icon mb-2'>
                    <IconLock />
                  </div>
                  <InputGroup
                    className='pl-3'
                    type='password'
                    name='curPassword'
                    placeholder='Enter Current Password'
                    onChange={handleChange}
                  />
                </div>
                <div className='password-input d-flex mb-5'>
                  <div className='pr-3 pl-2 pass-icon mb-2'>
                    <IconLock />
                  </div>
                  <InputGroup
                    className='pl-3'
                    type='password'
                    name='newPassword'
                    placeholder='Enter New Password'
                    onChange={handleChange}
                  />
                </div>
                <div className='password-input d-flex'>
                  <div className='pr-3 pl-2 pass-icon mb-2'>
                    <IconLock />
                  </div>
                  <InputGroup
                    className='pl-3'
                    type='password'
                    name='cnfPassword'
                    placeholder='Confirm Password'
                    onChange={handleChange}
                  />
                </div>
                {errors.curPassword && (
                  <p className='text-danger mb-0 mt-3 text-center'>{errors.curPassword}</p>
                )}
                {errors.newPassword && (
                  <p className='text-danger mb-0 mt-3 text-center'>{errors.newPassword}</p>
                )}
                {backError && <p className='text-danger mb-0 mt-3 text-center'>{backError}</p>}
                <div className='text-center mt-5'>
                  <ButtonLine type='submit' disabled={fetch}>
                    Change
                  </ButtonLine>
                </div>
              </form>
            </>
          ) : (
            <h4 className='text-center mt-4 mb-5'>Your password has been changed</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
