import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCourses } from '../Courses/actions';
import { findCourseFirstLesson, getError } from 'utils/appHelpers';
import { IconCoursesNew } from 'shared/components/Icons';
import CourseItem from 'shared/components/CourseItem';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';
import CheckBox from 'shared/components/CheckBox';
import CreatableSelect from 'react-select/creatable';
import { hasAccess } from 'utils/permissionHelper';
import {
  API_RESPONSE_STATUS_CODES,
  COURSE_TYPES,
  COURSE_TYPES_BY_TYPENAME,
} from 'configs/constants';

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: 300,
    color: '#dddddd',
  }),
};

const Courses = ({ courses, history, getCourses, match, location }) => {
  const isEvent = hasAccess('event_user');
  const { type } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [franch, setFranch] = useState();
  const [types, setTypes] = useState(type ? [Number(type)] : []);
  const [courseTags, setCourseTags] = useState([]);
  const [tags, setTags] = useState([]);

  const isVisitorUser = hasAccess('visitor_user');

  const getFranchises = async () => {
    try {
      const { data } = await Api.get('/common/franchise');
      setFranch(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getTags = async () => {
    try {
      const { data } = await Api.get('/tag/Tags', {
        params: {
          limit: 1000,
        },
      });
      setCourseTags(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleFilterChange = id => {
    const newTypes = [...types];
    if (newTypes.includes(Number(id))) {
      newTypes.splice(newTypes.indexOf(id), 1);
    } else {
      newTypes.push(Number(id));
    }
    setTypes(newTypes);
  };

  const courseClickHandle = async course => {
    history.push(`/allcourses/details/${course.id}`);
  };

  useEffect(() => {
    getCourses();
    getFranchises();
    getTags();
    //eslint-disable-next-line
  }, [getCourses]);

  const filteredCourses = courses.filter(item => {
    const hasType = types.length;
    const hasTag = tags.length;
    if (!hasType && !hasTag) return item;
    let result = false;

    if (hasType && !hasTag) {
      if (types.length && item.franchises && item.franchises.length) {
        result = types.filter(x => item.franchises.includes(x)).length;
      }
    } else if (!hasType && hasTag) {
      if (tags.length && item.courseTagsIds && item.courseTagsIds.length) {
        result = tags.filter(x => item.courseTagsIds.includes(x.id)).length;
      }
    } else {
      result =
        types.filter(x => item.franchises.includes(x)).length &&
        tags.filter(x => item.courseTagsIds.includes(x.id)).length;
    }

    return result;
  });

  const getOnlyCaseTypeLessonsCourses = courses => {
    return courses.filter(course => {
      if (course.lessons.some(l => l.type === 'cases')) {
        return true;
      }
      return false;
    });
  };

  return (
    <div>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconCoursesNew color='#131f6b' />
          </span>
          Available Courses
        </div>
      </div>
      <div className='p-3'>
        <div className='d-flex justify-content-between mb-4'>
          <div className='d-flex align-items-center'>
            {franch &&
              franch.map(item => (
                <CheckBox
                  key={item.id}
                  name={item.name}
                  label={item.name}
                  checked={types.includes(item.id)}
                  onChange={handleFilterChange.bind(null, item.id)}
                />
              ))}
          </div>
        </div>
        <div className='mb-4'>
          <CreatableSelect
            styles={customStyles}
            className='multi-select'
            classNamePrefix='multi-select-pre'
            value={tags}
            onChange={data => setTags(data)}
            options={courseTags}
            isMulti
            getOptionLabel={o => o.name}
            getOptionValue={o => o.id}
            placeholder='Search with tags'
          />
        </div>
        <div className='courses d-flex flex-wrap'>
          {filteredCourses.map(item => (
            <CourseItem
              key={item.id}
              item={item}
              courseClick={courseClickHandle.bind(null, item)}
              hideProgress={true}
            />
          ))}
        </div>
        <p className='mt-3 mb-1 text-annotations small'>
          Please note that all products and technologies may not be available in all markets.
        </p>
        {!isEvent && (
          <p className='text-annotations small'>
            Please click on the course thumbnail for course details and to enroll in a course.
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ courses }) => ({
  courses,
});

export default connect(
  mapStateToProps,
  { getCourses },
)(Courses);
