import React from 'react';

const InformationPart = ({ data, caseId, activeStep }) => {
  const stepComment = data.answers[activeStep] && data.answers[activeStep].title;
  return (
    <div className='description-area pt-3 px-3 d-flex justify-content-between flex-column'>
      <div className='w-100'>
        <h5>CASE #{caseId}:</h5>
        {data.sections && data.sections.length ? (
          <div className='bg-semi-light description-container'>
            {data &&
              data.sections &&
              data.sections.map((item, i) => {
                return (
                  <div className='mb-2' key={i}>
                    <p className='m-0 mb-1'>
                      <b>{item.title}</b>
                    </p>
                    <ul className='mb-4'>
                      {item.items &&
                        item.items.map((row, i) => {
                          return <li key={i}>{row.text}</li>;
                        })}
                    </ul>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className='bg-semi-light description-container'>
            No information provided for this case
          </div>
        )}
      </div>
      <div className='step-description'>
        {stepComment && (
          <div className={`bg-semi-light description-container ${stepComment ? 'h-100' : ''}`}>
            {stepComment}
          </div>
        )}
      </div>
    </div>
  );
};

export default InformationPart;
