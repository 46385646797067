import React, { useState } from 'react';
import { IconClose } from 'shared/components/Icons';
import Toggle from 'shared/components/Toggle';

const data = [
  {
    title: 'Necessary',
    content: (
      <>
        Necessary cookies are absolutely essential for the website to function properly. This
        category only includes cookies that ensures basic functionalities and security features of
        the website. These cookies do not store any personal information.
      </>
    ),
    enabledText: 'Always Enabled',
  },
  {
    title: 'Unnecessary',
    name: 'unnecessary_state',
    content: (
      <>
        Any cookies that may not be particularly necessary for the website to function and is used
        specifically to collect user personal data via analytics, ads, other embedded contents are
        termed as non-necessary cookies. It is mandatory to procure user consent prior to running
        these cookies on your website.
      </>
    ),
  },
  {
    title: 'Functional',
    name: 'functional_state',
    content: (
      <>
        Functional cookies help to perform certain functionalities like sharing the content of the
        website on social media platforms, collect feedbacks, and other third-party features.
      </>
    ),
  },
];

const CookieSettingsModal = ({ onClose, state, setState, saveAndAccept }) => {
  const [infoState, setInfoState] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleAccordion = index => setActiveIndex(activeIndex === index ? null : index);

  const handleCookieSettingsChange = (name, val) => {
    setState({ ...state, [name]: val });
  };

  return (
    <>
      <div className='modal-box cookie-settings-modal deactivate-modal d-flex flex-column'>
        <div className='align-items-center d-flex header justify-content-between'>
          <div>Privacy Overview</div>
          <div onClick={onClose} tabIndex='-1' role='button' className='pointer'>
            <IconClose />
          </div>
        </div>
        <div className='cookie-content flex-fill overflow-auto'>
          <p className={`cookie-info mb-2 ${!infoState ? 'text-three-line' : ''}`}>
            This website uses cookies to improve your experience while you navigate through the
            website. Out of these, the cookies that are categorized as necessary are stored on your
            browser as they are essential for the working of basic functionalities of the website.
            We also use third-party cookies that help us analyze and understand how you use this
            website. These cookies will be stored in your browser only with your consent. You also
            have the option to opt-out of these cookies. But opting out of some of these cookies may
            affect your browsing experience.
          </p>
          <button
            className='btn p-0 mb-3 font-weight-normal fz-14'
            onClick={() => setInfoState(!infoState)}
          >
            <u>Show {infoState ? 'less' : 'more'}</u>
          </button>
          <div className='accordion accordion-cookie'>
            {data.map((item, index) => {
              const isChecked = state[item.name];
              return (
                <ul className='accordion-item' key={index}>
                  <li
                    className={`item-header ${activeIndex === index ? 'active' : ''}`}
                    onClick={() => toggleAccordion(index)}
                    role='presentation'
                  >
                    <div className='d-flex justify-content-between align-items-center'>
                      <div>{item.title}</div>
                      <div className='right-part d-flex align-items-center'>
                        {item.enabledText && item.enabledText}
                        {!item.enabledText && (
                          <Toggle
                            className={`custom-switch pl-2 ${isChecked ? 'checked' : ''}`}
                            name={item.name}
                            id={item.name}
                            checked={isChecked}
                            onChange={handleCookieSettingsChange.bind(null, item.name)}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                  {activeIndex === index && <div className='item-content'>{item.content}</div>}
                </ul>
              );
            })}
          </div>
        </div>
        <div className='modal-footer text-right'>
          <button className='btn btn-success' onClick={saveAndAccept}>
            SAVE & ACCEPT
          </button>
        </div>
      </div>
    </>
  );
};

export default CookieSettingsModal;
