import React, { memo } from 'react';
import { IconRight } from './Icons';

const ButtonLine = ({ children, disabled, type = 'button', className, onClick, hideArrow }) => (
  <button
    className={`btn btn-line mt-1 ${className || ''}`}
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    <div className='d-flex align-items-baseline'>
      <span>{children}</span> {!hideArrow && <IconRight className='ml-2' />}
    </div>
  </button>
);

export default memo(ButtonLine);
