import React, { useState, useEffect, memo } from 'react';
// import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setDiagnosticData } from '../../actions';
import SpinnerLoading from './../SpinnerLoading';
import Feedback from './../Feedback';
import ViewResult from './ViewResult';
import { HOST } from 'configs';

const imageFile = `${HOST.API.CONTENT}/test/bw-test1.jpg`;
const downloadSize = 5083725; //bytes;

// Bandwidth duration
const settings = {
  optimal: 8,
  minimal: 5,
  timeout: 35000,
};

const minDownloadSpeed = settings.minimal / 8;
const minimalBandwidthBytesPerSecond = minDownloadSpeed * 1024 * 1024; // Convert Mbps to bytes per second

// Calculate max wait time (in seconds)
const maxBandwidthWaitTime = downloadSize / minimalBandwidthBytesPerSecond;

settings.minimal = maxBandwidthWaitTime;
settings.optimal = (maxBandwidthWaitTime * 2) / 3;

const BandwidthTest = ({ stoppedEarly, setStoppedEarly }) => {
  // Check wether the bandwith data is exists in the redux store
  // const testCompleted = useSelector(state => state.diagnosticData.bandwidth);
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [aborted, setAborted] = useState(false);

  const checkBandwidth = () => {
    if (aborted) return;

    let startTime, intervalId;
    const download = new Image();

    download.onload = function() {
      clearInterval(intervalId); // Clear the interval when the image loads successfully
      showResults((new Date().getTime() - startTime) / 1000);
    };

    download.onerror = function(err, msg) {
      clearInterval(intervalId); // Clear the interval in case of an error
      setAborted(true);
    };

    const checkDuration = () => {
      const currentDuration = (new Date().getTime() - startTime) / 1000;
      if (currentDuration > maxBandwidthWaitTime) {
        clearInterval(intervalId); // Stop the interval check
        download.src = ''; // Stop downloading the image
        showResults(currentDuration, true); // Pass true to indicate the test was stopped
      }
    };

    startTime = new Date().getTime();
    const cacheBuster = '?nnn=' + startTime;
    download.src = imageFile + cacheBuster;

    intervalId = setInterval(checkDuration, 100); // Start the interval check loop

    function showResults(duration, stoppedEarly = false) {
      const result = {
        received: 5.2,
        duration,
        success: duration <= settings.optimal,
        failed: duration > settings.minimal,
      };
      setStoppedEarly(stoppedEarly);
      setResult(result);
    }
  };

  useEffect(() => {
    if (result) {
      dispatch(setDiagnosticData({ bandwidth: result }));
    }
  }, [dispatch, result]);

  useEffect(() => {
    if (aborted && !stoppedEarly) {
      dispatch(setDiagnosticData({ failed: true }));
    }
  }, [aborted, dispatch]);

  useEffect(() => {
    checkBandwidth();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='box'>
        <p className='m-0'>
          <span className='weight-600 mr-2'>Bandwidth:</span>
          <span className='weight-100'>
            {aborted && !stoppedEarly ? (
              'No Response.'
            ) : result ? (
              <ViewResult
                maxWaitTime={maxBandwidthWaitTime}
                stoppedEarly={stoppedEarly}
                data={result}
              />
            ) : (
              <SpinnerLoading />
            )}
          </span>
        </p>
      </div>
      <Feedback
        stoppedEarly={stoppedEarly}
        data={result}
        optimalSettings={settings.optimal}
        minimalSettings={settings.minimal}
        resultKey='duration'
        successText='The bandwidth is in normal range.'
        failText={
          <>
            The bandwidth is too low for optimal viewing performance.
            <br />
            Hint: Try other internet connection.
          </>
        }
        aborted={aborted}
      />
    </>
  );
};

export default memo(BandwidthTest);
