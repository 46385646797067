/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { withStyles } from '@material-ui/core/styles';
import { getError } from 'utils/appHelpers';
// Ensure you are using a compatible version of notistack with Material-UI v3.9
import { useSnackbar } from 'notistack';
import Button from 'shared/components/Button';
import ButtonLoading from 'shared/components/ButtonLoading';
import ResponsiveImage from 'shared/ResponsiveImage';

const styles = theme => ({
  dialogTitle: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  content: {
    padding: '16px 0',
    borderTop: '1px solid #D3D4D7',
    margin: '0 24px',
    height: 470,
  },
  footer: {
    boxShadow: '0px 0px 14px 0px #C7D2FF',
    borderTop: '1px solid #D3D4D7',
    margin: 0,
    height: 67,
    padding: '16px 24px',
  },
  applyBtn: {
    padding: '8px 55px',
    fontSize: 12,
    fontWeight: 400,
  },
});

const EntitySelectDialog = ({
  isOpen,
  onClose,
  classes,
  assignProduct,
  productName,
  specialty,
  data,
  setUserSelectedEntity,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const userEntities = useSelector(state => state.account.entities);
  const [selectedEntity, setSelectedEntity] = useState('');

  const addOrUpdateParam = (key, value) => {
    if (window.history.pushState) {
      const url = new URL(window.location.href);
      url.searchParams.set(key, value);
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
  };

  const handleApply = async () => {
    try {
      setIsFetching(true);
      await addOrUpdateParam('entity', selectedEntity);
      assignProduct(selectedEntity);
      setUserSelectedEntity(selectedEntity);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      onClose();
      setIsFetching(false);
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby='create-link-dialog-title'
        className='create-link-dialog'
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id='create-link-dialog-title' className={classes.dialogTitle}>
          Select the entity
        </DialogTitle>
        <DialogContent className={`${classes.content} user-creation-form`} dividers>
          {data?.message ? (
            <p className='section-desc text-align-left'>{data?.message}</p>
          ) : (
            <p className='section-desc text-align-left'>
              Select one of you Entities from which you are assigned to take the course of "
              {productName}" as {specialty}.
            </p>
          )}
          <div className='product-entities-block'>
            {userEntities.map((entity, idx) => {
              const { name, imageUrl } = entity;
              return (
                <div
                  onClick={() => setSelectedEntity(name)}
                  key={idx}
                  className={`entity-item ${name === selectedEntity && 'active'}`}
                >
                  {imageUrl ? (
                    <div className='img-block'>
                      <ResponsiveImage alt={name} imgUrl={imageUrl} />
                    </div>
                  ) : (
                    <div className='title-as-icon-block'>{name?.[0]}</div>
                  )}
                  <p className='name regular-text-sm'>{name}</p>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <Button
            disabled={!selectedEntity || isFetching}
            className={`btn-primary apply-btn ${!selectedEntity && 'global_disabled'} ${
              classes.applyBtn
            }`}
            onClick={handleApply}
          >
            {isFetching ? <ButtonLoading /> : 'Apply'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(EntitySelectDialog);
