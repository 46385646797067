import React from 'react';
import { viewerDialogJson } from './../config/consts';
const { otherTools } = viewerDialogJson;

const OtherTools = () => (
  <div>
    {otherTools.map(({ icon, description, buttons }, index) => {
      return (
        <p key={index} className='mt-4 d-flex align-items-center help-description'>
          <img src={icon} alt={description} className='mr-4' />
          <span className='mr-4'>{description}</span>
          {buttons.map((btn, index) => (
            <button key={index}>{btn}</button>
          ))}
        </p>
      );
    })}
  </div>
);

export default OtherTools;
