import React from 'react';

const Loading = ({ classView, classSpinner }) => (
  <div
    className={`text-center text-primary h-100 d-flex align-items-center justify-content-center ${classView ||
      'loading'}`}
  >
    <div className={`spinner-border ${classSpinner || ''}`} role='status' />
  </div>
);

export default Loading;
