/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { IconDicom, IconPlayFill } from 'shared/components/Icons';
import EmptyView from '../EmptyView';
import placeholder from 'assets/case_placeholder.jpg';

const TypeCases = ({ lesson, onCasePlayClick }) => {
  const cases = lesson.episodes;
  return (
    <div className='case-part flex-1 d-flex justify-content-center'>
      {lesson.activeCases?.length === 0 && (
        <EmptyView text='Here will be featured the DICOM lesson' Icon={IconDicom} />
      )}
      {lesson.activeCases?.length !== 0 && (
        <>
          <div onClick={onCasePlayClick} disabled={!cases || !cases.length} className='play-btn'>
            <IconPlayFill />
          </div>
          <img height='100%' src={lesson.imageUrl || placeholder} alt='placeholder' />
        </>
      )}
    </div>
  );
};

export default TypeCases;
