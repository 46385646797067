import React from 'react';
import { MAX_WAIT_TIME } from '../../constants';

const ViewResult = ({ data }) => {
  const { min, max, avg } = data;
  const localMin = min > MAX_WAIT_TIME ? MAX_WAIT_TIME : min?.toFixed(0);
  const localMax = max > MAX_WAIT_TIME ? MAX_WAIT_TIME : max?.toFixed(0);
  const localAvg = avg > MAX_WAIT_TIME ? MAX_WAIT_TIME : avg?.toFixed(0);
  return (
    <>
      {!min && !max && !avg ? (
        <span>No Response</span>
      ) : (
        <span>
          (Average: {avg > MAX_WAIT_TIME && `>`} <b>{localAvg}</b>ms, Min:{' '}
          {min > MAX_WAIT_TIME && `>`} <b>{localMin}</b>ms, Max: {max > MAX_WAIT_TIME && `>`}{' '}
          <b>{localMax}</b>ms)
        </span>
      )}
    </>
  );
};

export default ViewResult;
