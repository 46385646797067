import SideBar from 'app/Main/components/SideBar';
import React from 'react';

const PublicWrapper = ({ children, hasNotNav }) => {
  return (
    <div className='container-fluid p-0'>
      <div>
        {!hasNotNav && (
          <nav className='d-flex flex-column justify-content-between p-0 sidebar'>
            <SideBar path={''} />
          </nav>
        )}
        <main role='main' className='d-flex flex-column p-0 min-vh-100'>
          {children}
        </main>
      </div>
    </div>
  );
};

export default PublicWrapper;
