import step2Icon from 'assets/stepsnew/2n.svg';
import step2IconActive from 'assets/stepsnew/2s.svg';
import step2IconPassed from 'assets/stepsnew/2p.svg';
import step2IconPActive from 'assets/stepsnew/2ps.svg';

import step3Icon from 'assets/stepsnew/3n.svg';
import step3IconActive from 'assets/stepsnew/3s.svg';
import step3IconPassed from 'assets/stepsnew/3p.svg';
import step3IconPActive from 'assets/stepsnew/3ps.svg';

import step4Icon from 'assets/stepsnew/4n.svg';
import step4IconActive from 'assets/stepsnew/4s.svg';
import step4IconPassed from 'assets/stepsnew/4p.svg';
import step4IconPActive from 'assets/stepsnew/4ps.svg';

import tool4Icon from 'assets/tools/4p.svg';
import tool4IconActive from 'assets/tools/4a.svg';
import tool5Icon from 'assets/tools/5p.svg';
import tool5IconActive from 'assets/tools/5a.svg';
import tool6Icon from 'assets/tools/6p.svg';
import tool6IconActive from 'assets/tools/6a.svg';
import tool7Icon from 'assets/tools/7p.svg';
import tool7IconActive from 'assets/tools/7a.svg';
import tool8Icon from 'assets/tools/8p.svg';
import tool8IconActive from 'assets/tools/8a.svg';
import tool9Icon from 'assets/tools/9p.svg';
import tool9IconActive from 'assets/tools/9a.svg';

const dicomTypes = {
  '3dq': '3DQ',
  i2d: '2D',
  g2d: '2D',
  r2d: '2D',
  tomo: 'tomo',
  sub: 'tomo',
};

export const stepsProperties = {
  RCC: {
    active: true,
    position: 'right',
    thumbpos: 'left',
    has_switcher: false,
    dataType: '2D',
    key: 'RCC',
  },
  LCC: {
    active: true,
    position: 'left',
    thumbpos: 'right',
    has_switcher: false,
    dataType: '2D',
    key: 'LCC',
  },
  RMLO: {
    active: true,
    key: 'RMLO',
    position: 'right',
    thumbpos: 'left',
    has_switcher: false,
    dataType: '2D',
  },
  LMLO: {
    active: true,
    key: 'LMLO',
    position: 'left',
    thumbpos: 'right',
    has_switcher: false,
    dataType: '2D',
  },
};

export const answerSteps = [
  {
    id: 1,
    name: '2D CC Views',
    icon: step2Icon,
    iconActive: step2IconActive,
    iconPassed: step2IconPassed,
    iconPassedActive: step2IconPActive,
  },
  {
    id: 2,
    name: '2D MLO Views',
    icon: step3Icon,
    iconActive: step3IconActive,
    iconPassed: step3IconPassed,
    iconPassedActive: step3IconPActive,
  },
  {
    id: 3,
    name: '2D/3D RCC Views',
    icon: step4Icon,
    iconActive: step4IconActive,
    iconPassed: step4IconPassed,
    iconPassedActive: step4IconPActive,
  },
];

export const answerTools = [
  {
    name: 'R',
    title: 'Reset Viewers',
    icon: tool4Icon,
    iconActive: tool4IconActive,
    type: 'reset',
    cursor: 'default',
    onTimeAction: true,
  },
  {
    name: 'P',
    title: 'Activate Panning Tool',
    icon: tool5Icon,
    iconActive: tool5IconActive,
    type: 'pan',
    cursor: 'pan',
    onTimeAction: false,
  },
  {
    name: 'I Z',
    title: 'Activate Interactive Zoom',
    icon: tool6Icon,
    iconActive: tool6IconActive,
    type: 'zoomInteractive',
    cursor: 'izoom',
    onTimeAction: false,
  },
  {
    name: 'Z 1:1',
    title: 'Activate 1:1 Tool',
    icon: tool7Icon,
    iconActive: tool7IconActive,
    type: 'zoom1_1',
    cursor: 'fullzoom',
    onTimeAction: false,
  },
  {
    name: 'M',
    title: 'Activate Magnifier Tool',
    icon: tool8Icon,
    iconActive: tool8IconActive,
    type: 'zoomMagnifier',
    cursor: 'mzoom',
    onTimeAction: false,
  },
  {
    name: 'W/L',
    title:
      'Activate Window/Level Tool (W; for presets move mouse over viewer and press numeric keys 1, 2, 3, ...)',
    icon: tool9Icon,
    iconActive: tool9IconActive,
    type: 'windowLevel',
    cursor: 'wlevel',
    onTimeAction: false,
  },
];

export const getToolByType = type => {
  return answerTools.find(item => item.type === type);
};

export const mergeSteps = (views, backSteps) => {
  if (!backSteps || !backSteps.length) return [];
  return answerSteps.map((item, index) => {
    const step = backSteps[index];
    item.stepType = step.stepType;
    if (step.stepType === 'case') {
      const left = step.caseLeftFrame;
      const right = step.caseRightFrame;
      const leftDicom = Object.values(views).find(d => d.images.find(i => i.id === left.imageId));
      const rightDicom = Object.values(views).find(d => d.images.find(i => i.id === right.imageId));
      item.views = {};
      if (leftDicom) {
        const { imageLaterality, viewPosition, images, dicomImageType } = leftDicom;
        const imageIndex = images.findIndex(i => i.id === left.imageId);
        const dataType = dicomTypes[dicomImageType];
        const name = `${imageLaterality}${viewPosition}`;
        item.views[`${dataType}_${name}`] = {
          ...stepsProperties[name],
          imageIndex,
          dataType: dataType,
        };
      }
      if (rightDicom) {
        const { imageLaterality, viewPosition, images, dicomImageType } = rightDicom;
        const imageIndex = images.findIndex(i => i.id === right.imageId);
        const dataType = dicomTypes[dicomImageType];
        const name = `${imageLaterality}${viewPosition}`;
        item.views[`${dataType}_${name}`] = {
          ...stepsProperties[name],
          imageIndex,
          dataType: dataType,
        };
      }
    } else {
      item.views = {
        image: step.imageUrl,
      };
    }
    return item;
  });
};
