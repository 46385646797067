import { createReducer } from 'utils/reduxHelpers';
import { Api } from 'utils/connectors';
import { ON_LOGOUT } from 'app/Auth/actions';

const ON_AUTHORS_GET = '@@authors/ON_AUTHORS_GET';

const onAuthorsGet = payload => ({ type: ON_AUTHORS_GET, payload });

const authorsHandlers = {
  [ON_AUTHORS_GET]: (state, action) => action.payload,
  [ON_LOGOUT]: () => null,
};

const formatAuthorCourses = data => {
  if (!data || !data.length) return [];
  const result = [];
  data.forEach(({ course }) => {
    if (!course) return;
    result.push({
      id: course.id,
      title: course.title,
      info: course.info,
      status: course.courseStatus,
    });
  });
  return result;
};

const getAuthorObject = author => {
  if (!author) return {};
  return {
    id: author.id,
    name: author.name,
    surname: author.surname,
    profession: author.profession,
    image: author.imageUrl,
    description: author.info,
    bio: author.bio,
    educations: author.authorEducations,
    experiance: author.authorExperience,
    courses: formatAuthorCourses(author.courses),
    title: author.title || '-',
  };
};

const formatAuthorsData = data => {
  const result = [];
  data.forEach(author => {
    result.push(getAuthorObject(author));
  });
  return result;
};

export const formatCourseAuthors = data => {
  if (!data) return [];
  const result = [];
  data.forEach(item => {
    const author = item.authorProfile;
    result.push(getAuthorObject(author));
  });
  return result;
};

export const getAuthors = () => async dispatch => {
  try {
    const order = { orderKey: 'surname', order: true, limit: 100, page: 1 };
    const { data } = await Api.get('/authors/getauthors', { params: order });
    return await dispatch(onAuthorsGet(formatAuthorsData(data.data.results)));
  } catch (err) {
    return new Error(err);
  }
};

export const getAuthor = async id => {
  try {
    const { data } = await Api.get(`authors/getauthor/${id}`);
    return formatAuthorsData([data.data])[0];
  } catch (err) {
    return new Error(err);
  }
};

const authorsReducerInitialState = null;

export const authorsReducer = createReducer(authorsReducerInitialState, authorsHandlers);
