import React from 'react';

const ViewAlert = ({ data, optimalSettings, minimalSettings }) => {
  const { innerWidth, innerHeight } = data;
  return (
    <>
      {innerWidth >= optimalSettings.width && innerHeight >= optimalSettings.height ? (
        <p className='alert alert-success m-0 weight-100'>Passed</p>
      ) : innerWidth >= minimalSettings.width && innerHeight >= minimalSettings.height ? (
        <p className='alert alert-warning-light m-0 weight-100'>
          The optimal resolutions is {optimalSettings.width}x{optimalSettings.height}.
        </p>
      ) : (
        <p className='alert alert-warning m-0 weight-100'>
          The window size is too small, for optimal image quality.
          <br />
          Hint: Maximize the browser window.
        </p>
      )}
    </>
  );
};

export default ViewAlert;
