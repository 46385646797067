import { createReducer } from 'utils/reduxHelpers';
import { Api } from 'utils/connectors';
import { formatCourseAuthors } from '../../Authors/actions';
import { getDate } from 'utils/appHelpers';
import { ON_LOGOUT } from 'app/Auth/actions';
import { hasAccess } from 'utils/permissionHelper';
import quizBgSmallImage from 'assets/backgroundImages/quiz_bg_small.png';

const ON_COURSES_GET = '@@courses/ON_COURSES_GET';
const ON_MARKETING_COURSES_GET = '@@marketingCourses/ON_MARKETING_COURSES_GET';
const ON_COURSE_UPDATE = '@@courses/ON_COURSE_UPDATE';

const onCoursesGet = payload => ({ type: ON_COURSES_GET, payload });
const onMarketingCoursesGet = payload => ({ type: ON_MARKETING_COURSES_GET, payload });
export const onCourseUpdate = payload => ({ type: ON_COURSE_UPDATE, payload });

const coursesHandlers = {
  [ON_COURSES_GET]: (state, action) => action.payload,
  [ON_COURSE_UPDATE]: (state, action) => {
    const tempCourses = [...state];
    const courseIndex = tempCourses.findIndex(item => item.id === action.payload.id);
    tempCourses[courseIndex] = action.payload;
    return tempCourses;
  },
  [ON_LOGOUT]: () => null,
};

const marketingCoursesHandlers = {
  [ON_MARKETING_COURSES_GET]: (state, action) => action.payload,
};

const formatFranchisesData = data => {
  if (!data || !data.length) return [];
  return data.map(item => item.franchise.id);
};

const formatEpisodesData = (data, course) => {
  if (!data) return [];
  const result = [];
  data.forEach(episode => {
    const { userEpisode } = episode;
    result.push({
      id: episode.id,
      name: episode.title,
      time: episode.startFrom,
      duration: episode.duration,
      status: course.isOpened ? 1 : userEpisode ? userEpisode.userEpisodeStatus : -1,
      caseId: episode.caseId,
      orderNum: episode.orderNum,
      canSkipAfter: episode.canSkipAfter,
    });
  });
  return result;
};

const formatLessonsData = (data, course) => {
  if (!data) return [];
  const types = { 1: 'video', 2: 'pdf', 3: 'cases', 4: 'image', 5: 'feedback', 6: 'quiz' };
  const result = [];
  data.forEach(({ lesson }) => {
    if (!lesson) return;
    const cases = lesson.cases ? lesson.cases?.sort((a, b) => a.caseOrder - b.caseOrder) : [];
    const { userLesson, episodes } = lesson;
    if (types[lesson.contentType] === 'quiz') {
      result.push({
        ...course.quiz,
        id: lesson.id,
        image: quizBgSmallImage,
        duration: course.quiz?.timeLimit,
        episodes: course.quiz?.steps,
        type: 'quiz',
        name: 'Quiz Section',
        description: 'You must pass quiz to access other lessons.',
        attempt: course?.userCourse?.quizAttempts,
        status: course.isOpened ? 1 : userLesson ? userLesson.userLessonStatus : -1,
        progress: userLesson?.progress || 0,
        startDate: userLesson ? getDate(userLesson.startDate) : '',
        endDate: userLesson ? getDate(userLesson.endDate) : '',
        // status: isAllPassed ? 1 : -1,
      });
    } else {
      result.push({
        id: lesson.id,
        type: types[lesson.contentType],
        name: lesson.title,
        description: lesson.info,
        image: lesson.imageUrl,
        duration: lesson.duration,
        url: lesson.contentUrl,
        contentUrl: lesson.contentUrl,
        caseIds: cases,
        startDate: userLesson ? getDate(userLesson.startDate) : '',
        endDate: userLesson ? getDate(userLesson.endDate) : '',
        progress: userLesson ? userLesson.progress : 0,
        correctProgress: userLesson ? userLesson.questionaryProgress : null,
        completed: userLesson ? userLesson.completed : 0,
        status: course.isOpened ? 1 : userLesson ? userLesson.userLessonStatus : -1,
        userLessonId: userLesson ? userLesson.id : null,
        episodes: formatEpisodesData(episodes, course),
        lessonTags: lesson.lessonTags,
      });
    }
  });
  return result;
};

export const formatCourseData = data => {
  if (!data) return [];
  const result = [];
  data.forEach(data => {
    const course = data.course || data;
    const { userCourse, courseLessons, courseFranchises, courseTags } = course;
    result.push({
      id: course.id,
      accessToken: course.accessToken,
      mode: course.mode,
      name: course.title,
      certificateRegion: course.certificateRegion,
      description: course.info,
      image: course.imageUrl,
      progress: userCourse ? userCourse.progress : null,
      status: userCourse ? userCourse.userCourseStatus : hasAccess('visitor_user') ? 1 : -1,
      expire: userCourse ? userCourse.endDate : null,
      subscriptionId: userCourse ? userCourse.subscriptionId : null,
      userSubscriptionStatus: userCourse ? userCourse.userSubscriptionStatus : null,
      authors: formatCourseAuthors(course.courseAuthors),
      lessons: formatLessonsData(courseLessons, course),
      franchises: formatFranchisesData(courseFranchises),
      courseTags: courseTags,
      courseTagsIds: courseTags ? courseTags.map(item => item.tag.id) : [],
      certificateUrl: userCourse ? userCourse.certificateUrl : null,
      completedCases: userCourse ? userCourse.completedCases : null,
      totalCases: userCourse ? userCourse.totalCases : null,
      watchedVideoDuration: userCourse ? userCourse.watchedVideoDuration : null,
      totalVideoDuration: userCourse ? userCourse.totalVideoDuration : null,
      isOpened: course.isOpened,
      expirable: course.expirable,
      isCertificateAvailable: course.isCertificateAvailable,
      isEvaluationAvailable: course.isEvaluationAvailable,
      isPubliclyAvailable: course.isPubliclyAvailable,
      isPubliclyAccessible: course.isPubliclyAccessible,
      isQuizAvailable: course.isQuizAvailable,
      quiz: course.quiz,
      subscriptionPlans: course.subscriptionPlans,
      courseType: course.courseType,
      userCourse: course.userCourse,
      asrtMQSACredit: course.asrtMQSACredit,
      asrtNumber: course.asrtNumber,
      asrtProgramEndDate: course.asrtProgramEndDate,
      asrtProgramStartDate: course.asrtProgramStartDate,
      isASRTAccessible: course.isASRTAccessible,
    });
  });
  return result;
};

export const getCourses = () => async dispatch => {
  try {
    const { data } = await Api.get('/courses/getusercourses');
    await dispatch(onCoursesGet(formatCourseData(data.data)));
  } catch (err) {
    throw new Error(err);
  }
};

export const getMarketingCourses = () => async dispatch => {
  try {
    const { data } = await Api.get('/courses/marketing-materials', { params: { limit: 1000 } });
    await dispatch(onMarketingCoursesGet(formatCourseData(data.data.results)));
  } catch (err) {
    throw new Error(err);
  }
};

const coursesReducerInitialState = null;

const marketingCoursesReducerInitialState = null;

export const marketingCoursesReducer = createReducer(
  marketingCoursesReducerInitialState,
  marketingCoursesHandlers,
);

export const coursesReducer = createReducer(coursesReducerInitialState, coursesHandlers);
