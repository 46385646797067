import React, { memo } from 'react';

const RadioBox = ({ name, label, value, onChange, className, checked }) => {
  return (
    <div className={`align-items-center checkbox-item d-flex mr-3 ${className || ''}`}>
      <input
        type='radio'
        id={`radio${value}`}
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <label htmlFor={`radio${value}`}>{label}</label>
    </div>
  );
};

export default memo(RadioBox);
