import React, { useEffect, useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import Select from 'shared/components/Select';
import LeftLayout from 'app/Product/components/LeftLayout';
import RightLayout from 'app/Product/components/RightLayout';
import useQuery from 'shared/hooks/useQuery';
import { EyeIcon } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import Entities from './components/Entities';

const ProductRegister = ({ match, history, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { urlPath } = match.params;
  const { description } = location.state || {};
  const queryParams = useQuery();
  const { entity, spec = '' } = queryParams;
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [form, setForm] = useState({
    product: urlPath,
    entity: {
      name: entity,
    },
    firstName: '',
    lastName: '',
    email: '',
    speciality: spec,
    password: '',
    rpassword: '',
  });

  const leftLayoutDetails = {
    entity: entity,
    entityForwardPageKey: 'register',
    description:
      description ||
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever ",
  };

  const rightLayoutDetails = {
    footerForwardUrl: `/product/${urlPath}/login?entity=${entity}&spec=${form.speciality}`,
    question: 'Already have an account?',
    buttonText: 'Sign In',
  };

  const getSpecialtiesList = async () => {
    try {
      const { data } = await Api.get('/common/specialities');
      setSpecialties(
        data?.data?.length ? data.data.map(specialty => ({ name: specialty, id: specialty })) : [],
      );
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const body = {
        ...form,
        learnerProfile: {
          speciality: form.speciality,
        },
      };
      delete body.speciality;
      const res = await Api.post('/auth/register', body);
      history.push({
        pathname: `/product/${urlPath}/verify`,
        state: { registeringUserEmail: res?.data?.data?.email },
        search: `?entity=${entity}&spec=${spec}`,
      });
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempFOrm = { ...form };
    tempFOrm[name] = value;
    setForm(tempFOrm);
  };

  useEffect(() => {
    getSpecialtiesList();
  }, []);

  const passwordMatch = form.password === form.rpassword;

  return (
    <div className='d-flex flex-column min-vh-100'>
      <div className='flex-1 d-flex'>
        <LeftLayout {...leftLayoutDetails} />
        <RightLayout {...rightLayoutDetails}>
          {entity ? (
            <div className='reg-form-container'>
              <p className='form-title'>Personal Information</p>
              <form className='register-content' onSubmit={handleSubmit}>
                <div className='inputs-block'>
                  <div className='left-block block-item'>
                    <InputGroup
                      type='text'
                      name='firstName'
                      onChange={handleChange}
                      className='focus-bottom-border'
                      placeholder='Name*'
                      value={form.firstName}
                      required
                    />
                    <InputGroup
                      type='text'
                      name='lastName'
                      className='focus-bottom-border'
                      onChange={handleChange}
                      placeholder='Surname*'
                      value={form.lastName}
                      required
                    />
                    <Select
                      label='Specialty*'
                      name='speciality'
                      className='pl-1 focus-bottom-border'
                      value={form.speciality}
                      items={specialties}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='right-block block-item'>
                    <InputGroup
                      type='email'
                      name='email'
                      onChange={handleChange}
                      className='focus-bottom-border'
                      placeholder='Email*'
                      value={form.email}
                      required
                    />
                    <InputGroup
                      type='password'
                      name='password'
                      onChange={handleChange}
                      className='focus-bottom-border'
                      placeholder='Password'
                      value={form.password}
                      eyeIcon={<EyeIcon />}
                      required
                    />
                    <InputGroup
                      type='password'
                      name='rpassword'
                      onChange={handleChange}
                      className='focus-bottom-border'
                      placeholder='Confirm password'
                      value={form.rpassword}
                      eyeIcon={<EyeIcon />}
                      required
                    />
                  </div>
                </div>
                <div className='text-right'>
                  {!passwordMatch && form.rpassword && (
                    <div className='error-text'>Passwords mismatch</div>
                  )}
                  {error && <div className='error-text'>{error}</div>}
                </div>
                <Button
                  className='btn-blue-dark-redesigned form-button w-100 mt-100'
                  type='submit'
                  disabled={!Object.values(form).every(val => !!val) || fetch}
                  disabledClassName='btn-disabled-gray'
                >
                  Sign Up
                </Button>
              </form>
            </div>
          ) : (
            <Entities form={form} setForm={setForm} />
          )}
        </RightLayout>
      </div>
    </div>
  );
};

export default ProductRegister;
