import React from 'react';
import AccordionItem from 'shared/components/AccordionItem';

const faqData = [
  {
    key: 'panel1',
    question: 'Is there a free trial available?',
    answer:
      "Yes, you can try us for free for 30 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    key: 'panel2',
    question: 'Can I change my plan later?',
    answer: 'You can change your plan at any time. Please contact our support team for assistance.',
  },
  {
    key: 'panel3',
    question: 'What is your cancellation policy?',
    answer:
      "Yes, you can try us for free for 30 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    key: 'panel4',
    question: 'Can other info be added to an invoice?',
    answer: 'You can change your plan at any time. Please contact our support team for assistance.',
  },
  {
    key: 'panel5',
    question: 'How does billing work?',
    answer:
      "Yes, you can try us for free for 30 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
  },
  {
    key: 'panel6',
    question: 'How do I change my account email?',
    answer: 'You can change your plan at any time. Please contact our support team for assistance.',
  },
];

const FAQ = () => {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className='faq-container'>
      <div className='title-row'>
        <h2 className='section-title-dark-gray'>Frequently asked questions</h2>
        <p className='section-desc-gray'>
          Everything you need to know about the product and billing.
        </p>
      </div>
      <div>
        {faqData.map(item => (
          <AccordionItem
            key={item.key}
            panelKey={item.key}
            summary={item.question}
            details={item.answer}
            expanded={expanded === item.key}
            onChange={handleChange(item.key)}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
