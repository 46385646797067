import React from 'react';
import { MarketingBanner } from './components/marketingBanner';
import { MarketingCourses } from './components/MarketingCourses';
import { MarketingFooter } from './components/MarketingFooter';
import { MarketingHeader } from './components/MarketingHeader';
import { MarketingPresentationSection } from './components/MarketingPresentationSection';
import SendQuestionSection from 'shared/components/SendQuestionSection';

export const MarketingLanding = () => {
  return (
    <div className='marketing-landing'>
      <MarketingHeader />
      <MarketingBanner />
      <MarketingCourses />
      <MarketingPresentationSection />
      <SendQuestionSection />
      <MarketingFooter />
    </div>
  );
};
