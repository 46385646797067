import React from 'react';
import { connect, useSelector } from 'react-redux';
import AuthorsList from './../CourseDetails/components/AuthorsList';
import LessonsList from './../CourseDetails/components/LessonsList';
import { IconKey, IconLock } from 'shared/components/Icons';
import TagsViewBlock from 'shared/components/TagsViewBlock';
import { useState } from 'react';
import { Api } from 'utils/connectors';
import {
  findCourseFirstLesson,
  findCourseFirstLessonAndRedirect,
  getError,
} from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import { formatSubscription } from 'utils/formatHelpers';
import { useEffect } from 'react';
import Loading from 'shared/components/Loading';
import { formatCourseData, getCourses } from '../Courses/actions';
import { API_RESPONSE_STATUS_CODES, SUBS_TYPES_BY_KEY_REQUIRED } from 'configs/constants';
import ResponsiveImage from 'shared/ResponsiveImage';

const subsTypes = {
  1: 'No Key Required',
  2: 'Paid',
  3: (
    <>
      <IconKey /> Key Required
    </>
  ),
};

const CourseDetailsPublic = ({ history, match, getCourses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = match.params;
  const isCourseIdShareToken = isNaN(id);
  const [subscription, setSubscription] = useState(null);
  const courses = useSelector(store => store.courses);
  const userCourse = courses.find(item => item.id === Number(id));
  const [course, setCourse] = useState(null);
  const [isCheckingCourseAccess, setIsCheckingCourseAccess] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await Api.get(`courses/all-courses?id=${id}`);
      const course = data.data ? formatCourseData(data.data)[0] : null;
      const subs = course && course.subscriptionPlans[0];
      if (course) {
        setCourse(course);
        setSubscription(formatSubscription(subs));
      } else {
        history.push('/');
      }
    } catch (err) {
      history.push('/');
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const checkAccess = async () => {
    try {
      setIsCheckingCourseAccess(true);
      const res = await Api.get(`/courses/check-to-enroll/${id}`);
      if (
        res?.status === API_RESPONSE_STATUS_CODES.Success ||
        res?.status === API_RESPONSE_STATUS_CODES.CreateUpdate
      ) {
        const needToGetCourses = res?.status === API_RESPONSE_STATUS_CODES.CreateUpdate;
        findCourseFirstLessonAndRedirect(course, history, needToGetCourses);
      }
    } catch (err) {
      return;
    } finally {
      setIsCheckingCourseAccess(false);
    }
  };

  const onClickSignIn = () => {
    history.push({ pathname: '/login', state: { loginForwardUrl: `/allcourses/details/${id}` } });
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userCourse) {
      if (!isCourseIdShareToken) {
        checkAccess();
      } else {
        findCourseFirstLessonAndRedirect(userCourse, history);
      }
    }
  }, [course]);

  if (!subscription || !course || isCheckingCourseAccess) {
    return <Loading classView='p-5 min-vh-100' />;
  }

  return (
    <div className='public-course-view'>
      <div className='course-view flex-1 d-flex flex-column position-relative'>
        <div className='py-3 d-flex'>
          <div className='col-8'>
            <div className='px-3'>
              <h5 className='py-3 color-blue d-flex align-items-center'>
                <IconLock className='mr-2' />
                {course.name}*
              </h5>
              {course.description && (
                <div className='text-muted mb-5'>
                  <span className='d-block mb-2 text-lg'>About course</span>
                  <span className='d-block white-space-pre'>{course.description}</span>
                </div>
              )}
              {course.authors && course.authors.length > 0 && (
                <AuthorsList data={course.authors} history={history} />
              )}
              <p className='text-secondary'>Lessons</p>
              {course.lessons && course.lessons.length > 0 && <LessonsList data={course.lessons} />}
            </div>
          </div>
          <div className='col-4 py-3'>
            <div className='plan-info-box'>
              <ResponsiveImage imgUrl={course.image} alt='course' />
              <h3 className='mt-3 mb-2 font-weight-light font-size-15'>
                <b>{course.name}*</b>
              </h3>
              <div className='d-flex justify-content-between'>
                <span>Registration Code</span>
                <span>{SUBS_TYPES_BY_KEY_REQUIRED[subscription.keyRequired] || '-'}</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>ASRT</span>
                <span>{course.isASRTAccessible ? 'Required' : 'No Required'}</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>ASRT Number</span>
                <span>{course.asrtNumber || '-'}</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>Parts</span>
                <span>{course.lessons ? course.lessons.length : '0'} parts</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span>Faculty</span>
                <span>{course.authors.length} Faculty</span>
              </div>
              <div className='d-flex justify-content-between'>
                <span> Validity Period</span>
                <span>
                  {subscription.expirable ? `${subscription.days} days` : 'No Expiration'}
                </span>
              </div>
              <div className='d-flex justify-content-center'>
                <button className='btn subscribe-btn-block mt-3' onClick={onClickSignIn}>
                  Sign In
                </button>
              </div>
              <p className='mt-3 mb-0 enroll-text'>
                * To get access to this course you first need to Sign In
              </p>
            </div>
            <TagsViewBlock tags={course.courseTags} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  courses: state.courses,
});

const mapDispatchToProps = {
  getCourses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseDetailsPublic);
