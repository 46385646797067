import React from 'react';
import Select from 'shared/components/Select';

const FeedSelect = ({ index, data, onChange, answers = [], isDisabled, isAnswered }) => {
  const variants = data?.labels || data?.feedbackItems?.map(item => item.label);
  const items = variants?.map(item => ({ name: item, id: item }));

  const handleChnage = e => {
    onChange({ id: index, value: e.target.value, feedbackItemId: data?.id });
  };

  return (
    <li className='d-flex align-items-center'>
      <div className='col-6 pl-0'>
        <label className='m-0'>{data.question}</label>
      </div>
      <div className='input-group p-0'>
        {!isAnswered ? (
          <Select
            className='border-0'
            selectClassName='m-0'
            name='select'
            value={answers[index]}
            items={items}
            onChange={handleChnage}
            displayProperty='name'
            disabled={isDisabled}
            label='Select option'
            required
          />
        ) : (
          answers[index] || answers[data.id]
        )}
      </div>
    </li>
  );
};

export default FeedSelect;
