/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { forwardRef, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconGaidsClose, ShapesGroupIcon } from 'shared/components/Icons';
import { SHAPE_ICONS } from '../constants';

const AssociationItem = ({ association, isActive, handleSelectGaid, index }) => {
  return (
    <div
      onClick={() => handleSelectGaid(association, index)}
      className={`association-item ${isActive && 'active'}`}
    >
      <div className='content-block'>
        <div className='dcm-name'>
          DICOM: <span className='fw-600'>{association.fileName}</span>
        </div>
        <div className='row-1'>
          <span>
            Image View: <span className='fw-600'>{association.imageView}</span>
          </span>
          <span>
            Image Type: <span className='fw-600'>{association.imageType}</span>
          </span>
        </div>
        <div className='row-2'>
          <span>
            Slice N:{' '}
            <span className='fw-600'>
              {association.currentSlice}/{association.totalSlices}
            </span>
          </span>
          <span>
            Prediction: <span className='fw-600'>{association.percentage}%</span>
          </span>
        </div>
      </div>
    </div>
  );
};

const AssociationsBlock = forwardRef(
  (
    {
      associations,
      selectedPoint,
      tempSelectedPoint,
      details,
      isFetching,
      positionStyle,
      setSelectedPoint,
      setTempSelectedPoint = () => {},
      handleSelectGaid,
      handleClickClose,
    },
    ref,
  ) => {
    const { enqueueSnackbar } = useSnackbar();

    const [items, setItems] = useState(associations.map(asocc => ({ ...asocc, id: asocc.gaidId })));

    useEffect(() => {
      setItems(associations.map(asocc => ({ ...asocc, id: asocc.gaidId })));
      return () => {
        setTempSelectedPoint(null);
      };
    }, [associations]);

    if (isFetching || !items.length) {
      return (
        <div ref={ref} style={positionStyle} className='associations-dialog empty'>
          <div className='empty-content'>
            <ShapesGroupIcon />
            <div className='empty-associations'>
              {isFetching ? 'Loading...' : 'Select marking to see results'}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={ref} style={positionStyle} className='associations-dialog'>
        {!!details && (
          <div className='title-row'>
            <div className='left-block'>
              <div className='details-row'>
                <img alt='shape' src={SHAPE_ICONS[details.shape]} />
                <span>
                  Designator: <span className='designator'>{details.designator}</span>
                </span>
              </div>
              <div className='case-name'>Case: {details.case.title}</div>
            </div>
            <div className='right-block'>
              <div onClick={handleClickClose} className='close-icon-block'>
                <IconGaidsClose />
              </div>
            </div>
          </div>
        )}
        <div className='associations-container'>
          {items.map((association, index) => {
            const isActive = tempSelectedPoint
              ? tempSelectedPoint?.gaidId === association?.id
              : selectedPoint?.handles?.end?.id === association?.id;
            return (
              <AssociationItem
                handleSelectGaid={handleSelectGaid}
                key={index}
                id={association.id}
                isActive={isActive}
                index={index}
                association={association}
              />
            );
          })}
        </div>
      </div>
    );
  },
);

export default AssociationsBlock;
