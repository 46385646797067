/* eslint-disable jsx-a11y/no-static-element-interactions */
import LeftLayout from 'app/Product/components/LeftLayout';
import RightLayout from 'app/Product/components/RightLayout';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from 'shared/components/Button';
import { IconSearch } from 'shared/components/Icons';
import useQuery from 'shared/hooks/useQuery';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

const EntitySelect = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { urlPath } = match.params;
  const [entities, setEntities] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [forwardUrl, setForwardUrl] = useState('');

  const { state } = useLocation();
  const { entityForwardPageKey = 'register', description } = state || {};
  const queryParams = useQuery();
  const { entity, spec = '' } = queryParams;

  const qParams = `${entity ? `entity=${entity}` : ''}${
    spec ? `${entity ? '&' : ''}spec=${spec}` : ''
  }`;

  const leftLayoutDetails = {
    entity: entity,
    entityForwardPageKey: 'register',
    description:
      description ||
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever ",
  };

  const rightLayoutDetails = {
    footerForwardUrl: `/product/${urlPath}/login?${qParams}`,
    question: 'Already have an account?',
    buttonText: 'Sign In',
  };

  const filteredEntities =
    entities?.length &&
    entities.filter(entity => entity.name.toLowerCase().includes(search.toLowerCase()));

  const addOrUpdateParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    return url.pathname + url.search;
  };

  const handleSelectEntity = entity => {
    setSelectedEntity(entity);
    setForwardUrl(addOrUpdateParam('entity', entity.name));
  };

  const getEntities = async () => {
    try {
      const params = { page: 1, limit: 1000 };
      const { data } = await Api.get(`/common/entities`, { params });
      setEntities(data.data);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getEntities();
  }, []);

  return (
    <div className='d-flex flex-column min-vh-100'>
      <div className='flex-1 d-flex'>
        <LeftLayout {...leftLayoutDetails} />
        <RightLayout {...rightLayoutDetails}>
          <div className='entities-section'>
            <h6 className='title'>Select Your Company</h6>
            <div className='content-block'>
              <span className='regular-txt'>Select entity</span>
              <div className='search-block entity-search-block w-100'>
                <input
                  type='text'
                  value={search}
                  onChange={({ target }) => setSearch(target.value)}
                  className='form-control has-icon-left w-100'
                  placeholder='Search'
                />
                <IconSearch className='left-icon' color='#91939A' />
              </div>
              <div className='entity-items-block'>
                {filteredEntities?.length ? (
                  filteredEntities.map((entity, idx) => {
                    return (
                      <div
                        onClick={() => handleSelectEntity(entity)}
                        key={idx}
                        className={`entity-item ${selectedEntity?.name === entity?.name &&
                          'active'}`}
                      >
                        {entity.imageUrl ? (
                          <img className='banner' alt={entity.name} src={entity.imageUrl} />
                        ) : (
                          <div className='no-img-banner'>
                            <h6 className='first-letter'>{entity?.name?.[0]}</h6>
                            <p className='name'>{entity.name}</p>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className='no-result-block'>
                    <p>No Result</p>
                  </div>
                )}
              </div>
            </div>
            <Link
              to={`/product/${urlPath}/${entityForwardPageKey}?entity=${
                selectedEntity?.name
              }&spec=${spec}`}
            >
              <Button
                className='btn-blue-dark-redesigned form-button'
                disabled={!selectedEntity}
                disabledClassName='btn-disabled-gray'
              >
                Next
              </Button>
            </Link>
          </div>
        </RightLayout>
      </div>
    </div>
  );
};

export default EntitySelect;
