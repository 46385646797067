/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { IconArrowDown, IconArrowUp } from 'shared/components/Icons';

const EventSpeakers = ({ event }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`event-speakers ${open ? 'open' : ''}`} id='speakersSection'>
      <div className='container'>
        <h1 className='text-right large-title'>Faculty</h1>
        <div className={`event-speakers-list`}>
          {event.speakers &&
            event.speakers.map((data, index) => {
              const item = data.authorProfile || {};
              return (
                <div className='d-flex speaker-block' key={index}>
                  <div className='img-area'>
                    <img
                      height='226'
                      src={
                        item.imageUrl ||
                        'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png'
                      }
                      alt='a'
                    />
                  </div>
                  <div className='info-area'>
                    <p className='name'>
                      {item.name} {item.surname}, <span>{item.profession}</span>
                    </p>
                    <p className='info'>{item.info}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className='show-more' onClick={() => setOpen(!open)}>
        {!open ? <IconArrowDown color='#131F6B' /> : <IconArrowUp color='#131F6B' />} Show{' '}
        {!open ? 'more' : 'less'}
      </div>
    </div>
  );
};

export default EventSpeakers;
