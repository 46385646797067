import React, { useState } from 'react';
import { RadioTypeView } from './components/RadioTypeView';
import { MultiTypeView } from './components/MultiTypeView';
import { BooleanTypeView } from './components/BooleanTypeView';
import { ImageTypeView } from './components/ImageTypeView';
import Button from 'shared/components/Button';
import { ANSWER_STATUSES } from '../../constants';

const QuestionViewComponent = {
  radio: RadioTypeView,
  multi: MultiTypeView,
  yesno: BooleanTypeView,
  ['image-category']: ImageTypeView,
};

const QuizResults = ({ results = [], totalLength }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep < results.length - 1) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const currentResult = results[currentStep];
  const isSucceed = currentResult.status === ANSWER_STATUSES.Success;
  const isFailed = currentResult.status === ANSWER_STATUSES.Failure;
  const isExpired = currentResult.status === ANSWER_STATUSES.Expired;
  const isNotAnswered = currentResult.status === ANSWER_STATUSES.NoAnswer;
  const QuestionComponent = QuestionViewComponent[currentResult.type];

  return (
    <div className='question-container result-container'>
      <div className='question-block'>
        <div className='question-head'>
          <span className='q-number'>{currentStep + 1}</span>
          <p className='q-description'>{currentResult?.title}</p>
          <span className='q-number-in-all'>
            <span
              className={`${isSucceed && 'success'} ${(isFailed || isExpired) &&
                'failed'} ${isNotAnswered && 'skipped'}`}
            >
              {currentStep + 1}
            </span>
            /{totalLength}
          </span>
        </div>
        <div className='question-body'>
          {isExpired && <div className='expired-question-indication'>TIME</div>}
          <QuestionComponent question={currentResult} />
        </div>
        <div className='answer-btns-block'>
          <Button
            className='btn-outline-blue btn-size-regular'
            onClick={prevStep}
            disabled={currentStep === 0}
          >
            Back
          </Button>
          <Button
            className='btn-blue btn-size-regular'
            onClick={nextStep}
            disabled={currentStep === results.length - 1}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuizResults;
