import React from 'react';

const SpinnerLoading = ({ className = 'spinner-border-sm' }) => {
  return (
    <span className={`spinner-border ${className}`} role='status'>
      <span className='sr-only'>Loading...</span>
    </span>
  );
};

export default SpinnerLoading;
