import React from 'react';
import Icon2 from 'assets/home-icon-2.svg';
import { IconArrowRight } from 'shared/components/Icons';

const DashBoxEvents = ({ onOpenUrl }) => {
  return (
    <div className='d-flex flex-column h-100 justify-content-between'>
      <div className='title-anim text-right'>
        <span>Upcoming Events</span>
        <img src={Icon2} height='20' alt='icon' />
      </div>
      <div>
        <h3>Medical Education Events</h3>
        <a
          href='https://hologiced.com/events/'
          target='_blank'
          rel='noopener noreferrer'
          onClick={e => {
            e.preventDefault();
            onOpenUrl('https://hologiced.com/events/', e);
          }}
        >
          Show More
          <IconArrowRight color='#fff' />
        </a>
      </div>
    </div>
  );
};

export default DashBoxEvents;
