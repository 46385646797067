import React from 'react';
import useWindowSize from 'shared/hooks/useWindowSize';
import { getSize } from 'utils/appHelpers';
import DashBoxEvents from './components/DashBoxEvents';
import DashBoxInfo from './components/DashBoxInfo';
import DashBoxSpeciality from './components/DashBoxSpeciality';
import DashImageSlider from './components/DashImageSlider';
import { confirmAlert } from 'react-confirm-alert';
import DashBoxCourses from './components/DashBoxCourses';
import DashBoxTranscript from './components/DashBoxTranscript';
import DashBoxEvent from './components/DashBoxEvent';
import DashBoxAuthors from './components/DashBoxAuthors';
import { hasAccess } from 'utils/permissionHelper';
import DashBoxMarketing from './components/DashBoxMarketing';

const Home = () => {
  const isVisitorUser = hasAccess('visitor_user');
  const [width] = useWindowSize();

  const onOpenUrl = (url, e) => {
    if (e) e.preventDefault();
    if (!url) return;
    confirmAlert({
      message:
        'A new browser tab will now be opened. You can close it at any time and come back to this tab to continue.',
      buttons: [
        {
          label: 'Continue',
          onClick: () => window.open(url, '_blank'),
        },
        {
          label: 'Cancel',
        },
      ],
    });
  };

  const zoom = getSize(width < 780 ? 780 : width, 1, 1);

  const dashStyles = {
    transform: `scale(${zoom})`,
    transformOrigin: `top center`,
    marginLeft: (1366 * zoom - 1366) / 2,
    marginRight: (1366 * zoom - 1366) / 2,
    MozTransform: `scale(${zoom},${zoom})`,
    MozTransformOrigin: 'top center',
  };

  return (
    <div className='d-flex flex-wrap home-dashboard' style={{ ...dashStyles }}>
      <div className='col-3 dash-box'>
        <div className='dash-content dash-box-events'>
          <DashBoxEvents onOpenUrl={onOpenUrl} />
        </div>
      </div>
      <div className='col-3 dash-box'>
        <div className='dash-content dash-box-info'>
          <DashBoxInfo />
        </div>
      </div>
      <div className='col-6 dash-box'>
        <div className='dash-content dash-box-img-slider'>
          <DashImageSlider onOpenUrl={onOpenUrl} />
        </div>
      </div>
      <div className='col-3 dash-box'>
        <div className='dash-content dash-box-courses'>
          <DashBoxCourses />
        </div>
      </div>
      <div className='col-3 dash-box'>
        <div className='dash-content dash-box-speciality'>
          <DashBoxSpeciality />
        </div>
      </div>
      <div className={`${isVisitorUser ? 'col-6' : 'col-3'} dash-box`}>
        <div className='dash-content dash-box-marketing'>
          <DashBoxMarketing />
        </div>
      </div>
      {!isVisitorUser && (
        <div className='col-3 dash-box'>
          <div className='dash-content dash-box-transcript'>
            <DashBoxTranscript />
          </div>
        </div>
      )}
      <div className='col-9 dash-box'>
        <div className='dash-content dash-box-event'>
          <DashBoxEvent onOpenUrl={onOpenUrl} />
        </div>
      </div>
      <div className='col-3 dash-box'>
        <div className='dash-content dash-box-authors'>
          <DashBoxAuthors />
        </div>
      </div>
    </div>
  );
};

export default Home;
