/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef, useState } from 'react';
import topLogo from 'assets/ACE-logo.svg';
import topLogoRSNA from 'assets/RSNA_logo.svg';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowDown } from 'shared/components/Icons';
import { logout } from 'app/Auth/actions';
import { hasAccess, hasAccessAny } from 'utils/permissionHelper';
import { getEventId, getEventUrl, onOpenExternalUrl } from 'utils/appHelpers';
import { navigation } from './configs';
import ResponsiveImage from 'shared/ResponsiveImage';

const HeaderNav = ({ openAdmin }) => {
  const eventId = getEventId();
  const dispatch = useDispatch();
  const [accountMenu, setAccountMenu] = useState();
  const [dropMenu, setDropMenu] = useState();
  const accountMenuEl = useRef();
  const dropMenuEl = useRef();
  const account = useSelector(state => state.account);
  const events = useSelector(state => state.events);
  const event = events && eventId ? events[eventId] : null;
  const isVisitorUser = hasAccess('visitor_user');

  const logoutProfile = e => {
    e.preventDefault();
    dispatch(logout());
  };

  useOutsideClick(accountMenuEl, () => setAccountMenu(false));
  useOutsideClick(dropMenuEl, () => setDropMenu(false));

  const isEvent = hasAccess('event_user');
  const path = isEvent ? getEventUrl() : '';
  const homeUrl = isEvent ? getEventUrl(true) : '/home';

  return (
    <div className='dashboard-header d-flex flex-column justify-content-center'>
      <div className='dashboard-nav w-100'>
        <div className='d-flex justify-content-between'>
          <Link to={homeUrl}>
            <img src={isEvent ? topLogoRSNA : topLogo} height='45' alt='topLogo' />
          </Link>
          <ul className='m-0 p-0 d-flex justify-content-end align-items-center'>
            {isEvent && event && <NavLink to={homeUrl}>RSNA 2020</NavLink>}
            {navigation &&
              navigation.map(nav => {
                if (nav.permission && !hasAccess(nav.permission)) return null;
                if (nav.menu)
                  return (
                    <li key={nav.name}>
                      <div
                        className={`drop-link ${dropMenu ? 'open' : ''}`}
                        onClick={e => setDropMenu(!dropMenu)}
                        ref={dropMenuEl}
                      >
                        {nav.name}{' '}
                        <IconArrowDown width='10' height='10' className='ml-1' color='#fff' />
                        {dropMenu && (
                          <ul className='drop-link-menu'>
                            {nav.menu.map((item, i) => {
                              if (item.hideIfPermission && hasAccessAny(item.hideIfPermission))
                                return null;
                              return (
                                <li key={i}>
                                  {item.path ? (
                                    <NavLink activeClassName='active' to={`${path}/${item.path}`}>
                                      {item.name}
                                    </NavLink>
                                  ) : (
                                    <a
                                      href={item.link}
                                      target='_blank'
                                      onClick={onOpenExternalUrl.bind(null, item.link)}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </li>
                  );
                return (
                  <li key={nav.name}>
                    <NavLink activeClassName='active' to={`${path}/${nav.path}`}>
                      {nav.name}
                    </NavLink>
                  </li>
                );
              })}
            {!isVisitorUser && (
              <li>
                <div
                  className={`drop-link drop-account d-flex align-items-center ${
                    accountMenu ? 'open' : ''
                  }`}
                  onClick={e => setAccountMenu(!accountMenu)}
                  ref={accountMenuEl}
                >
                  <div className='avatar'>
                    <ResponsiveImage imgUrl={account?.imageUrl} alt={account?.name} />
                  </div>
                  <span>
                    {account.firstName} <br /> {account.lastName}
                  </span>
                  <IconArrowDown width='10' height='10' color='#fff' />
                  {accountMenu && (
                    <ul className='drop-link-menu'>
                      <li>
                        <NavLink activeClassName='active' to={`${path}/profile`}>
                          Profile
                        </NavLink>
                      </li>
                      {(hasAccess('kol_user') || hasAccess('admin_access')) && (
                        <li>
                          <a
                            href='#'
                            onClick={e => {
                              e.preventDefault();
                              openAdmin();
                            }}
                          >
                            To Admin
                          </a>
                        </li>
                      )}
                      <li>
                        <a href='#' onClick={logoutProfile}>
                          Log out
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            )}
            {isVisitorUser && (
              <>
                <li key='login'>
                  <NavLink activeClassName='active' to={`/login`}>
                    Sign In
                  </NavLink>
                </li>
                <li key='register'>
                  <NavLink activeClassName='active' to={`/register`}>
                    Sign Up
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderNav;
