import React from 'react';
import { viewerDialogJson } from './../config/consts';
const { zoomAndMagnificationTool } = viewerDialogJson;

const ZoomAndMagnificationTools = () => (
  <div>
    <p>
      Generally, a double click into an image will magnify it-usually to the available size of the
      viewer. Mammograms
      <br /> are magnified to 1;1,i.e., original size.
    </p>
    {zoomAndMagnificationTool.map(({ icon, description, buttons, note }, index) => {
      return (
        <p key={index} className='mt-4 d-flex align-items-center help-description'>
          <img src={icon} alt={description} className='mr-4' />
          <span className='mr-4'>{description}</span>
          {buttons.map((btn, index) => (
            <button key={index}>{btn}</button>
          ))}
        </p>
      );
    })}
  </div>
);

export default ZoomAndMagnificationTools;
