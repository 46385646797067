import React from 'react';
import BackButton from 'shared/BackButton';

const HeaderSection = ({ title, hasBack, onBackClick }) => {
  return (
    <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between'>
      <div className='d-flex align-items-center'>
        <div className='course-name border-0 pr-4 text-two-line d-flex align-items-center'>
          {hasBack && <BackButton onClick={onBackClick} className='mr-2' />}
          {title}
        </div>
      </div>
    </div>
  );
};
export default HeaderSection;
