import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import FeedbackHeader from './FeedbackHeader';
import ButtonLine from 'shared/components/ButtonLine';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import FeedCheckbox from './components/FeedCheckbox';
import FeedRating from './components/FeedRating';
import FeedSelect from './components/FeedSelect';
import FeedText from './components/FeedText';
import Loading from 'shared/components/Loading';
import { getCourses } from '../Courses/actions';
import { useHistory } from 'react-router';
import { LESSON_STATUSES } from 'configs/constants';

const feedItemsComp = {
  rating: FeedRating,
  radio: FeedSelect,
  checkbox: FeedCheckbox,
  text: FeedText,
};

const CourseFeedback = ({ match, lesson, course }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { courseId } = match.params;
  const { enqueueSnackbar } = useSnackbar();
  const [userFeedBack, setUserFeedBack] = useState();
  const [feedItems, setFeedItems] = useState();
  const [fetch, setFetch] = useState(false);
  const [answers, setAnswers] = useState({});
  const courseCompleted =
    lesson &&
    (lesson.status === LESSON_STATUSES.inProgress || lesson.status === LESSON_STATUSES.completed);

  const getUserFeedback = async () => {
    try {
      const { data } = await Api.get(`/feedback/getuserfeedbacks/me/${courseId}`);
      const res = {};
      data.data.forEach(item => {
        res[item.courseFeedbackId] = item.value;
      });
      setAnswers(res);
      setUserFeedBack(data.data);
    } catch (err) {
      setUserFeedBack([]);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getCourseFeedback = async () => {
    try {
      const { data } = await Api.get(`/feedback/getcoursefeedbacks/${courseId}`);
      const res = data.data.map(item => ({ ...item.feedback, id: item.id }));
      setFeedItems(res);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const updateLesson = async () => {
    try {
      const body = { lessonId: Number(lesson.id), completed: 1, courseId: course.id };
      await Api.post('/courses/updateuserlesson', body);
      dispatch(getCourses());
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      const feedbacks = Object.keys(answers).map(item => ({
        courseFeedbackId: item,
        value: answers[item],
      }));
      const body = { feedbacks };
      const { data } = await Api.post('/feedback/set', body);
      updateLesson();
      getUserFeedback();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const handleChange = data => {
    const tempAnswers = { ...answers };
    tempAnswers[data.id] = data.value;
    setAnswers(tempAnswers);
  };

  useEffect(() => {
    if (courseCompleted) {
      getUserFeedback();
      getCourseFeedback();
    }
    //eslint-disable-next-line
  }, [courseCompleted]);

  if ((!feedItems || !userFeedBack) && courseCompleted) return <Loading classView='min-vh-100' />;

  const isAnswered = userFeedBack && userFeedBack.length;

  if (!course.isEvaluationAvailable) {
    return (
      <div>
        <FeedbackHeader history={history} course={course} />
        <div className='text-center py-5 certificate-page'>
          No Evaluation is available for this course
        </div>
      </div>
    );
  }

  return (
    <div>
      <FeedbackHeader history={history} course={course} />
      {courseCompleted && (
        <div className='p-4 col-9'>
          <form onSubmit={handleSubmit}>
            <ul className='default-form p-0'>
              {feedItems &&
                feedItems.map(item => {
                  const FeedItem = feedItemsComp[item.type];
                  return (
                    <FeedItem
                      key={item.id}
                      data={item}
                      onChange={handleChange}
                      answers={answers}
                      isDisabled={isAnswered}
                      isAnswered={isAnswered}
                    />
                  );
                })}
              {!!isAnswered && (
                <li className='d-flex d-flex align-items-center'>
                  <div className='col-6 p-0'>
                    <label className='m-0'>Submission Date</label>
                  </div>
                  <div className='d-flex'>
                    {userFeedBack[0] ? moment(userFeedBack[0].createdAt).format('MM/DD/YYYY') : '-'}
                  </div>
                </li>
              )}
            </ul>
            {!isAnswered && (
              <div className='d-flex justify-content-end mt-2'>
                <ButtonLine
                  type='submit'
                  disabled={fetch || isAnswered}
                  className='btn-contact-send btn-blue'
                >
                  Send
                </ButtonLine>
              </div>
            )}
          </form>
        </div>
      )}
      {!courseCompleted && (
        <div className='text-center py-5 certificate-page'>
          Course evaluation will be available after completion of the course.
        </div>
      )}
    </div>
  );
};

export default CourseFeedback;
