import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import ButtonLine from 'shared/components/ButtonLine';
import { IconNotification } from 'shared/components/Icons';
import FeedCheckbox from './components/FeedCheckbox';
import FeedRating from './components/FeedRating';
import FeedSelect from './components/FeedSelect';
import FeedText from './components/FeedText';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { getCourses } from 'app/Main/routes/Courses/actions';

const feedItemsComp = {
  rating: FeedRating,
  radio: FeedSelect,
  checkbox: FeedCheckbox,
  text: FeedText,
};

const TypeEvaluation = ({ course }) => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(state => state.account);
  const [answers, setAnswers] = useState({});
  const [fetch, setFetch] = useState(false);
  const [userFeedBack, setUserFeedBack] = useState([]);
  const [lesson, setLesson] = useState();

  const dispatch = useDispatch();
  const params = useParams();
  const courseId = params?.id;
  const lessonId = params?.lessonId;
  const isCourseIdShareToken = isNaN(courseId);

  const hasFeedback = !!course.courseFeedbacks?.length;
  const isAnswered = userFeedBack && userFeedBack.length;

  const handleChange = data => {
    const tempAnswers = { ...answers };
    tempAnswers[data.feedbackItemId] = data.value;
    setAnswers(tempAnswers);
  };

  const getUserFeedback = async () => {
    try {
      const { data } = await Api.get(
        `/feedback/getuserfeedbacks/me/${
          isCourseIdShareToken ? course?.id : courseId || course?.id
        }`,
      );
      const res = {};
      data.data.forEach(item => {
        res[item.courseFeedbackId] = item.value;
      });
      setAnswers(res);
      setUserFeedBack(data.data);
    } catch (err) {
      setUserFeedBack([]);
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      const feedbacks = Object.keys(answers).map(item => ({
        courseFeedbackId: item,
        value: answers[item],
      }));
      const body = { feedbacks };
      const { data } = await Api.post('/feedback/set', body);
      dispatch(getCourses());
      getUserFeedback();
      enqueueSnackbar(data.message, { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    getUserFeedback();
  }, [user]);

  useEffect(() => {
    if (course) setLesson(course?.lessons?.find(lesson => lesson.id === Number(lessonId)));
    //eslint-disable-next-line
  }, [params]);

  return (
    <div>
      {hasFeedback && (
        <div className='p-4 col-9'>
          <form onSubmit={onFormSubmit}>
            <ul className='default-form p-0'>
              {course.courseFeedbacks &&
                course.courseFeedbacks.map((it, index) => {
                  const item = { ...it.feedback, id: it.id };
                  const FeedItem = feedItemsComp[item.type || item.feedbackType];
                  if (!FeedItem) return null;
                  return (
                    <FeedItem
                      index={index}
                      key={index}
                      data={item}
                      onChange={handleChange}
                      answers={answers}
                      isDisabled={isAnswered}
                      isAnswered={isAnswered}
                    />
                  );
                })}
            </ul>
            {!isAnswered && (
              <div className='d-flex justify-content-end mt-2'>
                <ButtonLine disabled={fetch} type='submit' className='btn-contact-send btn-blue'>
                  Send
                </ButtonLine>{' '}
              </div>
            )}
          </form>
          {!!isAnswered && (
            <li className='d-flex d-flex align-items-center'>
              <div className='col-6 p-0'>
                <label className='m-0'>Submission Date</label>
              </div>
              <div className='d-flex'>
                {userFeedBack[0] ? moment(userFeedBack[0].createdAt).format('MM/DD/YYYY') : '-'}
              </div>
            </li>
          )}
        </div>
      )}
      {!hasFeedback && (
        <div className='text-center mt-100'>
          <IconNotification width='90px' height='84px' className='mb-4' color='#131F6B' />
          <p className='fz-20'>
            Feedback form is not configured yet. <br /> Please, add content to see the preview.
          </p>
        </div>
      )}
    </div>
  );
};

export default TypeEvaluation;
