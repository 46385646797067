/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import EmptyView from '../EmptyView';
import { downloadFile } from 'utils/downloadHelpers';
import { Api } from 'utils/connectors';
import ZoomSlider from 'shared/components/ZoomSlider';
import { getCourses, onCourseUpdate } from 'app/Main/routes/Courses/actions';
import {
  IconDownload,
  IconFullScreenMaximize,
  IconFullScreenMinimize,
  IconPdf,
} from 'shared/components/Icons';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const TypePDF = ({ lesson, isExpandedView, setIsExpandedView, course }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const pdfContainerRef = useRef();
  const scrollingOffset = useRef();
  const regularWidth = window.innerWidth - 230 - 310;
  const expandedWidth = window.innerWidth - 230;
  const width = (isExpandedView ? expandedWidth : regularWidth) * zoomLevel;

  const updateLessonsTimeAndEpisode = page => {
    const tempCourse = { ...course };
    const lessonIndex = tempCourse.lessons.findIndex(item => item.id === lesson.id);
    const tempLesson = tempCourse.lessons[lessonIndex];
    tempLesson.completed = page;
    dispatch(onCourseUpdate(tempCourse));
  };

  const sendLessonTime = page => {
    const body = { lessonId: lesson.id, completed: page, courseId: course.id };
    Api.post('/courses/updateuserlesson', body);
    updateLessonsTimeAndEpisode(page);
    getCourses();
  };

  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setPages(new Array(numPages).fill(1));
  };

  const selectPage = index => {
    setTimeout(() => {
      const elementPDF = document.querySelector('.react-pdf__Document');
      if (elementPDF) elementPDF.scrollTop = 0;
      setPage(index + 1);
      sendLessonTime(index + 1);
    }, 0);
  };

  const debouncedSetZoomLevel = useCallback(
    debounce(newValue => {
      requestAnimationFrame(() => {
        setZoomLevel(newValue);
      });
    }, 10),
    [],
  );

  const handleZoomChange = (event, newValue) => {
    debouncedSetZoomLevel(newValue);
  };

  const handlePdfScrolling = () => {
    if (pages.length < 2) return;
    const elementPDF = document.querySelector('.react-pdf__Document');
    if (!elementPDF) return;
    const { scrollHeight, scrollTop, clientHeight } = elementPDF;
    const scrolledTillEnd = scrollTop + clientHeight >= scrollHeight;

    setTimeout(() => {
      const nextCondition =
        scrollingOffset.current < scrollTop && scrolledTillEnd && page !== pages.length;
      const prevCondition = scrollingOffset.current > scrollTop && scrollTop === 0 && page !== 1;
      if (nextCondition) {
        elementPDF.scrollTop = 1;
        setPage(page + 1);
        sendLessonTime(page + 1);
      } else if (prevCondition) {
        elementPDF.scrollTop = scrollHeight - clientHeight - 1;
        setPage(page - 1);
        sendLessonTime(page - 1);
      }
      scrollingOffset.current = scrollTop;
    }, 0);
  };

  const handleToggleExpandView = () => {
    setIsExpandedView(!isExpandedView);
    const elementPDF = document.querySelector('.react-pdf__Document');
    if (!isExpandedView) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
      if (elementPDF) {
        elementPDF.style.position = 'fixed';
        elementPDF.style.top = '80px';
        elementPDF.style.left = '230px';
        elementPDF.style.width = `calc(100% - 230px)`;
        elementPDF.style.height = 'calc(100% - 134px)';
        elementPDF.style.zIndex = '9999';
      }
    } else {
      document.body.style.overflow = 'auto';
      setZoomLevel(1);
      if (elementPDF) {
        elementPDF.style.position = '';
        elementPDF.style.top = '';
        elementPDF.style.left = '';
        elementPDF.style.width = '';
        elementPDF.style.height = '';
        elementPDF.style.zIndex = '';
      }
    }
  };

  const handleDownload = () => {
    downloadFile(lesson.contentUrl, `${lesson.name}.pdf`);
  };

  useEffect(() => {
    if (page > lesson.completed) {
      sendLessonTime(page);
    }
    //eslint-disable-next-line
  }, [page]);

  return (
    <>
      <div ref={pdfContainerRef} className='col p-0' onScroll={handlePdfScrolling} id='myElement'>
        {lesson.contentUrl && (
          <div className='video-area flex-1 d-flex justify-content-center h-100'>
            <Document
              file={lesson.contentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              id='myElement2'
            >
              <Page width={width} pageNumber={page} id='myElement3' />
            </Document>
            <div className={`pdf-actions ${isExpandedView && 'expanded'}`}>
              {isExpandedView && (
                <ZoomSlider
                  zoomLevel={zoomLevel}
                  handleZoomChange={handleZoomChange}
                  min={1}
                  max={2}
                  step={0.1}
                  ariaLabel='zoom-slider'
                  className='zoom-slider'
                  hasLabel
                />
              )}
              <div className='document-actions'>
                <div onClick={handleDownload} className='pdf-action-icon'>
                  <IconDownload />
                </div>
                <div onClick={handleToggleExpandView} className='pdf-action-icon'>
                  {isExpandedView ? <IconFullScreenMinimize /> : <IconFullScreenMaximize />}
                </div>
              </div>
            </div>
          </div>
        )}
        {!lesson.contentUrl && (
          <div className='video-area flex-1 d-flex justify-content-center h-100'>
            <EmptyView text='No PDF file specified.' Icon={IconPdf} />
          </div>
        )}
      </div>
      <div className='course-parts'>
        <p className='course-title'>{lesson.title} - Chapters</p>
        <div className='course-parts-lists'>
          {pages.map((item, index) => (
            <div
              onClick={() => selectPage(index)}
              role='button'
              tabIndex='-1'
              key={index}
              className={`part-item d-flex align-items-center px-3 ${
                index + 1 === page ? 'active' : ''
              }`}
            >
              <div>
                <p className='name'>Page N{index + 1}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TypePDF;
