import React, { useState, createRef, useEffect, useCallback } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import { Slider } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { Api } from 'utils/connectors';
import { onCourseUpdate, getCourses } from 'app/Main/routes/Courses/actions';
import { isMobileDevice } from 'utils/appHelpers';
import { downloadFile } from 'utils/downloadHelpers';
import {
  CircleFillIcon,
  IconDownload,
  IconFullScreenMaximize,
  IconFullScreenMinimize,
  VideoPlayCircleNotAllowedIcon,
} from 'shared/components/Icons';
import ZoomSlider from 'shared/components/ZoomSlider';
import ResponsiveImage from 'shared/ResponsiveImage';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const TypePDF = ({ lesson, course, getCourses, isExpandedView, setIsExpandedView }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(1);

  const pdfContainerRef = createRef();
  let scrollingOffset = 0;
  let PDFNode = null;
  const regularWidth = window.innerWidth - 230 - 310;
  const expandedWidth = window.innerWidth - 230;
  const width = (isExpandedView ? expandedWidth : regularWidth) * zoomLevel;

  const updateLessonsTimeAndEpisode = page => {
    const tempCourse = { ...course };
    const lessonIndex = tempCourse.lessons.findIndex(item => item.id === lesson.id);
    const tempLesson = tempCourse.lessons[lessonIndex];
    tempLesson.completed = page;
    dispatch(onCourseUpdate(tempCourse));
  };

  const sendLessonTime = page => {
    const body = { lessonId: lesson.id, completed: page, courseId: course.id };
    Api.post('/courses/updateuserlesson', body);
    updateLessonsTimeAndEpisode(page);
    getCourses();
  };

  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setPages(new Array(numPages).fill(1));
  };

  const selectPage = index => {
    if (!PDFNode) {
      PDFNode = Array.from(pdfContainerRef.current.children).find(
        elem => elem.className === 'react-pdf__Document' && elem,
      );
    }
    setTimeout(() => {
      PDFNode.scrollTop = 0;
      setPage(index + 1);
    }, 0);
  };

  const debouncedSetZoomLevel = useCallback(
    debounce(newValue => {
      requestAnimationFrame(() => {
        setZoomLevel(newValue);
      });
    }, 10),
    [],
  );

  const handleZoomChange = (event, newValue) => {
    debouncedSetZoomLevel(newValue);
  };

  const handlePdfScrolling = () => {
    if (pages.length < 2) return;
    if (!PDFNode) {
      PDFNode = Array.from(pdfContainerRef.current.children).find(
        elem => elem.className === 'react-pdf__Document' && elem,
      );
    }
    const { scrollHeight, scrollTop, clientHeight } = PDFNode;
    const scrolledTillEnd = scrollTop + clientHeight >= scrollHeight;

    setTimeout(() => {
      const nextCondition = scrollingOffset < scrollTop && scrolledTillEnd && page !== pages.length;
      const prevCondition = scrollingOffset > scrollTop && scrollTop === 0 && page !== 1;
      if (nextCondition) {
        PDFNode.scrollTop = 1;
        setPage(page + 1);
      } else if (prevCondition) {
        PDFNode.scrollTop = scrollHeight - clientHeight - 1;
        setPage(page - 1);
      }
      scrollingOffset = scrollTop;
    }, 0);
  };

  const handleToggleExpandView = () => {
    setIsExpandedView(!isExpandedView);
    const elementPDF = document.querySelector('.react-pdf__Document');
    if (!isExpandedView) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
      if (elementPDF) {
        elementPDF.style.position = 'fixed';
        elementPDF.style.top = '80px';
        elementPDF.style.left = '230px';
        elementPDF.style.width = `calc(100% - 230px)`;
        elementPDF.style.height = 'calc(100% - 134px)';
        elementPDF.style.zIndex = '9999';
      }
    } else {
      document.body.style.overflow = 'auto';
      setZoomLevel(1);
      if (elementPDF) {
        elementPDF.style.position = '';
        elementPDF.style.top = '';
        elementPDF.style.left = '';
        elementPDF.style.width = '';
        elementPDF.style.height = '';
        elementPDF.style.zIndex = '';
      }
    }
  };

  const handleDownload = () => {
    downloadFile(lesson.contentUrl, `${lesson.name}.pdf`);
  };

  useEffect(() => {
    if (page > lesson.completed) {
      sendLessonTime(page);
    }
    //eslint-disable-next-line
  }, [page]);

  return (
    <>
      {course.isMarketingMaterial && isMobileDevice() ? (
        <div className='video-area flex-1 d-flex justify-content-center flex-column'>
          <div className='mobile-img-sec'>
            <VideoPlayCircleNotAllowedIcon className='absolute-centered-video-play' />
            {!lesson.image && !lesson.imageUrl ? (
              <div className='marketing-mobile-no-image'>No Image</div>
            ) : (
              <ResponsiveImage
                imgUrl={lesson.image || lesson.imageUrl}
                height='100%'
                alt='lesson'
              />
            )}
          </div>
          {course?.isMarketingMaterial && isMobileDevice() && (
            <div className='mobile-marketing-lesson-name-container'>
              <div className='name-block'>
                <CircleFillIcon />
                <div className='text-block'>
                  <h2 className='title'>{lesson.title}</h2>
                  <p className='description'>{lesson.info}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            ref={pdfContainerRef}
            className='col p-0'
            onScroll={handlePdfScrolling}
            id='myElement'
          >
            <Document
              ref={pdfContainerRef}
              file={lesson.url || lesson.contentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              id='myElement2'
            >
              <Page width={width} pageNumber={page} id='myElement3' />
            </Document>
            <div className={`pdf-actions max-100-230 pl-5 ${isExpandedView && 'expanded'}`}>
              {isExpandedView && (
                <ZoomSlider
                  zoomLevel={zoomLevel}
                  handleZoomChange={handleZoomChange}
                  min={1}
                  max={2}
                  step={0.1}
                  ariaLabel='zoom-slider'
                  className='zoom-slider'
                  hasLabel
                />
              )}
              <div className='document-actions'>
                <div onClick={handleDownload} className='pdf-action-icon'>
                  <IconDownload />
                </div>
                <div onClick={handleToggleExpandView} className='pdf-action-icon'>
                  {isExpandedView ? <IconFullScreenMinimize /> : <IconFullScreenMaximize />}
                </div>
              </div>
            </div>
          </div>

          <div className='course-parts'>
            <p className='course-title'>{lesson.name} - Chapters</p>
            <div className='course-parts-lists'>
              {pages.map((item, index) => (
                <div
                  onClick={() => selectPage(index)}
                  role='button'
                  tabIndex='-1'
                  key={index}
                  className={`part-item d-flex align-items-center px-3 ${
                    index + 1 === page ? 'active' : ''
                  }`}
                >
                  <div>
                    <p className='name'>Page N{index + 1}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect(
  null,
  { getCourses },
)(TypePDF);
