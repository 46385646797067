import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { getCourses } from 'app/Main/routes/Courses/actions';
import { QuizStart } from './components/QuizStart';
import { QuizFlow } from './components/QuizFlow';
import { QuizStartModal } from './components/QuizStartModal';
import { QuestionContainer } from './components/QuestionContainer';
import { QuizTerminateModal } from './components/QuizTerminateModal';
import { QuizSuccess } from './components/QuizSuccess';
import { QuizFailure } from './components/QuizFailure';
import { QUESTION_TYPES_OBJ, QUIZ_STATUSES } from './constants';
import QuizResults from './components/QuizResults';
import OfflineModal from './components/OfflineModal';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';
import { getError, getEventUrl } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';
import { LESSON_STATUSES } from 'configs/constants';
import Loading from 'shared/components/Loading';

const TypeQuiz = ({ history, lesson, course, currentLessons, setLesson, lessonId, getCourses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenTerminateModal, setIsOpenTerminateModal] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [isOfflineModalOpen, setIsOfflineModalOpen] = useState(false);
  const [offlineCountdown, setOfflineCountdown] = useState(60);
  const [quiz, setQuiz] = useState(null);
  const [quizStartTime, setQuizStartTime] = useState(null);
  const [currentQuizQuestion, setCurrentQuizQuestion] = useState(null);
  const [isQuizInProgress, setIsQuizInProgress] = useState(false);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [passedQuestions, setPassedQuestions] = useState(null);
  const [currentAttempt, setCurrentAttempt] = useState(1);
  const [lastQuizDetails, setLastQuizDetails] = useState({});
  const [quizCompeteDetails, setQuizCompleteDetails] = useState(null);
  const [passingThresholdStep, setPassingThresholdStep] = useState(null);
  const [isShowResults, setIsShowResults] = useState(false);
  const [quizUsageSeconds, setQuizUsageSeconds] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [gotQuizCompletionOnMount, setIsGotQuizCompletionOnMount] = useState(false);

  const isLessonCompleted = lesson?.status === LESSON_STATUSES.completed;

  const offlineTimerRef = useRef(null);

  const path = hasAccess('event_user') ? getEventUrl() : '';

  const answerResults =
    quizCompeteDetails?.userQuiz?.quiz?.steps &&
    quizCompeteDetails.userQuiz.quiz.steps
      .filter(s => s.question)
      .map(step => ({
        ...step.question,
        status: step?.question?.userAnswerStatus,
      }));

  const handleOpenStartModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenTerminateModal = () => {
    setIsOpenTerminateModal(true);
  };

  const handleOffline = () => {
    setIsOfflineModalOpen(true);
    offlineTimerRef.current = setInterval(() => {
      setOfflineCountdown(prev => {
        if (prev === 1) {
          clearInterval(offlineTimerRef.current);
          history.goBack();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleOnline = () => {
    setIsOfflineModalOpen(false);
    clearInterval(offlineTimerRef.current);
    setOfflineCountdown(60);
    handleStartQuiz();
  };

  const handleShowResults = () => {
    setIsShowResults(true);
  };

  const getQuizDetails = async isOnMount => {
    try {
      setIsFetching(true);
      const { data } = await Api.get(`/courses/${course.id}/user-quizes`);
      setLastQuizDetails(
        data?.data?.userQuiz?.status === QUIZ_STATUSES.InProgress ? data.data?.userQuiz : {},
      );
      setCurrentAttempt(data?.data?.totalAttempts || currentAttempt);
      if (data?.data?.userQuiz?.status === QUIZ_STATUSES.Success) {
        setQuizCompleteDetails(data.data);
        setQuiz(data.data?.userQuiz?.quiz);
        setPassedQuestions(data.data.userQuiz.quiz.steps.filter(step => !!step.question));
        setIsQuizCompleted(true);
        if (isOnMount) {
          setIsGotQuizCompletionOnMount(true);
        }
        if (data?.data?.totalAttempts === data?.data?.userQuiz?.attempts || !isOnMount) {
          await getCourses();
        }
      }
      setIsFetching(false);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleStartQuiz = async () => {
    try {
      const { data } = await Api.post(`/courses/${course.id}/quiz/start`);
      const currStep = data.data.userQuiz.currentStep;
      const currentQuestion = {
        ...data.data.userQuiz.quiz.steps.find(({ step }) => step === currStep)?.question,
        timeLimit: data.data.userQuiz.quiz.steps.find(({ step }) => step === currStep)?.timeLimit,
      };
      setQuiz(data.data.userQuiz.quiz);
      setPassedQuestions(data.data.userQuiz.quiz.steps.filter(step => !!step.question));
      setCurrentQuizQuestion(currentQuestion);
      setQuizStartTime(data.data.userQuiz.startedAt);
      setQuizUsageSeconds(data?.data?.userQuiz?.duration);
      setPassingThresholdStep(data.data.userQuiz.passingThresholdStep);
      setCurrentStep(currStep);
      if (data.data.userQuiz.status === QUIZ_STATUSES.Success) {
        setIsQuizInProgress(false);
        setIsQuizCompleted(true);
        setQuizCompleteDetails(data.data);
        setCurrentStep(data.data.userQuiz.quiz.steps.length + 1);
        await getCourses();
      } else {
        setIsQuizCompleted(false);
        setIsQuizInProgress(true);
        setQuizCompleteDetails({});
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSkipStep = async () => {
    try {
      const res = await Api.post(`/courses/${course.id}/quiz/no-answer`, {
        questionId: currentQuizQuestion.id,
      });
      const { data } = res;
      const currStep = data.data.userQuiz.currentStep;
      const currentQuestion = {
        ...data.data.userQuiz.quiz.steps.find(({ step }) => step === currStep)?.question,
        timeLimit: data.data.userQuiz.quiz.steps.find(({ step }) => step === currStep)?.timeLimit,
      };
      setQuiz(data.data.userQuiz.quiz);
      setPassedQuestions(data.data.userQuiz.quiz.steps.filter(step => !!step.question));
      setCurrentQuizQuestion(currentQuestion);
      setCurrentStep(currStep);
      if (res?.status === 200) {
        setQuizCompleteDetails(res.data.data);
        setIsQuizCompleted(true);
        setIsQuizInProgress(false);
        setQuizStartTime(null);
        setCurrentQuizQuestion(null);
        setCurrentStep(1);
        getQuizDetails();
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const quizUsage = async ({ passedSeconds, passedSecondsOnQuestion }) => {
    try {
      const { data } = await Api.post(`/courses/${course.id}/quiz/usage`, {
        usage: passedSeconds,
        answerUsage: { questionId: currentQuizQuestion.id, usage: passedSecondsOnQuestion },
      });
      setQuizUsageSeconds(data?.data?.usage);
    } catch (err) {
      const { data } = err.response.data;
      const currStep = data?.userQuiz?.currentStep;
      const currentQuestion = {
        ...data?.userQuiz?.quiz?.steps?.find(({ step }) => step === currStep)?.question,
        timeLimit: data?.userQuiz?.quiz?.steps.find(({ step }) => step === currStep)?.timeLimit,
      };
      if (data?.userQuiz?.status === QUIZ_STATUSES.Expired) {
        setIsQuizCompleted(true);
        setIsQuizInProgress(false);
      }
      setPassingThresholdStep(data?.userQuiz?.passingThresholdStep);
      setQuiz(data?.userQuiz?.quiz);
      setQuizCompleteDetails(data);
      setPassedQuestions(data?.userQuiz?.quiz?.steps.filter(step => !!step.question));
      setCurrentQuizQuestion(currentQuestion);
      setCurrentStep(currStep);
      getQuizDetails();
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSubmitAnswer = async (answer, questionType) => {
    if (!answer.length) {
      handleSkipStep();
      return;
    }
    try {
      const body = {
        questionId: currentQuizQuestion.id,
        imageAnswer: questionType === QUESTION_TYPES_OBJ['image-category'] ? answer : null,
        answer: questionType !== QUESTION_TYPES_OBJ['image-category'] ? answer : null,
      };

      const res = await Api.post(`/courses/${course.id}/quiz/answer`, body);
      const { data } = res;
      const currStep = data.data.userQuiz.currentStep;
      const currentQuestion = {
        ...data.data.userQuiz.quiz.steps.find(({ step }) => step === currStep)?.question,
        timeLimit: data.data.userQuiz.quiz.steps.find(({ step }) => step === currStep)?.timeLimit,
      };
      setQuiz(data.data.userQuiz.quiz);
      setPassedQuestions(data.data.userQuiz.quiz.steps.filter(step => !!step.question));
      setPassingThresholdStep(data.data.userQuiz.passingThresholdStep);
      setCurrentQuizQuestion(currentQuestion);
      setCurrentStep(currStep);
      if (res?.status === 200) {
        setQuizCompleteDetails(res.data.data);
        setIsQuizCompleted(true);
        setIsQuizInProgress(false);
        setQuizStartTime(null);
        setCurrentQuizQuestion(null);
        setCurrentStep(1);
        getQuizDetails();
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleTerminateQuiz = async () => {
    try {
      const res = await Api.post(`/courses/${course.id}/quiz/terminate`);
      setQuizStartTime(null);
      setIsQuizInProgress(false);
      setCurrentQuizQuestion(null);
      setIsQuizCompleted(false);
      setPassedQuestions(null);
      setCurrentStep(1);
      enqueueSnackbar(res.data.message, { variant: 'success' });
      getQuizDetails();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleResetQuiz = () => {
    setQuizStartTime(null);
    setIsQuizInProgress(false);
    setCurrentQuizQuestion(null);
    setIsQuizCompleted(false);
    setPassedQuestions(null);
    setCurrentStep(1);
    setQuiz({});
  };

  const handleRetakeCourse = () => {
    const firstLesson = currentLessons[0];
    const lessonPath = `${path}/courses/${course.id}/${firstLesson.type}/${firstLesson.id}`;
    history.push(lessonPath);
  };

  const handleClose = () => {
    const lastLesson = currentLessons[currentLessons.length - 1];
    const lessonPath = `${path}/courses/${course.id}/${lastLesson.type}/${lastLesson.id}`;
    history.push(lessonPath);
  };

  const handleGoToCourses = () => history.push('/courses');

  useEffect(() => {
    if (course) {
      const currLesson = currentLessons.find(lesson => lesson.id === Number(lessonId));
      setLesson(currLesson);
      setCurrentAttempt(currLesson?.attempt);
      if (currLesson) {
        setPassingThresholdStep(
          Math.ceil((currLesson.steps?.length * currLesson.passingThreshold) / 100),
        );
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getQuizDetails(true);
    return () => {
      setQuizStartTime(null);
      setIsQuizInProgress(false);
      setCurrentQuizQuestion(null);
      setIsQuizCompleted(false);
      setPassedQuestions(null);
      setCurrentStep(1);
      setIsShowResults(false);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = e => {
      if (isQuizInProgress) {
        e.preventDefault();
        e.returnValue = 'The quiz will be terminated as you leave! Are you sure you want to leave?';
        return 'The quiz will be terminated as you leave! Are you sure you want to leave?';
      }
    };

    if (lesson.terminateOnLeave) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (lesson.terminateOnLeave) window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isQuizInProgress]);

  useEffect(() => {
    // Only attach listeners if the quiz is in progress and not completed
    if (isQuizInProgress && !isQuizCompleted) {
      window.addEventListener('offline', handleOffline);
      window.addEventListener('online', handleOnline);
    }

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
      clearInterval(offlineTimerRef.current);
    };
  }, [isQuizInProgress, isQuizCompleted]);

  useEffect(() => {
    if (isQuizInProgress && lesson.terminateOnLeave) {
      const unblock = history.block((location, action) => {
        // If navigating within the app, show your custom modal
        if (action !== 'PUSH' && action !== 'REPLACE') {
          return; // Allow 'POP' navigation actions (like back button)
        }

        handleOpenTerminateModal();
        return false; // Block the navigation
      });

      return () => {
        unblock(); // Stop blocking when component unmounts or condition changes
      };
    }
  }, [isQuizInProgress]);

  useEffect(() => {
    if (offlineCountdown === 0) {
      handleResetQuiz();
    }
  }, [offlineCountdown]);

  return (
    <>
      <QuizStartModal
        onStart={handleStartQuiz}
        passedDuration={quizUsageSeconds}
        lesson={lesson}
        isOpen={isModalOpen}
        currentAttempt={currentAttempt}
        onClose={() => setIsModalOpen(false)}
      />
      <QuizTerminateModal
        passedDuration={quizUsageSeconds}
        currentAttempt={currentAttempt}
        onTerminate={handleTerminateQuiz}
        lesson={lesson}
        isOpen={isOpenTerminateModal}
        onClose={() => setIsOpenTerminateModal(false)}
      />
      <OfflineModal isOpen={isOfflineModalOpen} countdown={offlineCountdown} />
      {!isLessonCompleted && !isQuizInProgress && !isQuizCompleted && (
        <div className='quiz-area preview flex-1 d-flex justify-content-center'>
          <QuizStart
            status={lastQuizDetails?.status}
            handleOpenStartModal={handleOpenStartModal}
            handleContinue={handleStartQuiz}
            isLessonCompleted={isLessonCompleted}
          />
        </div>
      )}
      {(isLessonCompleted || isQuizCompleted) && (
        <>
          {isFetching && (
            <div className='w-100'>
              <Loading />
            </div>
          )}
          {quizCompeteDetails?.userQuiz?.status === QUIZ_STATUSES.Success &&
            !isShowResults &&
            !isFetching && (
              <div className='quiz-area flex-1 d-flex justify-content-center'>
                <QuizSuccess
                  gotQuizCompletionOnMount={gotQuizCompletionOnMount}
                  isFetching={isFetching}
                  handleClose={handleClose}
                  quizCompeteDetails={quizCompeteDetails}
                  handleShowAnswers={handleShowResults}
                />
              </div>
            )}
          {(quizCompeteDetails?.userQuiz?.status === QUIZ_STATUSES.Failure ||
            quizCompeteDetails?.userQuiz?.status === QUIZ_STATUSES.Expired) &&
            !isShowResults &&
            !isFetching &&
            !gotQuizCompletionOnMount && (
              <div className='quiz-area flex-1 d-flex justify-content-center'>
                <QuizFailure
                  currentAttempt={currentAttempt}
                  handleShowAnswers={handleShowResults}
                  quizCompeteDetails={quizCompeteDetails}
                  totalAttemptsCount={lesson.attempts}
                  showWhatIsWrong={lesson.showWhatIsWrong}
                  handleRetakeQuiz={handleOpenStartModal}
                  handleRetakeCourse={handleRetakeCourse}
                  handleClickOk={handleGoToCourses}
                />
              </div>
            )}
        </>
      )}
      {isShowResults && (
        <QuizResults
          results={answerResults}
          totalLength={quizCompeteDetails?.userQuiz?.quiz?.steps?.length}
        />
      )}
      {isQuizInProgress && (
        <div className='quiz-area flex-1 d-flex justify-content-center'>
          <QuestionContainer
            handleSubmitAnswer={handleSubmitAnswer}
            answer={answer}
            setAnswer={setAnswer}
            currentStep={currentStep}
            isRequired={lesson?.steps?.[currentStep - 1]?.required}
            questionsCount={lesson.steps.length}
            question={currentQuizQuestion}
            isLessonCompleted={isLessonCompleted}
          />
        </div>
      )}
      <div className='course-parts d-flex flex-column'>
        <QuizFlow
          quizUsage={quizUsage}
          handleSkipStep={handleSkipStep}
          isModalOpen={isOfflineModalOpen || isOpenTerminateModal || !isQuizInProgress}
          passingThresholdStep={passingThresholdStep}
          answer={answer}
          setAnswer={setAnswer}
          isQuizCompleted={isQuizCompleted}
          getQuizDetails={getQuizDetails}
          passedDuration={lastQuizDetails.duration || 0}
          questionPassedDuration={lastQuizDetails?.userCourseAnswers?.[currentStep - 1]?.duration}
          courseId={course.id}
          question={currentQuizQuestion}
          currentStep={currentStep}
          quiz={quiz}
          lesson={lesson}
          passedQuestions={passedQuestions}
          startTime={quizStartTime}
          onClickLeave={lesson.terminateOnLeave ? handleOpenTerminateModal : history.goBack}
          isQuizInProgress={isQuizInProgress}
          handleSubmitAnswer={handleSubmitAnswer}
        />
      </div>
    </>
  );
};

export default connect(
  null,
  { getCourses },
)(TypeQuiz);
