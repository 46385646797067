import React from 'react';

const EventAbout = ({ event }) => {
  return (
    <div className='event-about' id='aboutSection'>
      <div className='container'>
        <h1 className='text-right large-title'>About Event</h1>
        <div className='info' dangerouslySetInnerHTML={{ __html: event.about }} />
        <p className='text-black-50 text-right'>
          *Not all products or technologies featured are available in all markets
        </p>
      </div>
    </div>
  );
};

export default EventAbout;
