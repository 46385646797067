// linkUtils.js
import { confirmAlert } from 'react-confirm-alert';

export const goWithExternalLink = (url, event) => {
  if (event) {
    event.preventDefault();
  }

  if (!url) return;

  confirmAlert({
    message:
      'A new browser tab will now be opened. You can close it at any time and come back to this tab to continue.',
    buttons: [
      {
        label: 'Continue',
        onClick: () => window.open(url, '_blank'),
      },
      {
        label: 'Close',
      },
    ],
  });
};
