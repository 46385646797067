import React from 'react';
import HeaderSection from './HeaderSection';
import TypeCasesPlay from 'app/PublicCourseView/CoursePreview/components/TypeCasesPlay';
import { generateViewerData } from 'app/Main/routes/Viewer/utils';

const CaseView = ({ caseData }) => {
  const cases = generateViewerData(caseData, { episodes: [] });

  const constructData = {
    isOpened: true,
    title: caseData.title,
    cases,
  };

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <HeaderSection title={caseData.title} />
      <TypeCasesPlay fullData={constructData} />
    </div>
  );
};

export default CaseView;
