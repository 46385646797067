/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import iconBreast from 'assets/dash/spec-breast.png';
import iconScreening from 'assets/dash/spec-screening.png';
import iconSurgery from 'assets/dash/spec-surgery.png';
import iconSkeletal from 'assets/dash/spec-skeletal.png';
import { useHistory } from 'react-router';

const DashBoxSpeciality = () => {
  const history = useHistory();

  const goToCourses = type => {
    history.push(`/allcourses/${type}`);
  };

  return (
    <div className='d-flex flex-column justify-content-between h-100'>
      <div className='d-flex justify-content-between'>
        <div className='spec-box pointer' onClick={goToCourses.bind(null, 1)}>
          <img className='imgl' src={iconScreening} alt='im' />
          <p className='mb-0'>
            Breast <br /> Screening
          </p>
        </div>
        <div className='spec-box pointer' onClick={goToCourses.bind(null, 2)}>
          <img src={iconBreast} alt='im' />
          <p className='mb-0'>
            Breast <br /> Diagnostics
          </p>
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        <div className='spec-box pointer' onClick={goToCourses.bind(null, 3)}>
          <img src={iconSurgery} alt='im' />
          <p className='mb-0'>
            Breast <br /> Surgery
          </p>
        </div>
        <div className='spec-box skeletal pointer' onClick={goToCourses.bind(null, 4)}>
          <img src={iconSkeletal} alt='im' />
          <p className='mb-3'>Skeletal</p>
        </div>
      </div>
    </div>
  );
};

export default DashBoxSpeciality;
