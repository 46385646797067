import React from 'react';
import { viewerDialogJson } from './../config/consts';
const { navigation } = viewerDialogJson;

const Navigation = () => (
  <div>
    {navigation.map(({ icon, description, buttons = [], isSlash, width }, index) => {
      return (
        <p key={index} className='mt-4 d-flex align-items-center help-description'>
          <img style={{ width }} src={icon} alt={description} className='mr-4' />
          <span className='mr-4'>{description}</span>
          {buttons.map((btn, index) => (
            <button className={`${isSlash ? 'btn-slash' : ''}`} key={index}>
              {btn}
            </button>
          ))}
        </p>
      );
    })}
  </div>
);

export default Navigation;
