import React from 'react';

const ProgressBar = ({ progress, className = '' }) => (
  <span
    className={`plan-progress ${className} ${
      progress && progress < 100 ? 'progress-incomplete' : ''
    }`}
  >
    <span className='progress-completed' style={{ width: `${progress}%` }} />
  </span>
);

export default ProgressBar;
