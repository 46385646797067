import React, { useEffect, useState, useRef, Fragment } from 'react';
import Loading from 'shared/components/Loading';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { useSnackbar } from 'notistack';

const Input = ({ forId, type, name, label, value, onChange, className, checked, disabled }) => {
  const inputRef = useRef();
  const active = inputRef.current && inputRef.current.checked;

  return (
    <div
      className={`${type === 'checkbox' ? 'input-checkbox' : ''} ${
        active ? 'active' : ''
      } ${className}`}
    >
      <input
        className='input-custom input-custom-question'
        id={forId}
        ref={inputRef}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={type !== 'checkbox'}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={forId} className='mb-0'>
        {label}
      </label>
    </div>
  );
};

const QuestionaryModal = ({ changeStep, completedSteps, step, lesson, isOpened, caseId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [initialForm, setInitialForm] = useState(null);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [result, setResult] = useState({});
  const isDisabled = completedSteps.length < step.disabled_no_active;

  const handleSubmit = async e => {
    e.preventDefault();
    completedSteps.push(9);
    changeStep(10);
  };

  const handleChange = (id, value, type, index) => {
    let modifiedValue = '';
    if (type === 'checkbox' && result[id]) {
      let values = result[id].split(',');
      values[index] = value;
      modifiedValue = values.join(',');
    }
    const res = { ...result, [id]: type === 'checkbox' ? modifiedValue : value };
    setResult(res);
  };

  const getQuestionary = async () => {
    if (lesson.caseQuestions) {
      const initalQuestion = [
        {
          question_type: 'radio',
          answers: [
            { name: 'No Findings', value: 'a' },
            { name: 'Findings Available', value: 'b', subQuestions: lesson.caseQuestions[caseId] },
          ],
        },
      ];
      setInitialForm(initalQuestion);
    } else if (lesson.episodes) {
      let body = lesson.episodes.filter(item => item.caseId === caseId);
      body = body.sort((a, b) => a.orderNum - b.orderNum);
      if (!body[9]) return;
      try {
        const { id } = body[9];
        const { data } = await Api.get(`/questionary/getepisodequestionaries/${id}`);
        data.data.forEach((group, i) => {
          data.data[i].answers = group.options;
          data.data[i].question_type = group.type;
          data.data[i].answers.forEach((sGroup, sIndex) => {
            sGroup.name = sGroup.key;
            if (sGroup.subQuestions) {
              sGroup.subQuestions.forEach((ssGroup, ssIndex) => {
                ssGroup.answers = ssGroup.options;
                ssGroup.question_type = ssGroup.type;
                ssGroup.name = ssGroup.key;
              });
            }
          });
        });
        setInitialForm(data.data);
      } catch (err) {
        enqueueSnackbar(getError(err), { variant: 'error' });
      }
    }
  };

  const fillUserAnswers = group => {
    if (group.userAnswer) {
      if (!alreadyAnswered) setAlreadyAnswered(true);
      return group.userAnswer;
    }
    if (group.question_type === 'checkbox') {
      return Array(group.answers.length)
        .fill(false)
        .join(',');
    }
    return null;
  };

  const initializeResults = () => {
    if (!initialForm) return;
    const result = {};
    initialForm.forEach((group, gIndex) => {
      result[gIndex] = fillUserAnswers(group);
      group.answers.forEach((subGroup, subGroupIndex) => {
        if (subGroup.subQuestions) {
          subGroup.subQuestions.forEach((subGroupOption, i) => {
            result[i] = fillUserAnswers(subGroupOption);
          });
        }
      });
    });
    setResult(result);
  };

  const isInputChecked = (subG, subGroup, sGroupIndex, sIndex) => {
    if (subGroup.question_type === 'checkbox') {
      if (result[`sg${sGroupIndex}`]) {
        const arr = result[`sg${sGroupIndex}`].split(',');
        return arr[sIndex] === 'true';
      }
      return false;
    } else {
      return subG.value === result[sGroupIndex];
    }
  };

  useEffect(() => {
    if (!isDisabled) getQuestionary();
    //eslint-disable-next-line
  }, []);

  useEffect(initializeResults, [initialForm]);

  if (isOpened) {
    return (
      <div className='p-4 text-center text-white w-100'>
        This step is not required for this case
      </div>
    );
  }

  if (isDisabled) {
    return (
      <div className='p-4 text-center text-white w-100'>
        You must first review all steps of the hanging protocol as indicated below
      </div>
    );
  }

  if (!initialForm) return <Loading classView='justify-content-around w-100' />;

  return (
    <div className='modal-custom modal__fullscreen modal__fluid modal-questionary'>
      <div className='inner-content questionary-content'>
        <h5 className='mb-2'>Score the most suspicious finding</h5>
        <form className='d-flex flex-column flex-fill' onSubmit={handleSubmit} name='questionForm'>
          <div className='flex-fill overflow-auto questions-area'>
            {initialForm.length > 0 &&
              initialForm.map((group, gIndex) => {
                // LEVEL 1
                return (
                  <Fragment key={gIndex}>
                    {group.answers &&
                      group.answers.map((groupOption, gOptIndex) => {
                        const checked = groupOption.value === result[`g${gIndex}`];
                        const isCorrect = group.correct === groupOption.value;
                        // ITEM ON LEVEL 1
                        return (
                          <Fragment key={gOptIndex}>
                            <Input
                              id={group.name}
                              forId={groupOption.name}
                              type={group.question_type}
                              name={`g${gIndex}`}
                              value={groupOption.value}
                              checked={checked}
                              disabled={alreadyAnswered}
                              onChange={e =>
                                handleChange(
                                  `g${gIndex}`,
                                  group.question_type === 'radio'
                                    ? e.target.value
                                    : e.target.checked,
                                  group.question_type,
                                  gOptIndex,
                                )
                              }
                              label={groupOption.name}
                              className={`${isCorrect && alreadyAnswered ? 'a-correct' : ''} ${
                                checked && !isCorrect && alreadyAnswered ? 'a-incorrect' : ''
                              } question-parent d-flex align-items-center`}
                            />
                            {groupOption.subQuestions &&
                              groupOption.subQuestions.length > 0 &&
                              result[`g${gIndex}`] === groupOption.value && (
                                <div className='question-group'>
                                  {groupOption.subQuestions.map((subGroup, sGroupIndex) => {
                                    // LEVEL 2
                                    return (
                                      <div
                                        className={`sub-question-group sub-question-group__${
                                          subGroup.question_type
                                        }`}
                                        key={sGroupIndex}
                                      >
                                        {subGroup.answers &&
                                          subGroup.answers.map((subG, sIndex) => {
                                            let isCorrect = subGroup.correct === subG.value;
                                            let checked = isInputChecked(
                                              subG,
                                              subGroup,
                                              `sg${sGroupIndex}`,
                                              `sga${sIndex}`,
                                            );
                                            const isCheckBox =
                                              subGroup.question_type === 'checkbox';
                                            if (isCheckBox) {
                                              isCorrect =
                                                subGroup.correct &&
                                                subGroup.correct.split(',')[`sga${sIndex}`] ===
                                                  'true';
                                            }
                                            // ITEM ON LEVEL 2
                                            return (
                                              <Fragment key={sIndex}>
                                                <Input
                                                  id={`sga${sIndex}`}
                                                  label={subG.name}
                                                  forId={subG.name}
                                                  type={subGroup.question_type}
                                                  name={`sga${sIndex}`}
                                                  value={subG.value}
                                                  checked={isInputChecked(
                                                    subG,
                                                    subGroup,
                                                    `sga${sIndex}`,
                                                  )}
                                                  disabled={alreadyAnswered}
                                                  onChange={e =>
                                                    handleChange(
                                                      `sga${sIndex}`,
                                                      subGroup.question_type === 'radio'
                                                        ? e.target.value
                                                        : e.target.checked,
                                                      subGroup.question_type,
                                                      `sga${sIndex}`,
                                                    )
                                                  }
                                                  className={`${
                                                    isCorrect && alreadyAnswered
                                                      ? `a-correct${isCheckBox ? '-check' : ''}`
                                                      : ''
                                                  } ${
                                                    checked && !isCorrect && alreadyAnswered
                                                      ? `a-incorrect${isCheckBox ? '-check' : ''}`
                                                      : ''
                                                  } question-child`}
                                                />
                                              </Fragment>
                                            );
                                          })}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                          </Fragment>
                        );
                      })}
                  </Fragment>
                );
              })}
          </div>
          <div className='d-flex align-items-center justify-content-end'>
            <button
              disabled={alreadyAnswered}
              type='submit'
              className='btn btn-blue-dark btn-rounded btn-outlined-white btn-width-300 mt-3'
            >
              Submit Assessment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuestionaryModal;
