import React, { useState } from 'react';
import Button from 'shared/components/Button';
import { ChangePasswordModal } from '.';

const PasswordManage = ({ email }) => {
  const [open, toggleModal] = useState(false);
  return (
    <>
      <ul className='list-default'>
        <li className='d-flex px-0 align-items-center'>
          <div className='col weight-900'>Change Password:</div>
          <div className='col weight-100 d-flex justify-content-end'>
            <Button className='btn-white' onClick={() => toggleModal(true)}>
              Change
            </Button>
          </div>
        </li>
      </ul>
      {open && <ChangePasswordModal email={email} onClose={() => toggleModal(false)} />}
    </>
  );
};

export default PasswordManage;
