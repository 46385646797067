/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import ResponsiveImage from 'shared/ResponsiveImage';
import { IconCirclePlay, IconLock } from 'shared/components/Icons';
import { saveToStore } from 'utils/storeHelpers';

const EventCourses = ({ event }) => {
  const isAuth = useSelector(state => state.isAuthenticated);

  const openItemUrl = () => {
    if (!isAuth) saveToStore('redirect', `/events/${event.id}/dashboard/allcourses`);
    const url = isAuth
      ? `/events/${event.eventUrl}/dashboard/allcourses`
      : `/events/${event.eventUrl}/login`;
    window.open(url, '_blank');
  };

  return (
    <div className='event-courses' id='noncmeSection'>
      <div className='container'>
        <h1 className='text-right large-title'>
          <span>Non-CME</span>
          Courses
        </h1>
        <div className='event-cme-list d-flex justify-content-between'>
          {event.courses &&
            event.courses.map(item => {
              const Icon = item.isLocked ? IconLock : IconCirclePlay;
              return (
                <div
                  className={`cme-block d-flex flex-column ${item.isLocked ? 'locked' : ''}`}
                  key={item.id}
                >
                  <div className='text-center bg-secondary img-area'>
                    <div
                      className='icon-area d-flex pointer justify-content-center align-items-center'
                      onClick={openItemUrl}
                    >
                      <Icon />
                    </div>
                    <ResponsiveImage imgUrl={item.imageUrl} height='190' alt='o' />
                  </div>
                  <div className='info-area flex-1 d-flex flex-column justify-content-between'>
                    <p className='title pointer' onClick={openItemUrl}>
                      {item.title}
                    </p>
                    <p className='info'>{item.description}</p>
                    <a
                      href={
                        isAuth
                          ? `/events/${event.eventUrl}/dashboard/allcourses`
                          : `/events/${event.id}/login`
                      }
                    >
                      Open
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default EventCourses;
