import React from 'react';
import Year16Img from '../mockImages/16y.png';
import Year20Img from '../mockImages/20y.png';
import Year30Img from '../mockImages/30y.png';
import Year40Img from '../mockImages/40y.png';
import Year50Img from '../mockImages/50y.png';
import Year60Img from '../mockImages/60y.png';
import CardImg from '../mockImages/preparation-card-img.png';
import Button from 'shared/components/Button';
import ResponsiveImage from 'shared/ResponsiveImage';

const ageGroups = [
  { ageRange: '13-19', image: Year16Img },
  { ageRange: '20s', image: Year20Img },
  { ageRange: '30s', image: Year30Img },
  { ageRange: '40s', image: Year40Img },
  { ageRange: '50s', image: Year50Img },
  { ageRange: '60+', image: Year60Img },
];

const boldTexts = [
  'Evaluation and Appropriate Testing',
  'STI Testing',
  'Immunizations',
  'Visual Exam of Breast & Pelvic Area',
];

const ExamPreparationSection = () => {
  return (
    <div className='prep-section'>
      <div className='title-block'>
        <h2 className='section-title'>What to Expect at Your Well Woman Exam</h2>
        <p className='section-desc'>
          Depending on your age, the Well Woman exam may cover different health topics. <br /> Let’s
          take a look at what you can expect.
        </p>
      </div>
      <div className='circles-row-block'>
        {ageGroups.map((group, index) => (
          <div className={`circle-container ${!index && 'selected'}`} key={index}>
            <div className='circle'>
              <ResponsiveImage alt='year' imgUrl={group.image} />
            </div>
            <span className='age-range'>{group.ageRange}</span>
          </div>
        ))}
      </div>
      <div className='card-block'>
        <div className='info-block'>
          <h5 className='sub-section-title'>In Your Late Teens</h5>
          <p className='section-desc max-row-5 text-align-left'>
            {' '}
            This is likely your first Well Woman exam. Now is the time to establish open
            communication with your health provider. Be willing to have an honest discussion about
            your sexual and reproductive health – no topic should be off-limits. While your Well
            Woman exam will be designed with your unique health in mind, you can expect it to
            include the following elements:
          </p>
          <div className='row-texts-block'>
            {boldTexts.map((text, idx) => (
              <span key={idx} className='b-text'>
                {text}
              </span>
            ))}
          </div>
          <Button className='btn-purple-filled'>Learn More</Button>
        </div>
        <div className='img-block'>
          <img alt='year' src={CardImg} />
        </div>
      </div>
    </div>
  );
};

export default ExamPreparationSection;
