import React from 'react';
import { Link } from 'react-router-dom';
import { IconAuthorsNew } from 'shared/components/Icons';
import { DropDown, DropdownHeader, DropdownBody } from 'shared/components/DropDown';
import { hasAccess } from 'utils/permissionHelper';
import { getEventUrl } from 'utils/appHelpers';
import ResponsiveImage from 'shared/ResponsiveImage';

const avatarPlaceholder =
  'https://i1.wp.com/ggrmlawfirm.com/wp-content/uploads/avatar-placeholder.png?fit=256%2C256&ssl=1';

const AuthorsDropdown = ({ data = [] }) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';
  return (
    <DropDown>
      <DropdownHeader>
        <span className='mr-2 d-flex'>
          <IconAuthorsNew /> Faculty:
        </span>
      </DropdownHeader>
      <DropdownBody>
        <ul className='authors-dropdown'>
          {data.length > 0 &&
            data.map(item => {
              const { id, name = '', surname, profession = '', image } = item;
              return (
                <li className='drop-item' key={id}>
                  <Link to={`${path}/authors`} className='d-flex'>
                    <div className='author-image'>
                      <ResponsiveImage imgUrl={image || avatarPlaceholder} alt={name} />
                    </div>
                    <p className='text-truncate d-flex flex-column justify-content-center m-0'>
                      <span className='text-truncate item-name'>
                        {name} {surname}
                      </span>
                      <span className='text-truncate item-description'>{profession}</span>
                    </p>
                  </Link>
                </li>
              );
            })}
          {!data.length && <li className='drop-item disabled text-center'>No results found...</li>}
        </ul>
      </DropdownBody>
    </DropDown>
  );
};

export default AuthorsDropdown;
