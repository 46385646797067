import React, { useState } from 'react';
import LandingCircleProgress from 'shared/components/LandingCircleProgress';

const CoursesProgressCircle = props => {
  const { coursesForProgresses } = props;
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className='course-progress-container'>
      {coursesForProgresses.map((course, index) => (
        <React.Fragment key={index}>
          <LandingCircleProgress
            course={course}
            isHovered={hoveredIndex === index}
            containerClassName={`circle-item ${course.progress === 100 && 'active'}`}
            progress={course.progress}
            key={index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default CoursesProgressCircle;
