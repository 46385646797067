import React from 'react';
import { SOCIALS } from 'configs/constants';

const footerTexts = [
  'Privacy Policy',
  'Terms & Conditions',
  'Patent Info',
  'AdvaMed Code of Ethics',
  'International Modern Slavery Statement',
];

const LandingFooter = ({ texts = footerTexts }) => {
  return (
    <>
      <div className='bottom-footer'>
        <div className='icons-block'>
          {SOCIALS.map((item, idx) => (
            <a key={idx} href={item.url} target='_blank' rel='noreferrer'>
              <img alt={item.url} src={item.img} />
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default LandingFooter;
