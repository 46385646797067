import React from 'react';
import ACELogo from 'assets/logos/ACE-logo_product-page.png';

const Header = () => {
  return (
    <div className='header'>
      <div className='top-sec'>
        <p className='mb-0'>Women’s Health Index</p>
        <p className='mb-0'>Project Health Equity</p>
        <p className='mb-0'>Careers</p>
      </div>
      <div className='bottom-sec'>
        <img alt='Hologic' src={ACELogo} height={60} />
      </div>
    </div>
  );
};

export default Header;
