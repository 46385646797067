import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { setDiagnosticData } from './actions';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { IconRight } from 'shared/components/Icons';
import { ResolutionTest, LatencyTest, BandwidthTest } from './components';
import Modal from 'react-modal';
import SpinnerLoading from './components/SpinnerLoading';
import Feedback from './components/Feedback';
import { saveToStore } from 'utils/storeHelpers';

navigator.userAgentVersion = (function() {
  var ua = navigator.userAgent;
  var tem;
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();

navigator.userOsInfo = (function() {
  var OSName = 'Unknown';
  if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) OSName = 'Windows 10';
  if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) OSName = 'Windows 8';
  if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) OSName = 'Windows 7';
  if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) OSName = 'Windows Vista';
  if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) OSName = 'Windows XP';
  if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) OSName = 'Windows 2000';
  if (window.navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Mac/OS';
  if (window.navigator.userAgent.indexOf('X11') !== -1) OSName = 'UNIX';
  if (window.navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux';
  return OSName;
})();

let fetching = false;

const ConnectionAndDeviceTestModal = ({ isOpen, onFinish }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const storageData = useSelector(state => state.diagnosticData);

  const [finished, setFinished] = useState(false);
  const [requestConfirmation, setRequestConfirmation] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [stoppedEarly, setStoppedEarly] = useState(false);

  const sendConnectionAndTestResults = useCallback(async () => {
    if (fetching) return;
    fetching = true;
    const result = {
      testData: JSON.stringify({
        ...storageData,
        browser: navigator.userAgentVersion,
        os: navigator.userOsInfo,
      }),
    };
    try {
      await Api.post('/common/saveconnectiontest', { ...result });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
    fetching = false;
  }, [enqueueSnackbar, storageData]);

  const defineStorageKey = () => {
    saveToStore('connectionAndDeviceTested', true);
  };

  const finishAndCloseDialog = () => {
    defineStorageKey();
    dispatch(
      setDiagnosticData({
        resolution: null,
        latency: null,
        bandwidth: null,
        failed: false,
      }),
    );
    onFinish();
  };

  const setLoadingWidth = () => {
    const width = storageData.failed
      ? 100
      : (100 / 3) * Object.values(storageData).filter(v => v && v !== true).length; //objects count only
    return `${width}%`;
  };

  const setRequirementsCheckbox = () => {
    const { bandwidth, latency, resolution, failed } = storageData;
    const allSuccess =
      bandwidth &&
      bandwidth.success &&
      latency &&
      latency.success &&
      resolution &&
      resolution.success &&
      !failed;
    setDisabled(!allSuccess);
    setRequestConfirmation(!allSuccess);
  };

  const manageSuccess = useCallback(() => {
    const { resolution, latency, bandwidth } = storageData;
    const success = resolution.success && latency.success && bandwidth.success;
    setFinished(true);
    sendConnectionAndTestResults();
    if (!success) setRequirementsCheckbox();
    //eslint-disable-next-line
  }, [sendConnectionAndTestResults, storageData]);

  const manageFailure = useCallback(() => {
    setRequirementsCheckbox();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { resolution, latency, bandwidth } = storageData;
    if (storageData.failed) manageFailure();
    if (resolution && latency && bandwidth) manageSuccess();
  }, [storageData, manageFailure, manageSuccess]);

  return (
    <Modal
      className='testing-modal flex-column d-flex'
      isOpen={isOpen}
      contentLabel='Connection and device test'
    >
      <div className='modal-header'>
        <h5 className='m-0'>Connection Test</h5>
      </div>
      <div className='modal-body flex-1'>
        <ResolutionTest />
        <LatencyTest />
        <BandwidthTest stoppedEarly={stoppedEarly} setStoppedEarly={setStoppedEarly} />
        <div className='box'>
          <p className='m-0'>
            <span className='weight-600 mr-2'>Connection to server:</span>
            <span className='weight-100'>
              {!storageData.latency ? (
                <SpinnerLoading />
              ) : storageData.failed && !stoppedEarly ? (
                <>{storageData.latencyTimedOut ? 'Not defined' : 'No'}</>
              ) : (
                <>{storageData.latencyTimedOut ? 'Not defined' : 'Yes'}</>
              )}
            </span>
          </p>
        </div>
        <Feedback
          stoppedEarly={stoppedEarly}
          data={storageData.latency}
          optimalSettings={100000}
          minimalSettings={8000}
          resultKey='min'
          successText='Connection to server has no problem'
          failText='Connection to server failed'
          aborted={storageData.failed}
        />
      </div>
      <div className='modal-footer'>
        {requestConfirmation && (
          <div className='d-flex align-items-center small'>
            <input
              id='agreementCheckbox'
              type='checkbox'
              checked={!disabled}
              onChange={e => setDisabled(!e.target.checked)}
            />
            <label htmlFor='agreementCheckbox' className='mb-0'>
              <small className='opc-6 ml-2'>
                I wish to proceed knowing my system may not meet the proper requirements.
              </small>
            </label>
          </div>
        )}
        {(finished || requestConfirmation) && (
          <button
            className='col bg-white text-right p-0 modal-continue'
            disabled={disabled}
            onClick={finishAndCloseDialog}
          >
            <span className='mr-2'>Continue</span>
            <IconRight />
          </button>
        )}
        <div className='col p-0 connection-progress'>
          <div
            className='progress-completed'
            style={{
              width: setLoadingWidth(),
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConnectionAndDeviceTestModal;
