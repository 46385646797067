import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { EyeIcon } from 'shared/components/Icons';
import { loginUser } from 'app/Auth/actions';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import LeftLayout from 'app/Product/components/LeftLayout';
import Button from 'shared/components/Button';
import RightLayout from 'app/Product/components/RightLayout';
import useQuery from 'shared/hooks/useQuery';
import { API_RESPONSE_STATUSES } from 'configs/constants';

const ProductLogin = ({ match, history, location }) => {
  const { id, urlPath } = match.params;
  const queryParams = useQuery();
  const { description, currentEmail = '', currentPassword = '' } = location?.state || {};

  const { entity = '', spec = '' } = queryParams;

  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [auth, setAuth] = useState({
    email: currentEmail,
    password: currentPassword,
    eventId: id,
  });

  const qParams = `${entity ? `entity=${entity}` : ''}${
    spec ? `${entity ? '&' : ''}spec=${spec}` : ''
  }`;

  const leftLayoutDetails = {
    entity: entity,
    entityForwardPageKey: 'reset-password',
    description:
      description ||
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever ",
  };

  const rightLayoutDetails = {
    footerForwardUrl: entity
      ? `/product/${urlPath}/register?${qParams}`
      : `/product/${urlPath}/entity-select`,
    question: 'Don’t have an account yet?',
    buttonText: 'Sign Up',
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const { data } = await Api.post('/auth/login', auth);
      if (data?.result === 0) {
        await dispatch(
          loginUser(data, history, `/product/${urlPath}?entity=${entity}&spec=${spec}`),
        );
      }
      if (data?.data?.pendingConfirmation) {
        history.push({
          pathname: `/product/${urlPath}/verify`,
          search: `?${qParams}`,
          state: { registeringUserEmail: auth.email },
        });
      }
    } catch (err) {
      setError(getError(err));
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...auth };
    tempAuth[name] = value;
    setAuth(tempAuth);
  };

  return (
    <div className='product-sign-container d-flex flex-column min-vh-100'>
      <div className='flex-1 d-flex'>
        <LeftLayout {...leftLayoutDetails} />
        <RightLayout {...rightLayoutDetails}>
          <div className='form-block'>
            <h5 className='desc-title'>Please Sign In to Continue</h5>
            <form onSubmit={handleSubmit}>
              <div className='inputs-block'>
                <InputGroup
                  type='text'
                  name='email'
                  onChange={handleChange}
                  className='focus-bottom-border input-item'
                  placeholder='Email address*'
                  value={auth.email}
                  required
                />
                <InputGroup
                  hasError={error}
                  type='password'
                  name='password'
                  onChange={handleChange}
                  className='focus-bottom-border input-item'
                  placeholder='Password*'
                  value={auth.password}
                  required
                  eyeIcon={<EyeIcon />}
                />
                {error && <div className='error-text'>{error}</div>}
                <Link
                  className='forgot-pass-link'
                  to={`/product/${urlPath}/reset-password?entity=${entity}&spec=${spec}`}
                >
                  Forgot Password?
                </Link>
              </div>
              <Button
                className='btn-blue-dark-redesigned form-button w-100'
                type='submit'
                disabled={!auth.email || !auth.password || fetch}
                disabledClassName='btn-disabled-gray'
              >
                Sign In
              </Button>
            </form>
          </div>
        </RightLayout>
      </div>
    </div>
  );
};

export default ProductLogin;
