import React from 'react';
import { Helmet } from 'react-helmet';

const capitalizeWord = word => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
};

const isNumeric = str => {
  if (typeof str != 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const PageTitle = ({ match }) => {
  let urlParts = match.path.split('/');
  let firstWordOfUrl = urlParts?.[1] ? capitalizeWord(urlParts[1]) : '';
  let secondWordOfUrl =
    urlParts?.[2] && !urlParts[2].startsWith(':') ? capitalizeWord(urlParts[2]) : '';
  let thirdWordOfUrl =
    urlParts?.[3] && !urlParts[3].startsWith(':') ? capitalizeWord(urlParts[3]) : '';
  let paramId = (!isNumeric(match.params.id) && match.params.id) || '';
  let paramType = (!isNumeric(match.params.type) && match.params.type) || '';
  paramType = paramType ? capitalizeWord(paramType) : '';

  let title = `${firstWordOfUrl} ${secondWordOfUrl} ${thirdWordOfUrl} ${paramId} ${paramType}`.trim();

  return (
    <Helmet>
      <title>{`ACE` || title}</title>
    </Helmet>
  );
};

export default PageTitle;
