import React, { useEffect, useState } from 'react';
import { IconEducation } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';

const PrivacyAndPolicy = () => {
  const [html, setHtml] = useState();

  const getPrivacyData = async () => {
    const { data } = await Api.get('/common/privacy');
    setHtml(data);
  };

  useEffect(() => {
    getPrivacyData();
  }, []);

  if (!html) return <Loading classView='min-vh-100' />;

  return (
    <>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconEducation color='#131f6b' />
          </span>
          Privacy and Policy
        </div>
      </div>
      <div className='pt-4 px-4'>
        <div
          className='col-9 mt-3 mb-5 privacy-content'
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </div>
    </>
  );
};

export default PrivacyAndPolicy;
