import React, { useState, createRef, useEffect } from 'react';

const CodeVerificationInputs = ({ onCodeChange, onPaste, onEnter }) => {
  const [inputs, setInputs] = useState(Array(6).fill(''));
  const elements = Array(6)
    .fill()
    .map((_, idx) => createRef());

  const focusNext = (idx, str) => {
    if (str && idx < 5) {
      elements[idx + 1].current.focus();
    }
  };

  const handleChange = (str, idx) => {
    if (!str || str.match(/[0-9]/)) {
      setInputs(inputs.map((s, index) => (index === idx ? str : s)));
      focusNext(idx, str);
      onCodeChange(idx, str); // Notify the parent component of the change
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (
      pastedData &&
      pastedData.length === 6 &&
      pastedData.split('').every(char => !Number.isNaN(parseInt(char, 10)))
    ) {
      const pastedArray = pastedData.split('');
      setInputs(pastedArray);
      onPaste(pastedArray); // Pass the pasted array to the parent component
      elements[5].current.focus();
    }
  };

  useEffect(() => {
    elements[0].current.focus();
  }, []);

  return (
    <div className='d-flex justify-content-center gap-3 verify_code_block'>
      <div className='d-flex justify-content-between verify_code_sub_block'>
        {inputs.slice(0, 3).map((input, idx) => (
          <input
            key={idx}
            value={input}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onEnter();
              }
            }}
            onPaste={handlePaste}
            onChange={e => handleChange(e.target.value, idx)}
            maxLength={1}
            style={{ width: '30px', height: '30px', textAlign: 'center', border: '1px solid gray' }}
            ref={elements[idx]}
          />
        ))}
      </div>
      <div className='d-flex justify-content-between verify_code_sub_block'>
        {inputs.slice(3, 6).map((input, idx) => (
          <input
            key={idx + 3}
            value={input}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                onEnter();
              }
            }}
            onPaste={handlePaste}
            onChange={e => handleChange(e.target.value, idx + 3)}
            maxLength={1}
            style={{ width: '30px', height: '30px', textAlign: 'center', border: '1px solid gray' }}
            ref={elements[idx + 3]}
          />
        ))}
      </div>
    </div>
  );
};

export default CodeVerificationInputs;
