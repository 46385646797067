import React from 'react';
import { Link } from 'react-router-dom';
import blueLogo from 'assets/ACE-logo-blue.svg';

const LinkInfo = ({ title, link, name }) => (
  <p>
    <span className='opc-3'>{title}</span>
    <Link className='link ml-2' to={link}>
      {name}
    </Link>
  </p>
);

const AuthBlock = ({
  title,
  subTitle,
  children,
  links,
  successBlock,
  noFooterInfo,
  large,
  subTitleClassName = 'opc-3',
}) => {
  return (
    <div
      className={`align-items-center auth-block d-flex justify-content-between py-5 ${large &&
        'large'}`}
    >
      <div className='text-center col-9'>
        <Link to='/login'>
          <img src={blueLogo} alt='logo' height='75' className='mt-5' />
        </Link>
        {successBlock ? (
          successBlock
        ) : (
          <>
            {title && <h3 className='title text-center mt-5'>{title}</h3>}
            {subTitle && <p className={subTitleClassName}>{subTitle}</p>}
            <div className='align-items-center d-flex flex-column'>{children}</div>
          </>
        )}
      </div>
      {!noFooterInfo && (
        <div className='text-center'>
          {links.reset && <LinkInfo title='Forgot password?' link='/forgot' name='Reset' />}
          {links.signup && (
            <LinkInfo title='Don’t have an account?' link='/register' name='Sign Up' />
          )}
          {links.signin && (
            <LinkInfo
              title='Already have an account?'
              link={links.signinUrl || '/login'}
              name='Sign In'
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AuthBlock;
