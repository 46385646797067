import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
import HelpModal from './components/HelpModal/';
import QuestionaryModal from './components/QuestionaryModal/';
import ExplanationModal from './components/ExplanationModal/';
import ControlArea from './components/ControlArea';
import StepsArea from './components/StepsArea';
import CanvasView from './components/CanvasView';
import { steps as initialSteps, leftOverlayViews, rightOverlayViews, tools } from './configs';
import { Api } from 'utils/connectors';
import { generateViewerData } from './utils';
import Loading from 'shared/components/Loading';
import { getCourses, onCourseUpdate } from '../Courses/actions';
import HeatmapModal from './components/HeatmapModal';
import { removeFromStore } from 'utils/storeHelpers';
import { getError } from 'utils/appHelpers';
import SpinnerLoading from 'app/Main/components/ConnectionAndDeviceTestModal/components/SpinnerLoading';
import './style.scss';
import CanvasOverlay from './components/CanvasOverlay';
import { OVERLAY_VIEW_KEY_BY_IDX } from './constants';

const LATERALITY_BY_POSITION = {
  right: 'R',
  left: 'L',
};

const STEP_VIEWS_OVERLAYS_BY_LATERALITY = {
  L: leftOverlayViews,
  R: rightOverlayViews,
};

const Viewer = ({ match, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const courses = useSelector(store => store.courses);
  const viewerOptions = useSelector(state => state.viewerOptions);
  const dispatch = useDispatch();
  const { courseId, lessonId } = match.params;

  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState({});
  const [tempSelectedPoint, setTempSelectedPoint] = useState(null);
  const [fetch, setFetch] = useState();
  const [course, setCourse] = useState();
  const [activeCase, setActiveCase] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayData, setOverlayData] = useState(null);
  const [selectedViewLaterality, setSelectedViewLaterality] = useState(null);
  const [selectedDicomIndex, setSelectedDicomIndex] = useState(null);
  const [mainSliceIndex, setMainSliceIndex] = useState(0);
  const [sideSliceIndex, setSideSliceIndex] = useState(0);
  const [overlayViewsLoading, setOverlayViewsLoading] = useState({ 0: false, 1: false });
  const [overlayStackControlByIdx, setOverlayStackControlByIdx] = useState({
    0: undefined,
    1: undefined,
  });
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const reduxCourse = courses?.find(item => item?.id === Number(courseId));
  const reduxLesson =
    reduxCourse && reduxCourse.lessons.find(lesson => lesson.id === Number(lessonId));

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  const handelActiveToolChange = tool => {
    setActiveTool(tool);
    if (tool && tool.type === 'findingReset') resetFinding();
  };

  const getCases = async () => {
    if (!reduxLesson || !reduxLesson.caseIds || !reduxLesson.caseIds.length) return;
    const { caseId, caseUniqueId } = reduxLesson.caseIds[activeCase];
    const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
    const { data } = await Api.get(endpoint);
    const cases = generateViewerData(data.data, reduxLesson);
    setCourse({
      title: reduxLesson.name,
      lesson: reduxLesson,
      cases,
    });
    // Update first step
    if (!cases[0].completed_steps.includes(0)) {
      updateDicomStepData(0);
    }
  };

  const onCaseChange = async value => {
    if (!course.cases[value]) {
      setFetch(true);
      const { caseId, caseUniqueId } = reduxLesson.caseIds[value];
      const endpoint = `/cases/getcasebyid/${caseUniqueId || caseId}?lut=1&dicomType=all`;
      const { data } = await Api.get(endpoint);
      const caseData = generateViewerData(data.data, reduxLesson);
      const cases = [...course.cases, ...caseData];
      if (!cases[value].completed_steps.includes(0)) {
        updateDicomStepData(0, caseId);
        cases[value].completed_steps.push(0);
      }
      await setCourse({ ...course, cases });
      await setActiveCase(value);
      setFetch(false);
    } else {
      setActiveCase(value);
    }
  };

  const updateLessonsEpisode = index => {
    const tempCourse = { ...reduxCourse };
    const lessonIndex = tempCourse.lessons.findIndex(item => item.id === Number(lessonId));
    const tempLesson = tempCourse.lessons[lessonIndex];
    const tempEpisode = tempLesson.episodes[index];
    if (tempEpisode) {
      tempEpisode.status = 1;
      dispatch(onCourseUpdate(tempCourse));
    }
  };

  const updateDicomStepData = async (step, id) => {
    const caseId = id || reduxLesson.caseIds[activeCase].caseId;
    const body = {
      lessonId,
      completed: 1,
      step: step + 1,
      caseId: Number(caseId),
      courseId: reduxCourse?.id,
    };
    await Api.post('/courses/updateuserlesson', body);
    updateLessonsEpisode(step);
  };

  const updateAndAddCompletedStep = async step => {
    await updateDicomStepData(step);
    completedSteps.push(step);
  };

  const handleClickCloseAssociations = () => {
    setTempSelectedPoint(null);
    setSelectedPoint({});

    document.querySelectorAll('.viewport-element').forEach(el => {
      const selectTool = cornerstoneTools.getToolForElement(el, 'SelectTool');
      if (selectTool && typeof selectTool.clearSelection === 'function') {
        selectTool.clearSelection(el);
      }
    });
  };

  const stepChangeHandler = async step => {
    await handleClickCloseAssociations();
    const isModal = step === 9 || step === 10 || step === 11;
    if (isModal) setActiveTool({});
    if (!completedSteps.includes(step) && !isModal) {
      await updateAndAddCompletedStep(step);
    }
    setActiveStep(step);
  };

  const updateFinding = async body => {
    await Api.post('/dicom/savedicomvectordata', body);
  };

  const resetFinding = async () => {
    try {
      const body = {
        userLessonId: reduxLesson.userLessonId,
        caseId: Number(reduxLesson.caseIds[activeCase].caseId),
      };
      await Api.delete('/dicom/dicomvectordata', {
        data: body,
      });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const lessonStartLog = () => {
    Api.post('/courses/lesson/start', {
      courseId: reduxCourse.id,
      lessonId: reduxLesson.id,
    });
  };

  const handleOpenOverlay = (newOverlayData, newCanvasViewKey, newCaseViewPosition, newIdx) => {
    const isSameOverlay =
      overlayData?.handles?.end?.id === newOverlayData?.handles?.end?.id &&
      selectedDicomIndex === newIdx &&
      selectedViewLaterality === LATERALITY_BY_POSITION[newCaseViewPosition];

    // If the overlay is the same, do not update state to prevent re-render and infinite loop
    if (showOverlay && isSameOverlay) {
      return;
    }
    setSelectedViewLaterality(LATERALITY_BY_POSITION[newCaseViewPosition]);
    setOverlayData(newOverlayData);
    setSelectedDicomIndex(newIdx);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setOverlayData(null);
  };

  useEffect(() => {
    fullScreenChange();
    getCases();
    removeFromStore('viewerToolTip');
    lessonStartLog();
    //eslint-disable-next-line
  }, []);

  // THIS MAYBE NEEDED TO REMOVE THEN
  useEffect(() => {
    if (!showOverlay) {
      setOverlayData(null);
      setSelectedViewLaterality(null);
      setSelectedDicomIndex(null);
    }
  }, [showOverlay]);

  if (!course || fetch) return <Loading classView='min-vh-100' />;

  let steps = [...initialSteps];

  const selectedCase = reduxLesson.caseIds[activeCase];
  if (!!reduxCourse.isOpened || !selectedCase.hasHeatmap) {
    // remove questionary and heatmaps
    const excludeNames = reduxCourse.isOpened
      ? ['question', 'heatmap']
      : !selectedCase.hasHeatmap
      ? ['heatmap']
      : [];
    steps = steps.filter(item => !excludeNames.includes(item.name));
  }

  const isModal = steps[activeStep].type === 'modal';
  const caseViews = course.cases[activeCase].views;
  const completedSteps = course.cases[activeCase].completed_steps;
  const stepViews = steps[activeStep].views;
  const stepViewsData = Object.values(stepViews);
  const viewsCount = Object.values(stepViews).length;

  const sideDicomIndex = selectedDicomIndex === 1 ? 0 : 1;
  const defaultTypeKey = '3DQ';

  const findCorrelationIndex = typeKey =>
    caseViews?.[
      `${selectedViewLaterality}${
        OVERLAY_VIEW_KEY_BY_IDX?.[selectedDicomIndex]
      }_${typeKey?.toUpperCase()}`
    ]?.images?.findIndex(slice =>
      slice.geniusAIDataList.some(gaid => {
        if (overlayData?.handles?.end?.designator) {
          return gaid?.designator === overlayData?.handles?.end?.designator;
        } else {
          return gaid?.id === overlayData?.handles?.end?.id;
        }
      }),
    );

  const correlation3DQIndex = findCorrelationIndex('3DQ');
  const correlationTomoIndex = findCorrelationIndex('TOMO');

  const correlationTypeKey =
    correlation3DQIndex !== -1 ? '3DQ' : correlationTomoIndex !== -1 ? 'tomo' : defaultTypeKey;
  const correlationIndex =
    correlationTypeKey === 'tomo' ? correlationTomoIndex : correlation3DQIndex;

  const findSideDicomCorrelationIndex = typeKey => {
    const sideDicomImages =
      caseViews?.[
        `${selectedViewLaterality}${
          OVERLAY_VIEW_KEY_BY_IDX?.[sideDicomIndex]
        }_${typeKey?.toUpperCase()}`
      ]?.images;
    return sideDicomImages?.findIndex(slice =>
      slice.geniusAIDataList.some(
        gaid => gaid?.designator === overlayData?.handles?.end?.designator,
      ),
    );
  };

  // Manual Switch for GAID2
  const manualMainCorrelationTypeKey = overlayStackControlByIdx[selectedDicomIndex];
  const manualSideCorrelationTypeKey = overlayStackControlByIdx[sideDicomIndex];
  const manualMainCorrelationIndex = findCorrelationIndex(manualMainCorrelationTypeKey);
  const manualSideCorrelationIndex = findSideDicomCorrelationIndex(manualSideCorrelationTypeKey);

  const manualMainCorrelationSlice =
    manualMainCorrelationIndex !== -1 && imagesLoaded ? manualMainCorrelationIndex : null;
  // Made side correlation to be in the same slice as main when not found
  const manualSideCorrelationSlice = imagesLoaded
    ? manualSideCorrelationIndex !== -1
      ? manualSideCorrelationIndex
      : manualMainCorrelationSlice ?? correlationIndex
    : null;

  const sideIdx = findSideDicomCorrelationIndex(correlationTypeKey);
  const sideDicomCorrelationIndex = sideIdx === -1 ? correlationIndex : sideIdx;

  // If manually changed recalculate the differences
  const currentMainCorrelationSlice =
    typeof manualMainCorrelationSlice === 'number' ? manualMainCorrelationSlice : correlationIndex;
  const currentSideCorrelationSlice =
    typeof manualSideCorrelationSlice === 'number'
      ? manualSideCorrelationSlice
      : sideDicomCorrelationIndex;

  const slicesDifference = currentMainCorrelationSlice - currentSideCorrelationSlice;

  const gaid2SlicesConfigs = {
    mainSliceIndex,
    sideSliceIndex,
    slicesDifference,
    overlayData,
    imagesLoaded,
    overlayStackControlByIdx,
    overlayViewsLoading,
    setOverlayViewsLoading,
    setMainSliceIndex,
    setSideSliceIndex,
    setOverlayStackControlByIdx,
    setImagesLoaded,
  };

  return (
    <div className='viewer d-flex flex-column flex-fill'>
      <ControlArea
        course={course}
        fullScreen={fullScreen}
        activeTool={activeTool}
        setActiveTool={handelActiveToolChange}
        history={history}
        viewerOptions={viewerOptions}
        tools={tools}
      />
      <div
        className={`viewer-area d-flex ${fullScreen ? 'fullscreen' : ''}`}
        key={`${activeCase}_${activeStep}`}
      >
        {!isModal &&
          stepViewsData.map((item, index) => {
            return (
              <CanvasView
                key={item.key + index}
                dataTomo={caseViews[`${item.key}_TOMO`]}
                data2D={caseViews[`${item.key}_2D`]}
                data3DQ={caseViews[`${item.key}_3DQ`]}
                step={item}
                caseViews={caseViews}
                allSteps={steps}
                activeTool={activeTool}
                setActiveTool={handelActiveToolChange}
                fullScreen={fullScreen}
                index={index}
                viewsCount={viewsCount}
                updateFinding={updateFinding}
                viewerOptions={viewerOptions}
                setActiveStep={setActiveStep}
                selectedPoint={selectedPoint}
                setSelectedPoint={setSelectedPoint}
                tempSelectedPoint={tempSelectedPoint}
                setTempSelectedPoint={setTempSelectedPoint}
                handleClickClose={handleClickCloseAssociations}
                openOverlay={handleOpenOverlay}
                showOverlay={showOverlay}
                canvasViewKey={item.key}
                position={item.position}
                correlationIndex={
                  index === selectedDicomIndex ? correlationIndex : sideDicomCorrelationIndex
                }
                sideDicomCorrelationIndex={sideDicomCorrelationIndex}
              />
            );
          })}
        {showOverlay && (
          <CanvasOverlay
            isLoading={overlayViewsLoading?.[0] || overlayViewsLoading?.[1]}
            fullScreen={fullScreen}
            activeCase={activeCase}
            activeStep={activeStep}
            onClose={handleCloseOverlay}
          >
            {!!selectedViewLaterality &&
              Object.values(STEP_VIEWS_OVERLAYS_BY_LATERALITY[selectedViewLaterality]).map(
                (item, index) => {
                  const currentCorrelationIndex =
                    index === selectedDicomIndex
                      ? manualMainCorrelationSlice ?? correlationIndex
                      : manualSideCorrelationSlice ?? sideDicomCorrelationIndex;
                  const defaultStackControl =
                    index === selectedDicomIndex
                      ? manualMainCorrelationTypeKey || correlationTypeKey
                      : manualSideCorrelationTypeKey || correlationTypeKey;
                  return (
                    <CanvasView
                      key={item.key + index + 'overlay'}
                      dataTomo={
                        caseViews[`${selectedViewLaterality}${OVERLAY_VIEW_KEY_BY_IDX[index]}_TOMO`]
                      }
                      data2D={
                        caseViews[`${selectedViewLaterality}${OVERLAY_VIEW_KEY_BY_IDX[index]}_2D`]
                      }
                      data3DQ={
                        caseViews[`${selectedViewLaterality}${OVERLAY_VIEW_KEY_BY_IDX[index]}_3DQ`]
                      }
                      step={item}
                      activeTool={activeTool}
                      setActiveTool={handelActiveToolChange}
                      fullScreen={fullScreen}
                      index={index}
                      viewsCount={viewsCount}
                      updateFinding={updateFinding}
                      viewerOptions={viewerOptions}
                      selectedPoint={selectedPoint}
                      setSelectedPoint={setSelectedPoint}
                      tempSelectedPoint={tempSelectedPoint}
                      setTempSelectedPoint={setTempSelectedPoint}
                      handleClickClose={handleClickCloseAssociations}
                      openOverlay={handleOpenOverlay}
                      canvasViewKey={item.key}
                      position={item.position}
                      defaultStackControl={defaultStackControl}
                      correlationIndex={currentCorrelationIndex}
                      sideDicomCorrelationIndex={sideDicomCorrelationIndex}
                      isPrimaryDicom={index === selectedDicomIndex}
                      isGaid2View
                      {...gaid2SlicesConfigs}
                    />
                  );
                },
              )}
          </CanvasOverlay>
        )}
        {isModal && (
          <>
            {steps[activeStep].name === 'question' && (
              <QuestionaryModal
                episodes={reduxLesson.episodes}
                lessonId={lessonId}
                changeStep={stepChangeHandler}
                completedSteps={completedSteps}
                step={steps[activeStep]}
                isOpened={reduxCourse.isOpened}
                caseId={reduxLesson.caseIds[activeCase].caseId}
                updateLessonsEpisode={updateLessonsEpisode}
                course={reduxCourse}
              />
            )}
            {steps[activeStep].name === 'explanator' && (
              <ExplanationModal
                isDisabled={completedSteps.length < steps[activeStep].disabled_no_active}
                updateStep={updateAndAddCompletedStep}
                caseData={reduxLesson.caseIds[activeCase]}
                lessonId={lessonId}
                caseId={reduxLesson.caseIds[activeCase].caseId}
                caseViews={caseViews}
              />
            )}
            {steps[activeStep].name === 'heatmap' && (
              <HeatmapModal
                isDisabled={completedSteps.length < steps[activeStep].disabled_no_active}
                caseId={reduxLesson.caseIds[activeCase].caseId}
                lessonId={lessonId}
                isOpened={reduxCourse.isOpened}
                userLessonId={reduxLesson.userLessonId}
                updateStep={updateAndAddCompletedStep}
              />
            )}
          </>
        )}
      </div>
      <StepsArea
        reduxCourse={reduxCourse}
        reduxLesson={reduxLesson}
        steps={steps}
        completedSteps={completedSteps}
        activeCase={activeCase}
        changeActiveCase={onCaseChange}
        activeStep={activeStep}
        changeActiveStep={stepChangeHandler}
        setActiveStep={setActiveStep}
        onHelpModalOpen={setOpenHelpModal}
        history={history}
      />
      {openHelpModal && <HelpModal onModalClose={() => setOpenHelpModal(false)} />}
    </div>
  );
};

export default Viewer;
