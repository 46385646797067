import React, { useEffect, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { SortableImgItem } from './components/SortableImageItem';
import { Column } from './components/Column';
import { DroppableUncategorizedArea } from './components/DroppableUncategorizedArea';
import { scrollVerticallyToContainerEnd } from 'utils/domHelpers';

const getCategories = ansImageCategories => {
  const resCats = [];
  ansImageCategories.forEach((imgCat, idx) => {
    if (!resCats.some(cat => cat.name === imgCat.category)) {
      resCats.push({ name: imgCat.category, id: idx + 1, items: [] });
    }
  });
  return resCats;
};

export const ImageTypeQuestion = props => {
  const { handleSelectStep, question, setAnswer, currentStep } = props;

  const [imageColumns, setImageColumns] = useState(
    question.imageAnswers.map((col, idx) => ({
      ...col,
      items: [],
      id: idx + 1,
      name: col.columnName,
    })),
  );
  const [unCategorizedImages, setUnCategorizedImages] = useState(
    question.imageAnswers
      .map(iA => [...iA.rightAnswers.map(a => ({ ...a, id: a.questionAnswerId }))])
      .flat(),
  );
  const [hasStepsContainerScroll, setHasStepsContainerScroll] = useState(false);
  const [isDroppedFromColumn, setIsDroppedFromColumn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [activeItemId, setActiveItemId] = useState(null);
  const [overItemId, setOverItemId] = useState(null);
  const [selectedItemIdFromColumn, setSelectedItemIdFromColumn] = useState(null);
  const [selectedImg, setSelectedImg] = useState('');

  const allColumnItems = imageColumns.map(c => c.items).flat();
  const unCategorizedRowId = 'uncategorized_row';
  let animationFrameId = null;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Helper to get over column index
  const getOverColumnIndex = overId =>
    imageColumns.findIndex(
      column => column.id === overId || column.items.some(item => item.id === overId),
    );

  // Helper function to handle drag from a column to unCategorizedImages
  const handleFromColumnToUncategorized = (active, over) => {
    // Find the item in the column
    const columnIndex = imageColumns.findIndex(column =>
      column.items.some(item => item.id === active.id),
    );
    const itemIndex = imageColumns[columnIndex].items.findIndex(item => item.id === active.id);
    const item = imageColumns[columnIndex].items[itemIndex];

    // Remove from the column
    imageColumns[columnIndex].items.splice(itemIndex, 1);

    // Find the index in unCategorizedImages where the item will be inserted
    const indexToInsertAt = unCategorizedImages.findIndex(item => item.id === over.id);

    // Insert into unCategorizedImages
    const updatedUnCategorizedImages = [...unCategorizedImages];
    updatedUnCategorizedImages.splice(indexToInsertAt, 0, item);

    // Update state
    // setQuizConfigObj(updatedUnCategorizedImages); // Update this function to set unCategorizedImages
    setAnswer(imageColumns);
    setImageColumns(imageColumns);
    setUnCategorizedImages(updatedUnCategorizedImages);
  };

  // Helper function to handle drag from unCategorizedImages to a column
  const handleFromUncategorizedToColumn = (active, over) => {
    // Find the item and remove it from unCategorizedImages
    const item = unCategorizedImages.find(image => image.id === active.id);
    const overColumnIndex = getOverColumnIndex(over.id);

    // Add to the new column
    const updatedColumnItems = [...imageColumns[overColumnIndex].items, item];
    const updatedColumns = [...imageColumns];
    updatedColumns[overColumnIndex].items = updatedColumnItems;

    // Update state
    const updatedUnCategorizedImages = [...unCategorizedImages];
    const itemIdx = updatedUnCategorizedImages.findIndex(image => image.id === active.id);
    updatedUnCategorizedImages.splice(itemIdx, 1);
    setAnswer(updatedColumns);
    setImageColumns(updatedColumns);
    setUnCategorizedImages(updatedUnCategorizedImages);
    scrollVerticallyToContainerEnd(`column_body_items_block${imageColumns?.[overColumnIndex]?.id}`);
  };

  const handleFromColumnToColumn = (active, over) => {
    const fromColumnIndex = imageColumns.findIndex(column =>
      column.items.some(item => item.id === active.id),
    );
    const itemIndex = imageColumns[fromColumnIndex].items.findIndex(item => item.id === active.id);
    const item = imageColumns[fromColumnIndex].items[itemIndex];
    const overColumnIndex = getOverColumnIndex(over.id);

    // Remove from the fromColumn
    imageColumns[fromColumnIndex].items.splice(itemIndex, 1);

    // Insert into the new column
    const updatedColumnItems = [...imageColumns[overColumnIndex].items, item];
    const updatedColumns = [...imageColumns];
    updatedColumns[overColumnIndex].items = updatedColumnItems;

    // Update state
    setAnswer(imageColumns);
    scrollVerticallyToContainerEnd(`column_body_items_block${imageColumns?.[overColumnIndex]?.id}`);
  };

  const handleSortInUncategorizedList = (active, over) => {
    const fromIndex = unCategorizedImages.findIndex(item => item.id === active.id);
    const toIndex = unCategorizedImages.findIndex(item => item.id === over.id);

    // Reorder the items
    const updatedUnCategorizedImages = arrayMove(unCategorizedImages, fromIndex, toIndex);

    // Update state
    setUnCategorizedImages(updatedUnCategorizedImages);
  };

  const handleSortInsideColumn = (active, over) => {
    const fromIndex = imageColumns.findIndex(col => col.id === active.id);
    const toIndex = imageColumns.findIndex(col => col.id === over.id);
    const updatedColumns = arrayMove(imageColumns, fromIndex, toIndex);

    setAnswer(updatedColumns);
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    // Reset these regardless of whether or not `over` is null.
    setActiveItemId(null);
    setOverItemId(null);

    // If drop is outside any container, return
    if (!over) {
      return;
    }

    // Check if dragged from unCategorizedImages
    const isFromUnCategorized = unCategorizedImages.some(item => item.id === active.id);
    // Check is dragged from a column
    const isFromAColumn = allColumnItems.some(item => item.id === active.id);
    // Check if dropped on unCategorizedImages
    const isOverUnCategorized = unCategorizedImages.some(item => item.id === over.id);
    const isOveredEmptyUnCategorizedRow = over.id === unCategorizedRowId;
    // Check if dropped on a column
    const overColumnIndex = imageColumns.findIndex(
      column => column.id === over.id || column.items.some(item => item.id === over.id),
    );
    const isOverColumn = overColumnIndex !== -1;
    // Dropped on the uncategorized list from a column
    if (isFromAColumn && isOverUnCategorized) {
      handleFromColumnToUncategorized(active, over);
    }

    if (isFromUnCategorized && isOverColumn) {
      handleFromUncategorizedToColumn(active, over);
      if (selectedItemIdFromColumn === active.id) {
        setSelectedItemIdFromColumn(null);
      }
    }
    if (isFromAColumn && isOverColumn) {
      handleFromColumnToColumn(active, over);
    }

    if (isFromUnCategorized && isOverUnCategorized) {
      handleSortInUncategorizedList(active, over);
    }
    if (
      imageColumns.some(col => col.id === active.id) &&
      imageColumns.some(col => col.id === over.id)
    ) {
      handleSortInsideColumn(active, over);
    }
    if (isFromAColumn && isOveredEmptyUnCategorizedRow) {
      handleFromColumnToUncategorized(active, over);
    }
  };

  const handleDragStart = event => {
    const { active, over } = event;
    const isFromAColumn = allColumnItems.some(item => item.id === active.id);
    setIsDroppedFromColumn(isFromAColumn);
    setActiveItemId(active.id);
  };

  const handleDragOver = ({ over }) => {
    setOverItemId(over?.id ? over.id : null);
  };

  const handleDragCancel = () => {
    setActiveItemId(null);
  };

  const handleScroll = e => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId);
    }

    const element = e.target;
    animationFrameId = requestAnimationFrame(() => {
      setIsScrolled(element.scrollLeft > 0);

      const totalScrollWidth = element.scrollWidth - element.clientWidth;
      setIsAtEnd(element.scrollLeft >= totalScrollWidth);
    });
  };

  const handleSelectItem = id => {
    const allImageAnswerOptions = question.imageAnswers.map(a => [...a.rightAnswers]).flat();
    const currentItem = allImageAnswerOptions.find(item => item.questionAnswerId === id);
    if (currentItem) setSelectedImg(currentItem);
  };

  const handleSelectItemFromColumn = id => {
    const allImageAnswerOptions = question.imageAnswers.map(a => [...a.rightAnswers]).flat();
    const currentItem = allImageAnswerOptions.find(item => item.questionAnswerId === id);
    setSelectedItemIdFromColumn(id === selectedItemIdFromColumn ? null : id);
    if (currentItem) setSelectedImg(currentItem);
  };

  useEffect(() => {
    const stepsContainer = document.getElementById('img_cat_edit_columns');
    if (stepsContainer) {
      setHasStepsContainerScroll(stepsContainer?.scrollWidth > stepsContainer?.clientWidth);
    }
  }, [unCategorizedImages]);

  useEffect(() => {
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, []);

  return (
    <>
      <div className='descriptions-block'>
        <span className='drag-descriprion'>
          Sort the items by dragging them to the appropriate boxes.{' '}
        </span>
        <span className='img-description'>{selectedImg.description}</span>
      </div>
      <div className='dnd_block_container'>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragOver={handleDragOver}
        >
          <SortableContext items={[...imageColumns]} strategy={rectSortingStrategy}>
            <SortableContext items={imageColumns.map(col => col.id)} strategy={rectSortingStrategy}>
              <div
                id='img_cat_edit_columns'
                className='columns_container custom-scrollbar-block horizontal'
              >
                <div className='absolute_overlay'>
                  {hasStepsContainerScroll && isScrolled && <div className='gradient start' />}
                  {hasStepsContainerScroll && !isAtEnd && <div className='gradient end' />}
                </div>
                {imageColumns.map((column, columnIndex) => {
                  return (
                    <Column
                      columnIndex={columnIndex}
                      column={column}
                      overColumnId={
                        !imageColumns.some(({ id }) => id === activeItemId) && overItemId
                      }
                      id={column.id}
                      key={columnIndex}
                      className={`${imageColumns.length < 3 && 'wide'}`}
                    >
                      <SortableContext
                        items={column.items.map(item => item.id)}
                        strategy={rectSortingStrategy}
                      >
                        <div
                          id={`column_body_items_block${column.id}`}
                          className='column_body_items_block'
                        >
                          <div className='empty_col_img_space' />
                          {column.items.map((item, index) => (
                            <SortableImgItem
                              handleSelectItem={handleSelectItemFromColumn}
                              size='small'
                              isSelected={selectedImg.questionAnswerId === item.id}
                              activeItemId={activeItemId}
                              key={item.id}
                              id={item.id}
                              index={index}
                              {...item}
                            />
                          ))}
                        </div>
                      </SortableContext>
                    </Column>
                  );
                })}
              </div>
            </SortableContext>
            <div className='steps_part_block'>
              <DroppableUncategorizedArea id={unCategorizedRowId} handleScroll={handleScroll}>
                <SortableContext items={unCategorizedImages} strategy={rectSortingStrategy}>
                  {unCategorizedImages.map((item, index) => {
                    return (
                      <SortableImgItem
                        handleSelectItem={handleSelectItem}
                        activeItemId={activeItemId}
                        isSelected={selectedImg.questionAnswerId === item.id}
                        overItemId={isDroppedFromColumn && overItemId}
                        handleSelectStep={handleSelectStep}
                        size='medium'
                        key={item.id}
                        id={item.id}
                        index={index}
                        url={item.imageUrl}
                        {...item}
                      />
                    );
                  })}
                </SortableContext>
              </DroppableUncategorizedArea>
            </div>
          </SortableContext>
          {!imageColumns.some(({ id }) => id === activeItemId) && (
            <DragOverlay dropAnimation={null}>
              {activeItemId ? (
                <SortableImgItem
                  {...[...unCategorizedImages, ...allColumnItems].find(
                    item => item.id === activeItemId,
                  )}
                  size={allColumnItems.some(item => item.id === activeItemId) ? 'small' : 'medium'}
                  isActiveDragging={true} // You can use this prop to style the overlay if you want
                />
              ) : null}
            </DragOverlay>
          )}
        </DndContext>
      </div>
    </>
  );
};
