import React from 'react';

const FeedCheckbox = ({ data, onChange, answers, isDisabled, isAnswered }) => {
  const items = data.feedbackItems.map(item => ({ name: item.label, id: item.feedbackId }));

  const handleChange = e => {
    onChange({ id: data.id, value: e.target.checked });
  };

  return (
    <li className='d-flex'>
      <div className='col-6 pl-0 pt-1'>
        <label className='m-0'>{data.question}</label>
      </div>
      <div className='d-flex'>
        {!isAnswered
          ? items.map(item => (
              <div key={item.id} className='d-flex align-items-center mr-3'>
                <input
                  id={`${item.id}_check`}
                  type='checkbox'
                  name='feedcheck'
                  checked={Boolean(answers[data.id])}
                  onChange={handleChange}
                  disabled={isDisabled}
                />
                <label
                  htmlFor={`${item.id}_check`}
                  className={`ml-2 mb-0 weight-${false ? 500 : 100}`}
                >
                  {item.name}
                </label>
              </div>
            ))
          : items.map(item => (
              <div key={item.id} className='d-flex align-items-center mr-3'>
                {answers[data.id] && answers[data.id] !== 'false' ? 'Yes' : 'No'}
              </div>
            ))}
      </div>
    </li>
  );
};

export default FeedCheckbox;
