/* eslint-disable consistent-return */
import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Player, BigPlayButton } from 'video-react';
import { bindDuration, getEpisodeStartTime } from 'utils/appHelpers';
import EmptyView from '../EmptyView';
import { getCourses, onCourseUpdate } from 'app/Main/routes/Courses/actions';
import { LESSON_STATUSES } from 'configs/constants';
import { Api } from 'utils/connectors';
import { IconPlayFill, IconLock, IconVideo } from 'shared/components/Icons';

let activeTime = 0;
let lastSendTime = 0;
let checkTime = 0;
let videoFinish = false;

const TypeVideo = ({ lesson, course, action }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activePart, setActivePart] = useState(lesson.episodes?.length && lesson.episodes[0]);
  const player = useRef(null);

  const videoSettings = {
    src: lesson.url || lesson.contentUrl,
    fluid: false,
    preload: 'auto',
    height: 465,
  };

  const epsiodeTimes = lesson.episodes?.map(item => item.startFrom);

  const navigateToNextLesson = () => {
    const currentLessonIndex = course.lessons.findIndex(l => l.id === lesson.id);
    const nextLesson = course.lessons[currentLessonIndex + 1];
    if (nextLesson) {
      history.push(`/courses/${course.id}/${nextLesson.type}/${nextLesson.id}`);
    }
  };

  const setLastViewedTime = lesson => {
    if (lesson.completed < lesson.duration) player.current.seek(lesson.completed);
  };

  const updateLessonsTimeAndEpisode = episodeId => {
    const tempCourse = { ...course };
    const lessonIndex = tempCourse.courseLessons.findIndex(item => item?.lesson?.id === lesson.id);
    const tempLesson = tempCourse.courseLessons[lessonIndex]?.lesson;
    const episodeIndex = tempLesson.episodes.findIndex(item => item.id === episodeId);
    const tempEpisode = tempLesson.episodes[episodeIndex];
    tempLesson.completed = activeTime;
    tempEpisode.status = 1;
  };

  const autoSelectEpisode = ({ currentTime, seeking }) => {
    const videoTime = Math.ceil(currentTime);
    if (
      !videoTime ||
      videoTime === checkTime ||
      seeking ||
      !lesson.episodes ||
      !lesson.episodes.length
    )
      return;
    let activeKey = 0;
    checkTime = videoTime;
    epsiodeTimes?.forEach((item, key) => {
      if (checkTime >= item) activeKey = key;
    });
    updateLessonsTimeAndEpisode(lesson.episodes[activeKey].id);
    setActivePart(lesson.episodes[activeKey]);
  };

  const sendLessonTime = async (time, videoFinished, isSkipEpisode) => {
    const completed = time > Math.ceil(lesson.duration) ? lesson.duration : Math.ceil(time);
    const body = { lessonId: lesson.id, completed, courseId: course.id };
    if (videoFinished) {
      await Api.post('/courses/updateuserlesson', body);
      if (isSkipEpisode) {
        const tempCourse = { ...course };
        const lessonIndex = tempCourse.lessons.findIndex(item => item.id === lesson.id);
        tempCourse.lessons[lessonIndex].status = LESSON_STATUSES.completed;
        const nextLesson = tempCourse.lessons[lessonIndex + 1];
        if (nextLesson) {
          nextLesson.status = LESSON_STATUSES.inProgress;
          dispatch(onCourseUpdate(tempCourse));
        }
      } else {
        await getCourses();
        navigateToNextLesson();
      }
    } else {
      Api.post('/courses/updateuserlesson', body);
    }
  };

  const handlePartSelect = episode => {
    player.current.seek(episode.startFrom);
    setActivePart(episode);
  };

  const lessonStartLog = () => {
    Api.post('/courses/lesson/start', {
      courseId: course.id,
      lessonId: lesson.id,
    });
  };

  const updateLessonTime = time => {
    const sendTime = Math.ceil(time);
    if (sendTime % 10 === 0 && sendTime !== lastSendTime) {
      lastSendTime = sendTime;
      sendLessonTime(sendTime);
    }
  };

  const handleStateChange = async nextState => {
    autoSelectEpisode(nextState);
    if (nextState.currentTime > activeTime) {
      if (!nextState.seeking) {
        activeTime = Math.max(activeTime, nextState.currentTime);
        updateLessonTime(activeTime);
        if (Math.ceil(activeTime) === Math.ceil(nextState.duration) && !videoFinish) {
          videoFinish = true;
          await sendLessonTime(Math.ceil(nextState.duration), true);
          navigateToNextLesson();
        }
      }
    }
  };

  useEffect(() => {
    activeTime = lesson.completed || 0;
    setLastViewedTime(lesson);
    const subs = player.current.subscribeToStateChange(handleStateChange);
    const pCurrent = player.current;
    if (action && action === 'auto') {
      pCurrent.play();
    }

    return function cleanup() {
      pCurrent.pause();
      subs();
    };
    //eslint-disable-next-line
  }, [lesson]);

  useEffect(() => {
    lessonStartLog();
    //eslint-disable-next-line
  }, []);

  const episodes = lesson.episodes ? lesson.episodes.filter(i => i.duration) : [];

  return (
    <>
      <div className='video-area type-video flex-1 d-flex justify-content-center'>
        {lesson.contentUrl && (
          <Player {...videoSettings} ref={player}>
            <BigPlayButton position='center' />
          </Player>
        )}
        {!lesson.contentUrl && (
          <EmptyView text='Here will be featured the video lesson' Icon={IconVideo} />
        )}
      </div>
      <div className='course-parts d-flex flex-column'>
        <p className='course-title'>{lesson.title} - Chapters</p>
        <div className='course-parts-lists'>
          {episodes &&
            episodes.map((item, index) => {
              const active = true;
              const Icon = active ? IconPlayFill : IconLock;
              const selected = activePart && activePart.title === item.title ? 'active' : '';
              const disabled = !active ? 'disabled' : '';
              const startTime = getEpisodeStartTime(episodes, index, true);
              return (
                <div
                  onClick={() => handlePartSelect(item)}
                  role='button'
                  tabIndex='-1'
                  key={item.title + index}
                  className={`part-item d-flex align-items-center px-3 ${disabled} ${selected}`}
                >
                  <div className='mr-2'>
                    <Icon />
                  </div>
                  <div>
                    <p className='name'>{item.title}</p>
                    <p className='time'>{bindDuration(startTime)}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default TypeVideo;
