import React, { useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import EventHeader from '../EventLanding/components/EventHeader';
import Select from 'shared/components/Select';
import { countries, degrees, specialty, states } from 'app/Auth/routes/Register/configs';
import blueLogo from 'assets/ACE-logo-blue.svg';
import AuthPage from 'app/Auth/components/AuthPage';
import Button from 'shared/components/Button';

const EventRegister = ({ match, history }) => {
  const { id, name } = match.params;
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [isConfirm, setIsConfirm] = useState(false);
  const [form, setForm] = useState({
    fname: '',
    lname: '',
    email: '',
    institution: '',
    countryId: '',
    city: '',
    address: '',
    phone: '',
    zipCode: '',
    degree: '',
    specialty: '',
    prefferedNameOnCertificate: '',
    password: '',
    rpassword: '',
    state: '',
    eventId: Number(id),
    eventUrl: name,
  });

  const areAllRequiredFieldsFilled = () => {
    const requiredFields = [
      'fname',
      'lname',
      'degree',
      'specialty',
      'email',
      'password',
      'rpassword',
      'institution',
      'countryId',
      'city',
      'state',
      'zipCode',
      'address',
      'phone',
    ];
    return requiredFields.every(field => Boolean(form[field]));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const body = {
        email: form.email,
        password: form.password,
        firstname: form.fname,
        lastname: form.lname,
        usertype: 1,
        countryid: form.countryId,
        eventUrl: name,
        learnerprofile: {
          state: form.state,
          institution: form.institution,
          city: form.city,
          address: form.address,
          zipCode: form.zipCode,
          phone: form.phone,
          degree: form.degree,
          profession: form.specialty,
          prefferedNameOnCertificate: form.prefferedNameOnCertificate,
        },
      };
      await Api.post('/auth/register', body);
      history.push({
        pathname: `/events/${name}/email-verification`,
        state: { email: form.email },
      });
      setIsConfirm(true);
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempForm = { ...form };
    tempForm[name] = value;
    setForm(tempForm);
  };

  const isCountryUs = form.countryId === '226';
  const passwordMatch = form.password === form.rpassword;

  return (
    <div className='event-view d-flex flex-column min-vh-100'>
      <EventHeader name={name} eventId={id} />
      <div className='mt-3 mb-5 text-center'>
        <img src={blueLogo} alt='logo' height='75' />
      </div>
      <AuthPage>
        <form onSubmit={handleSubmit} className='form-block'>
          <h6 className='color-blue mb-3 font-weight-normal'>Personal Information</h6>
          <div className='d-flex mb-4'>
            <InputGroup
              type='text'
              name='fname'
              onChange={handleChange}
              className='mr-3'
              placeholder='First Name*'
              value={form.fname}
              required
            />
            <InputGroup
              type='text'
              name='lname'
              className='ml-3'
              onChange={handleChange}
              placeholder='Last Name*'
              value={form.lname}
              required
            />
          </div>
          <div className='d-flex mb-4'>
            <Select
              label='Degree*'
              name='degree'
              parentClassName=''
              className='bottom_bordered mr-3'
              value={form.degree}
              items={degrees}
              emptyValueClassName='empty_gray_select'
              onChange={handleChange}
              displayProperty={'name'}
              required
            />
            <Select
              label='Specialty*'
              name='specialty'
              parentClassName='ml-3'
              className='bottom_bordered'
              emptyValueClassName='empty_gray_select'
              value={form.specialty}
              items={specialty}
              onChange={handleChange}
              required
              displayProperty={'name'}
            />
          </div>
          <div className='d-flex mb-4'>
            <InputGroup
              type='text'
              name='prefferedNameOnCertificate'
              onChange={handleChange}
              className=''
              placeholder='Customized Name on Certificate'
              value={form.prefferedNameOnCertificate}
            />
          </div>
          <div className='d-flex mb-4'>
            <InputGroup
              type='email'
              name='email'
              onChange={handleChange}
              className=''
              placeholder='Email*'
              value={form.email}
              required
            />
          </div>
          <div className='d-flex mb-4'>
            <InputGroup
              type='password'
              name='password'
              onChange={handleChange}
              className='mr-3'
              placeholder='Password'
              value={form.password}
              required
            />
            <InputGroup
              type='password'
              name='rpassword'
              onChange={handleChange}
              className='ml-3'
              placeholder='Confirm password'
              value={form.rpassword}
              required
            />
          </div>
          {!passwordMatch && form.rpassword && (
            <div className='error-text w-100 d-flex justify-content-end'>Passwords mismatch</div>
          )}
          <h5 className='color-blue mb-3 font-weight-normal'>Institute Information</h5>
          <div className='d-flex mb-4'>
            <InputGroup
              type='text'
              name='institution'
              onChange={handleChange}
              className=''
              placeholder='Institute Name*'
              value={form.institution}
              required
            />
          </div>
          <div className='d-flex mb-4'>
            <Select
              label='Country*'
              name='countryId'
              parentClassName=''
              className='bottom_bordered mr-3'
              value={form.countryId}
              items={countries}
              emptyValueClassName='empty_gray_select'
              onChange={handleChange}
              displayProperty={'name'}
              required
            />
            <div className='d-flex mb-0'>
              <InputGroup
                type='text'
                name='city'
                onChange={handleChange}
                className=''
                placeholder='City*'
                value={form?.learnerProfile?.city}
                required
              />
            </div>
          </div>
          <div className='d-flex mb-4'>
            {isCountryUs ? (
              <Select
                label='State/Province*'
                name='state'
                value={form.state}
                items={states}
                onChange={handleChange}
                required
              />
            ) : (
              <InputGroup
                type='text'
                name='state'
                onChange={handleChange}
                placeholder='State/Province*'
                value={form.state}
                required
              />
            )}
            <InputGroup
              type='text'
              name='zipCode'
              onChange={handleChange}
              className='ml-3'
              placeholder='Zip Code*'
              value={form.zipCode}
              required
            />
          </div>
          <div className='d-flex mb-4'>
            <InputGroup
              type='text'
              name='address'
              onChange={handleChange}
              className=''
              placeholder='Address*'
              value={form.address}
              required
            />
          </div>
          <div className='d-flex mb-4'>
            <InputGroup
              type='number'
              name='phone'
              onChange={handleChange}
              className=''
              placeholder='Phone Number*'
              value={form.phone}
              required
            />
          </div>
          <div className='text-center'>
            {error && <div className='error-text'>{error}</div>}
            <Button
              type='submit'
              disabled={!areAllRequiredFieldsFilled() || !passwordMatch || fetch}
              className='btn btn-blue-dark w-100'
            >
              Next
            </Button>
          </div>
        </form>
      </AuthPage>
    </div>
  );
};

export default EventRegister;
