import React from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    const params = new URLSearchParams(search);
    const paramsObject = {};
    for (let param of params) {
      paramsObject[param[0]] = param[1];
    }
    return paramsObject;
  }, [search]);
};

export default useQuery;
