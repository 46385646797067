import { useState } from 'react';

export const useDialog = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [dialogData, setDialogData] = useState(null);

  const openDialog = data => {
    setDialogData(data);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setDialogData(null);
    setIsOpen(false);
  };

  return {
    isOpen,
    dialogData,
    openDialog,
    closeDialog,
  };
};
