import React from 'react';
import BackButton from 'shared/BackButton';
import { getEventUrl } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';
import CourseHeaderRightNav from '../CourseView/components/CourseHeaderRightNav';

const ProgressHeader = ({ course, history, file }) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';
  return (
    <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
      <div className='d-flex'>
        <div className='pr-2 d-flex'>
          <BackButton history={history} route={`${path}/courses/${course.id}`} />
          <span className='ml-1'>Certificate:</span>
        </div>
        {file && (
          <div>
            <a
              href={file}
              className='link'
              target='_blank'
              rel='noopener noreferrer'
              download='Certificate'
            >
              Download Certificate
            </a>
            <span className='text-secondary ml-2'>(.Pdf 184kb)</span>
          </div>
        )}
      </div>
      <CourseHeaderRightNav course={course} />
    </div>
  );
};
export default ProgressHeader;
