import { HOST } from 'configs/host';
import placeholder from 'assets/avatar.png';
import moment from 'moment';
import { hasAccess } from './permissionHelper';
import { confirmAlert } from 'react-confirm-alert';
import { LESSON_STATUSES } from 'configs/constants';

export const getError = err => {
  if (err?.response?.data?.data && Array.isArray(err.response.data.data)) {
    return err.response.data.data.map(error => `- ${error}`).join('\n');
  }
  if (err && err.response && err.response.data) {
    return err.response.data.message;
  }
  if (err && err.response && err.response.statusText) {
    return err && err.response && err.response.statusText;
  }
  if (err && err.data && err.data.message) {
    return err.data.message;
  }
  if (err && err.message) {
    return err.message;
  }
  return err;
};

export const getErrorCode = err => {
  const nonValue = { code: null, message: null };
  if (!err) return nonValue;
  const { response } = err;
  if (!response || !response.data) return nonValue;
  const { result, message } = response.data;
  return { code: result, message };
};

export const chunckArray = (arr, size) => {
  return arr.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / size);
    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item);
    return result;
  }, []);
};

export const openFullscreen = () => {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export const duplicate = json => JSON.parse(JSON.stringify(json));

export const getDate = date => {
  if (!date) return '-';
  return moment(date).format('MM/DD/YYYY');
};

export const getEndOfSubs = (date, expirable) => {
  if (!expirable) return 'No Expiration';
  const day = moment(date).diff(new Date(), 'days');
  const exDate = moment(date).format('MM/DD/YYYY');
  return day <= 0 ? `Expired on ${exDate}` : `Expires in ${day} days`;
};

export const parseJson = data => {
  if (!data || data.length < 10) return {};
  return JSON.parse(data);
};

export const bindDuration = duration => {
  if (!duration) return null;
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor(duration / 60 - hours * 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (val < 10 ? `0${val}` : val);
  return `${hours ? round(hours) + ':' : ''}${round(minutes)}:${round(seconds)}`;
};

export const bindDurationMinutes = duration => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (val < 10 ? `0${val}` : val);
  return `${round(minutes)}:${round(seconds)}`;
};

export const bindDurationSecondsGettingMins = durationInMinutes => {
  const totalSeconds = durationInMinutes * 60;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;
  const round = val => (val < 10 ? `0${val}` : val);
  return `${hours ? round(hours) + ':' : ''}${round(minutes)}:${round(seconds)}`;
};

export const bindDurationShowFull = duration => {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor(duration / 60 - hours * 60);
  const seconds = Math.floor(duration % 60);
  const round = val => (val < 10 ? `0${val}` : val);
  return `${round(hours)}:${round(minutes)}:${round(seconds)}`;
};

export const getImageUrl = image => {
  if (!image) return placeholder;
  return image.includes('http') ? image : `${HOST.API.URL}/${image}`;
};

export const getEventId = () => {
  const id = window.location.pathname.replace('/events/', '').split('/')[0];
  return id ? Number(id) : null;
};

export const getEventUrlName = () => {
  const eventUrl = window.location.pathname.replace('/events/', '').split('/')[0];
  return eventUrl || null;
};

export const getEventUrl = hidePath => {
  return `/events/${getEventId()}/${hidePath ? '' : 'dashboard'}`;
};

export const getFeedbackUrl = course => {
  const lesson = course.lessons.find(item => item.type === 'feedback');
  // if (!lesson) return null;
  const path = hasAccess('event_user') ? getEventUrl() : '';
  return `${path}/courses/${course.id}/feedback/${lesson ? lesson.id : 'empty'}`;
};

export const findCourseFirstLesson = (course, history) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';
  if (course.status === LESSON_STATUSES.inProgress) {
    const foundIdx = course.lessons.findIndex(item => item.status === 2);
    const unCompleteIndex = foundIdx === -1 ? 0 : foundIdx;
    const index = unCompleteIndex <= 0 ? 0 : unCompleteIndex - 1;
    const firstLesson = course.lessons[index];
    history.push(`${path}/courses/${course.id}/${firstLesson.type}/${firstLesson.id}`);
  } else {
    history.push(`${path}/allcourses/details/${course.id}`);
  }
};

export const findCourseFirstLessonAndRedirect = (course, history, needGetCourses) => {
  const unCompleteIndex = course.lessons.findIndex(item => item.status === 2);
  const index = unCompleteIndex <= 0 ? 0 : unCompleteIndex - 1;
  const firstLesson = course.lessons[index || 0];
  history.push({
    pathname: `/courses/${course.id}/${firstLesson.type}/${firstLesson.id}`,
    state: { needGetCourses },
  });
};

export const courseSubsStatuses = (id, status) => {
  const statuses = {
    active: [1, 7],
    pending: [3, 6],
    inactive: [2, 4, 8],
    expired: [5],
    declined: [2, 4, 8],
  };

  return statuses[status].includes(id);
};

export const isIE = () => {
  const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE '); // IE 10 or older
  const trident = ua.indexOf('Trident/'); //IE 11
  return msie > 0 || trident > 0;
};

export const scrollToEl = element => {
  if (element) {
    const el = document.getElementById(element);
    if (el) window.scroll({ top: el.offsetTop, behavior: 'smooth' });
  }
};

export const getSize = (width, original) => {
  return (width * original) / 1366;
};

export const onOpenExternalUrl = (url, e) => {
  if (e) e.preventDefault();
  if (!url) return;
  confirmAlert({
    message:
      'A new browser tab will now be opened. You can close it at any time and come back to this tab to continue.',
    buttons: [
      {
        label: 'Continue',
        onClick: () => window.open(url, '_blank'),
      },
      {
        label: 'Cancel',
      },
    ],
  });
};

export const arrSum = (arr, key) => {
  return arr.reduce((a, b) => parseInt(a, 10) + (parseInt(b[key], 10) || 0), 0);
};

export const getEpisodeStartTime = (data, index, noFormat) => {
  if (index === 0) return noFormat ? 0 : bindDurationMinutes(0);
  const prevItems = [...data];
  prevItems.splice(index, data.length - index);
  const prevItemsSum = arrSum(prevItems, 'duration');
  return noFormat ? prevItemsSum : bindDurationMinutes(prevItemsSum);
};

export const isMobileDevice = () => {
  return (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    typeof window.orientation !== 'undefined' ||
    window.innerWidth < 768
  );
};
