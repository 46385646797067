import React from 'react';

const getSmallerSize = imageWidth => {
  return Number((imageWidth / 50).toFixed());
};

const Thumbnail = ({ viewport }) => {
  if (!viewport || !viewport.image) return null;
  const { scale, initialScale, translation, rotation, vflip, hflip } = viewport;
  const smallNum = getSmallerSize(viewport.image.width);
  const width = viewport.image.width / smallNum;
  const height = viewport.image.height / smallNum;
  const coreSize = {
    width: (initialScale * width) / scale,
    height: (initialScale * height) / scale,
  };
  const corePos = {
    transform: `translate(${-(translation.x / smallNum)}px, ${-(translation.y / smallNum)}px)`,
  };
  const imageStyle = {
    backgroundImage: `url('${viewport.image.imageId}')`,
    transform: `rotate(${rotation}deg) rotateX(${vflip ? 180 : 0}deg) rotateY(${
      hflip ? 180 : 0
    }deg)`,
  };
  if (coreSize.width > 100 || initialScale >= scale) return null;
  return (
    <div className='thumbnail-view' style={{ width, height }}>
      <div style={imageStyle} className='image' />
      <div className='core' style={{ ...coreSize, ...corePos }} />
    </div>
  );
};

export default Thumbnail;
