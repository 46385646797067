/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import CourseItem from 'shared/components/CourseItem';
import { IconArrowRight, IconTranscript } from 'shared/components/Icons';
import { courseSubsStatuses, findCourseFirstLesson } from 'utils/appHelpers';

const DashBoxTranscript = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const history = useHistory();
  const courses = useSelector(item => item.courses);
  const transcripts = courses.filter(item => courseSubsStatuses(item.status, 'active'));

  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    className: 'home-courses-slide',
    autoplay: true,
    beforeChange: next => {
      setActiveSlide(next + 1);
    },
  };

  const courseClickHandle = course => {
    findCourseFirstLesson(course, history);
  };

  const item = transcripts && transcripts[activeSlide];

  return (
    <div className='courses position-relative'>
      {transcripts && (
        <Slider {...sliderSettings}>
          {transcripts.map(item => {
            return (
              <div key={item.id}>
                <CourseItem
                  hidePlay={true}
                  item={item}
                  courseClick={courseClickHandle.bind(null, item)}
                />
              </div>
            );
          })}
        </Slider>
      )}
      <div
        className='overlay-block d-flex flex-column justify-content-between'
        onClick={courseClickHandle.bind(null, item)}
      >
        <div className='title-anim text-right'>
          <span>My Transcript</span>
          <IconTranscript color='#fff' />
        </div>
        <div>
          <a
            href='/'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              history.push('/courses');
            }}
          >
            Show More
            <IconArrowRight color='#fff' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DashBoxTranscript;
