import React, { useState } from 'react';
import InputGroup from 'shared/components/InputGroup';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import AuthBlock from '../components/AuthBlock';
import { IconMail } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import AuthPage from '../components/AuthPage';

const Forgot = ({ history }) => {
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [email, setEmail] = useState('');

  const authOptions = {
    title: 'Password reset',
    subTitle: 'Please enter your email, so we can send you a code to reset your password',
    links: { signup: true },
    subTitleClassName: 'fsize_18',
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const res = await Api.post(`/auth/forgot`, { email });
      if (res.status === 200) {
        history.push({ pathname: '/email-verification', state: { email, isForgotPassword: true } });
      }
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  return (
    <AuthPage>
      <AuthBlock {...authOptions}>
        <form onSubmit={handleSubmit}>
          <InputGroup
            type='text'
            name='email'
            onChange={e => setEmail(e.target.value)}
            className='mb-4'
            placeholder='Email address'
            value={email}
            icon={<IconMail />}
            autoFocus
            required
          />
          {error && <div className='error-text'>{error}</div>}
          <Button className='btn btn-blue-dark w-100' type='submit' disabled={fetch}>
            Send
          </Button>
        </form>
      </AuthBlock>
    </AuthPage>
  );
};

export default Forgot;
