import React, { useState } from 'react';
import { ENGLISH_UPPERCASE_LETTERS } from '../constants';

export const BooleanTypeQuestion = ({ question, setAnswer, currentStep }) => {
  const [selectedAnswerId, setSelectedAnswerId] = useState(null);

  const handleRadioChange = (idx, id) => {
    setSelectedAnswerId(id);
    setAnswer([question.answersMulti[idx].answer]);
  };

  return (
    <>
      <div className='type-block'>
        <span>Answer Type:</span>
        <span className='color-primary'> Boolean Type Select</span>
      </div>
      <div className='answers-block'>
        {question.answersMulti.map((answer, idx) => {
          const isSelected = selectedAnswerId === answer.questionAnswerId;
          return (
            <div key={idx} className='answer-row'>
              <input
                className={`answer-radio ${isSelected && 'selected'}`}
                type='radio'
                name='answerOption'
                id={`answer_${answer.questionAnswerId}`}
                value={idx}
                checked={isSelected}
                onChange={() => handleRadioChange(idx, answer.questionAnswerId)}
              />
              <label className='answer-text' htmlFor={`answer_${answer.questionAnswerId}`}>
                <span className='custom-radio' />
                {ENGLISH_UPPERCASE_LETTERS[idx]}. {answer.answer}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};
