import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { IconSubscription, IconSearch } from 'shared/components/Icons';
import SubscriptionModal from 'shared/components/SubscriptionModal';
import RadioBox from 'shared/components/RadioBox';
import SubscriptionItem from './components/SubscriptionItem';
import ReactPaginate from 'react-paginate';
import { Api } from 'utils/connectors';
import { getError, courseSubsStatuses } from 'utils/appHelpers';
import useDebounce from 'shared/hooks/useDebounce';
import { formatSubscription } from 'utils/formatHelpers';
import Loading from 'shared/components/Loading';

const limit = 5;

const Subscriptions = ({ match }) => {
  const { enqueueSnackbar } = useSnackbar();
  const isAllSubscriptions = !!match.params.type;
  const [userSubs, setUserSubs] = useState([]);
  const [pendingSubs, setPendingSubs] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [subs, setSubs] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [filter, setFilter] = useState('all');
  const [modalData, setModalData] = useState(null);
  const debouncedSearch = useDebounce(search, 500);

  const getSubscriptions = useCallback(async () => {
    try {
      setFetch(true);
      const status = filter === 'all' ? null : filter;
      const params = { page: page + 1, limit, type: status, search: debouncedSearch };
      const { data } = await Api.get('/subscription/getsubscriptions', { params });
      const tempData = data.data.results.map(item => formatSubscription(item));
      setSubs(tempData);
      setPageCount(Math.ceil(data.data.count / limit));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setPageCount(0);
    } finally {
      setFetch(false);
    }
  }, [debouncedSearch, enqueueSnackbar, filter, page]);

  const getUserSubscription = useCallback(async () => {
    try {
      const { data } = await Api.get(`/subscription/getusersubscriptions`);
      const tempData = data.data.map(item => formatSubscription(item));
      const userSubsData = tempData.filter(
        item => !courseSubsStatuses(item.userSubs.userSubscriptionStatus, 'pending'),
      );
      const pendingSubsData = tempData.filter(item =>
        courseSubsStatuses(item.userSubs.userSubscriptionStatus, 'pending'),
      );
      setUserSubs(userSubsData);
      setPendingSubs(pendingSubsData);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  const handleFilterChange = ({ target }) => {
    setFilter(target.value);
    setPage(0);
  };

  const handleSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  const onSubscribeSuccess = () => {
    setSubs([]);
    setUserSubs([]);
    getUserSubscription();
    getSubscriptions();
  };

  useEffect(() => {
    getUserSubscription();
  }, [getUserSubscription]);

  useEffect(() => {
    getSubscriptions();
  }, [page, debouncedSearch, getSubscriptions]);

  return (
    <>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconSubscription color='#131f6b' />
          </span>
          Subscriptions
        </div>
      </div>
      <div className='p-3'>
        {!isAllSubscriptions && (
          <div className='section-header mb-2'>
            <p className='my-2 text-secondary'>My Subscriptions</p>
            {userSubs &&
              userSubs.map((item, index) => (
                <SubscriptionItem
                  className='mt-3'
                  isSubscribed={true}
                  item={item}
                  key={item.id + index}
                />
              ))}
            {pendingSubs && !!pendingSubs.length && (
              <p className='my-2 text-secondary'>Requested Subscriptions</p>
            )}
            {pendingSubs &&
              pendingSubs.map((item, index) => (
                <SubscriptionItem
                  className='mt-3'
                  isSubscribed={true}
                  item={item}
                  key={item.id + index}
                />
              ))}
          </div>
        )}
        {isAllSubscriptions && (
          <div>
            <p className='my-2 text-secondary'>Available Plans</p>
            <div className='d-flex justify-content-between mb-4'>
              <div className='d-flex align-items-center'>
                <RadioBox
                  name='filter'
                  value='all'
                  label='All'
                  checked={filter === 'all'}
                  onChange={handleFilterChange}
                />
                <RadioBox
                  name='filter'
                  value='1'
                  label='Opened'
                  checked={filter === '1'}
                  onChange={handleFilterChange}
                />
                <RadioBox
                  name='filter'
                  value='3'
                  label='Key Required'
                  checked={filter === '3'}
                  onChange={handleFilterChange}
                />
              </div>
              <div className='search-block'>
                <input
                  type='text'
                  value={search}
                  onChange={handleSearchChange}
                  className='form-control'
                />
                <IconSearch />
              </div>
            </div>
            {fetch && <Loading classView='h-auto mt-5' />}
            {!fetch && (
              <div className='subscriptions-items'>
                {subs &&
                  subs.map((item, index) => (
                    <SubscriptionItem
                      item={item}
                      key={item.id + index}
                      onSubscribe={item => setModalData(item)}
                    />
                  ))}
              </div>
            )}
            {subs && !subs.length && !fetch && (
              <div className='text-black-50 small text-center mt-4'>Subscriptions not found</div>
            )}
            {pageCount > 1 && (
              <ReactPaginate
                previousLabel={null}
                nextLabel={null}
                breakLabel={'...'}
                initialPage={0}
                forcePage={page}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={onPageChange}
                containerClassName={`pagination ${fetch ? 'd-none' : ''}`}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            )}
          </div>
        )}
      </div>
      {modalData && (
        <SubscriptionModal
          data={modalData}
          onModalClose={() => setModalData(null)}
          onSuccess={onSubscribeSuccess}
        />
      )}
    </>
  );
};

export default Subscriptions;
