import React from 'react';

import iconMagnifier from 'assets/tools/8p.svg';
import iconToggle from 'assets/tools/7p.svg';
import iconInteractiveZoom from 'assets/tools/6p.svg';
import iconMove from 'assets/tools/5p.svg';
import iconReset from 'assets/tools/4p.svg';
import iconBrightness from 'assets/tools/9p.svg';
import iconFinding from 'assets/tools/2p.svg';
import iconMaximize from 'assets/arrows/2p.svg';
import iconMinimize from 'assets/arrows/4p.svg';
import iconStudyOverview from 'assets/stepsnew/1n.svg';
import iconCC from 'assets/stepsnew/2n.svg';
import iconMLO from 'assets/stepsnew/3n.svg';
import iconCCRight2D from 'assets/stepsnew/4n.svg';
import iconMLORight2D from 'assets/stepsnew/5n.svg';
import iconCCLeft2D from 'assets/stepsnew/6n.svg';
import iconMLOLeft2D from 'assets/stepsnew/7n.svg';
import iconRightBrest from 'assets/stepsnew/8n.svg';
import iconLeftBrest from 'assets/stepsnew/9n.svg';
import iconAssessment from 'assets/stepsnew/11n.svg';
import iconDiagnostic from 'assets/stepsnew/heatmapn.svg';
import iconComments from 'assets/stepsnew/12n.svg';

// Temporary Icons
// import iconStructuredRoaming from 'assets/temp_icons/icon_quadrant_roaming.png';
// import iconInvert from 'assets/tools/9p.svg';
// import iconStack from 'assets/temp_icons/icon_stack.png';
import iconFindingsShow from 'assets/tools/1p.svg';
import iconFindingsDelete from 'assets/tools/10p.svg';
import iconSteps from 'assets/stepchange.png';
import iconCases from 'assets/casechange.png';
// contact, print

export const viewerDialogJson = {
  zoomAndMagnificationTool: [
    {
      description: 'Magnifier',
      icon: iconMagnifier,
      buttons: [], // ['R'],
    },
    {
      description: 'Interactive zoom',
      icon: iconInteractiveZoom,
      buttons: [], // ['Z'],
    },
    {
      description:
        'Full resolution mode: Clicking on the image will show in original full resolution. By clicking on it again, will toggle back to fit to viewer mode.',
      icon: iconToggle,
      buttons: [], // ['Shift', 'Z'],
    },
    {
      description: 'Maximize viewer',
      icon: iconMaximize,
      buttons: [], // ['Shift', 'M'],
    },
    // {
    //   description:
    //     'Structured roaming: Moves through zoomed views to make sure the whole tissue is displayed.',
    //   icon: iconStructuredRoaming,
    //   buttons: [], // ['TAB'],
    // },
    {
      description: 'Panning/moving image (also via right mouse button)',
      icon: iconMove,
      buttons: [], // ['P'],
    },
    {
      description: 'Reset viewers: Changes zoom and window/level to initial values',
      icon: iconReset,
      buttons: [], // ['Shift', 'R'],
    },
  ],
  otherTools: [
    {
      description: 'Change window/level settings',
      icon: iconBrightness,
      buttons: [], // ['W'],
    },
    // {
    //   description: 'Invert images',
    //   icon: iconInvert,
    //   buttons: [], // ['I'],
    // },
    // {
    //   description: 'Stacking/Slicing (also via ↑ / ↓ or scroll wheel) ',
    //   icon: iconStack,
    //   buttons: [], // ['↑', '↓', 'S'],
    // },
    {
      description: 'Finding tool: Allows to place arrows at regions of interest.',
      icon: iconFinding,
      buttons: [], // ['F'],
    },
    {
      description: 'Show Markings',
      icon: iconFindingsShow,
      buttons: [], // ['Shift', 'F'],
    },
    {
      description: 'Delete Markings',
      icon: iconFindingsDelete,
      buttons: [], // ['Shift', 'F'],
    },
  ],
  navigation: [
    {
      description: 'Full screen on',
      icon: iconMaximize,
      buttons: [], // ['0'],
    },
    {
      description: 'Full screen off',
      icon: iconMinimize,
      buttons: [], // ['0', 'Esc'],
      isSlash: true,
    },
    {
      description:
        'Hanging Protocol: Right (next) and left (prior) icons to step you through the images for that patient',
      icon: iconSteps,
      buttons: [], // ['←', '→'],
      width: '130px',
      isSlash: true,
    },
    {
      description:
        'Case Navigation: Right (next) and left (prior) icons to navigate through the course',
      icon: iconCases,
      buttons: [],
      width: '130px',
    },
  ],
  hangingProtocols: [
    {
      className: 'col-4',
      childs: [
        {
          description: 'Study overview',
          icon: iconStudyOverview,
          buttons: [],
          width: '70px',
        },
        {
          description: 'CC Right/Left',
          icon: iconCC,
          buttons: [],
        },
        {
          description: 'MLO Right/Left',
          icon: iconMLO,
          buttons: [],
        },
        {
          description: 'CC Right 2D/Tomo',
          icon: iconCCRight2D,
          buttons: [],
        },
        {
          description: 'MLO Right 2D/Tomo',
          icon: iconMLORight2D,
          buttons: [],
        },
        {
          description: 'CC Left 2D/Tomo',
          icon: iconCCLeft2D,
          buttons: [],
        },
        {
          description: 'MLO Left 2D/Tomo',
          icon: iconMLOLeft2D,
          buttons: [],
        },
      ],
    },
    {
      className: 'flex-fill',
      childs: [
        {
          description: 'Right breast of interest',
          icon: iconRightBrest,
          buttons: [],
        },
        {
          description: 'Left breast of interest',
          icon: iconLeftBrest,
          buttons: [],
        },
        {
          description: 'Assessment (if available)',
          icon: iconAssessment,
          buttons: [],
        },
        {
          description: 'Expert’s findings and comments',
          icon: iconComments,
          buttons: [],
        },
        {
          description: 'Diagnostic images (if available)',
          icon: iconDiagnostic,
          buttons: [],
        },
      ],
    },
  ],
  caseAccessment: [
    <span>Cases may be assessed only when all mandatory hangings were reviewed.</span>,
    <span>
      Open the reporting page via click on
      <img style={{ width: '45px' }} className='p-2' src={iconAssessment} alt='Assessement' />
      or as part of the workflow.
    </span>,
    <span>Note that you should report only the most suspicious finding per case.</span>,
    <span>
      If "Finding" was selected, all corresponding subcategories need to be filled in before
      submission is possible.
    </span>,
    <span>Submitted assessments cannot be changed afterwards.</span>,
    <span>
      For comparison open experts' assessment after your assessment via{' '}
      <img style={{ width: '45px' }} className='p-2' src={iconComments} alt='Assessement' />.
    </span>,
  ],
  faq: [
    {
      q: 'What to do if I cannot open the survey?',
      a: '',
      variants: [
        'Make sure that all previous course modules are completed and all case assessments were submitted (40 of 40)',
        'Reload page (F5) if multiple tabs were used',
      ],
    },
    {
      q: 'What to do if my scrolling direction seems to be contra-intuitively for me?',
      a:
        'This behavior is usually different on Windows PCs and Mac computers. If you are using a Mac, you may select the scroll direction as one of the mouse settings.',
    },
    {
      q: 'How to proceed if initial system tests fail?',
      a: 'Click on corresponding "more" links in the system test window for advice',
    },
    {
      q: 'How to reset a browser zoom? (e.g., if changed via Ctrl + scroll wheel)',
      a: 'Press (Ctrl + 0)',
    },
    {
      q: 'What to do if “exit fullscreen” annoys me in Chrome?',
      a: 'Use FireFox Version 31.0 or newer',
    },
    {
      q: 'What to do if IE11 fullscreen zero key does not work?',
      a: 'Use FireFox Version 31.0 or newer',
    },
    {
      q: 'What to do if in IE11 the mouse cursor for tools is not updated?',
      a: 'Use FireFox Version 31.0 or newer',
    },
    {
      q: 'How to control cine mode on tomo images?',
      a: 'Use context specific controlling options:',
      variants: [
        'To start cine on all displayed tomo images: Click on the cine button of the toolbar',
        'To start cine mode on one tomo image only: Use the context menu (via right mouse button)',
        'Note: Space bar will start/stop cine mode in all viewers if the mouse cursor is outside of them, and in the focus viewer only, if the mouse cursor is within one of the viewers',
      ],
    },
    {
      q: 'What to do if volume control in firefox (31.0) does not work for videos?',
      a:
        'Either use the volume control of the system in the lower right corner, or change the video volume control as usual, the volume will change, although the display of the volume control will not change. ',
    },
  ],
};
