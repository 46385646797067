import React, { memo } from 'react';

const Feedback = memo(
  ({
    data,
    resultKey,
    optimalSettings,
    minimalSettings,
    successText,
    warningText,
    failText,
    aborted,
  }) => {
    const feedback = {
      loading: {
        text: 'Testing... Please wait!',
        className: '',
      },
      success: {
        text: 'Passed',
        className: ' alert-success',
      },
      warning: {
        text: failText,
        className: ' alert-warning-light',
      },
      fail: {
        text: failText,
        className: ' alert-warning',
      },
      abort: {
        text: 'Bad connection detected! Aborted.',
        className: ' alert-danger',
      },
    };
    const feedbackKey = aborted
      ? 'abort'
      : !data
      ? 'loading'
      : data[resultKey] <= optimalSettings
      ? 'success'
      : data[resultKey] <= minimalSettings
      ? 'warning'
      : 'fail';
    return (
      <div className={`alert${feedback[feedbackKey].className}`}>
        <p className={`m-0 weight-100`}>{feedback[feedbackKey].text}</p>
      </div>
    );
  },
);

export default Feedback;
