import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { triggerDiagnosticsTest } from 'app/Main/components/ConnectionAndDeviceTestModal/actions';
import Loading from 'shared/components/Loading';
import { removeFromStore } from 'utils/storeHelpers';

const ConnectionTest = ({ history, triggerDiagnosticsTest }) => {
  useEffect(() => {
    removeFromStore('connectionAndDeviceTested');
    triggerDiagnosticsTest();
    history.goBack();
  }, [history, triggerDiagnosticsTest]);
  return <Loading classView='min-vh-100' />;
};

export default connect(
  null,
  { triggerDiagnosticsTest },
)(ConnectionTest);
