import React from 'react';
import img1 from 'assets/events/fb.svg';
import img2 from 'assets/events/twitter.svg';
import img3 from 'assets/events/linkedin.svg';
import footerImg from 'assets/events/footer-image.png';
import ResponsiveImage from 'shared/ResponsiveImage';

const soc = [
  {
    img: img1,
    url: 'https://www.facebook.com/Hologic',
  },
  {
    img: img2,
    url: 'https://twitter.com/Hologic',
  },
  {
    img: img3,
    url: 'https://www.linkedin.com/company/hologic',
  },
];

const EventFooter = ({ event }) => {
  return (
    <div className='event-footer' id='footerEvent'>
      <div className='container'>
        <h3 className='text-center'>About Hologic</h3>
        <p className='text-center'>
          From detection to treatment, Hologic is your trusted breast and skeletal health expert.
          We’re <br />
          committed to delivering clinically proven solutions across the continuum of care, to
          improve <br /> outcomes, reduce costs and increase patient satisfaction. Let us help you
          deliver exceptional breast <br /> and skeletal care to all women over the course of their
          lifetime.
        </p>
        <div className='text-center'>
          <img className='footer-img' src={footerImg} alt='soc' />
        </div>
        <div className='d-flex justify-content-between align-items-center copyright'>
          <p className='m-0'>© COPYRIGHT 2020 HOLOGIC, INC.</p>
          <ul className='d-flex m-0 p-0'>
            {soc.map((item, i) => (
              <li key={i} className={`${i === soc.length - 1 ? '' : 'mr-3'}`}>
                <a href={item.url} target='_blank' rel='noopener noreferrer'>
                  <ResponsiveImage imgUrl={item.img} alt='soc' />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventFooter;
