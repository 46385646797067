import React from 'react';
import { viewerDialogJson } from './../config/consts';
const { hangingProtocols } = viewerDialogJson;

const HangingProtocols = () => (
  <div>
    <div className='d-flex'>
      {hangingProtocols.map((group, groupIndex) => (
        <div key={groupIndex} className={`${group.className} p-0`}>
          {group.childs.map(({ icon, description, buttons = [], isSlash, width }, childIndex) => {
            return (
              <p
                key={`${groupIndex}-${childIndex}`}
                className='mt-4 d-flex align-items-center help-description'
              >
                <img style={{ width }} src={icon} alt={description} className='mr-4' />
                <span className='mr-4'>{description}</span>
                {buttons.map((btn, btnIndex) => (
                  <button key={`${groupIndex}-${childIndex}-${btnIndex}`}>{btn}</button>
                ))}
              </p>
            );
          })}
        </div>
      ))}
    </div>
    <p className='mt-2'>
      <b>Note</b>: The current (screening) study is always displayed first.
    </p>
    <p className='mt-3 help-description'>
      For hangings with viewers with multiple images: All images in a hanging can be toggled by
      clicking the tab icon.
    </p>
  </div>
);

export default HangingProtocols;
