import React from 'react';
import logo from 'assets/promo/toplogo.png';
import { Player, BigPlayButton } from 'video-react';

const links = {
  expromo: 'https://content.hologicace.com/content/expromo1.mp4',
  inpromo: 'https://content.hologicace.com/content/expromo2.mov',
};

const PromoPage = ({ history, location }) => {
  const isInpromo = location.pathname.includes('inpromo');
  const videoSettings = {
    src: links[isInpromo ? 'inpromo' : 'expromo'],
    fluid: false,
    preload: 'auto',
    height: window.innerHeight - 160,
    width: '100%',
  };

  const onClickAccessACE = () => {
    history.push('/login');
  };

  return (
    <div className='promo-page d-flex flex-column min-vh-100 justify-content-between'>
      <div className='header'>
        <div className='container d-flex align-items-center justify-content-between p-0 h-100'>
          <img src={logo} height='69' alt='img' />
          <button onClick={onClickAccessACE} className={`btn access-vip text-nowrap px-1`}>
            Access ACE
          </button>
        </div>
      </div>
      <div className='container content d-flex justify-content-center p-0'>
        <Player {...videoSettings}>
          <BigPlayButton position='center' />
        </Player>
      </div>
      <div className='footer container'>
        <p className='d-flex align-items-center justify-content-center h-100'>
          ADVANCED CONTINUING EDUCATION PLATFORM
        </p>
      </div>
    </div>
  );
};

export default PromoPage;
