import React from 'react';
import moment from 'moment';

const EventLine = ({ label, value, className }) => (
  <div className={`event-line ${className || ''}`}>
    <span className='label-key'>{label}</span>
    <span className='label-value'>{value}</span>
  </div>
);

const getMapUrl = item => {
  return `https://maps.google.com/maps?q=${item.lat},${item.lng}&hl=es&z=14&amp&output=embed`;
};

const entranceType = {
  0: 'Open',
  1: 'Invitation',
  2: 'Registration',
};

const eventTypes = {
  0: 'Webinar',
  1: 'TradeShow',
};

const typeNames = {
  0: 'Online',
  1: 'Offline',
};

const EventInfo = ({ event }) => {
  const isWebinar = eventTypes[event.eventType] === 'Webinar';

  return (
    <div className={`event-info ${isWebinar ? 'is-webinar' : ''}`}>
      <div className='container d-flex'>
        <div className='col-4 p-0'>
          <EventLine
            className='marg-1'
            label='Start Date'
            value={moment(event.startDate).format('dd, MM/DD/YYYY')}
          />
          <EventLine
            className='marg-1'
            label='End Date:'
            value={moment(event.endDate).format('dd, MM/DD/YYYY')}
          />
          <EventLine className='marg-1 line-border' label='Event Subject:' value={event.subject} />
          <EventLine className='marg-1' label='Type:' value={typeNames[event.eventType] || '-'} />
          <EventLine className='marg-2' label='Organizer:' value={event.organizer} />
          <EventLine className='marg-2' label='Country:' value={event.country} />
          <EventLine className='marg-1' label='City:' value={event.city} />
          <EventLine
            className='marg-2'
            label='Participation:'
            value={entranceType[event.entranceType]}
          />
          <EventLine
            className='marg-1'
            label='Event Capacity:'
            value={event.capacity < 1 ? 'No Limit' : event.capacity}
          />
        </div>
        {!isWebinar && (
          <div className='col d-flex justify-content-end p-0'>
            <iframe
              title='map'
              width='490'
              height='320'
              frameBorder='0'
              scrolling='no'
              marginHeight='0'
              marginWidth='0'
              src={getMapUrl(event)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EventInfo;
