import React from 'react';
import Button from 'shared/components/Button';
import { ANSWER_STATUSES } from '../constants';
import { bindDurationShowFull } from 'utils/appHelpers';

export const QuizFailure = ({
  handleRetakeQuiz,
  handleRetakeCourse,
  quizCompeteDetails,
  totalAttemptsCount,
  showWhatIsWrong,
  handleClickOk,
  handleShowAnswers,
  currentAttempt,
}) => {
  const answeredSteps = quizCompeteDetails?.userQuiz?.quiz?.steps.filter(s => s.question);
  const correctAnswersCount =
    answeredSteps &&
    answeredSteps.filter(s => s.question.userAnswerStatus === ANSWER_STATUSES.Success).length;
  const inCorrectAnswersCount =
    answeredSteps &&
    answeredSteps.filter(s => s.question.userAnswerStatus === ANSWER_STATUSES.Failure).length;
  const totalAnswersCount = quizCompeteDetails?.userQuiz?.quiz?.steps?.length;
  const passedPercent = ((correctAnswersCount / totalAnswersCount) * 100).toFixed(0);
  return (
    <div className='question-container success'>
      <div className='question-block success'>
        <div className='question-head success'>
          <h6 className='title'>{`Your Result: ${correctAnswersCount}/${totalAnswersCount} points (${passedPercent}%)`}</h6>
        </div>
        <div className='body'>
          <div className='details-block'>
            <div className='detail-item'>
              Status: <span className='value'>Not Passed</span>
            </div>
            <div className='detail-item'>
              Time:{' '}
              <span className='value'>
                {quizCompeteDetails.userQuiz.duration
                  ? `${bindDurationShowFull(quizCompeteDetails.userQuiz.duration)} min`
                  : 'No Limit'}
              </span>
            </div>
            <div className='detail-item'>
              Correct answers: <span className='value'>{correctAnswersCount}</span>
            </div>
            <div className='detail-item'>
              Incorrect answers: <span className='value'>{inCorrectAnswersCount}</span>
            </div>
            <div className='detail-item'>
              Attempt number:{' '}
              <span className={`value ${currentAttempt === totalAttemptsCount && 'danger'}`}>
                {currentAttempt}/{totalAttemptsCount}
              </span>
            </div>
          </div>
          <div className='congrats-block'>
            <p className='oops'>Oops...</p>
            <p className='description'>you’re almost there... Try it again!</p>
          </div>
        </div>
        {!!showWhatIsWrong && (
          <Button onClick={handleShowAnswers} className={`btn-blue btn-quiz-main what-wrong mt-2`}>
            What Did I do wrong?
          </Button>
        )}
        <div className='answer-btns-block'>
          {quizCompeteDetails.attempts === totalAttemptsCount ? (
            <Button onClick={handleClickOk} className={`btn-blue btn-quiz-outlined-white`}>
              Ok
            </Button>
          ) : (
            <Button onClick={handleRetakeQuiz} className={`btn-blue btn-quiz-outlined-white`}>
              Retake quiz
            </Button>
          )}
          <Button onClick={handleRetakeCourse} className={`btn-blue btn-quiz-outlined-white`}>
            Retake course
          </Button>
        </div>
      </div>
    </div>
  );
};
