/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import ResponsiveImage from 'shared/ResponsiveImage';

const QuizViewImageColumn = ({ column, columnsCount, handleSelectImg, selectedImg }) => {
  const { columnName, rightAnswers, userAnswers } = column;
  const incorrectUserAnswers = userAnswers.filter(
    ({ imageId }) => !rightAnswers.some(rA => rA.imageId === imageId),
  );
  return (
    <div className={`quiz_view_img_column_container ${columnsCount < 3 && 'wide'}`}>
      <div className='head_block'>{columnName}</div>
      <div className='body_block'>
        <div className='scrollable_block'>
          {rightAnswers?.length &&
            rightAnswers.map((imgItem, idx) => {
              const isAnsweredByUser = userAnswers.some(
                ({ imageId }) => imageId === imgItem.imageId,
              );
              return (
                <div
                  onClick={() => handleSelectImg(imgItem)}
                  key={idx}
                  className={`img_item ${selectedImg?.imageId === imgItem.imageId && 'selected'}`}
                >
                  {isAnsweredByUser && <div className='overlay success' />}
                  <ResponsiveImage alt='category_img' imgUrl={imgItem.imageUrl} />
                </div>
              );
            })}
          {!!incorrectUserAnswers?.length &&
            incorrectUserAnswers.map((imgItem, idx) => {
              const isAnsweredByUser = userAnswers.some(
                ({ imageId }) => imageId === imgItem.imageId,
              );
              return (
                <div
                  onClick={() => handleSelectImg(imgItem)}
                  key={idx}
                  className={`img_item ${selectedImg?.imageId === imgItem.imageId && 'selected'}`}
                >
                  {isAnsweredByUser && <div className='overlay danger' />}
                  <ResponsiveImage alt='category_img' imgUrl={imgItem.imageUrl} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export const ImageTypeView = ({ question }) => {
  const [selectedImg, setSelectedImg] = useState(null);
  const handleSelectImg = imgItem => {
    setSelectedImg(imgItem.imageId === selectedImg?.imageId ? null : imgItem);
  };
  return (
    <>
      <div className='descriptions-block'>
        <span className='drag-descriprion'>
          Sort the items by dragging them to the appropriate boxes.{' '}
        </span>
        <span className='img-description'>{selectedImg && selectedImg.description}</span>
      </div>
      <div className='images-columns-container custom-scrollbar-block'>
        {question.imageAnswers &&
          question.imageAnswers.map((column, colIdx) => {
            return (
              <QuizViewImageColumn
                column={column}
                key={colIdx}
                columnsCount={question.imageAnswers.length}
                handleSelectImg={handleSelectImg}
                selectedImg={selectedImg}
              />
            );
          })}
      </div>
    </>
  );
};
