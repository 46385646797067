import { formatCourseAuthors } from 'app/Main/routes/Authors/actions';
import { formatCourseData } from 'app/Main/routes/Courses/actions';

import placeholder from 'assets/video.jpg';

const findSubscriptionAuthors = courses => {
  if (!courses) return [];
  let result = [];
  courses.forEach(({ course }) => {
    if (course) {
      const authors = formatCourseAuthors(course.courseAuthors || []);
      result = [...result, ...authors];
    }
  });
  return result;
};

export const formatSubscription = data => {
  return {
    id: data.id,
    name: data.title,
    type: data.subscriptionType,
    questions: data.testQuestions,
    image: data.imageUrl || placeholder,
    info: data.description,
    days: data.courseDuration,
    time: data.planDuration,
    authors: findSubscriptionAuthors(data.courses),
    userSubs: data.userSubscription || (data.userSubscriptions ? data.userSubscriptions[0] : null),
    courses: formatCourseData(data.courses),
    totalCourses: data.totalCourses,
    totalAuthors: data.totalAuthors,
    isSystem: data.isSystem,
    expirable: data.expirable,
    keyRequired: data.keyRequired,
  };
};
