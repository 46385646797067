import React from 'react';

const ButtonLoading = ({ text }) => {
  return (
    <>
      <span className='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true' />
      {text || 'Loading'}
    </>
  );
};

export default ButtonLoading;
