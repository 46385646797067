/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { IconAbout } from 'shared/components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { getError, getEventId } from 'utils/appHelpers';
import { onGetEvents } from 'app/Events/actions';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';
import EventInfo from 'app/Events/routes/EventLanding/components/EventInfo';

const AboutEvent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const events = useSelector(state => state.events);
  const id = getEventId();
  const event = events[id];

  const getEventData = async id => {
    try {
      const { data } = await Api.get(`/events/event/${id}`);
      dispatch(onGetEvents({ ...events, [id]: data.data }));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getEventData(id);
    //eslint-disable-next-line
  }, [])

  if (!event) return <Loading classView='mt-5' />;

  return (
    <div className='event-view'>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconAbout color='#131f6b' />
          </span>
          About
        </div>
      </div>
      <EventInfo event={event} />
      <div className='about-event'>
        <div className='info' dangerouslySetInnerHTML={{ __html: event.about }} />
        <p className='text-black-50 text-right'>
          *Not all products or technologies featured are available in all markets
        </p>
      </div>
    </div>
  );
};

export default AboutEvent;
