import { useSnackbar } from 'notistack';

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export const SnackbarUtils = {
  enqueueSnackbar: (...args) => {
    useSnackbarRef.enqueueSnackbar(...args);
  },
};
