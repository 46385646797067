import React from 'react';

const DisabledPart = () => {
  return (
    <div className='p-4 text-center text-white w-100'>
      You must first review all steps of the hanging protocol as indicated below
    </div>
  );
};

export default DisabledPart;
