import { useRef, useEffect } from 'react';

/**
 * A hook that keeps track of the previous value of a state or Redux state.
 *
 * @param value - The current value of the state.
 * @returns The previous value of the state.
 */
export const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
