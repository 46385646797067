import React, { useRef, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import * as cornerstone from 'cornerstone-core';
import Select from 'shared/components/Select';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
import Loading from 'shared/components/Loading';

const cases = [
  { id: 156, name: '3_AITomoSlice_101' },
  { id: 157, name: '3_AITomoSlice_103' },
  { id: 158, name: '3_AITomoSlice_104' },
  { id: 159, name: '3_AITomoSlice_105' },
  { id: 160, name: '3_AITomoSlice_108' },
  { id: 161, name: '3_AITomoSlice_109' },
  { id: 162, name: '3_AITomoSlice_111' },
  { id: 163, name: '3_AITomoSlice_112' },
  { id: 164, name: '3_AITomoSlice_113' },
  { id: 165, name: '3_AITomoSlice_114' },
  { id: 166, name: '3_AITomoSlice_116' },
  { id: 167, name: '3_AITomoSlice_118' },
  { id: 168, name: '3_AITomoSlice_119' },
  { id: 169, name: '3_AITomoSlice_120' },
];

const PublicViewer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeCase, setActiveCase] = useState(156);
  const [activeImage, setActiveImage] = useState();
  const [dicomItems, setDicomItems] = useState();
  const [fetch, setFetch] = useState(false);
  const el1 = useRef(null);

  const getCaseImages = async () => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/cases/getcasebyid/${activeCase}`);
      const items = data.data.caseDicomItems.map(({ dicomItem }) => {
        return {
          id: dicomItem.dicomItemImages ? dicomItem.dicomItemImages[0].url : '',
          name: dicomItem.fileName,
        };
      });
      setDicomItems(items);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const onWindowResize = () => {
    cornerstone.resize(el1.current);
  };

  const loadImage = async url => {
    const image = await cornerstone.loadAndCacheImage(url);
    const viewportOptions = { pixelReplication: false, image };
    await cornerstone.displayImage(el1.current, image, viewportOptions);
  };

  const initElement = async () => {
    await cornerstone.enable(el1.current);
    await cornerstone.resize(el1.current);
    window.addEventListener('resize', onWindowResize);
    const options = {
      configuration: {
        invert: false,
        preventZoomOutsideImage: false,
        minScale: 0.01,
        maxScale: 5.0,
      },
    };
    cornerstoneTools.addToolForElement(el1.current, cornerstoneTools.ZoomMouseWheelTool, options);
    cornerstoneTools.addTool(cornerstoneTools.PanTool);
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 });
    cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1 });
  };

  const initData = async url => {
    await loadImage(url);
    await cornerstone.fitToWindow(el1.current);
    await cornerstone.resize(el1.current);
  };

  useEffect(() => {
    initElement();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCaseImages(activeCase);
    //eslint-disable-next-line
  }, [activeCase]);

  useEffect(() => {
    if (activeImage) initData(activeImage);
    //eslint-disable-next-line
  }, [activeImage]);

  return (
    <div className='public-viewer viewer d-flex flex-column flex-fill'>
      <div className='control-area bg-light p-2 d-flex'>
        {fetch && <Loading />}
        <Select
          className='col-3'
          label='Select Cases'
          name='cases'
          selectClassName='m-0'
          value={activeCase}
          items={cases}
          onChange={({ target }) => setActiveCase(target.value)}
        />
        {dicomItems && (
          <Select
            className='col-5 ml-2'
            label='Select DICOM'
            name='dicom'
            selectClassName='m-0'
            value={activeImage}
            items={dicomItems}
            onChange={({ target }) => setActiveImage(target.value)}
          />
        )}
      </div>
      <div className='pb-viewer-area'>
        <div className='viewport-element p-0' ref={el1}>
          <canvas className='cornerstone-canvas' />
        </div>
      </div>
    </div>
  );
};

export default PublicViewer;
