import React from 'react';
import { useSelector } from 'react-redux';
import CourseHeader from './CourseHeader';
import { Document, Page, pdfjs } from 'react-pdf';

const pdfVersion = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfVersion;

const CourseCertificate = ({ history, match }) => {
  const { id } = match.params;
  const courses = useSelector(store => store.courses);
  const course = courses.find(item => item.id === Number(id));
  const file = course.certificateUrl;

  if (!course.isCertificateAvailable) {
    return (
      <div className='certificate-page'>
        <CourseHeader history={history} course={course} file={file} />
        <div className='text-center py-5'>No Certificate is available for this course</div>
      </div>
    );
  }

  return (
    <div className='certificate-page'>
      <CourseHeader history={history} course={course} file={file} />
      {file && (
        <Document file={file} id='certificate'>
          <Page width={750} pageNumber={1} id='certificatePage' />
        </Document>
      )}
      {!file && (
        <div className='text-center py-5'>
          Your certificate will be available after completion of the course.
        </div>
      )}
    </div>
  );
};

export default CourseCertificate;
