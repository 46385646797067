import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IconContacts } from 'shared/components/Icons';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import ButtonLine from 'shared/components/ButtonLine';
import Select from 'shared/components/Select';

const categories = [
  {
    name: 'General Inquiry / Feedback',
    code: '1',
  },
  {
    name: 'Support Ticket',
    code: '2',
  },
];

const Contacts = () => {
  const account = useSelector(state => state.account);
  const initData = { name: account.fullName, email: account.email, text: '', ticket: '1' };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [data, setData] = useState(initData);

  const action = key => (
    <button
      className='btn btn-small p-0 text-white'
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      OK
    </button>
  );

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      setFetch(true);
      data.ticket = Number(data.ticket);
      await Api.post('/common/sendemail', data);
      enqueueSnackbar('Your message has been successfully submitted.', {
        variant: 'success',
        persist: true,
        action,
      });
      setData(initData);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempData = { ...data };
    tempData[name] = value;
    setData(tempData);
  };

  return (
    <div className='overflow-hidden'>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconContacts color='#131f6b' />
          </span>
          Contact
        </div>
      </div>
      <div className='contact-conten row p-3'>
        <div className='col-6 default-form'>
          <form onSubmit={handleSubmit}>
            <ul className='p-0 mb-0'>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Name:</label>
                </div>
                <div className='input-group p-0'>
                  <input
                    type='text'
                    name='name'
                    value={data.name}
                    onChange={handleChange}
                    className='w-100'
                    placeholder='Enter Name'
                    required
                  />
                </div>
              </li>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Email:</label>
                </div>
                <div className='input-group p-0'>
                  <input
                    type='email'
                    name='email'
                    value={data.email}
                    onChange={handleChange}
                    className='w-100'
                    placeholder='Enter Email'
                    required
                  />
                </div>
              </li>
              <li className='d-flex align-items-center'>
                <div className='col-3 pl-0'>
                  <label className='m-0'>Category:</label>
                </div>
                <div className='input-group p-0'>
                  <Select
                    name='ticket'
                    className='contact-category'
                    value={data.ticket}
                    items={categories}
                    onChange={handleChange}
                    required
                  />
                </div>
              </li>
              <li className='d-flex'>
                <div className='col-3 pl-0 pt-1'>
                  <label className='m-0'>Short Description:</label>
                </div>
                <textarea
                  name='text'
                  onChange={handleChange}
                  placeholder='Enter Description'
                  value={data.text}
                  required
                />
              </li>
            </ul>
            <div className='d-flex justify-content-end mt-2'>
              <ButtonLine type='submit' disabled={fetch} className='btn-contact-send btn-blue'>
                Send
              </ButtonLine>
            </div>
          </form>
        </div>
        <div className='col-6'>
          {/* <div className='contact-info text-secondary'>
            <p className='pr-2'>
              <strong>Didn't find a solution to your problem?</strong> <br /> <br />
              For US customers please contact through the support form or direct email at{' '}
              <a href='mailto:Selenia.Support@hologic.com' target='_self'>
                Selenia.Support@hologic.com
              </a>
              . <br />
              <br />
              For Australia or New Zealand customers please contact through the support form <br />{' '}
              or direct email at{' '}
              <a href='mailto:ANZonlinevirtualtomo@hologic.com' target='_self'>
                ANZonlinevirtualtomo@hologic.com
              </a>
              . <br />
            </p>
            <p>© 2013-2020 Hologic, Inc.</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Contacts;
