import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import { WithTitleRoute, hasAccess } from 'utils/permissionHelper';
import ProductLogin from './routes/ProductLogin';
import ProductRegister from './routes/ProductRegister';
import ProductLanding from './routes/ProductLanding';
import VerifyEmail from './routes/VerifyEmail';
import EntitySelect from './routes/EntitySelect';
import ResetPassword from './routes/ResetPassword';
import ProductCreatePassword from './CreatePassword';
import WithParamsRedirect from 'HOC/WithParamsRedirect';
import { useLocation } from 'react-router-dom';

const Product = ({ match }) => {
  const isAuthUser = useSelector(state => state.isAuthenticated);
  const isVisitorUser = hasAccess('visitor_user');
  const isAuth = isAuthUser && !isVisitorUser;
  const { pathname } = useLocation();
  return (
    <div className='events'>
      <Switch>
        <Redirect from='/:url*(/+)' to={pathname.slice(0, -1)} />
        {!isAuth && <WithTitleRoute path='/product/:urlPath/login' component={ProductLogin} />}
        {!isAuth && (
          <WithTitleRoute path='/product/:urlPath/register' component={ProductRegister} />
        )}
        {!isAuth && (
          <WithTitleRoute path='/product/:urlPath/entity-select' component={EntitySelect} />
        )}
        {!isAuth && <WithTitleRoute path='/product/:urlPath/verify' component={VerifyEmail} />}
        {!isAuth && (
          <WithTitleRoute path='/product/:urlPath/reset-password' component={ResetPassword} />
        )}
        {!isAuth && (
          <WithTitleRoute
            path='/product/:urlPath/create-password'
            component={ProductCreatePassword}
          />
        )}
        {!isAuth && (
          <Route path='/product/:urlPath'>
            <WithParamsRedirect from='/product/:urlPath' to='/product/:urlPath/login' />
          </Route>
        )}
        {isAuth && <WithTitleRoute path={`/product/:urlPath`} component={ProductLanding} />}
        {isAuth && (
          <Route path='/product/:urlPath'>
            <Redirect from={`*`} to='/product/:urlPath' />
          </Route>
        )}
      </Switch>
    </div>
  );
};

export default Product;
