import React, { memo } from 'react';

const CheckBox = ({ name, label, value, onChange, className, checked }) => {
  return (
    <div className={`align-items-center checkbox-item d-flex mr-3 ${className || ''}`}>
      <input
        type='checkbox'
        id={`checkbox${name}`}
        name={name}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <label htmlFor={`checkbox${name}`}>{label}</label>
    </div>
  );
};

export default memo(CheckBox);
