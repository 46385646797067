import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconArrowRight } from 'shared/components/Icons';
import Button from 'shared/components/Button';
import { triggerDiagnosticsTest } from 'app/Main/components/ConnectionAndDeviceTestModal/actions';
import {
  SIconResolution,
  SIconLatency,
  SIconBandwidth,
} from 'shared/components/StatisticTestIcons';
import { removeFromStore } from 'utils/storeHelpers';

const headers = [
  { label: 'Bandwidth', key: 'bandwidth' },
  { label: 'Screen Resolution', key: 'screen' },
  { label: 'Window Resolution', key: 'window' },
  { label: 'Latency', key: 'latency' },
  { label: 'Connection to server', key: 'server' },
  { label: 'Browser', key: 'browser' },
  { label: 'OS System', key: 'os' },
  { label: 'IP Address', key: 'ip' },
];

const icons = {
  resolution: SIconResolution,
  latency: SIconLatency,
  bandwidth: SIconBandwidth,
};

const Status = ({ connections, triggerDiagnosticsTest }) => {
  const data = connections ? connections[connections.length - 1] : {};

  const runTest = () => {
    removeFromStore('connectionAndDeviceTested');
    triggerDiagnosticsTest();
  };

  const showValue = (item, key) => {
    if (!item && key === 'server') {
      const latency = data?.['latency'] && data?.['latency']?.latency;
      return <span className='pl-4'>{latency && latency.min > 0 ? 'Yes' : 'No'}</span>;
    }

    if (typeof item === 'string') return <span className='pl-4'>{item}</span>;

    if (typeof item === 'object') {
      const Icon = icons[item.type];
      return (
        <>
          <Icon data={item} className='mr-1' /> {item.value}
        </>
      );
    }
    return 'Not tested yet';
  };

  return (
    <div className='col p-3 status-box'>
      <h5>Recent Connection and Device Test:</h5>
      <div className='d-flex align-items-end'>
        <ul className='flex-1 p-0 list-unstyled m-0 text-capitalize font-size-15'>
          {headers.map(item => (
            <li key={item.label} className='weight-100 d-flex'>
              <span className='mr-2 weight-500 d-inline-block col-4 p-0'>{item.label}:</span>
              <span>{showValue(data?.[item.key], item.key)}</span>
            </li>
          ))}
          <li className='mt-2'>
            <Button className='btn-sm btn-white' onClick={runTest}>
              Run Test
            </Button>
          </li>
        </ul>
        <Link to='profile/history'>
          View History <IconArrowRight />
        </Link>
      </div>
    </div>
  );
};

export default connect(
  null,
  { triggerDiagnosticsTest },
)(Status);
