import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const Column = ({
  children,
  id,
  overColumnId,
  column,
  className,
  handleClickNameEdit,
  handleChangeColumnName,
  handleSubmitName,
  questionIdx,
  columnIndex,
  handleRemoveColumn,
}) => {
  const { setNodeRef: setFirstDroppableRef } = useDroppable({
    id,
  });

  const isColumnOvered =
    overColumnId === id || (column.items && column.items.some(item => item.id === overColumnId));

  return (
    <div className={`category_column ${isColumnOvered && 'overed'} ${className}`}>
      <div className='header'>
        {!column.isEditing && <h6 className='title'>{column.columnName}</h6>}
      </div>
      <div ref={setFirstDroppableRef} className='body'>
        {children}
      </div>
    </div>
  );
};
