import React from 'react';
import BackButton from 'shared/BackButton';
import CourseHeaderRightNav from '../CourseView/components/CourseHeaderRightNav';

const CourseHeader = ({ history, course }) => (
  <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between'>
    <div className='d-flex align-items-center'>
      <BackButton history={history} route={`/courses/${course.id}`} />
      <span className='ml-1'>Evaluation</span>
    </div>
    <CourseHeaderRightNav course={course} />
  </div>
);
export default CourseHeader;
