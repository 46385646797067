import React from 'react';
import { QUESTION_TYPES_OBJ } from '../constants';
import { RadioTypeQuestion } from './RadioQuestion';
import { MultiTypeQuestion } from './MultiTypeQuestion';
import { BooleanTypeQuestion } from './BooleanTypeQuestion';
import { ImageTypeQuestion } from './ImageTypeQuestion';
import Button from 'shared/components/Button';

const Question = {
  [QUESTION_TYPES_OBJ.radio]: RadioTypeQuestion,
  [QUESTION_TYPES_OBJ.multi]: MultiTypeQuestion,
  [QUESTION_TYPES_OBJ.yesno]: BooleanTypeQuestion,
  [QUESTION_TYPES_OBJ['image-category']]: ImageTypeQuestion,
};

export const QuestionContainer = ({
  currentStep,
  question,
  questionsCount,
  handleSubmitAnswer,
  isRequired,
  answer,
  setAnswer,
}) => {
  const isImageType = question?.type === QUESTION_TYPES_OBJ['image-category'];

  const QuestionComponent = Question[question.type];

  return (
    <div className='question-container'>
      <div className='question-block'>
        <div className='question-head'>
          <span className='q-number'>{currentStep}</span>
          <p className='q-description'>{question?.title}</p>
          <span className='q-number-in-all'>
            {currentStep}/{questionsCount}
          </span>
        </div>
        <div className={`answers-content ${isImageType && 'image-type'}`}>
          {!!question.type && (
            <QuestionComponent
              setAnswer={setAnswer}
              question={question}
              currentStep={currentStep}
            />
          )}
        </div>
        <div className='answer-btns-block'>
          <Button
            onClick={() => {
              let currentAnswer = [...(answer || [])];
              if (question.type === QUESTION_TYPES_OBJ['image-category']) {
                currentAnswer = (answer || []).map(a => ({
                  category: a.name,
                  imageIds: a.items.map(img => img.imageId),
                }));
              }
              setAnswer(null);
              handleSubmitAnswer(currentAnswer, question?.type);
            }}
            className={`btn-blue btn-quiz-main`}
            disabled={isRequired && !answer?.length}
          >
            {currentStep === questionsCount ? 'Finish' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};
