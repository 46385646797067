import React, { useState } from 'react';
import { ENGLISH_UPPERCASE_LETTERS } from '../constants';
import { QuestionMultiCheckIcon } from 'shared/components/Icons';

export const MultiTypeQuestion = ({ question, setAnswer, currentStep }) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]); // It's now an array to store multiple selections

  const handleCheckboxChange = (idx, id) => {
    if (selectedAnswers.includes(id)) {
      setSelectedAnswers(selectedAnswers.filter(item => item !== id));
      setAnswer(prevAnswers => {
        return (prevAnswers || []).filter(a => a !== question.answersMulti[idx].answer);
      });
    } else {
      setSelectedAnswers([...selectedAnswers, id]);
      setAnswer(prevAnswers => [...(prevAnswers || []), question.answersMulti[idx].answer]);
    }
  };

  return (
    <>
      <div className='type-block'>
        <span>Answer Type:</span>
        <span className='color-primary'> Text Multiple Select</span>
      </div>
      <div className='answers-block'>
        {question.answersMulti.map((answer, idx) => {
          const isSelected = selectedAnswers.includes(answer.questionAnswerId);
          return (
            <div key={answer.questionAnswerId} className='answer-row'>
              <input
                className={`answer-checkbox ${isSelected && 'selected'}`}
                type='checkbox'
                name='answerOption'
                id={`answer_${answer.questionAnswerId}`}
                value={answer.questionAnswerId}
                checked={isSelected}
                onChange={() => handleCheckboxChange(idx, answer.questionAnswerId)}
              />
              <label className='answer-text' htmlFor={`answer_${answer.questionAnswerId}`}>
                <span className='custom-checkbox'>
                  {isSelected && <QuestionMultiCheckIcon className='answer-check' />}
                </span>
                {ENGLISH_UPPERCASE_LETTERS[idx]}. {answer.answer}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};
