import React, { useEffect, useState, cloneElement } from 'react';

const listenToOutsideClick = dispatch => {
  const header = document.querySelector('.app-dropdown-header');
  const body = document.querySelector('.app-dropdown-body');
  document.addEventListener('click', e => {
    const clicked = el => el.contains(e.target);
    if (!clicked(header) && !clicked(body)) dispatch(false);
  });
};

const DropdownHeader = ({ children, onClick }) => {
  return (
    <div className='app-dropdown-header' onClick={onClick} role='presentation'>
      {children}
    </div>
  );
};
const DropdownBody = ({ children }) => {
  return <div className='app-dropdown-body z-index-5 d-flex flex-column'>{children}</div>;
};

const DropDown = ({ children, className = '' }) => {
  const [open, toggleDropdown] = useState(false);

  useEffect(() => {
    if (open) listenToOutsideClick(toggleDropdown);
  }, [open]);

  return (
    <div className={`app-dropdown ${className} ${open ? 'active' : ''}`}>
      {cloneElement(children[0], { onClick: () => toggleDropdown(!open) })}
      {open && children[1]}
    </div>
  );
};

export { DropDown, DropdownHeader, DropdownBody };
