import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export const DroppableUncategorizedArea = ({ children, handleScroll, id }) => {
  const { setNodeRef: setFirstDroppableRef } = useDroppable({
    id,
  });
  return (
    <div
      ref={setFirstDroppableRef}
      id='add_steps_draggable'
      className='draggable_block custom-scrollbar-block horizontal'
      onScroll={handleScroll}
    >
      {children}
    </div>
  );
};
