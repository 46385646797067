import React from 'react';
import { useLocation } from 'react-router';
import BackButton from 'shared/BackButton';
import CourseHeaderRightNav from '../CourseView/components/CourseHeaderRightNav';

const ProgressHeader = ({ course, history }) => {
  const location = useLocation();
  const isFromAccount = location.state && location.state.from === 'account';
  const options = {
    [isFromAccount ? 'noBackRoute' : 'route']: `/courses/${course.id}`,
  };

  return (
    <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between'>
      <div className='d-flex'>
        <div className='pr-2 d-flex'>
          <BackButton history={history} {...options} />
          <span className='ml-1'>Progress for:</span>
        </div>
        <span className='weight-500'>{course.name}</span>
      </div>
      {!isFromAccount && <CourseHeaderRightNav course={course} />}
    </div>
  );
};
export default ProgressHeader;
