import { createReducer } from 'utils/reduxHelpers';
import { ON_LOGOUT } from 'app/Auth/actions';

const ON_DIAGNOSTIC_TEST_TRIGGER = '@@authors/ON_DIAGNOSTIC_TEST_TRIGGER';
const ON_DIAGNOSTIC_DATA_SET = '@@authors/ON_DIAGNOSTIC_DATA_SET';

const onDiagnosticsTestTrigger = () => ({ type: ON_DIAGNOSTIC_TEST_TRIGGER });
const onDiagnosticDataSet = payload => ({ type: ON_DIAGNOSTIC_DATA_SET, payload });

const diagnosticTestReducerInitialState = false;
const diagnosticDataReducerInitialState = {
  resolution: null,
  latency: null,
  bandwidth: null,
  failed: false,
};

const diagnosticTestHandler = {
  [ON_DIAGNOSTIC_TEST_TRIGGER]: (state, action) => !state,
  [ON_LOGOUT]: () => false,
};
const diagnosticDataHandler = {
  [ON_DIAGNOSTIC_DATA_SET]: (state, action) => ({ ...state, ...action.payload }),
  [ON_LOGOUT]: () => diagnosticDataReducerInitialState,
};

export const triggerDiagnosticsTest = bool => dispatch => dispatch(onDiagnosticsTestTrigger());
export const setDiagnosticData = params => dispatch => dispatch(onDiagnosticDataSet(params));

export const diagnosticTestReducer = createReducer(
  diagnosticTestReducerInitialState,
  diagnosticTestHandler,
);
export const diagnosticDataReducer = createReducer(
  diagnosticDataReducerInitialState,
  diagnosticDataHandler,
);
