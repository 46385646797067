/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Loading from 'shared/components/Loading';

import { SearchInput } from 'shared/components/SearchInput';
import { getDate, getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';

import NotFoundImage from 'assets/marketing/not-found.png';
import CourseDefaultImage from 'assets/marketing/course_default_image.png';
import ResponsiveImage from 'shared/ResponsiveImage';

const types = { 1: 'video', 2: 'pdf', 3: 'cases', 4: 'image', 5: 'feedback', 6: 'quiz' };

const getFirstLessonPath = course => {
  Api.get(`/courses/check-to-enroll/${course.id}`);
  const firstLesson = course.courseLessons[0]?.lesson;
  return `/marketing-courses/${course.id}/${types[firstLesson.contentType]}/${firstLesson.id}`;
};

export const MarketingCourses = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [courses, setCourses] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [tags, setTags] = useState([]);
  const [activeSearchTags, setActiveSearchTags] = useState(['all']);

  const coursesPerPage = 12;

  const params = { page: currentPage, limit: coursesPerPage, search };

  const getMarketingMaterials = async tags => {
    try {
      const currentParams = tags ? { ...params, tags: tags.join(',') } : { ...params };
      setIsFetching(true);
      const { data } = await Api.get('/courses/marketing-materials', { params: currentParams });
      setCourses(data.data.results);
      setPageCount(data.data.pageCount);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const getMarketingTags = async () => {
    try {
      const { data } = await Api.get('/tag/learner-tags', {
        params: { isMarketingMaterial: 1, limit: 1000 },
      });
      setTags(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSearch = e => setSearch(e.target.value);

  const handlePageClick = selectedItem => {
    const coursesBlockElm = document.getElementById('marketing-courses');
    coursesBlockElm.scrollIntoView({ block: 'start' });
    setCurrentPage(selectedItem.selected + 1);
  };

  const handleToggleSelectedTags = id => {
    if (activeSearchTags[0] === 'all') {
      setActiveSearchTags([id]);
    } else if (activeSearchTags.includes(id)) {
      const updatedSearchTags = activeSearchTags.filter(tagId => tagId !== id);
      setActiveSearchTags(updatedSearchTags);
      if (!updatedSearchTags.length) setActiveSearchTags(['all']);
    } else {
      setActiveSearchTags([...activeSearchTags, id]);
    }
  };

  useEffect(() => {
    const tags = activeSearchTags[0] !== 'all' && activeSearchTags;
    getMarketingMaterials(tags);
  }, [currentPage, activeSearchTags]);

  useEffect(() => {
    getMarketingTags();
  }, []);

  return (
    <div className='marketing-landing-content' id='noncmeSection'>
      <div id='marketing-courses' className='courses-container'>
        <h4 className='title'>Marketing Courses</h4>
        <div className='search-block'>
          <SearchInput
            onEnter={() => getMarketingMaterials(activeSearchTags)}
            onChange={handleSearch}
            value={search}
            placeholder='Search in materials'
          />
          <div className='search-types-block'>
            <div
              onClick={() => setActiveSearchTags(['all'])}
              className={`search-type-item first ${activeSearchTags[0] === 'all' && 'active'}`}
            >
              All
            </div>
            {tags.map((tag, idx) => (
              <div
                key={idx}
                onClick={() => handleToggleSelectedTags(tag.id)}
                className={`search-type-item ${activeSearchTags.includes(tag.id) && 'active'}`}
              >
                {tag?.name}
              </div>
            ))}
          </div>
        </div>
        <div className='courses-block'>
          {!isFetching && !courses?.length && (
            <div className='not_found_block'>
              <img alt='not found' src={NotFoundImage} />
              <p>No Results Found</p>
            </div>
          )}
          {!courses || isFetching ? (
            <Loading classView='mt-5 m-auto' />
          ) : (
            courses.map((course, index) => (
              <Link
                onClick={() => Api.get(`/courses/check-to-enroll/${course.id}`)}
                to={getFirstLessonPath(course)}
                key={index}
                className='landing-course-item'
              >
                <div className='img_block'>
                  <ResponsiveImage
                    className='course-image'
                    alt='course banner'
                    imgUrl={course.imageUrl || CourseDefaultImage}
                  />
                </div>
                <div className='title-block'>
                  <div className='marketing-course-title'>{course.title}</div>
                  <div className='create-date'> {getDate(course.createdAt)}</div>
                </div>
                <div className='course-description'>{course.info}</div>
              </Link>
            ))
          )}
        </div>
        <ReactPaginate
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={'landing-pagination'}
          activeClassName={'active'}
          forcePage={currentPage - 1}
        />
      </div>
    </div>
  );
};
