/* eslint-disable consistent-return */
import React, { useRef, useEffect } from 'react';

const WithMouseFollowOrb = WrappedComponent => {
  return props => {
    return <WrappedComponent {...props} />;
  };
};

const useMouseFollowOrb = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    canvas.width = rect.width;
    canvas.height = rect.height;

    let gradientX = 100;
    let gradientY = 150;

    const updateGradient = (x, y) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let gradient = ctx.createRadialGradient(x, y, 30, x, y, 700);
      // Define gradient color stops
      gradient.addColorStop(0, 'rgba(110, 75, 155, 0.8)');
      gradient.addColorStop(0.2, 'rgba(110, 75, 155, 0.7)');
      gradient.addColorStop(0.3, 'rgba(110, 75, 155, 0.6)');
      gradient.addColorStop(0.4, 'rgba(110, 75, 155, 0.4)');
      gradient.addColorStop(0.5, 'rgba(110, 75, 155, 0.2)');
      gradient.addColorStop(1, 'rgba(110, 75, 155, 0.1)');

      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    };

    updateGradient(gradientX, gradientY);

    const mouseMoveHandler = e => {
      gradientX = e.clientX - rect.left;
      gradientY = e.clientY - rect.top;
      updateGradient(gradientX, gradientY);
    };

    window.addEventListener('mousemove', mouseMoveHandler);

    return () => window.removeEventListener('mousemove', mouseMoveHandler);
  }, []);

  return canvasRef;
};

export { WithMouseFollowOrb, useMouseFollowOrb };
