import React from 'react';
import { Link } from 'react-router-dom';
import ButtonLine from 'shared/components/ButtonLine';
import blueLogo from 'assets/ACE-logo-blue.svg';

const RightLayout = ({
  children,
  question = "Don't have an account yet?",
  buttonText = 'Sign Up',
  footerForwardUrl = '/product/register',
}) => {
  return (
    <div className='product-login-block d-flex flex-column justify-content-between align-items-center col-7'>
      <div className='text-center'>
        <img src={blueLogo} alt='logo' height='50' />
      </div>
      {children}
      <div className='not-have-account-block'>
        <div className='question-row'>
          <hr className='line' />
          <span className='account-question'>{question}</span>
          <hr className='line' />
        </div>
        <ButtonLine className='sign-up-link'>
          <Link className='sign-up-forward' to={footerForwardUrl}>
            {buttonText}
          </Link>
        </ButtonLine>
      </div>
    </div>
  );
};

export default RightLayout;
