import React from 'react';
import AuthorsDropdown from './AuthorsDropdown';
import BackButton from 'shared/BackButton';
import CourseHeaderRightNav from './CourseHeaderRightNav';
import { hasAccess } from 'utils/permissionHelper';

const HeaderSection = ({ course, history }) => {
  return (
    <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between'>
      <div className='d-flex align-items-center'>
        <BackButton history={history} route={hasAccess('vip_user') ? '/courses' : '/allcourses'} />
        <div className='course-name pr-3 text-two-line'>{course.name}</div>
        <div className='align-items-center course-authors d-flex pl-3'>
          <AuthorsDropdown data={course.authors} />
        </div>
      </div>
      <CourseHeaderRightNav course={course} />
    </div>
  );
};
export default HeaderSection;
