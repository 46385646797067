/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import logo from 'assets/white-logo.svg';
// import accessImg from 'assets/events/accesslogo.svg';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowDown } from 'shared/components/Icons';
import { logout } from 'app/Auth/actions';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { hasAccess } from 'utils/permissionHelper';

const EventHeader = ({ eventId, name }) => {
  const dispatch = useDispatch();
  // const account = useSelector(state => state.account);
  // const [dropMenu, setDropMenu] = useState();
  const [accountMenu, setAccountMenu] = useState();
  const isAuthUser = useSelector(state => state.isAuthenticated);
  const isVisitorUser = hasAccess('visitor_user');
  const isAuth = isAuthUser && !isVisitorUser;
  // const dropMenuEl = useRef();
  const accountMenuEl = useRef();

  const homeUrl = `/events/${name}`;

  const logoutProfile = e => {
    e.preventDefault();
    dispatch(logout());
  };

  // const onClickAccessVip = () => {
  //   const isAlreadyVIP = account.userType !== 6;
  //   let win;
  //   if (isAlreadyVIP) {
  //     win = window.open('/allcourses', '_blank');
  //   } else {
  //     win = window.open('/register', '_blank');
  //   }
  //   win.focus();
  // };

  // useOutsideClick(dropMenuEl, () => setDropMenu(false));
  useOutsideClick(accountMenuEl, () => setAccountMenu(false));

  return (
    <div className='event-header d-flex flex-column justify-content-between'>
      {/* <div className='container'> */}
      {/* <img src={logo} height='30' alt='logo' /> */}
      {/* {!!isAuth && (
          <button className='btn p-0 position-absolute mt--6 ml-2' onClick={onClickAccessVip}>
            <img src={accessImg} height='60' alt='logo' />
          </button>
        )} */}
      {/* </div> */}
      <div className='event-nav'>
        <div className='container d-flex justify-content-between'>
          <img src={logo} height='30' alt='logo' />
          <ul className='m-0 p-0 d-flex justify-content-end align-items-end'>
            <li>
              <NavLink activeClassName='active' to={homeUrl}>
                Home
              </NavLink>
            </li>
            {!!isAuth && (
              <li>
                <NavLink to={`/events/${name}/dashboard/allcourses`}>Available Courses</NavLink>
              </li>
            )}
            {/* <li>
              <div
                className={`drop-link ${dropMenu ? 'open' : ''}`}
                onClick={e => setDropMenu(!dropMenu)}
                ref={dropMenuEl}
              >
                Additions <IconArrowDown className='ml-2' color='#fff' />
                {dropMenu && (
                  <ul className='drop-link-menu'>
                    <li>
                      <a href='#'>360 Tour</a>
                    </li>
                    <li>
                      <a href='#'>AR Guide</a>
                    </li>
                    <li>
                      <a href='#'>AR Equipment</a>
                    </li>
                    <li>
                      <a href='#'>Online Classroom</a>
                    </li>
                  </ul>
                )}
              </div>
            </li> */}
            {!isAuth && (
              <li>
                <NavLink activeClassName='active' to={`/events/${name}/register`}>
                  Register
                </NavLink>
              </li>
            )}
            {!isAuth && (
              <li>
                <NavLink activeClassName='active' to={`/events/${name}/login`}>
                  Login
                </NavLink>
              </li>
            )}
            {!!isAuth && (
              <li>
                <div
                  className={`drop-link ${accountMenu ? 'open' : ''}`}
                  onClick={e => setAccountMenu(!accountMenu)}
                  ref={accountMenuEl}
                >
                  Account <IconArrowDown className='ml-2' color='#fff' />
                  {accountMenu && (
                    <ul className='drop-link-menu'>
                      <li>
                        <NavLink
                          activeClassName='active'
                          className='px-3'
                          to={`/events/${eventId}/dashboard/profile`}
                        >
                          Profile
                        </NavLink>
                      </li>
                      <li>
                        <a href='#' className='px-3' onClick={logoutProfile}>
                          Log out
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventHeader;
