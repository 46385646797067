import React from 'react';
import SpecialistCourses from './SpecialistCourses';

const RequiredCoursesSection = props => {
  const { product, user } = props;
  const userHasEntity = user?.entities?.length;

  return <SpecialistCourses userHasEntity={userHasEntity} productId={product.id} {...product} />;
};

export default RequiredCoursesSection;
