export const navigation = [
  {
    name: 'Faculty',
    path: 'authors',
  },
  {
    name: 'About',
    path: 'about',
    permission: 'vip_user',
  },
  {
    name: 'Support',
    permission: 'vip_user',
    menu: [
      {
        name: 'FAQ',
        path: 'faq',
      },
      {
        name: 'Contact',
        path: 'contacts',
      },
      {
        name: 'All Requests',
        path: 'messages/list',
        hideIfPermission: ['visitor_user'],
      },
      {
        name: 'Privacy Policy',
        link: 'https://www.hologic.com/privacy-policy',
      },
      {
        name: 'System Reqs.',
        path: 'requirements',
      },
    ],
  },
];
