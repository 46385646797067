/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import Button from 'shared/components/Button';

import PresentationImage1 from '../mockImages/presentation1.png';
import PresentationImage2 from '../mockImages/presentation2.png';
import { IconPlayMarketing, MarketingCirclesIcon } from 'shared/components/Icons';
import { goWithExternalLink } from 'utils/linkUtils';

const mockPresentations = [
  {
    title: 'About Hologic',
    info:
      'The Science of Sure reflects who we are as a leading innovator in women’s health, helping healthcare professionals around the world.',
    image: PresentationImage1,
    type: 'video',
    videoUrl: 'https://content.nodemo.site/content/purpose_passion_promise.mp4',
  },
  {
    title: 'Our Innovations',
    info:
      'Our innovations are designed to achieve exceptional clinical results, making it possible to detect, diagnose and treat illnesses and other health conditions.',
    image: PresentationImage2,
    type: 'image',
  },
];

export const MarketingPresentationSection = ({ data }) => {
  const [isPresentationVideoPlaying, setIsPresentationVideoPlaying] = useState(false);
  const presentations = data || mockPresentations;

  const handlePlayPresentationVideo = () => {
    setIsPresentationVideoPlaying(true);
  };

  return (
    <div className='presentation-container'>
      {presentations.map((presentation, idx) => {
        return (
          <div key={idx} className={`presentation-item ${idx % 2 && 'row-reverse'}`}>
            <div className={`description-sec ${idx % 2 ? 'icon-bottom-right' : 'icon-top-left'}`}>
              <MarketingCirclesIcon
                className={`${idx % 2 ? 'icon-bottom-right' : 'icon-top-left'}`}
              />
              <div className='inner-block'>
                <h4 className='title'>{presentation.title}</h4>
                <p className='desc'>{presentation.info}</p>
                <Button
                  onClick={e => goWithExternalLink('https://hologic.com/about', e)}
                  className='learn-more-btn'
                >
                  Learn about Hologic
                </Button>
              </div>
            </div>
            <div className='image-sec'>
              {presentation.type === 'image' && <img alt='description' src={presentation.image} />}
              {presentation.type === 'video' && !isPresentationVideoPlaying && (
                <>
                  <img alt={presentation.title} src={presentation.image} className='thumbnail' />
                  <div onClick={handlePlayPresentationVideo} className='play-icon'>
                    <IconPlayMarketing className={'play-svg'} />
                  </div>
                </>
              )}
              {presentation.type === 'video' && !!isPresentationVideoPlaying && (
                <video width='100%' controls autoPlay>
                  <source src={presentation.videoUrl} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
