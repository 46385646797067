/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import { IconAbout } from 'shared/components/Icons';
import { confirmAlert } from 'react-confirm-alert';
import CopyRightInfo from 'shared/components/CopyRightInfo';
import PublicWrapper from 'shared/components/layouts/PublicWrapper';
import { useSelector } from 'react-redux';
import ResponsiveImage from 'shared/ResponsiveImage';

const specialities = [
  {
    id: 1,
    name: 'GYN SURGICAL',
    desc:
      'Minimally invasive GYN treatment options for women who suffer from abnormal uterine bleeding.',
    img: 'https://hologiced.com/wp-content/uploads/2018/06/uterus-1-1.png',
    link: 'https://hologiced.com/gyn-surgical/',
  },
  {
    id: 2,
    name: 'DIAGNOSTIC',
    desc:
      'Innovative technology for cervical cancer screening, testing for STIs and vulvovaginal infections, and preterm labor assessment.',
    img: 'https://hologiced.com/wp-content/uploads/2018/06/diagnostic-icon.svg',
    link: 'https://hologiced.com/diagnostic/',
  },
  {
    id: 3,
    name: 'BREAST',
    desc:
      'Life-saving solutions accelerated by cutting edge science for early detection, more certain diagnosis and accurate, compassionate treatment',
    img: 'https://hologiced.com/wp-content/uploads/2018/06/breast-icon.svg',
    link: 'https://hologiced.com/breast-health/',
  },
  {
    id: 4,
    name: 'SKELETAL',
    desc:
      'Clinical images that enable doctors to make more informed diagnoses and treatment decisions in time to have a positive impact on patient’s health.',
    img: 'https://hologiced.com/wp-content/uploads/2018/06/skeletal-icon.svg',
    link: 'https://hologiced.com/skeletal-health/',
  },
];

const About = () => {
  const isAuth = useSelector(state => state.isAuthenticated);

  const goWithLink = (url, e) => {
    e.preventDefault();
    if (!url) return;
    confirmAlert({
      message:
        'A new browser tab will now be opened. You can close it at any time and come back to this tab to continue.',
      buttons: [
        {
          label: 'Continue',
          onClick: () => window.open(url, '_blank'),
        },
        {
          label: 'Close',
        },
      ],
    });
  };

  const Wrapper = isAuth ? React.Fragment : PublicWrapper;

  return (
    <Wrapper>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconAbout color='#131f6b' />
          </span>
          About
        </div>
      </div>
      <div className='p-3'>
        <div className='about-content mt-4 text-center px-5'>
          <p className='w-75 m-auto pb-4'>
            The mission of Hologic Medical Education is to improve patient care through excellence
            in education, communication of clinical and scientific evidence, and partnerships with
            the healthcare community.
          </p>
        </div>
        <p className='spec-title mt-5 text-center'>
          AREAS OF <strong>SPECIALTY</strong>
        </p>
        <div className='d-flex flex-wrap justify-content-center mt-5 specialities'>
          {specialities.map(item => (
            <div className='speciality-item mb-4' key={item.id}>
              <div
                className='img-box pointer'
                onClick={goWithLink.bind(null, item.link)}
                role='button'
                tabIndex='-1'
              >
                <ResponsiveImage imgUrl={item.img} alt='img' />
              </div>
              <h5
                className='pointer'
                onClick={goWithLink.bind(null, item.link)}
                role='button'
                tabIndex='-1'
              >
                {item.name}
              </h5>
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
      <CopyRightInfo />
    </Wrapper>
  );
};

export default About;
