import React from 'react';
import { Link } from 'react-router-dom';
import { IconArrowRight } from 'shared/components/Icons';
import { getEventUrl, getFeedbackUrl } from 'utils/appHelpers';
import { hasAccess } from 'utils/permissionHelper';
import { LESSON_STATUSES, LESSON_TYPES } from 'configs/constants';

const CourseHeaderRightNav = ({ course }) => {
  const isEventUser = hasAccess('event_user');
  const isKolUser = hasAccess('kol_user');
  const path = isEventUser ? getEventUrl() : '';

  const quizLesson = course?.lessons?.find(lesson => lesson.type === LESSON_TYPES.quiz);

  const isQuizOpenToAccess =
    !!course.isQuizAvailable &&
    (quizLesson?.status === LESSON_STATUSES.completed ||
      quizLesson?.status === LESSON_STATUSES.inProgress);

  const quizLessonId = quizLesson?.id;

  return (
    <div className={`course-links d-flex align-items-center ${isKolUser ? 'has-m' : ''}`}>
      {!isEventUser && (
        <Link
          to={`${path}/courses/progress/${course.id}`}
          className='d-flex align-items-center mr-2 link light'
        >
          Progress <IconArrowRight className='ml-1' />
        </Link>
      )}
      {!!course.isQuizAvailable && (
        <Link
          to={{
            pathname: quizLessonId ? `${path}/courses/${course.id}/quiz/${quizLessonId}` : '#',
            state: { quizAccessBlocked: !isQuizOpenToAccess },
          }}
          className={'d-flex align-items-center mr-2 link light'}
        >
          Quiz <IconArrowRight className='ml-1' />
        </Link>
      )}
      {getFeedbackUrl(course) && (
        <Link to={getFeedbackUrl(course)} className='d-flex align-items-center mr-2 link light'>
          Evaluation <IconArrowRight className='ml-1' />
        </Link>
      )}
      {!isEventUser && (
        <Link
          to={`${path}/courses/certificate/${course.id}`}
          className='d-flex align-items-center mr-2 link light'
        >
          Certificate <IconArrowRight className='ml-1' />
        </Link>
      )}
    </div>
  );
};
export default CourseHeaderRightNav;
