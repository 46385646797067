import React, { useState } from 'react';
import ReactModal from 'react-modal';
import ButtonLine from 'shared/components/ButtonLine';
import LicenseManageModal from './LicenseManageModal';

const LicensesBlock = ({ data, getLicenses }) => {
  const [open, toggleModal] = useState(false);
  return (
    <div className='blue-box px-1 mb-3 d-flex flex-column justify-content-between'>
      {data && data.length ? (
        <>
          <div className='d-flex'>
            <div className='col-4'>
              <p>Registration Codes:</p>
            </div>
            <div className='col-4 p-0'>
              <p>Course:</p>
            </div>
            <div className='col-4'>
              <p>Source:</p>
            </div>
          </div>
          {data.map(item => {
            return (
              <React.Fragment key={item.id}>
                <div className='d-flex mb-2'>
                  <div className='col-4'>
                    <span className='info d-block'>{item.licenseKey}</span>
                  </div>
                  <div className='col-4 p-0'>
                    <span className='info d-block'>
                      {item.subscription && item.subscription.title}
                    </span>
                  </div>
                  <div className='col-4'>{item?.entity ? 'Entity' : 'User'}</div>
                </div>
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <div className='col-12 info d-block'>No Registration Codes Available</div>
      )}
      <div className='d-flex justify-content-end'>
        <ButtonLine onClick={() => toggleModal(true)} className='btn-line-blue py-0'>
          Manage Registration Codes
        </ButtonLine>
      </div>
      <ReactModal isOpen={open} className='custom-modal'>
        <LicenseManageModal
          data={data}
          onRefreshData={getLicenses}
          onClose={() => toggleModal(false)}
        />
      </ReactModal>
    </div>
  );
};

export default LicensesBlock;
