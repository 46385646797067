/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import LandingFooter from 'shared/components/LandingFooter';
import { goWithExternalLink } from 'utils/linkUtils';

const firstInnerBlockItems = [
  { text: 'GYN Surgical', link: 'https://hologiced.com/gyn-surgical/' },
  { text: 'Diagnostic', link: 'https://hologiced.com/diagnostic/' },
  { text: 'Breast Health', link: 'https://hologiced.com/breast-health/' },
  { text: 'Skeletal Health', link: 'https://hologiced.com/skeletal-health/' },
];
const secondInnerBlockItem = [
  { text: 'Hologic Corporate', link: 'https://www.hologic.com/' },
  { text: 'HOLOGIC GRANT PROGRAM', link: 'https://hologiced.com/hologic-grant-program/' },
  { text: 'Terms', link: 'https://hologiced.com/terms/' },
  { text: 'Privacy', link: 'https://hologiced.com/privacy/' },
  { text: 'CALENDAR OF EVENTS', link: 'https://hologiced.com/events' },
];

export const MarketingFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='marketing-footer' id='marketingFooter'>
      <LandingFooter />
      <div className='main-footer'>
        <div className='inner-sec'>
          <div className='inner-block'>
            {firstInnerBlockItems.map((item, idx) => (
              <p
                onClick={e => goWithExternalLink(item.link, e)}
                key={idx}
                className='blue-txt mb-0'
              >
                {item.text}
              </p>
            ))}
          </div>
          <div className='inner-block'>
            {secondInnerBlockItem.map((item, idx) => (
              <p
                onClick={e => goWithExternalLink(item.link, e)}
                key={idx}
                className='gray-txt text-uppercase mb-0'
              >
                {item.text}
              </p>
            ))}
          </div>
          <p className='copyright-text mb-0'>
            © Copyright {currentYear} Hologic Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
