import React from 'react';
import BackButton from 'shared/BackButton';

const CertificateHeader = ({ history }) => {
  return (
    <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
      <div className='d-flex'>
        <BackButton history={history} />
        <span className='ml-1'>Certificates</span>
      </div>
    </div>
  );
};

export default CertificateHeader;
