/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Slider from 'react-slick';
import { IconArrowRight, IconCoursesNew } from 'shared/components/Icons';
import { findCourseFirstLesson } from 'utils/appHelpers';
import { DEFAULT_IMG_URLS } from 'configs/constants';
import { ResponsiveBackgroundImage } from 'shared/components/ResponsiveBackgroundImage';

const DashBoxCourses = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const courses = useSelector(item => item.courses);
  const history = useHistory();

  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    className: 'home-courses-slide',
    autoplay: true,
    beforeChange: next => setActiveSlide(next),
  };

  const courseClickHandle = course => {
    findCourseFirstLesson(course, history);
  };

  const item = courses && courses?.[activeSlide];

  return (
    <div className='courses-block'>
      {courses && (
        <Slider {...sliderSettings}>
          {courses.map(item => {
            return (
              <div key={item?.id}>
                <ResponsiveBackgroundImage
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(19, 31, 107, 0.2), rgba(19, 31, 107, 0.2))`,
                  }}
                  imgUrl={item?.image || DEFAULT_IMG_URLS.course}
                  className='overlay-block'
                />
              </div>
            );
          })}
        </Slider>
      )}
      <div
        className='overlay-block is-second d-flex flex-column justify-content-between'
        onClick={courseClickHandle.bind(null, item)}
      >
        <div className='title-anim text-right'>
          <span>Available Courses</span>
          <IconCoursesNew color='#fff' />
        </div>
        <div>
          <p className='text-two-line'>{item?.name}</p>
          <a
            href='/'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              history.push('/allcourses');
            }}
          >
            Show More
            <IconArrowRight color='#fff' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DashBoxCourses;
