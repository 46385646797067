import React, { useState } from 'react';
import Modal from 'react-modal';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import {
  ZoomAndMagnificationTools,
  OtherTools,
  Navigation,
  HangingProtocols,
  CaseAccessment,
  // FAQ,
  Print,
} from './components';

const modalStyles = {
  overlay: {
    // backgroundColor: '#000000',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const tabs = [
  {
    label: 'Zoom and Magnification Tools',
    component: <ZoomAndMagnificationTools />,
  },
  {
    label: 'Other Tools',
    component: <OtherTools />,
  },
  {
    label: 'Navigation',
    component: <Navigation />,
  },
  {
    label: 'Hanging Protocols',
    component: <HangingProtocols />,
  },
  {
    label: 'Case Accessment',
    component: <CaseAccessment />,
  },
  // {
  //   label: 'FAQ',
  //   component: <FAQ />,
  // },
  {
    label: 'Print',
    component: <Print />,
  },
];

const HelpModal = ({ onModalClose }) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (e, index) => {
    setTab(index);
  };

  return (
    <Modal
      style={modalStyles}
      className='modal-default viewer-modal flex-column d-flex'
      isOpen={true}
      onRequestClose={onModalClose}
      contentLabel='Connection and device test'
    >
      <button className='button-times dark' onClick={onModalClose} />
      <h4>Radiologist Training - Online Help</h4>
      <AppBar position='static'>
        <Tabs value={tab} onChange={handleTabChange} aria-label='simple tabs example'>
          {tabs.map(({ label, component }, index) => (
            <Tab key={index} label={label} style={{ minWidth: 0 }} />
          ))}
        </Tabs>
      </AppBar>
      <div className='py-3 flex-1 overflow-auto'>{tabs[tab].component}</div>
    </Modal>
  );
};

export default HelpModal;
