import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const WithParamsRedirect = ({ from, to }) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const matchPattern = (pattern, pathname) => {
      const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '([^\\s/]+)')}$`);
      return regex.test(pathname);
    };

    if (matchPattern(from, location.pathname)) {
      const newPath = Object.keys(params).reduce((path, param) => {
        return path.replace(`:${param}`, params[param]);
      }, to);

      const pathToRedirect = `${newPath}${location.search}`.replace(/\/\/+/g, '/');
      history.replace(pathToRedirect);
    }
  }, [from, to, params, location, history]);

  return null;
};

export default WithParamsRedirect;
