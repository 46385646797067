import React from 'react';

const FeedText = ({ data, onChange, answers, isDisabled, isAnswered }) => {
  const item = data.feedbackItems && data.feedbackItems[0];

  const handleChange = e => {
    onChange({ id: data.id, value: e.target.value });
  };

  return (
    <li className='d-flex'>
      <div className='col-6 pl-0 pt-1'>
        <label className='m-0'>{data.question}</label>
      </div>
      {!isAnswered ? (
        <textarea
          name='comment'
          placeholder={item.label}
          onChange={handleChange}
          value={answers[data.id]}
          disabled={isDisabled}
        />
      ) : (
        answers[data.id]
      )}
    </li>
  );
};

export default FeedText;
