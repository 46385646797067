import { removeFromStore } from './storeHelpers';

// storing the token
export const saveLocalToken = token => {
  localStorage.setItem('auth', token);
};

export const getLocalToken = () => {
  return localStorage.getItem('auth');
};

export const removeLocalToken = () => {
  removeFromStore('auth');
  removeFromStore('connectionAndDeviceTested');
  removeFromStore('redirectUrl');
};
