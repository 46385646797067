/* eslint-disable no-useless-escape */
/* eslint-disable consistent-return */
import React, { useEffect, useState, useRef } from 'react';
import { IMAGE_RESIZE_WIDTHS } from 'configs/constants';
import { HOST } from 'configs';

const ResponsiveImage = props => {
  const { imgUrl, sizes = IMAGE_RESIZE_WIDTHS.default } = props;
  const imageRef = useRef(null);
  const [src, setSrc] = useState('');
  const [isUpdatingImgSrc, setIsUpdatingImgSrc] = useState(false);

  const getClosestHigherWidth = parentWidth => {
    for (let size of sizes) {
      if (parentWidth <= size) {
        return size;
      }
    }
    // If parentWidth is greater than the largest size, return a high value to indicate the original image size
    return 1001;
  };

  const getNewImageUrl = (url, width) => {
    if (width > 1000) {
      return url;
    }

    const contentDomain = HOST.API.CONTENT;
    const baseUrlPattern = new RegExp(`^${contentDomain}/`);
    const baseUrlMatch = url.match(baseUrlPattern);

    if (!baseUrlMatch) return url;

    const baseUrl = baseUrlMatch[0];
    const pathAndFile = url.slice(baseUrl.length);
    if (!pathAndFile) return url;
    const [path, file] = pathAndFile.split(/\/(?=[^\/]*$)/); // Split into path and file
    if (!file) return url;
    const [filename, extension] = file.split('.');

    const newFilename = `${filename}@${Math.round(width)}x`;
    return `${baseUrl}${path}/${newFilename}.${extension}`;
  };

  const updateImageSrc = () => {
    setIsUpdatingImgSrc(true);
    if (imageRef.current && imageRef.current.parentElement && imgUrl) {
      const parentWidth = imageRef.current.parentElement.offsetWidth;
      const closestWidth = getClosestHigherWidth(parentWidth);
      const newSrc = getNewImageUrl(imgUrl, closestWidth);

      if (newSrc !== imgUrl) {
        preloadImage(
          newSrc,
          () => {
            setSrc(newSrc);
            setIsUpdatingImgSrc(false);
          },
          () => {
            setSrc(imgUrl); // Fallback to original if the new src fails to load
            setIsUpdatingImgSrc(false);
          },
        );
      } else {
        setSrc(imgUrl);
        setIsUpdatingImgSrc(false);
      }
    }
  };

  const preloadImage = (src, onLoad, onError) => {
    const img = new Image();
    img.src = src;
    img.onload = onLoad;
    img.onerror = onError;
  };

  useEffect(() => {
    updateImageSrc();
  }, [imgUrl, sizes]);

  return (
    <>{isUpdatingImgSrc ? null : <img {...props} ref={imageRef} src={src} alt={props.alt} />}</>
  );
};

export default ResponsiveImage;
