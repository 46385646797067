import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TOKEN_RESPONSE_CODES } from './configs';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';

import InfoStep from './components/InfoStep';
import FormStep from './components/FormStep';
import InformativeMessageComponent from 'app/Auth/components/InformativeMessage';

import { IconLeft } from 'shared/components/Icons';
import blueLogo from 'assets/ACE-logo-blue.svg';
import AuthPage from 'app/Auth/components/AuthPage';

const Register = ({ history, match }) => {
  const isAuth = useSelector(state => state.isAuthenticated);
  const account = useSelector(state => state.account);
  const [error, setError] = useState();
  const [fetch, setFetch] = useState(false);
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({});
  const [form, setForm] = useState({});
  const [isTokenIncorrect, setIsTokenIncorrect] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false);
  const regCode = match.params.code;
  const isEventUser = isAuth && account && account.userType === 6;

  const incorrectInfoSectionDetails = {
    title: 'Incorrect Link Token',
    subtitles: [
      `The link you are trying to access is incorrect.`,
      `Please check your email and copy the correct full code.`,
    ],
    actionText: 'Go to Sign in',
    actionHandler: () => history.push('/login'),
  };

  const expiredInfoSectionDetails = {
    title: 'Your Link is Expired',
    subtitles: [`Please contact with administrator to get new link.`],
    actionText: 'Contact with admin',
    actionHandler: () => {},
  };

  const registeredInfoSectionDetails = {
    title: 'You are already registered',
    subtitles: ['Please click to sign in and enter your email and password.'],
    actionText: 'Sign in',
    actionHandler: () => history.push('/login'),
  };

  const handleFormSubmit = async (data, form) => {
    setFetch(true);
    try {
      setForm({ ...form, email: '', password: '', rpassword: '' });
      await Api.post('/auth/register', data);
      history.push({
        pathname: `/email-verification/${regCode || ''}`,
        state: { email: form.email },
      });
    } catch (err) {
      setError(getError(err));
    } finally {
      setFetch(false);
    }
  };

  const handleStepChange = bool => {
    if (bool) {
      setStep(step + 1);
      return;
    }
    step === 1 ? history.goBack() : setStep(step - 1);
  };

  const getUserdata = async code => {
    try {
      setFetch(true);
      const { data } = await Api.get(`/auth/getinviteuser/${code}`);
      if (data.data.pendingConfirmation) {
        history.push({ pathname: `/email-verification/${regCode}` });
      }
      setUser({
        fname: data.data.invitation.firstName,
        lname: data.data.invitation.lastName,
        email: data.data.invitation.email,
        degree: data.data.invitation.degree,
      });
      if (data.data.pendingConfirmation === false && data.data.pendingRegistration === false) {
        setIsAlreadyRegistered(true);
      }
    } catch (err) {
      if (err.response.data.result === TOKEN_RESPONSE_CODES.invalidInvitationToken) {
        setIsTokenIncorrect(true);
      } else if (err.response.data.result === TOKEN_RESPONSE_CODES.expiredInvitationToken) {
        setIsTokenExpired(true);
      }
    }
    setFetch(false);
  };

  const setEventUserData = () => {
    setUser({
      fname: account.firstName,
      lname: account.lastName,
      email: account.email,
      institution: account.learnerProfile.institution,
    });
  };

  useEffect(() => {
    if (regCode) getUserdata(regCode);
    if (isEventUser) setEventUserData();
    //eslint-disable-next-line
  }, [regCode]);

  return (
    <AuthPage>
      {isAlreadyRegistered && <InformativeMessageComponent {...registeredInfoSectionDetails} />}
      {isTokenIncorrect && <InformativeMessageComponent {...incorrectInfoSectionDetails} />}
      {isTokenExpired && <InformativeMessageComponent {...expiredInfoSectionDetails} />}
      {!isTokenIncorrect && !isTokenExpired && !isAlreadyRegistered && (
        <div
          className={`align-items-center auth-block overflow-auto large d-flex py-5 position-relative ${
            step === 3 ? 'justify-content-center is-success' : ''
          }`}
        >
          {step < 3 && (
            <div className='position-absolute l-0'>
              <button className='btn btn-line d-flex' onClick={handleStepChange.bind(null, false)}>
                <IconLeft className='ml-2' />
                <span>Back</span>
              </button>
            </div>
          )}
          <div className='text-center logo-block'>
            <Link to='/login'>
              <img src={blueLogo} alt='logo' height='75' className='opc-3' />
            </Link>
          </div>
          {step === 1 && <InfoStep onNextClick={handleStepChange.bind(null, true)} />}
          {step === 2 && (
            <FormStep
              formSubmit={handleFormSubmit}
              user={user}
              error={error}
              fetch={fetch}
              regCode={regCode}
              lastForm={form}
              isEventUser={isEventUser}
            />
          )}
        </div>
      )}
    </AuthPage>
  );
};

export default Register;
