import React from 'react';

const FeedText = ({ index, data, onChange, answers, isDisabled, isAnswered }) => {
  const item = data?.labels?.[0] || data?.feedbackItems?.[0]?.label;

  const handleChange = e => {
    onChange({ id: index, value: e.target.value, feedbackItemId: data?.id });
  };

  return (
    <li className='d-flex'>
      <div className='col-6 pl-0 pt-1'>
        <label className='m-0'>{data.question}</label>
      </div>
      {!isAnswered ? (
        <textarea
          name='comment'
          placeholder={item}
          onChange={handleChange}
          value={answers[index]}
          disabled={isDisabled}
        />
      ) : (
        answers[index] || answers[(data?.id)]
      )}
    </li>
  );
};

export default FeedText;
