import React from 'react';

const TagsViewBlock = ({ className, tags, title = 'Tags' }) => {
  if (!tags || !tags.length) return null;
  return (
    <div className={`tags-view-block ${className || ''}`}>
      <h1>{title}</h1>
      <div className='d-flex flex-wrap tag-wrap'>
        {tags &&
          tags.map((item, i) => {
            const tag = item.tag || item;
            return (
              <div className='tag-item' key={i}>
                {tag.label || tag.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TagsViewBlock;
