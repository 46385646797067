import React from 'react';
import { viewerDialogJson } from './../config/consts';
const { caseAccessment } = viewerDialogJson;

const CaseAccessment = () => {
  return (
    <ul>
      {caseAccessment.map((item, index) => (
        <li className='pb-3' key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default CaseAccessment;
