import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IconRight, IconKey } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { getError, courseSubsStatuses } from 'utils/appHelpers';
import { formatSubscription } from 'utils/formatHelpers';
import SubscriptionModal from 'shared/components/SubscriptionModal';
import Loading from 'shared/components/Loading';
import ProgressBar from 'shared/components/ProgressBar';
import AuthorsList from './components/AuthorsList';
import CoursesList from './components/CoursesList';
import ButtonLoading from 'shared/components/ButtonLoading';
import { getCourses } from '../Courses/actions';
import BackButton from 'shared/BackButton';
import ResponsiveImage from 'shared/ResponsiveImage';
import { SUBS_TYPES_BY_KEY_REQUIRED } from 'configs/constants';

const subsTypes = {
  1: 'Opened',
  2: 'Paid',
  3: (
    <>
      <IconKey /> Key Required
    </>
  ),
};

const SubscriptionView = ({ history, match, getCourses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [subscription, setSubscription] = useState();
  const [modalData, setModalData] = useState(null);
  const [fetch, setFetch] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await Api.get(`subscription/getsubscription/${match.params.id}`);
      setSubscription(formatSubscription(data.data));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  }, [enqueueSnackbar, match.params.id]);

  const onUnSubscribeSubscription = async () => {
    try {
      const subscriptionId = match.params.id;
      setFetch(true);
      await Api.post('/subscription/unsubscribe', { subscriptionId });
      await getCourses();
      await fetchData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (!subscription) return <Loading classView='mt-5' />;

  const isPending =
    subscription.userSubs &&
    courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'pending');
  const isDeclined =
    !subscription.userSubs ||
    (subscription.userSubs &&
      courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'declined'));
  const isAccepted =
    subscription.userSubs &&
    courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'active');

  return (
    <>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <BackButton history={history} />
          </span>
          {subscription.name}
        </div>
      </div>
      <div className='d-flex p-3 row'>
        <div className='col-8'>
          <p className='text-desc'>{subscription.info}</p>
          <p className='text-secondary'>Faculty</p>
          {subscription.authors && subscription.authors.length > 0 && (
            <AuthorsList data={subscription.authors} history={history} />
          )}
          <p className='text-secondary'>Courses</p>
          {subscription.courses && subscription.courses.length > 0 && (
            <CoursesList data={subscription.courses} />
          )}
        </div>
        <div className='col-4'>
          <div className='plan-info-box'>
            <ResponsiveImage imgUrl={subscription.image} alt='subscription' />
            <p className='mt-3 mb-2'>
              <b>{subscription.name}</b>
            </p>
            <div className='subs-type mb-3'>
              {SUBS_TYPES_BY_KEY_REQUIRED[subscription.keyRequired] || '-'}
            </div>
            <div className='d-flex justify-content-between'>
              <span>Duration</span>
              <span>{subscription.time} hours</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Parts</span>
              <span>{subscription.courses ? subscription.courses.length : '0'} parts</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Validity</span>
              <span>{subscription.expirable ? `${subscription.days} days` : 'No Expiration'}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Faculty</span>
              <span>{subscription.authors.length} Faculty</span>
            </div>
            {isDeclined && (
              <div className='d-flex justify-content-center'>
                <button
                  className='btn d-flex align-items-center subscribe-btn mt-3'
                  onClick={() => setModalData(subscription)}
                >
                  Subscribe now <IconRight color='#131f6b' className='ml-1' />
                </button>
              </div>
            )}
            {isAccepted && (
              <>
                <div className='d-flex flex-column pt-3'>
                  <div className='d-flex justify-content-between pb-2'>
                    <span className='mr-4'>
                      <b className='mr-2'>Start On:</b>
                      {subscription.userSubs.startDate.substr(0, 10)}
                    </span>
                    {!!subscription.expirable && (
                      <span>
                        <b className='mr-2'>End On:</b>
                        {subscription.userSubs.endDate.substr(0, 10)}
                      </span>
                    )}
                  </div>

                  <div className='d-flex align-items-center justify-content-between'>
                    <span className='mr-5 text-nowrap'>
                      Progress {subscription.userSubs.progress || 0}%
                    </span>
                    <ProgressBar progress={subscription.userSubs.progress || 0} />
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <button
                    className='btn mt-4 text-danger'
                    onClick={onUnSubscribeSubscription}
                    disabled={fetch || subscription.isSystem}
                  >
                    {fetch ? <ButtonLoading /> : 'Unsubscribe'}
                  </button>
                </div>
              </>
            )}
            {isPending && <div className='text-center text-secondary small mt-4'>Request Sent</div>}
          </div>
        </div>
      </div>
      {modalData && (
        <SubscriptionModal
          data={modalData}
          onModalClose={() => setModalData(null)}
          onSuccess={fetchData}
        />
      )}
    </>
  );
};

export default connect(
  null,
  { getCourses },
)(SubscriptionView);
