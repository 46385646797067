import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { setDiagnosticData } from '../../actions';
import ViewAlert from './ViewAlert';

const settings = {
  optimal: { width: 1024, height: 768 },
  minimal: { width: 1024, height: 600 },
};

const ResolutionTest = () => {
  const dispatch = useDispatch();
  const { innerWidth, innerHeight, screen } = window;
  const { width, height } = screen;
  const result = {
    screen: `${width}x${height}`,
    window: `${innerWidth}x${innerHeight}`,
    success: innerWidth >= settings.optimal.width && innerHeight >= settings.optimal.height,
  };

  dispatch(setDiagnosticData({ resolution: result }));

  return (
    <>
      <div className='box'>
        <p className='m-0'>
          <span className='weight-600 mr-2'>Screen resolution:</span>
          <span className='weight-100'>({`${width}x${height}`})</span>
        </p>
        <p className='m-0'>
          <span className='weight-600 mr-2'>Window resolution:</span>
          <span className='weight-100'>({`${innerWidth}x${innerHeight}`})</span>
        </p>
      </div>
      <ViewAlert
        data={{ innerWidth, innerHeight }}
        optimalSettings={settings.optimal}
        minimalSettings={settings.minimal}
      />
    </>
  );
};

export default memo(ResolutionTest);
