/* eslint-disable no-console */
import React, { useRef, useEffect, useState } from 'react';
import * as cornerstone from 'cornerstone-core';
import { HOST } from 'configs';

const original = `${
  HOST.API.CONTENT
}/dicom/2018_CHDI2D_107_BTOJPEGLossless_MG_L_MLO_TomoHD_24X29_20180331_/img_1_lut.jpg`;

const TestViewerWL = () => {
  const [imageUrl, setImageUrl] = useState(
    `${
      HOST.API.CONTENT
    }/dicom/2018_CHDI2D_107_BTOJPEGLossless_MG_L_MLO_TomoHD_24X29_20180331_/img_1.jpg`,
  );
  const [voi, setVoi] = useState({
    windowCenter: 0,
    windowWidth: 0,
  });
  const el1 = useRef(null);
  const el2 = useRef(null);

  const onImageRendered = () => {
    try {
      const viewport = cornerstone.getViewport(el1.current);
      setVoi(viewport.voi);
    } catch (err) {
      console.log(err);
    }
  };

  const onWindowResize = () => {
    cornerstone.resize(el1.current);
  };

  const loadImage = async (element, url) => {
    const image = await cornerstone.loadAndCacheImage(url || imageUrl);
    const viewportOptions = { pixelReplication: false, image };
    await cornerstone.displayImage(element, image, viewportOptions);
  };

  const initElement = async element => {
    await cornerstone.enable(element);
    await cornerstone.resize(element);
    element.addEventListener('cornerstoneimagerendered', onImageRendered);
    window.addEventListener('resize', onWindowResize);
  };

  const initData = async (element, url) => {
    await loadImage(element, url);
    await cornerstone.fitToWindow(element);
    await cornerstone.resize(element);
  };

  const unmountElement = async () => {
    // element.current.removeEventListener('cornerstoneimagerendered', onImageRendered);
    // window.removeEventListener('resize', onWindowResize);
    // cornerstone.disable(el1.current);
  };

  const onImageChange = e => {
    e.preventDefault();
    initData(el1.current);
  };

  const onChangeVoi = ({ target }) => {
    const body = { ...voi, [target.name]: target.value };
    setVoi(body);
    const viewport = cornerstone.getViewport(el1.current);
    viewport.voi = body;
    cornerstone.setViewport(el1.current, viewport);
  };

  const onChangeVoiSubmit = e => {
    e.preventDefault();
    const viewport = cornerstone.getViewport(el1.current);
    viewport.voi = voi;
    cornerstone.setViewport(el1.current, viewport);
  };

  useEffect(() => {
    initElement(el1.current);
    initElement(el2.current);
    return () => unmountElement();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    initData(el1.current);
    initData(el2.current, original);
    //eslint-disable-next-line
  }, []);

  return (
    <div className='viewer d-flex flex-column flex-fill'>
      <div className='control-area bg-light p-2 d-flex justify-content-between'>
        <form className='form-inline' onSubmit={onImageChange}>
          <div className='form-group mb-2'>
            <label className='sr-only'>Image</label>
            <input
              type='text'
              className='form-control-plaintext border small'
              onChange={({ target }) => setImageUrl(target.value)}
              value={imageUrl}
            />
          </div>
          {/* <button type='submit' className='btn btn-primary mb-2 btn-sm ml-2'>
            change image
          </button> */}
        </form>
        <form onSubmit={onChangeVoiSubmit} className='d-flex align-items-end'>
          <div className='form-row'>
            <div className='form-group col-md-6 m-0'>
              <label className='m-0 small'>Window Center</label>
              <input
                type='number'
                name='windowCenter'
                className='form-control-plaintext border small'
                onChange={onChangeVoi}
                value={voi.windowCenter}
              />
            </div>
            <div className='form-group col-md-6 m-0'>
              <label className='m-0 small'>Window Width</label>
              <input
                type='number'
                name='windowWidth'
                className='form-control-plaintext border small'
                onChange={onChangeVoi}
                value={voi.windowWidth}
              />
            </div>
          </div>
          <button type='submit' className='btn btn-primary btn-sm ml-2'>
            change level
          </button>
        </form>
      </div>
      <div className='viewer-area d-flex'>
        <div className='viewport-element p-0' ref={el1}>
          <canvas className='cornerstone-canvas' />
        </div>
        <div className='viewport-element p-0' ref={el2}>
          <canvas className='cornerstone-canvas' />
        </div>
      </div>
    </div>
  );
};

export default TestViewerWL;
