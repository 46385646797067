import React, { useState, useEffect, useCallback } from 'react';
import BackButton from 'shared/BackButton';
import { useSnackbar } from 'notistack';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { IconSearch } from 'shared/components/Icons';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

const limit = 8;

const Messages = ({ history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, setFetch] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const getMessages = useCallback(async () => {
    try {
      setFetch(true);
      const params = { page: page + 1, limit };
      const { data } = await Api.get('/common/getusercontacts', { params });
      setData(data.data.results);
      setPageCount(Math.ceil(data.data.count / limit));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      setPageCount(0);
    } finally {
      setFetch(false);
    }
  }, [enqueueSnackbar, page]);

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    getMessages();
  }, [page, getMessages]);

  return (
    <div>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <BackButton history={history} />
          <span className='ml-1'>My Requests</span>
        </div>
      </div>
      <div className='p-3'>
        <table className='table table-custom'>
          <thead>
            <tr>
              <th scope='col'>
                <div className='d-flex align-items-center'>Date</div>
              </th>
              <th scope='col'>
                <div className='d-flex align-items-center'>Ticket Number</div>
              </th>
              <th scope='col'>
                <div className='d-flex align-items-center'>Name</div>
              </th>
              <th scope='col'>
                <div className='d-flex align-items-center'>Reply-To</div>
              </th>
              <th scope='col'>
                <div className='d-flex align-items-center'>Category</div>
              </th>
              <th scope='col' className='cell-fixed__xxlg'>
                <div className='d-flex align-items-center'>Description</div>
              </th>
            </tr>
          </thead>
          <tbody className='font-size-15'>
            {data && data.length > 0 ? (
              data.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        {item.createdAt ? moment(item.createdAt).format('MM/DD/YYYY') : ''}
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>{item.ticketSeq || '-'}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>{item.name || 'Unknown'}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>{item.email || '-'}</div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>{item.category || '-'}</div>
                    </td>
                    <td className='cell-fixed__xxlg'>
                      <div className='d-flex align-items-center'>{item.text}</div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan='7'>
                  <div>
                    <IconSearch className='mr-1' /> No Results Found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {pageCount > 1 && (
          <ReactPaginate
            previousLabel={null}
            nextLabel={null}
            breakLabel={'...'}
            initialPage={0}
            forcePage={page}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={onPageChange}
            containerClassName={`pagination ${fetch ? 'd-none' : ''}`}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        )}
      </div>
    </div>
  );
};

export default Messages;
