import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { IconRight } from './Icons';

const LinkIcon = ({ children, to, className, color = '#131f6b' }) => (
  <Link to={to} className={className}>
    {children} <IconRight color={color} className='ml-1' />
  </Link>
);

export default memo(LinkIcon);
