import React, { useState } from 'react';
import { connect } from 'react-redux';
import AuthorBox from 'shared/components/AuthorBox';
import { IconAuthorsNew } from 'shared/components/Icons';

const Authors = ({ authors }) => {
  const [active, setActive] = useState();

  const onSeeMoreClick = (index, e) => {
    e.preventDefault();
    setActive(active === index ? '' : index);
  };

  return (
    <>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconAuthorsNew color='#131f6b' />
          </span>
          Faculty
        </div>
      </div>
      <div className='p-3 mt-2'>
        <div className='authors d-flex flex-wrap'>
          {authors &&
            authors.map((item, index) => (
              <AuthorBox
                bordered='true'
                className='col-3 mb-4'
                key={item.id}
                author={item}
                onSeeMoreClick={onSeeMoreClick.bind(null, index)}
                active={active === index}
              />
            ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ authors }) => ({
  authors,
});

export default connect(
  mapStateToProps,
  {},
)(Authors);
