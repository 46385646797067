import React from 'react';

const ViewResult = ({ data, stoppedEarly, maxWaitTime }) => {
  const { received, duration } = data;
  const showingDuration = stoppedEarly ? Math.ceil(maxWaitTime) : duration?.toFixed(1);
  return (
    <span>
      received <b>{received}</b> MB {duration > maxWaitTime ? '>' : 'in'} <b>{showingDuration}</b>s
    </span>
  );
};

export default ViewResult;
