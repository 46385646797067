import React from 'react';
import { IconArrowRight } from 'shared/components/Icons';

const DashBoxMarketing = ({ onOpenUrl }) => {
  return (
    <div className='d-flex flex-column h-100 justify-content-between'>
      <div className='title-anim text-right'>
        <h4 className='title'>Marketing</h4>
      </div>
      <div>
        <a href='/marketing-materials' rel='noopener noreferrer'>
          Show More
          <IconArrowRight color='#fff' />
        </a>
      </div>
    </div>
  );
};

export default DashBoxMarketing;
