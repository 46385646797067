import React from 'react';
import { viewerDialogJson } from './../config/consts';
const { faq } = viewerDialogJson;

const FAQ = () => {
  return (
    <div>
      {faq.map(({ q, a, variants }, qIndex) => (
        <div key={qIndex} className='mb-4'>
          <p className='m-0'>
            <b>Q: {q}</b>
          </p>
          <p className='m-0'>A: {a}</p>
          {variants && variants.length > 0 && (
            <ul>
              {variants.map((variant, vIndex) => (
                <li key={`${qIndex}-${vIndex}`}>{variant}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
