import React from 'react';
import moment from 'moment';
import { HOST } from 'configs';

const CopyRightInfo = () => {
  return (
    <div className='page-footer p-2 d-flex px-4 small'>
      <span>Copyright {moment().format('YYYY')} Hologic, In</span>
      <span className='ml-4'>Version: {HOST.API.VERSION}</span>
    </div>
  );
};

export default CopyRightInfo;
