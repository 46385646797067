import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { CircleMinusIcon, CirclePlusIcon } from 'shared/components/Icons';

const ExpansionPanel = withStyles({
  root: {
    maxWidth: 768,
    borderBottom: '1px solid #EAECF0',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: 0,
    borderBottom: '1px solid #EAECF0',
    marginBottom: -1,
    minHeight: 56,
    '& > p': {
      fontSize: '18px',
      fontWeight: 500,
      color: '#101828',
    },
    '&$expanded': {
      minHeight: 56,
      borderBottom: 0,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: '8px 48px 32px 0',
    '& > p': {
      fontSize: '16px',
      fontWeight: 400,
      color: '#667085',
    },
    '&$expanded': {
      borderBottom: '1px solid #EAECF0',
    },
  },
}))(MuiExpansionPanelDetails);

const AccordionItem = ({ summary, details, expanded, onChange, panelKey }) => {
  return (
    <ExpansionPanel square expanded={expanded} onChange={onChange}>
      <ExpansionPanelSummary expandIcon={expanded ? <CircleMinusIcon /> : <CirclePlusIcon />}>
        <Typography>{summary}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>{details}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AccordionItem;
