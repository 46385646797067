import { loginUser } from 'app/Auth/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLine from 'shared/components/ButtonLine';
import { IconMail, IconPassword } from 'shared/components/Icons';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import { getFromStore } from 'utils/storeHelpers';
import EventHeader from '../EventLanding/components/EventHeader';
import blueLogo from 'assets/ACE-logo-blue.svg';

const EventLogin = ({ match, history }) => {
  const hasRedirect = getFromStore('redirect');
  const { id, name } = match.params;
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [auth, setAuth] = useState({
    email: '',
    password: '',
    eventId: id,
    eventUrl: name,
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const res = await Api.post('/auth/login', auth);
      if (res.data.data.pendingConfirmation) {
        history.push({ pathname: 'email-verification', state: { email: auth.email } });
        return;
      }
      await dispatch(loginUser(res.data, history, `/events/${name}`));
      setFetch(false);
    } catch (err) {
      setError(getError(err));
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...auth };
    tempAuth[name] = value;
    setAuth(tempAuth);
  };

  return (
    <div className='event-view d-flex flex-column min-vh-100'>
      <EventHeader name={name} eventId={id} />
      <div className='event-login flex-1 d-flex align-items-start justify-content-center py-5'>
        <div className='event-login-block col-12 col-sm-8 col-lg-4 col-md-6 py-4 mt-5'>
          <div className='mt-3 mb-5 text-center'>
            <img src={blueLogo} alt='logo' height='75' />
          </div>
          <form onSubmit={handleSubmit}>
            <InputGroup
              type='text'
              name='email'
              onChange={handleChange}
              className='mb-4'
              placeholder='Email address'
              value={auth.email}
              icon={<IconMail />}
              required
            />
            <InputGroup
              hasError={error}
              type='password'
              name='password'
              onChange={handleChange}
              className='mb-4'
              placeholder='Password'
              value={auth.password}
              icon={<IconPassword />}
              required
            />
            {error && <div className='error-text'>{error}</div>}
            <div className='text-right'>
              <ButtonLine type='submit' disabled={fetch}>
                Sign In
              </ButtonLine>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EventLogin;
