import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Header from './components/Header';
import Banner from './components/Banner';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Pages from './components/Pages';
import SpecialtySection from './components/SpecialtySection';
import useQuery from 'shared/hooks/useQuery';
import Loading from 'shared/components/Loading';
import LinkTitleSection from './components/LinkTitleSection';
import ExamPreparationSection from './components/ExamPreparationSection';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import RequiredCoursesSection from './components/RequiredCourses';
import EntitySelectDialog from './components/EntitySelectDialog';
import { useDialog } from 'shared/hooks/useDialog';
import { API_RESPONSE_STATUSES } from 'configs/constants';
import SendQuestionSection from 'shared/components/SendQuestionSection';

const ProductLanding = () => {
  const [userSelectedEntity, setUserSelectedEntity] = useState(null);
  const user = useSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const { entity } = useQuery();

  const location = useLocation();

  const spec = user?.learnerProfile?.speciality;

  const history = useHistory();

  const { urlPath } = useParams();
  const [product, setProduct] = useState(null);
  const [userProductPages, setUserProductPages] = useState(null);
  const entitySelectDialog = useDialog();
  const [isLinkAccessReady, setIsLinkAccessReady] = useState(false);

  const headSectionPages =
    (userProductPages?.length &&
      userProductPages.some(page => page.id === product?.currentPage?.id)) ||
    entitySelectDialog.isOpen
      ? [...(userProductPages || [])]
      : [{ ...product?.currentPage, isNotUserPage: true }, ...(userProductPages || [])];

  const closeForwardUrl = userProductPages?.length
    ? `/product/${userProductPages[0]?.urlPath}${location.search}`
    : '/';

  const addOrUpdateParam = (key, value) => {
    if (window.history.pushState) {
      const url = new URL(window.location.href);
      url.searchParams.set(key, value);
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
  };

  const accessLinkHandler = () => {
    const isUserAssignedToLinkEntity =
      user?.entities && user.entities.some(ent => ent.name === entity);
    const isCurrentPageAssignedToUser =
      userProductPages && userProductPages.some(page => page?.id === product?.currentPage?.id);
    const isProductHasSpecialty = product?.pages?.some(
      page => page.specialty === user?.learnerProfile?.speciality,
    );
    if (!isProductHasSpecialty || !user?.entities?.length) return;
    if (entity) {
      const isUserUrlEntityMember = user.entities.some(ent => ent.name === entity);
      const isUserAssignedTroughEntity =
        userProductPages &&
        userProductPages.some(page =>
          page.entities.some(
            ent =>
              user.entities && user.entities.some(userEntity => userEntity.id === ent.entity.id),
          ),
        );
      if (isCurrentPageAssignedToUser && isUserAssignedTroughEntity) return;
      if (!isCurrentPageAssignedToUser && isUserUrlEntityMember) {
        assignProduct(entity);
      }
      if (!isUserUrlEntityMember) {
        entitySelectDialog.openDialog({
          message: `You are not a member of ${entity}. If you still want to take the course ${product?.name ||
            ''} as ${user?.learnerProfile?.speciality || ''} then select one of your Entities.`,
        });
      }
    } else {
      if (user?.entities?.length > 1) {
        if (!userSelectedEntity) {
          entitySelectDialog.openDialog();
        }
      } else {
        if (isCurrentPageAssignedToUser) {
          addOrUpdateParam('entity', user?.entities?.[0]?.name);
        }
        if (!isCurrentPageAssignedToUser) {
          entitySelectDialog.openDialog();
        }
      }
    }
  };

  const getProductData = async () => {
    try {
      const { data } = await Api.get(`/product/product-path/${urlPath}`);
      if (data?.data) {
        const currentPage = data?.data?.pages.find(page => page.specialty === spec);
        const updatedCurrPage = currentPage
          ? {
              ...currentPage,
              requiredCourses: currentPage.requiredCourses.map(({ course }) => ({
                ...course,
              })),
            }
          : { title: data?.data?.name || '' };
        setProduct({
          ...data.data,
          bannerImg: data.data?.images?.[0]?.imageUrl,
          currentPage: updatedCurrPage,
        });
      } else {
        enqueueSnackbar(getError(data), { variant: 'error' });
        history.push('/home');
      }
    } catch (err) {
      history.push('/home');
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getUserProducts = async isNeedUpdateProduct => {
    try {
      const { data } = await Api.get('/product/user-products');
      if (data?.data?.length) {
        const prodPages = [];
        data.data.map(prod =>
          prodPages.push(
            prod?.pages?.map(page => ({ ...page, urlPath: prod.urlPath, entities: prod.entities })),
          ),
        );
        const flattenPages = prodPages.flat();
        flattenPages.sort(page => (page.id === product?.currentPage?.id ? -1 : 1));
        setUserProductPages(flattenPages);
        if (product && isNeedUpdateProduct) {
          const currentPage = product?.pages?.find(page => page.specialty === spec);
          const updatedCurrPage = currentPage
            ? {
                ...currentPage,
                requiredCourses: currentPage.requiredCourses.map(({ course }) => ({
                  ...course,
                })),
              }
            : null;
          setProduct({ ...product, currentPage: updatedCurrPage });
        }
      }
      setIsLinkAccessReady(true);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const assignProduct = async ent => {
    try {
      const currEntity = ent || entity;
      const body = { productId: product?.id, entity: currEntity };
      const { data } = await Api.post('/auth/assign-product', body);
      if (data.result !== API_RESPONSE_STATUSES.ProductPageNotFound) {
        enqueueSnackbar(
          `You are successfully assigned to take the program through your ${currEntity} Entity`,
          { variant: 'success' },
        );
        await getUserProducts(true);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getProductData();
    getUserProducts();
  }, [urlPath]);

  useEffect(() => {
    if (isLinkAccessReady) {
      accessLinkHandler();
    }
  }, [user, product, entity, userProductPages, isLinkAccessReady]);

  if (!product) {
    return <Loading />;
  }

  return (
    <div className='product-page-container'>
      <EntitySelectDialog
        setUserSelectedEntity={setUserSelectedEntity}
        data={entitySelectDialog.dialogData}
        productName={product?.name || ''}
        specialty={spec || ''}
        assignProduct={assignProduct}
        onClose={() => entitySelectDialog.closeDialog()}
        isOpen={entitySelectDialog.isOpen}
      />
      <Header />
      <Pages
        hasUserPages={!!userProductPages?.length}
        closeForwardUrl={closeForwardUrl}
        currentPage={product.currentPage}
        headSectionPages={headSectionPages}
      />
      <Banner {...product} />
      <SpecialtySection {...product} />
      <RequiredCoursesSection currentPage={product.currentPage} user={user} product={product} />
      {product?.currentPage?.externalLinks?.length && (
        <LinkTitleSection externalLinks={product?.currentPage?.externalLinks} />
      )}
      <ExamPreparationSection />
      <FAQ />
      <SendQuestionSection />
      <Footer />
    </div>
  );
};

export default ProductLanding;
