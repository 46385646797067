/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React, { useRef, useEffect, useState } from 'react';
import {
  zoomImageMouseClick,
  maximazeElement,
  getFindingsCoordinates,
  createFindingEventData,
  getDicomImagesObj,
  createGenuieEventData,
  sumShapes,
} from '../utils';
import Thumbnail from './Thumbnail';
// import ScaleRules from './ScaleRules';
import StackControl from './StackControl';

import * as cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import Hammer from 'hammerjs';
import Loading from 'shared/components/Loading';
import ArrowTool from '../plugins/ArrowTool';
import GenuineTool from '../plugins/GenuineTool';
import GenuineTransparentTool from '../plugins/GenuineTransparentTools';

import iconMax from 'assets/arrows/2p.svg';
import { genuieShapes, getDefaultType, getToolByType, tools } from '../configs';
import { useDispatch } from 'react-redux';
import { setCanBeActive } from '../actions';
import { getToolState } from '../plugins/stateManagement/toolState';
import { IconGaidVectorLeft, IconGaidVectorRight, IconGenuine } from 'shared/components/Icons';
import SelectTool from '../plugins/SelectTool';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { useSnackbar } from 'notistack';
import AssociationsBlock from './AssociationsBlock';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import { SHAPE_ICONS_GRAY_BORDERED } from '../constants';
import { getCurrentImageMarksForPreOrPostSlice } from '../plugins/util';

cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

cornerstoneTools.init({ mouseEnabled: true, showSVGCursors: false, autoResizeViewports: true });
cornerstoneTools.toolStyle.setToolWidth('4');

const switchers = [
  { name: '2D', value: '2D' },
  { name: 'Tomo', value: 'tomo' },
  { name: '3DQ', value: '3DQ' },
];

const CanvasView = props => {
  const {
    dataTomo,
    data2D,
    data3DQ,
    activeTool,
    step,
    caseViews,
    allSteps,
    fullScreen,
    viewsCount,
    index,
    setActiveTool,
    updateFinding,
    viewSizesForced,
    currentImageIndex = 0,
    setActiveStep,
    selectedPoint,
    setSelectedPoint,
    tempSelectedPoint,
    setTempSelectedPoint,
    handleClickClose,
    openOverlay = () => {},
    showOverlay,
    canvasViewKey,
    position,
    defaultStackControl,
    correlationIndex = 0,
    isGaid2View,
    slicesDifference,
    onSliceChange,
    mainSliceIndex,
    setMainSliceIndex,
    sideSliceIndex,
    setSideSliceIndex,
    isPrimaryDicom,
    overlayViewsLoading,
    setOverlayViewsLoading,
    overlayData,
    selectedViewLaterality,
    overlayStackControlByIdx = {},
    setOverlayStackControlByIdx = () => {},
    imagesLoaded,
    setImagesLoaded = () => {},
  } = props;
  const dicomTypeData = {
    tomo: dataTomo,
    '2D': data2D,
    '3DQ': data3DQ,
  };

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [play, setPlay] = useState(false);
  const [showGenuie, setShowGenuie] = useState(true);
  const [imageFetch, setImageFetch] = useState(false);
  const [stackControl, setStackControl] = useState(
    overlayStackControlByIdx[index] ||
      defaultStackControl ||
      getDefaultType(step.dataType, dicomTypeData),
  );
  const [viewportState, setViewportState] = useState();
  const [gaidAssociations, setGaidAssociations] = useState([]);
  const [gaidDetails, setGaidDetails] = useState(null);
  const [isFetchingAssociations, setIsFetchingAssociations] = useState(false);
  const [positionStyle, setPositionStyle] = useState({});
  const [tempPointPosition, setTempPointPosition] = useState();
  const [isTempImgLoaded, setIsTempImgLoaded] = useState(false);
  const [currentGaidIdx, setCurrentGaidIdx] = useState(null);

  const element = useRef(null);
  const isFirstRun = useRef(true);
  const associationsDialogRef = useRef(null);
  const tempAreaRef = useRef(null);
  const switchersRef = useRef(null);
  const associationsSwitcherRef = useRef(null);

  const dicomData = dicomTypeData[stackControl];

  const curentLoopId = useRef();
  const dicomDataRef = useRef(dicomData);

  const outsideClickExcludeRefs = [associationsDialogRef];

  const handleClickOutsideCanvas = event => {
    if (
      element.current &&
      !element.current.contains(event.target) &&
      !isClickWithinDialog({ event }) &&
      !isClickWithinTempArea({ event }) &&
      !isClickWithinAssociationsSwitcherArea({ event })
    ) {
      const selectTool = cornerstoneTools.getToolForElement(element.current, 'SelectTool');
      if (selectTool && typeof selectTool.clearSelection === 'function') {
        selectTool.clearSelection(element.current);
        setSelectedPoint({});
      }
    }
  };

  // useOutsideClick(associationsDialogRef, handleClickOutsideCanvas, null, outsideClickExcludeRefs);

  useEffect(() => {
    return () => {
      setOverlayStackControlByIdx({ 0: undefined, 1: undefined });
    };
  }, []);

  useEffect(() => {
    if (!isPrimaryDicom && imagesLoaded) {
      setSideSliceIndex(correlationIndex);
    }
  }, [imagesLoaded]);

  const getAssociationsDialogPosition = index => {
    const position = { left: '-350px' };

    if (viewsCount === 4) {
      if (index <= 2) {
        position.left = index === 2 ? '-350px' : 'unset';
        position.right = index === 2 ? 'unset' : '-350px';
      }
    } else if (viewsCount === 2 && index === 0) {
      position.right = '-350px';
      position.left = 'unset';
    }

    setPositionStyle(position);
  };

  useEffect(() => {
    dicomDataRef.current = dicomData;
  }, [dicomData]);

  const addImageTransparentMarks = imageId => {
    if (curentLoopId.current === imageId) return;
    const currentDicomData = dicomDataRef.current;
    const marks = getCurrentImageMarksForPreOrPostSlice(imageId, currentDicomData.images);
    if (marks && marks.length) {
      cornerstoneTools.clearToolState(element.current, 'GenuineTransparentTool');
      marks.forEach(points => {
        points.forEach(item => {
          cornerstoneTools.addToolState(
            element.current,
            'GenuineTransparentTool',
            createGenuieEventData(item),
          );
        });
        curentLoopId.current = imageId;
      });
    }
  };

  const isClickWithinDialog = ({ event }) => {
    if (!associationsDialogRef.current) return false;
    const rect = associationsDialogRef.current.getBoundingClientRect();
    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    );
  };

  const isClickWithinTempArea = ({ event }) => {
    if (!tempAreaRef.current) return false;
    const rect = tempAreaRef.current.getBoundingClientRect();
    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    );
  };

  const isClickWithinAssociationsSwitcherArea = ({ event }) => {
    if (!associationsSwitcherRef.current) return false;
    const rect = associationsSwitcherRef.current.getBoundingClientRect();
    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    );
  };

  const isClickWithinSwitchers = ({ event }) => {
    if (!switchersRef.current) return false;
    const rect = switchersRef.current.getBoundingClientRect();
    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    );
  };

  const handleGenuineToolStateChange = (elm, step, index) => {
    const toolState = getToolState(elm, 'GenuineTool');
    if (toolState) {
      getAssociationsDialogPosition(index);
      const selectingPoint =
        toolState?.data?.find(state => state.handles.end.isActive) !== -1
          ? {
              ...toolState?.data?.find(state => state.handles.end.isActive),
              index: step.number,
            }
          : {};
      setSelectedPoint(selectingPoint);
    }
  };

  const handleImagesLoaded = status => {
    setImagesLoaded(status);
  };

  const getViewSizes = () => {
    if (viewSizesForced) return viewSizesForced();
    return {
      width: (window.innerWidth - (fullScreen ? 0 : 240)) / viewsCount,
      height: window.innerHeight - 95,
    };
  };

  const onArrowUpdate = image => {
    setTimeout(() => {
      try {
        const viewport = cornerstone.getViewport(element.current);
        const currentImage = viewport.images[image.imageId];
        if (!currentImage) return;
        const { emptyFinding, finding } = currentImage;
        const cuurentArrowData = getToolState(element.current, 'ArrowTool');
        const body = { ...(finding || emptyFinding) };
        body.vectorData = getFindingsCoordinates(cuurentArrowData);
        updateFinding(body);
      } catch (err) {
        console.log(err);
      }
    }, 100);
  };

  const updateCornerstone = () => {
    try {
      const viewport = cornerstone.getViewport(element.current);
      if (!viewport) return;
      cornerstone.setViewport(element.current, viewport);
    } catch (err) {
      console.log(err);
    }
  };

  const initFindings = () => {
    try {
      if (!element.current || !cornerstone.getImage(element.current)) {
        // Wait until the image is fully loaded
        return;
      }
      cornerstoneTools.addToolForElement(element.current, ArrowTool, {
        onArrowMove: onArrowUpdate,
        onArrowAdd: onArrowUpdate,
        onArrowRemove: onArrowUpdate,
      });
      cornerstoneTools.addToolForElement(element.current, SelectTool, {});
      dispatch(setCanBeActive('findingToggle', false));
      cornerstoneTools.setToolEnabledForElement(element.current, 'ArrowTool');
      cornerstoneTools.setToolEnabledForElement(element.current, 'SelectTool');
      setActiveToolEvent();
      setTimeout(updateCornerstone, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const initGenuines = () => {
    try {
      cornerstoneTools.addToolForElement(element.current, GenuineTool, {});
      cornerstoneTools.setToolEnabledForElement(element.current, 'GenuineTool');

      // Reapply the tool state for Genuines
      // THIS CAUSED TO MEMORY LEAK ISSUE
      // const image = cornerstone.getImage(element.current);
      // const toolState = getToolState(element.current, 'GenuineTool');
      // if (toolState && image) {
      //   toolState.data.forEach(item => {
      //     cornerstoneTools.addToolState(element.current, 'GenuineTool', item);
      //   });
      // }
      cornerstoneTools.addToolForElement(element.current, GenuineTransparentTool, {});
      cornerstoneTools.setToolEnabledForElement(element.current, 'GenuineTransparentTool');

      setTimeout(updateCornerstone, 500); // Allow some time for the viewport to update
    } catch (err) {
      console.log(err);
    }
  };

  const updateCurrentImageFindings = ({ image }, viewport) => {
    const imageInfo = viewport.images[image.imageId];
    if (!imageInfo) return;
    const findings = imageInfo.finding;
    if (findings && findings.vectorData && !imageInfo.inited) {
      findings.vectorData.forEach(item => {
        cornerstoneTools.addToolState(element.current, 'ArrowTool', createFindingEventData(item));
      });
    }
    imageInfo.inited = true;
  };

  const updateCurrentImageGenuine = ({ image }, viewport) => {
    const imageInfo = viewport.images[image.imageId];
    if (!imageInfo) return;
    const points = imageInfo.geniusAIDataList;
    if (points && points.length && !imageInfo.genuineInited) {
      points.forEach(item => {
        cornerstoneTools.addToolState(element.current, 'GenuineTool', createGenuieEventData(item));
      });
    }
    if (!imageInfo.inited) {
      if (points && points.length) {
        points.forEach(item => {
          cornerstoneTools.addToolState(
            element.current,
            'GenuineTool',
            createGenuieEventData(item),
          );
        });
      }
    }

    addImageTransparentMarks(image.imageId);
    imageInfo.genuineInited = true;
  };

  const onImageRendered = (e, v) => {
    try {
      const image = cornerstone.getImage(element.current);
      if (!element.current || !image) return;

      const viewport = cornerstone.getViewport(element.current);
      updateCurrentImageFindings(e.detail, viewport);
      updateCurrentImageGenuine(e.detail, viewport);
      setTimeout(() => {
        setViewportState(viewport);
      }, 100);
    } catch (err) {
      console.log(err);
    }
  };

  const onWindowResize = () => {
    cornerstone.resize(element.current);
    setElementDisplayPosition();
  };

  const setElementDisplayPosition = notMove => {
    const viewport = cornerstone.getViewport(element.current);
    if (!viewport) return;
    // const isLeft = step.position === 'left' && step.thumbpos === 'left';
    const { image, scale } = viewport;
    const { clientWidth } = element.current;
    const imageWidth = image.width; //!stackControl ? image.width : image.width + (isLeft ? -1000 : 0);
    const translation = { x: clientWidth / scale / 2 - imageWidth / 2, y: 0 };
    if (step.position === 'left') translation.x = -translation.x;
    if (!notMove) viewport.translation = translation;
    viewport.initialScale = scale;
    viewport.voi = { windowWidth: 255, windowCenter: 128 };
    cornerstone.setViewport(element.current, viewport);
  };

  const setImageStack = element => {
    const imageIds = dicomData.images.map(item => item.url);
    const stack = { currentImageIdIndex: correlationIndex, imageIds };
    cornerstoneTools.addStackStateManager(element, ['stack']);
    cornerstoneTools.addToolState(element, 'stack', stack);

    const correctImageIndex = correlationIndex;
    if (imageIds[correctImageIndex]) {
      cornerstone.loadAndCacheImage(imageIds[correctImageIndex]).then(image => {
        const viewport = cornerstone.getViewport(element);
        cornerstone.displayImage(element, image, viewport);
      });
    }
  };

  const loadImage = async () => {
    if (!element.current) return;
    setImageFetch(true);
    try {
      const images = getDicomImagesObj(dicomData.images);
      const dicomImage = dicomData.images[currentImageIndex] || dicomData.images[0];
      const image = await cornerstone.loadAndCacheImage(dicomImage.url);
      const viewportOptions = { pixelReplication: false, image, dicomImage, images };
      await cornerstone.displayImage(element.current, image, viewportOptions);
    } catch (err) {
      console.log(err);
    } finally {
      setImageFetch(false);
    }
  };

  const onRightClick = (el, e) => {
    if (e.which !== 3) return;
    e.preventDefault();
    let tool;
    setActiveTool(item => {
      tool = item;
      return getToolByType('pan');
    });

    const handleMouseUp = (tool, e) => {
      if (e.which !== 3) return;
      el.removeEventListener('mouseup', handleMouseUp.bind(null, tool), false);
      const setTool = tool && tool.type && tool.type !== 'reset' ? tool : { type: 'none' };
      setActiveTool(setTool);
    };

    el.addEventListener('mouseup', handleMouseUp.bind(null, tool), false);
    // eslint-disable-next-line consistent-return
    return false;
  };

  const initElement = async element => {
    try {
      await cornerstone.enable(element);
      await cornerstone.resize(element);
      element.addEventListener('cornerstoneimagerendered', onImageRendered);
      window.addEventListener('resize', onWindowResize);
      element.addEventListener('mousedown', onRightClick.bind(null, element), false);
      element.addEventListener('contextmenu', e => e.preventDefault(), false);
    } catch (err) {
      console.log(err);
    }
  };

  const initData = async element => {
    try {
      if (!dicomData) return;
      await element.classList.remove('maximize');
      await setImageStack(element);
      await loadImage();
      await cornerstone.fitToWindow(element);
      await cornerstone.resize(element);
      setElementDisplayPosition();
      setActiveToolEvent();
      setTimeout(initFindings, 100);
      setTimeout(initGenuines, 100);
    } catch (err) {
      console.log(err);
    }
  };

  const getGeniusData = async pointId => {
    try {
      setIsFetchingAssociations(true);
      const res = await Api.get(`/dicom/geniusData/${pointId}`);
      if (res?.data?.data) {
        res.data.data.gaid?.sort((g1, g2) => g1.order - g2.order);
        setGaidAssociations(
          res.data.data.gaid ? res.data.data.gaid.map((gaid, idx) => ({ ...gaid, idx })) : [],
        );
        setGaidDetails(res.data.data);
        const selectedPointIdx = res.data.data.gaid.findIndex(g => g.gaidId === pointId);
        setCurrentGaidIdx(selectedPointIdx);
      }
      setActiveTool(tools.find(tool => tool.type === 'associations'));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetchingAssociations(false);
    }
  };

  const unmountElement = async () => {
    try {
      element.current.removeEventListener('cornerstoneimagerendered', onImageRendered);
      window.removeEventListener('resize', onWindowResize);
      element.current.removeEventListener('mousedown', onRightClick);
      cornerstone.disable(element.current);
    } catch (err) {
      console.log(err);
    }
  };

  const removeTool = name => {
    const { current } = element;
    if (cornerstoneTools.getToolForElement(current, name)) {
      cornerstoneTools.removeToolForElement(current, name);
    }
  };

  const setActiveToolEvent = async () => {
    try {
      cornerstoneTools.addTool(SelectTool, {
        configuration: {
          index,
          handleGenuineToolStateChange: elm => handleGenuineToolStateChange(elm, step, index),
          setTempPointPosition,
          isClickWithinDialog,
          isClickWithinTempArea,
          isClickWithinAssociationsSwitcherArea,
          openOverlay: e => {
            const idx = canvasViewKey.includes('CC') ? 0 : 1;
            openOverlay(e, canvasViewKey, position, idx);
          },
        },
      });
      if (!element.current || !cornerstone.getImage(element.current)) {
        // Wait until the image is fully loaded
        return;
      }

      if (activeTool.type === 'associations') {
        // cornerstoneTools.setToolDisabledForElement(element.current, 'SelectTool');
      } else {
        cornerstoneTools.setToolActive('SelectTool', {
          mouseButtonMask: 1,
          isSelectActive: true,
          index,
          handleGenuineToolStateChange: elm => handleGenuineToolStateChange(elm, step, index),
        });
        document.querySelectorAll('.viewport-element').forEach(el => {
          const selectTool = cornerstoneTools.getToolForElement(el, 'SelectTool');
          if (selectTool && typeof selectTool.clearSelection === 'function') {
            selectTool.clearSelection(el);
          }
        });
      }

      const viewport = cornerstone.getViewport(element.current);
      if (!viewport || !activeTool.type) return;
      const { type, onTimeAction, noResetFindings } = activeTool;
      const { current } = element;
      current.onclick = null;

      removeTool('Pan');
      removeTool('Wwwc');
      removeTool('Zoom');
      removeTool('ZoomMouseWheel');

      if (type === 'none' || (onTimeAction && !noResetFindings)) {
        cornerstoneTools.setToolEnabledForElement(current, 'ArrowTool');
        cornerstoneTools.setToolEnabledForElement(current, 'GenuineTransparentTool');
      }

      if (type === 'reset') {
        current.classList.remove('maximize');
        setTimeout(async () => {
          await cornerstone.resize(current);
          await cornerstone.fitToWindow(current);
          await setElementDisplayPosition();
        });
      } else if (type === 'pan') {
        cornerstoneTools.addToolForElement(current, cornerstoneTools.PanTool);
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 1 });
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 2 });
      } else if (type === 'zoomInteractive') {
        const options = {
          configuration: {
            invert: false,
            preventZoomOutsideImage: false,
            minScale: 0.01,
            maxScale: 5.0,
          },
        };
        cornerstoneTools.addToolForElement(current, cornerstoneTools.ZoomMouseWheelTool, options);
        cornerstoneTools.addToolForElement(current, cornerstoneTools.ZoomTool, options);
        cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1 });
        cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 1 });
      } else if (type === 'zoomMagnifier') {
        current.onclick = zoomImageMouseClick.bind(null, cornerstone, current, true, false);
      } else if (type === 'windowLevel') {
        cornerstoneTools.addToolForElement(current, cornerstoneTools.WwwcTool);
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1 });
      } else if (type === 'finding') {
        cornerstoneTools.setToolActive('ArrowTool', { mouseButtonMask: 1 });
      } else if (type === 'findingToggle') {
        const tool = cornerstoneTools.getToolForElement(current, 'ArrowTool');
        const isDisabled = tool && tool.mode === 'disabled';
        dispatch(setCanBeActive(type, !isDisabled));
        if (isDisabled) {
          cornerstoneTools.setToolEnabledForElement(current, 'ArrowTool');
        } else {
          cornerstoneTools.setToolDisabledForElement(current, 'ArrowTool');
        }
        setTimeout(updateCornerstone, 500);
      } else if (type === 'findingReset') {
        cornerstoneTools.clearToolState(current, 'ArrowTool');
        setTimeout(updateCornerstone, 100);
      } else if (type === 'zoom1_1') {
        current.onclick = () => {
          maximazeElement(cornerstone, current);
          zoomImageMouseClick(cornerstone, current, true, 1);
        };
      }
    } catch (err) {
      console.log(err);
    }
  };

  const toggleMax = async () => {
    maximazeElement(cornerstone, element.current, true);
    setElementDisplayPosition(true);
  };

  const toggleGenuie = () => {
    if (showGenuie) {
      cornerstoneTools.setToolDisabledForElement(element.current, 'GenuineTool');
    } else {
      cornerstoneTools.setToolEnabledForElement(element.current, 'GenuineTool');
    }
    setTimeout(updateCornerstone, 200);
    setShowGenuie(!showGenuie);
  };

  const fullScreenChange = async () => {
    if (!element || !element.current) return;
    try {
      setTimeout(() => {
        cornerstone.resize(element.current);
        cornerstone.fitToWindow(element.current);
        setElementDisplayPosition();
      }, 500);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectGaid = association => {
    setTempSelectedPoint(association);
    setCurrentGaidIdx(association?.idx);
  };

  const loadTempImage = event => {
    const img = event.target;
    const initialWidth = img.naturalWidth;
    const initialHeight = img.naturalHeight;
    const renderedWidth = img.width;
    const renderedHeight = img.height;

    const scaleFactorX = renderedWidth / initialWidth;
    const scaleFactorY = renderedHeight / initialHeight;

    const scaledPosition = {
      top: `${tempSelectedPoint.y * scaleFactorY - 50}px`,
      left: `${tempSelectedPoint.x * scaleFactorX - 40}px`,
    };

    setTempPointPosition(scaledPosition);
    setIsTempImgLoaded(true);
  };

  const handleSelectPrevAssociation = e => {
    if (gaidAssociations.length === 0) return;

    if (currentGaidIdx > 0) {
      const newIndex = currentGaidIdx - 1;
      setTempSelectedPoint(gaidAssociations[newIndex]);
      setCurrentGaidIdx(newIndex);
    }
  };

  const handleSelectNextAssociation = () => {
    if (gaidAssociations.length === 0) return;

    if (currentGaidIdx < gaidAssociations.length - 1) {
      const newIndex = currentGaidIdx + 1;
      setTempSelectedPoint(gaidAssociations[newIndex]);
      setCurrentGaidIdx(newIndex);
    }
  };

  const handleSwitchType = value => {
    if (value === stackControl) return;
    setImagesLoaded(false);
    setStackControl(value);
    setOverlayStackControlByIdx(prev => ({ ...prev, [index]: value }));
  };

  useEffect(() => {
    if (selectedPoint?.handles?.end?.id) {
      getGeniusData(selectedPoint?.handles?.end?.id);
    } else {
      setGaidAssociations([]);
      setGaidDetails(null);
      setActiveTool({ type: 'none' });
    }
  }, [selectedPoint]);

  useEffect(() => {
    initElement(element.current);
    return () => unmountElement();
    //eslint-disable-next-line
  }, [stackControl]);

  useEffect(() => {
    initData(element.current);
    //eslint-disable-next-line
  }, [dataTomo, step, stackControl]);

  useEffect(() => {
    if (element.current) onWindowResize();
    //eslint-disable-next-line
  }, [step]);

  useEffect(() => {
    setActiveToolEvent();
    //eslint-disable-next-line
  }, [activeTool]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    fullScreenChange();
    //eslint-disable-next-line
  }, [fullScreen]);

  useEffect(() => {
    const initializeElement = async () => {
      try {
        await cornerstone.enable(element.current);
        await cornerstone.resize(element.current);
        cornerstoneTools.addTool(SelectTool, {
          configuration: {
            openOverlay: e => {
              const idx = canvasViewKey.includes('CC') ? 0 : 1;
              openOverlay(e, canvasViewKey, position, idx);
            },
          },
        });
        cornerstoneTools.setToolActive('SelectTool', { mouseButtonMask: 1 });
        initGenuines();
      } catch (err) {
        console.error('Error initializing Cornerstone:', err);
      }
    };

    initializeElement();
  }, []);

  const showSlice = element && viewportState && stackControl !== '2D' && !currentImageIndex;
  const hasGenuie = !!dicomData?.images.filter(item => !!item.geniusAIDataList?.length).length;

  return (
    <>
      <div
        className={`viewport-element ${step.thumbpos}`}
        style={{ ...getViewSizes() }}
        ref={element}
        key={stackControl}
      >
        {activeTool.type === 'associations' &&
          selectedPoint?.handles?.end?.id &&
          step.number === selectedPoint?.index && (
            <>
              <div ref={associationsSwitcherRef} className='associations-switcher'>
                <span className='vector-btn' onClick={handleSelectPrevAssociation}>
                  <IconGaidVectorLeft />
                </span>
                <span>
                  {currentGaidIdx + 1} / {gaidAssociations?.length}
                </span>
                <span className='vector-btn' onClick={handleSelectNextAssociation}>
                  <IconGaidVectorRight />
                </span>
              </div>
              {tempSelectedPoint && tempSelectedPoint?.gaidId !== selectedPoint?.handles?.end?.id && (
                <div ref={tempAreaRef} className='temp-selected-area'>
                  <div className='img-part-block'>
                    <img
                      className='dicom-img'
                      alt='dicom'
                      src={tempSelectedPoint?.url}
                      onLoad={loadTempImage}
                    />
                    {isTempImgLoaded && (
                      <div style={tempPointPosition} className='point-container'>
                        {
                          <img
                            className='shape-icon'
                            alt='shape'
                            src={SHAPE_ICONS_GRAY_BORDERED[(tempSelectedPoint?.shape)]}
                          />
                        }
                        <div className='details-block'>
                          <span className='designator'>{gaidDetails?.designator}</span>
                          <span className='percentage'>{tempSelectedPoint?.percentage}%</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <AssociationsBlock
                handleClickClose={handleClickClose}
                ref={associationsDialogRef}
                handleSelectGaid={handleSelectGaid}
                setSelectedPoint={setSelectedPoint}
                positionStyle={positionStyle}
                isFetching={isFetchingAssociations}
                details={gaidDetails}
                selectedPoint={selectedPoint}
                setTempSelectedPoint={setTempSelectedPoint}
                tempSelectedPoint={tempSelectedPoint}
                associations={gaidAssociations}
              />
            </>
          )}
        {hasGenuie && (
          <div className={`genuie-button-area ${step.thumbpos}`}>
            <button className={`btn p-0 toggle-genuie-button`} onClick={toggleGenuie}>
              <IconGenuine color={showGenuie ? '#05a6db' : undefined} />
            </button>
            {showGenuie && (
              <div className='genuie-modes d-flex flex-column'>
                {genuieShapes.map((item, i) => {
                  const Icon = item.icon;
                  const shapesCount = sumShapes(dicomData.images, item.shape);
                  return (
                    <button key={i} className={`btn btn-sm p-0`}>
                      <Icon />
                      {!!shapesCount && <span>{shapesCount}</span>}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {viewportState && <Thumbnail viewport={viewportState} />}
        {dicomData && (
          <div className='dicom-number d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <button onClick={toggleMax} className='btn maximize-icon mr-2'>
                <img src={iconMax} alt='maximize' />
              </button>
              <span>
                0{dicomData.patient.age}Y {dicomData.imageLaterality} {dicomData.viewPosition}
              </span>
            </div>
          </div>
        )}
        {step.has_switcher && (
          <div ref={switchersRef} className='tomo-switch public d-flex justify-content-center'>
            <div>
              {switchers.map(item => (
                <button
                  key={item.value}
                  disabled={!dicomTypeData[item.value]}
                  onClick={() => handleSwitchType(item.value)}
                  className={stackControl === item.value ? 'active' : ''}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        )}
        {/* {viewportState && (
        <ScaleRules number={viewportState.scale * 1000} date={dicomData.patient.birthDate} />
      )} */}
        {showSlice && dicomData && (
          <StackControl
            onImagesLoaded={handleImagesLoaded}
            overlayData={overlayData}
            overlayViewsLoading={overlayViewsLoading}
            setOverlayViewsLoading={setOverlayViewsLoading}
            index={index}
            initGenuines={initGenuines}
            setActiveTool={setActiveTool}
            cornerstone={cornerstone}
            cornerstoneTools={cornerstoneTools}
            element={element.current}
            play={play}
            setPlay={setPlay}
            images={dicomData.images}
            imageType={dicomData.viewPosition}
            stackControl={stackControl}
            className={step.thumbpos}
            isGenuine={hasGenuie}
            defaultSliceIndex={correlationIndex}
            isGaid2View={isGaid2View}
            slicesDifference={slicesDifference}
            mainSliceIndex={mainSliceIndex}
            setMainSliceIndex={setMainSliceIndex}
            sideSliceIndex={sideSliceIndex}
            setSideSliceIndex={setSideSliceIndex}
            isPrimaryDicom={isPrimaryDicom}
          />
        )}
        {imageFetch && <Loading />}
        {dicomData && <canvas className={`cornerstone-canvas cursor-${activeTool.cursor}`} />}
        {!dicomData && (
          <div className='viewer-no-image d-flex align-items-center justify-content-center text-white'>
            No Image Available
          </div>
        )}
      </div>
    </>
  );
};

export default CanvasView;
