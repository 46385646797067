/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef } from 'react';
import { bindDurationSecondsGettingMins } from 'utils/appHelpers';
import { QUIZ_ANSWER_STATUS_OBJ } from '../constants';
import { CountDown } from './CountDown';

export const QuizFlow = ({
  quiz,
  lesson,
  startTime,
  onClickLeave,
  currentStep,
  passedQuestions,
  isQuizInProgress,
  question,
  passedDuration = 0,
  questionPassedDuration = 0,
  isQuizCompleted,
  isModalOpen,
  handleSkipStep,
  passingThresholdStep,
  quizUsage,
}) => {
  const backendPingInterval = 10;
  const sortedPassedQuestions = passedQuestions
    ? passedQuestions.slice().sort((a, b) => a?.step - b?.step)
    : [];

  // Use refs to hold these values
  const passedSecondsRef = useRef(passedDuration);
  const passedSecondsOnQuestionRef = useRef(questionPassedDuration);

  const getIsCurrentQuestion = idx => currentStep === idx;

  const steps = quiz?.steps ? [...quiz.steps] : [...(lesson.steps || [])];
  if (sortedPassedQuestions.length) {
    steps.splice(0, sortedPassedQuestions.length, ...sortedPassedQuestions);
  }

  const passThresholdIdx = passingThresholdStep;
  const flowSteps = [{ type: 'start' }, ...steps, { type: 'results' }];
  const getIsBreakPoint = idx =>
    idx === passThresholdIdx ||
    idx === 0 ||
    idx === flowSteps.length - 1 ||
    (isQuizInProgress && idx === currentStep);
  const passedStep = isQuizCompleted ? steps.length + 3 : isQuizInProgress ? currentStep + 1 : 1;
  const isQuizNotStarted = !isQuizInProgress && !isQuizCompleted;

  useEffect(() => {
    passedSecondsOnQuestionRef.current = 0;
  }, [question?.id]);

  useEffect(() => {
    passedSecondsRef.current = 0;
  }, [isQuizInProgress]);

  return (
    <div className='quiz-flow-container'>
      <div className='head-part'>
        <h6 className='title'>Quiz Flow</h6>
        {isQuizInProgress && (
          <div onClick={onClickLeave} className='leave-label'>
            Leave Quiz
          </div>
        )}
      </div>
      <div className='flow-part-container custom-vertical-scrollbar-block '>
        <div className='flow-line-block'>
          {flowSteps.map((step, idx) => {
            return (
              <React.Fragment key={idx}>
                <div
                  className={`circle ${getIsBreakPoint(idx) && 'breakpoint'}  ${passedQuestions &&
                    QUIZ_ANSWER_STATUS_OBJ[(step.question?.userAnswerStatus)]} ${
                    (passedQuestions &&
                      QUIZ_ANSWER_STATUS_OBJ[(step.question?.userAnswerStatus)] === 'Pending') ||
                    idx === 0 ||
                    (isQuizCompleted && idx === flowSteps.length - 1)
                      ? 'regular'
                      : ''
                  }`}
                >
                  {passedQuestions && (
                    <>
                      {QUIZ_ANSWER_STATUS_OBJ[(step.question?.userAnswerStatus)] === 'Success' && (
                        <div className={`circle-labels ${getIsBreakPoint(idx) && 'breakpoint'}`}>
                          <span className='label-start'>Correct</span>
                        </div>
                      )}
                      {QUIZ_ANSWER_STATUS_OBJ[(step.question?.userAnswerStatus)] === 'Failure' && (
                        <div className={`circle-labels ${getIsBreakPoint(idx) && 'breakpoint'}`}>
                          <span className='label-start'>Incorrect</span>
                        </div>
                      )}
                      {QUIZ_ANSWER_STATUS_OBJ[(step.question?.userAnswerStatus)] === 'NoAnswer' && (
                        <div className={`circle-labels ${getIsBreakPoint(idx) && 'breakpoint'}`}>
                          <span className='label-start'>Not Answered</span>
                        </div>
                      )}
                      {QUIZ_ANSWER_STATUS_OBJ[(step.question?.userAnswerStatus)] === 'Expired' && (
                        <div className={`circle-labels ${getIsBreakPoint(idx) && 'breakpoint'}`}>
                          <span className='label-start'>Expired</span>
                        </div>
                      )}
                    </>
                  )}
                  <div className={`circle-labels ${getIsBreakPoint(idx) && 'breakpoint'}`}>
                    {!!question?.timeLimit && getIsCurrentQuestion(idx) && (
                      <span className='label-time active'>
                        <CountDown
                          isModalOpen={isModalOpen}
                          isQuizInProgress={isQuizInProgress}
                          passedDuration={passedDuration}
                          questionPassedDuration={questionPassedDuration}
                          question={question}
                          questionTimeLimit={question.timeLimit}
                          pingToBackend={quizUsage}
                          backendPingInterval={backendPingInterval}
                          startDate={startTime}
                          passedSecondsRef={passedSecondsRef}
                          passedSecondsOnQuestionRef={passedSecondsOnQuestionRef}
                          timeLimit={quiz && (quiz?.timeLimit || lesson?.timeLimit)}
                          onTimeEnded={handleSkipStep}
                          isQuestionTimer
                        />
                      </span>
                    )}

                    {idx === 0 && (
                      <>
                        <span className='label-start'>Start Quiz</span>
                        {startTime ? (
                          <>
                            {(!!quiz?.timeLimit || !!lesson.timeLimit) && (
                              <span className='label-time'>
                                <CountDown
                                  isModalOpen={isModalOpen}
                                  isQuizInProgress={isQuizInProgress}
                                  passedDuration={passedDuration}
                                  questionPassedDuration={questionPassedDuration}
                                  question={question}
                                  pingToBackend={quizUsage}
                                  backendPingInterval={backendPingInterval}
                                  passedSecondsRef={passedSecondsRef}
                                  passedSecondsOnQuestionRef={passedSecondsOnQuestionRef}
                                  startDate={startTime}
                                  timeLimit={quiz?.timeLimit || lesson.timeLimit}
                                  questionTimeLimit={question.timeLimit}
                                />
                              </span>
                            )}
                          </>
                        ) : (
                          <span className='label-time'>
                            {quiz?.timeLimit || lesson?.timeLimit
                              ? bindDurationSecondsGettingMins(
                                  (
                                    (quiz?.timeLimit || lesson?.timeLimit) -
                                    passedDuration / 60
                                  ).toFixed(1),
                                )
                              : 'No time limit'}
                          </span>
                        )}
                      </>
                    )}
                  </div>

                  {idx === passThresholdIdx && passThresholdIdx <= flowSteps.length - 2 && (
                    <div className='circle-labels breakpoint'>
                      <span className='label-start'>Passing Threshold</span>
                    </div>
                  )}
                  {idx === flowSteps.length - 1 && (
                    <>
                      {isQuizCompleted && <div className='inner-circle-regular' />}
                      <div className={`circle-labels breakpoint ${isQuizCompleted && 'regular'}`}>
                        <span className='label-start'>Results</span>
                      </div>
                    </>
                  )}
                  {getIsCurrentQuestion(idx) && isQuizInProgress && (
                    <div className='current-step'>{currentStep}</div>
                  )}
                </div>
                {idx !== flowSteps.length - 1 && (
                  <div className={`line ${passedStep > idx + 1 && 'passed'}`} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
