import React from 'react';

const ProgressIncorectBar = ({ progress, correctProgress, className = '', isDICOM }) => {
  const hasInccorect =
    correctProgress !== null && correctProgress !== undefined && correctProgress !== 100;
  const incorrect = isDICOM
    ? progress - (progress * correctProgress) / 100
    : progress - (correctProgress || 0);
  const correct = isDICOM
    ? (progress * correctProgress) / 100
    : progress - (hasInccorect ? incorrect : 0);
  const isCompleted = progress >= 100;
  const status =
    !isCompleted && !hasInccorect
      ? 'progress'
      : isCompleted && !hasInccorect
      ? 'completed'
      : 'correct';
  return (
    <span className={`plan-progress advanced d-flex ${className}`}>
      <span className={`progress-line ${status}`} style={{ width: `${correct}%` }} />
      {hasInccorect && (
        <span className='progress-line incorrect' style={{ width: `${incorrect + 1}%` }} />
      )}
    </span>
  );
};

export default ProgressIncorectBar;
