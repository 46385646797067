import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import BackButton from 'shared/BackButton';
import DateRange from 'shared/components/DateRangePicker';
import { duplicate, getError } from 'utils/appHelpers';
import { IconArrowLeft, IconArrowRight, IconClose, IconSearch } from 'shared/components/Icons';
import {
  SIconResolution,
  SIconLatency,
  SIconBandwidth,
} from 'shared/components/StatisticTestIcons';
import ReactPaginate from 'react-paginate';
import Loading from 'shared/components/Loading';
import { useSnackbar } from 'notistack';
import { Api } from 'utils/connectors';

const structureDateFilter = body => {
  const filter = JSON.parse(JSON.stringify(body));
  if (filter.date) {
    filter.startDate = moment(filter.date.start).format('MM.DD.YYYY');
    filter.endDate = moment(filter.date.end).format('MM.DD.YYYY');
    delete filter.date;
  }

  return filter;
};

const DiagnosticsHistory = ({ history }) => {
  const [dateRange, setDateRange] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [initialData, setInitialData] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const generateData = useCallback(() => {
    let copiedData = duplicate(initialData);
    const modifiedData = [];
    const parseData = data => {
      try {
        return JSON.parse(data);
      } catch (e) {
        return {};
      }
    };

    copiedData.forEach(item => {
      if (!item.testData) return;
      const parsedData = parseData(item.testData);
      if (!parsedData) return;
      parsedData.ip = item.hostAddress;
      parsedData.resolution = parsedData.resolution || null;
      parsedData.bandwidth = parsedData.bandwidth || null;
      parsedData.latency = parsedData.latency || null;
      parsedData.browser = parsedData.browser || '';
      parsedData.os = parsedData.os || '';
      parsedData.id = item.id;
      parsedData.date = moment(item.dateTime).format('MM/DD/YYYY') || 'Unknown';
      modifiedData.push(parsedData);
    });
    setData(modifiedData);
  }, [initialData]);

  const getUserTestHistory = async () => {
    try {
      setFetching(true);
      const params = {
        page: page + 1,
        limit: 8,
        orderKey: 'dateTime',
        order: false,
        ...structureDateFilter({ date: dateRange }),
      };
      const { data } = await Api.get(`/common/getconnectiontest`, { params });
      setPageCount(Math.ceil(data.data.count / 8));
      setInitialData(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  const onPageChange = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    getUserTestHistory();
    //eslint-disable-next-line
  }, [page, dateRange]);

  useEffect(() => {
    if (initialData) generateData(initialData);
  }, [generateData, initialData]);

  return (
    <div>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <BackButton history={history} />
          <span className='ml-1'>Connection and Device Test History</span>
        </div>
      </div>
      <div className='p-3 d-flex justify-content-end'>
        <DateRange onChange={setDateRange} value={dateRange} />
        {dateRange && (
          <button className='btn p-0 ml-2' onClick={() => setDateRange(null)}>
            <IconClose />
          </button>
        )}
      </div>
      {!fetching && (
        <div className='px-3'>
          <table className='table table-custom'>
            <thead>
              <tr>
                <th scope='col' className='col-index'>
                  #
                </th>
                <th scope='col'>Date</th>
                <th scope='col'>Resolution</th>
                <th scope='col'>Latency</th>
                <th scope='col'>Bandwidth</th>
                <th scope='col'>IP Address</th>
                <th scope='col'>Browser</th>
                <th scope='col cell-fixed__md'>OS Info</th>
              </tr>
            </thead>
            <tbody className='font-size-12'>
              {data && data.length > 0 ? (
                data.map(({ resolution, bandwidth, latency, browser, os, id, date, ip }, index) => {
                  return (
                    <tr key={id}>
                      <td className='col-index'>
                        <b>{index + 1}</b>
                      </td>
                      <td>{date}</td>
                      <td>
                        <div className='d-flex align-items-center justify-content-between'>
                          {resolution
                            ? `Screen: ${resolution.screen}, Window: ${resolution.window}`
                            : '-'}
                          <SIconResolution className='ml-2' data={{ resolution }} />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-between'>
                          {latency
                            ? `Avg: ${latency.avg}ms, Min: ${latency.min}ms, Max: ${latency.max}ms`
                            : '-'}
                          <SIconLatency className='ml-2' data={{ latency }} />
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center justify-content-between'>
                          {bandwidth
                            ? `Received ${Number(bandwidth.received).toFixed(2)} MB in ${Number(
                                bandwidth.duration,
                              ).toFixed(2)}s`
                            : '-'}
                          <SIconBandwidth className='ml-2' data={{ bandwidth }} />
                        </div>
                      </td>
                      <td className='text-nowrap text-center'>{ip || '-'}</td>
                      <td className='text-nowrap'>{browser || '-'}</td>
                      <td className={`cell-fixed__md ${os.length > 40 ? 'font-size-10' : ''}`}>
                        {os || '-'}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='10'>
                    <div>
                      <IconSearch className='mr-1' /> No Results Found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {fetching && <Loading />}
      {pageCount > 1 && !fetching && (
        <ReactPaginate
          previousLabel={<IconArrowLeft />}
          nextLabel={<IconArrowRight />}
          breakLabel={'...'}
          forcePage={page}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          onPageChange={onPageChange}
          containerClassName={`pagination show-arrow`}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
        />
      )}
    </div>
  );
};

export default DiagnosticsHistory;
