import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCourses } from '../Courses/actions';
import { courseSubsStatuses, findCourseFirstLesson } from 'utils/appHelpers';
import { IconCourses } from 'shared/components/Icons';
import certificate from 'assets/certificate.png';
import { hasAccess } from 'utils/permissionHelper';
import CourseItem from 'shared/components/CourseItem';

const Transcript = props => {
  const { courses, history, getCourses, marketingCourses } = props;
  const all = courses.filter(item => courseSubsStatuses(item.status, 'active'));
  const notCompletedCourses = all.filter(item => item.progress < 100);
  const completedCourses = all.filter(item => item.progress >= 100);
  const isEventUser = hasAccess('event_user');

  const activeMarketingCourses =
    marketingCourses?.length &&
    marketingCourses.filter(course => courseSubsStatuses(course?.status, 'active'));

  const courseClickHandle = course => {
    findCourseFirstLesson(course, history);
  };

  const marketingCourseClickHandle = course => {
    history.push(`/marketing-courses/${course.id}`);
  };

  const goToCertificates = () => {
    history.push('/profile/certificate');
  };

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  return (
    <div>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between bg-white'>
        <div className='d-flex'>
          <span className='d-flex mr-2'>
            <IconCourses color='#131f6b' />
          </span>
          My Transcript
        </div>
      </div>
      <div className='p-3'>
        <p className='my-2 text-secondary'>Enrolled</p>
        <div className='courses d-flex flex-wrap'>
          {notCompletedCourses.map(item => (
            <CourseItem
              key={item.id}
              item={item}
              courseClick={courseClickHandle.bind(null, item)}
            />
          ))}
        </div>
        {!isEventUser && (
          <>
            <p className='my-2 text-secondary'>Completed</p>
            <div className='courses d-flex flex-wrap'>
              {completedCourses.map(item => (
                <CourseItem
                  key={item.id}
                  item={item}
                  courseClick={courseClickHandle.bind(null, item)}
                />
              ))}
            </div>
            <p className='my-2 text-secondary'>Certificate</p>
            <div className='courses d-flex flex-wrap'>
              <div
                className='p-2 certificate-item in-course mr-3'
                role='button'
                tabIndex='-1'
                onClick={goToCertificates}
              >
                <img width='250' src={certificate} alt='certificate' />
                <p>Certificates</p>
              </div>
            </div>
          </>
        )}
      </div>
      {!!activeMarketingCourses?.length && (
        <div className='p-3'>
          <p className='my-2 text-secondary'>Marketing Materials</p>
          <div className='courses d-flex flex-wrap'>
            {activeMarketingCourses.map(item => (
              <CourseItem
                isMarketingMaterial
                key={item.id}
                item={item}
                courseClick={marketingCourseClickHandle.bind(null, item)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ courses, marketingCourses }) => ({
  courses,
  marketingCourses,
});

export default connect(
  mapStateToProps,
  { getCourses },
)(Transcript);
