import React from 'react';
import InformativeMessageComponent from '../components/InformativeMessage';
import AuthPage from '../components/AuthPage';

const NoAccess = ({ match, history }) => {
  const info = {
    title: 'No Permission',
    subtitles: [
      `Your account doesn't have permission to access this area.`,
      `If you have an account with such permissions Sign in using other account.`,
    ],
    actionText: 'Sign in',
    actionHandler: () => history.push('/login'),
  };

  return (
    <AuthPage>
      <InformativeMessageComponent {...info} />
    </AuthPage>
  );
};

export default NoAccess;
