export const QUESTION_TYPES_OBJ = {
  radio: 'radio',
  multi: 'multi',
  yesno: 'yesno',
  'image-category': 'image-category',
};

export const QUIZ_STATUS_OBJ = {
  1: 'InProgress',
  2: 'Success',
  3: 'Failure',
  4: 'Terminated',
  5: 'Expired',
};

export const QUIZ_STATUSES = {
  InProgress: 1,
  Success: 2,
  Failure: 3,
  Terminated: 4,
  Expired: 5,
};

export const QUIZ_START_DESC_OBJ = {
  Pending: 'Your quiz is in progress. Press to Continue',
  Expired: 'Your quiz was expired. Click start to try to pass the quiz newly',
  Completed: 'You have successfully completed this quiz.',
};

export const QUIZ_ANSWER_STATUS_OBJ = {
  1: 'Pending',
  2: 'Success',
  3: 'Failure',
  4: 'NoAnswer',
  5: 'Expired',
};

export const ANSWER_STATUSES = {
  Pending: 1,
  Success: 2,
  Failure: 3,
  NoAnswer: 4,
  Expired: 5,
};

export const ENGLISH_UPPERCASE_LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
