import React, { useEffect, useState } from 'react';
import PublicWrapper from 'shared/components/layouts/PublicWrapper';
import Loading from 'shared/components/Loading';
import { Api } from 'utils/connectors';
import CaseView from './CaseView';

const PublicCaseView = ({ match, history }) => {
  const sharedToken = match.params?.id;
  const [caseData, setCaseData] = useState();

  const getCaseData = async () => {
    try {
      const { data } = await Api.get(`/cases/shared/case/${sharedToken}?lut=1&dicomType=all`);
      sessionStorage.setItem('caseSharedToken', sharedToken);
      setCaseData(data.data);
    } catch (err) {
      history.push('/no-web-access');
    }
  };

  useEffect(() => {
    getCaseData();
  }, [sharedToken]);

  return (
    <PublicWrapper>
      {!caseData ? <Loading classView='mt-5' /> : <CaseView caseData={caseData} />}
    </PublicWrapper>
  );
};

export default PublicCaseView;
