import ShapeCross from 'assets/shapes/shape-cross.svg';
import ShapeTriangle from 'assets/shapes/shape-triangle.svg';
import ShapeFlake from 'assets/shapes/shape-flake.svg';
import ShapeCrossGray from 'assets/shapes/shape-cross-gray.svg';
import ShapeTriangleGray from 'assets/shapes/shape-triangle-gray.svg';
import ShapeFlakeGray from 'assets/shapes/shape-flake-gray.svg';
import ShapeCrossGrayBordered from 'assets/shapes/shape-cross-gray-bordered.svg';
import ShapeTriangleGrayBordered from 'assets/shapes/shape-triangle-gray-bordered.svg';
import ShapeFlakeGrayBordered from 'assets/shapes/shape-flake-gray-bordered.svg';

export const SHAPE_ICONS = {
  1: ShapeCross,
  2: ShapeTriangle,
  3: ShapeFlake,
};

export const SHAPE_ICONS_GRAY = {
  1: ShapeCrossGray,
  2: ShapeTriangleGray,
  3: ShapeFlakeGray,
};

export const SHAPE_ICONS_GRAY_BORDERED = {
  1: ShapeCrossGrayBordered,
  2: ShapeTriangleGrayBordered,
  3: ShapeFlakeGrayBordered,
};

export const OVERLAY_VIEW_KEY_BY_IDX = {
  0: 'CC',
  1: 'MLO',
};
