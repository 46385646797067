import React, { useState } from 'react';
import { connect } from 'react-redux';
import InputGroup from 'shared/components/InputGroup';
import { loginUser } from './../actions/index';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import ButtonLine from 'shared/components/ButtonLine';
import AuthBlock from '../components/AuthBlock';
import { IconMail, IconPassword } from 'shared/components/Icons';
import AuthPage from '../components/AuthPage';

const Login = ({ loginUser, history, location }) => {
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [auth, setAuth] = useState({
    email: '',
    password: '',
  });

  const authOptions = {
    title: <>Welcome to ACE</>,
    links: { signup: true, reset: true },
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      const res = await Api.post('/auth/login', auth);
      if (res.data.data.pendingConfirmation) {
        history.push({ pathname: 'email-verification', state: { email: auth.email } });
        return;
      }
      await loginUser(res.data, history, location.state?.loginForwardUrl);
      setFetch(false);
    } catch (err) {
      setError(getError(err));
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    const tempAuth = { ...auth };
    tempAuth[name] = value;
    setAuth(tempAuth);
  };

  return (
    <AuthPage>
      <AuthBlock {...authOptions}>
        <form onSubmit={handleSubmit} className='mt-5'>
          <InputGroup
            type='text'
            name='email'
            onChange={handleChange}
            className='mb-4'
            placeholder='Email address'
            value={auth.email}
            icon={<IconMail />}
            autoFocus
            required
          />
          <InputGroup
            hasError={error}
            type='password'
            name='password'
            onChange={handleChange}
            placeholder='Password'
            value={auth.password}
            icon={<IconPassword />}
            required
          />
          {error && <div className='error-text'>{error}</div>}
          <ButtonLine type='submit' disabled={fetch}>
            Sign In
          </ButtonLine>
        </form>
      </AuthBlock>
    </AuthPage>
  );
};

export default connect(
  null,
  { loginUser },
)(Login);
