import React, { useEffect, useState } from 'react';
const rates = [0, 1, 2, 3, 4];

const StarRating = ({ onClick, rate = null, isDisabled }) => {
  const [value, setValue] = useState(rate);

  const onRate = v => {
    const result = 5 - v;
    setValue(result);
    onClick(result);
  };

  useEffect(() => {
    setValue(rate);
  }, [rate]);

  return (
    <div>
      <div className={`rating rating__${isDisabled ? 'completed' : ''}`}>
        {rates.map(v => (
          <span
            className={`pointer ${value && value >= 5 - v ? 'active' : ''}`}
            key={v}
            onClick={() => onRate(v)}
            role='presentation'
          >
            ☆
          </span>
        ))}
      </div>
    </div>
  );
};

export default StarRating;
