import React from 'react';
import EmptyView from '../EmptyView';
import { IconImage } from 'shared/components/Icons';

const TypeImage = ({ lesson }) => {
  const hasContentImage = lesson.contentUrl || lesson.url;
  return (
    <>
      {hasContentImage && (
        <div className='video-area flex-1 d-flex justify-content-center'>
          <img
            src={
              lesson.isMarketingMaterial
                ? lesson.contentUrl
                : lesson?.contentFile?.url || lesson.contentUrl
            }
            height='100%'
            alt='lesson'
          />
        </div>
      )}
      {!hasContentImage && (
        <div className='video-area flex-1 d-flex justify-content-center h-100'>
          <EmptyView text='No image file specified.' Icon={IconImage} />
        </div>
      )}
    </>
  );
};

export default TypeImage;
