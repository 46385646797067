/**
 * Scrolls the container with the given id to its horizontal end.
 * @param {string} containerId - The id of the container to scroll.
 */
export const scrollHorizontallyToContainerEnd = containerId => {
  const containerElm = document.getElementById(containerId);
  if (containerElm) {
    requestAnimationFrame(() => {
      containerElm.scrollLeft = containerElm.scrollWidth;
    });
  }
};

export const scrollVerticallyToContainerEnd = containerId => {
  const containerElm = document.getElementById(containerId);
  if (containerElm) {
    requestAnimationFrame(() => {
      containerElm.scrollTop = containerElm.scrollHeight;
    });
  }
};
