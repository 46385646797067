import { createReducer } from 'utils/reduxHelpers';

const ON_GET_EVENTS = '@@events/ON_GET_EVENTS';

export const onGetEvents = payload => ({ type: ON_GET_EVENTS, payload });

const eventsHandlers = {
  [ON_GET_EVENTS]: (state, action) => action.payload,
};

export const eventsInitialState = {};

export const eventsReducer = createReducer(eventsInitialState, eventsHandlers);
