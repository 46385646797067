/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { LeftArrowCircleIcon, RightArrowCircleIcon } from 'shared/components/Icons';

const NextArrow = props => {
  const { onClick, className } = props;
  return (
    <div className={className} onClick={onClick}>
      <RightArrowCircleIcon />
    </div>
  );
};

const PrevArrow = props => {
  const { onClick, className } = props;
  return (
    <div className={className} onClick={onClick}>
      <LeftArrowCircleIcon />
    </div>
  );
};

export const SLIDE_SETTINGS = {
  speed: 500,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
