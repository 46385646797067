export const initCookieState = {
  cookieAccepted: false,
  unnecessary_state: true,
  functional_state: true,
};

const storeKeys = {
  redirectUrl: 'functional_state',
  redirect: 'functional_state',
  connectionAndDeviceTested: 'functional_state',
  viewerToolTip: 'unnecessary_state',
};

export const saveToStore = (name, value) => {
  if (!localStorage || !localStorage.setItem) return;
  const storeState = getFromStore('cookie');
  const currentState = storeState ? JSON.parse(storeState) : { ...initCookieState };
  try {
    if (currentState[storeKeys[name]] || name === 'cookie') {
      localStorage.setItem(name, value);
    }
  } catch (err) {}
};

export const getFromStore = name => {
  if (!localStorage || !localStorage.getItem) return false;
  try {
    return localStorage.getItem(name) || false;
  } catch (err) {
    return false;
  }
};

export const removeFromStore = name => {
  if (!localStorage || !localStorage.removeItem) return;
  try {
    localStorage.removeItem(name);
  } catch (err) {}
};

export const removeAllStore = () => {
  if (!localStorage || !localStorage.clear) return;
  try {
    localStorage.clear();
  } catch (err) {}
};
