import { getImagesSortedSlices, getPrimarySlices } from '../../components/PrePostSlices';

export const getCurrentImageMarksForPreOrPostSlice = (imgURL, images) => {
  const mainMarks = getPrimarySlices(images);
  const prePostSlices = getImagesSortedSlices(images);
  const currentImgIndex = images.findIndex(i => i.url === imgURL);
  const existMarkIndexes = [];
  prePostSlices.forEach((items, curentIndex) => {
    if (items.includes(currentImgIndex)) {
      existMarkIndexes.push(curentIndex);
    }
  });
  const markGeniusData = [];
  existMarkIndexes.forEach(index => {
    if (mainMarks[index] === currentImgIndex) return;
    const markImage = images[mainMarks[index]];
    if (markImage) markGeniusData.push(markImage.geniusAIDataList);
  });
  return markGeniusData?.length ? markGeniusData : null;
};
