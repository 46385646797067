/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useLocation, Link } from 'react-router-dom';
import {
  BookCheckIcon,
  CalendarDaysIcon,
  IconCloseCircle,
  IconInfoOutlined,
  ProductArticleIcon,
  ProductBiopsyIcon,
  ProductBoneCrackIcon,
  ProductBonesIcon,
  ProductCarbonReminderIcon,
  ProductFolderIcon,
  ProductGuidanceLabIcon,
  ProductHeartIcon,
  ProductMammographyIcon,
  ProductMedicalBagIcon,
} from 'shared/components/Icons';
import { SLIDE_SETTINGS } from '../swiperSettings';
import Button from 'shared/components/Button';

const PageIconTypes = {
  folder: <ProductFolderIcon className='shrink-0' />,
  medicalBag: <ProductMedicalBagIcon className='shrink-0' />,
  article: <ProductArticleIcon className='shrink-0' />,
  biopsy: <ProductBiopsyIcon className='shrink-0' />,
  mammography: <ProductMammographyIcon className='shrink-0' />,
  bones: <ProductBonesIcon className='shrink-0' />,
  guidanceLab: <ProductGuidanceLabIcon className='shrink-0' />,
  boneCrack: <ProductBoneCrackIcon className='shrink-0' />,
  carbonReminder: <ProductCarbonReminderIcon className='shrink-0' />,
  heart: <ProductHeartIcon className='shrink-0' />,
};

const DetailsCard = ({ data, activeId, handleNeedHelpClick = () => {}, closeForwardUrl = '/' }) => {
  const location = useLocation();

  const isNotUserPage = data.isNotUserPage;

  const cardContent = (
    <>
      {' '}
      <div className='head-part'>
        <div className='icon-bloc'>{PageIconTypes[data.titleIcon]}</div>
        <h6 className='card-title'>{data.title}</h6>
      </div>
      <div className='bottom-part'>
        {isNotUserPage ? (
          <div className='d-flex'>
            <IconInfoOutlined color='#E2574C' />
            <p className='desc max-row-4'>This product doesn't have courses for your specialty</p>
          </div>
        ) : (
          <div className='desc max-row-4'>{data.description}</div>
        )}
        <div className={`footer-part ${isNotUserPage && 'm-auto'}`}>
          {isNotUserPage ? (
            <Button onClick={handleNeedHelpClick} className='btn-purple-filled btn-need-help'>
              Need Support?
            </Button>
          ) : (
            <>
              <div className='footer-item'>
                <div className='icon-block'>
                  <CalendarDaysIcon />
                </div>
                <span className='key-name'>Assigned:</span>
                <span className='value'>05.09.2019</span>
              </div>
              <div className='footer-item'>
                <div className='icon-block'>
                  <BookCheckIcon />
                </div>
                <span className='key-name'>Courses:</span>
                <span className='value'>13</span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

  return isNotUserPage ? (
    <div className={`page-details-card ${data?.id === activeId ? 'active' : ''} danger`}>
      <Link className='page-close-icon' to={closeForwardUrl}>
        <IconCloseCircle />
      </Link>
      {cardContent}
    </div>
  ) : (
    <Link
      to={`/product/${data?.urlPath}${location.search}`}
      className={`page-details-card ${data?.id === activeId ? 'active' : ''}`}
    >
      {cardContent}
    </Link>
  );
};

const Pages = props => {
  const { headSectionPages, currentPage, hasUserPages, closeForwardUrl } = props;

  const [initialSlide, setInitialSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleNeedHelpClick = () => {
    const section = document.getElementById('question-submit-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    const activeIndex = headSectionPages.findIndex(page => page.id === currentPage?.id);
    setInitialSlide(activeIndex >= 0 ? activeIndex : 0);
  }, [currentPage, headSectionPages]);

  const updatedSlideSettings = {
    ...SLIDE_SETTINGS,
    initialSlide,
  };

  useEffect(() => {
    const navigateToSlide = targetSlide => {
      const effectiveSlideIndex = Math.max(0, targetSlide - (SLIDE_SETTINGS.slidesToShow - 1));

      if (sliderRef.current) {
        sliderRef.current.slickGoTo(effectiveSlideIndex);
      }
    };
    navigateToSlide(initialSlide);
  }, [initialSlide]);

  if (!hasUserPages) return null;

  return (
    <div className={`pages-container ${!hasUserPages && 'empty'}`}>
      <h2 className='section-title'>Product Pages</h2>
      <div className='page-container'>
        {!hasUserPages && (
          <div className='empty-block'>
            <p>You do not have any product pages assigned to you yet</p>
            <Button onClick={handleNeedHelpClick} className='btn-purple-filled btn-need-help'>
              Need Support?
            </Button>
          </div>
        )}
        {hasUserPages && (
          <div className='page-cards-sec'>
            {!!headSectionPages && (
              <Slider ref={sliderRef} {...updatedSlideSettings}>
                {headSectionPages.map((page, idx) => (
                  <DetailsCard
                    handleNeedHelpClick={handleNeedHelpClick}
                    activeId={currentPage?.id}
                    key={idx}
                    data={page}
                    closeForwardUrl={closeForwardUrl}
                  />
                ))}
              </Slider>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Pages;
