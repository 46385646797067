import * as cornerstone from 'cornerstone-core';
import toolStyle from './../stateManagement/toolStyle.js';
import toolColors from './../stateManagement/toolColors.js';
import path from './path.js';

const drawDashedRectAroundShape = (c, { x, y }, width = 74, height = 94) => {
  c.setLineDash([5, 5]);
  c.strokeStyle = '#FF0000';
  c.lineWidth = 2;

  // Calculate top left corner based on center point (x, y)
  const topLeftX = x - width / 2;
  const topLeftY = y - height / 2;

  // Draw rectangle
  c.beginPath();
  c.rect(topLeftX, topLeftY, width, height);
  c.stroke();

  // Reset line dash to solid
  c.setLineDash([]);
};

const drawArrow = (c, { x, y }) => {
  c.setTransform(1, 0, 0, 1, 10, 0);
  c.translate(x - 8, y + 4);
  c.arrow(30, 20, 0, 0, [0, 2, -10, 2, -10, 5]);
};

const drawFlake = (
  c,
  { x, y },
  percent,
  color = '#C8C8C8',
  fillColor = '#C8C8C8',
  borderColor = '#000',
  designator = '',
  isSelected,
) => {
  if (isSelected) {
    drawDashedRectAroundShape(c, { x, y });
  }
  x -= 12;
  y -= 12;
  if (designator) {
    const circleCenterY = y + 38;
    const circleCenterX = x - 7;
    const radius = 10;
    c.beginPath();
    c.arc(circleCenterX, circleCenterY, radius, 0, Math.PI * 2);
    c.fillStyle = fillColor;
    c.fill();
    c.font = '13px Roboto';
    c.fillStyle = borderColor;
    c.fillText(`${designator}`, x - 11, y + 42);
  }
  if (percent) {
    c.font = '16px Roboto';
    c.fillStyle = color;
    c.fillText(`${percent}%`, x + 10, y + 45);
  }

  c.setTransform(1.33333, 0, 0, 1.33333, x, y);
  c.setTransform(1, 0, 0, 1, x, y);
  c.setTransform(1, 0, 0, 1, x + 8.5, y + 8.475);
  c.setTransform(1, 0, 0, 1, x + 10.025, y);
  c.beginPath();
  c.moveTo(0, 0);
  c.lineTo(4, 0);
  c.lineTo(4, 24);
  c.lineTo(0, 24);
  c.closePath();
  c.fillStyle = color;
  c.fill('nonzero');
  c.strokeStyle = borderColor;
  c.stroke();
  c.setTransform(1, 0, 0, 1, x + 8.5, y + 6.719);
  c.beginPath();
  c.moveTo(13.57, -2.5);
  c.lineTo(15.551, 1.108);
  c.lineTo(-6.519, 14.477);
  c.lineTo(-8.5, 10.869);
  c.lineTo(13.57, -2.5);
  c.closePath();
  c.fill('nonzero');
  c.stroke();
  c.beginPath();
  c.moveTo(-6.519, -2.5);
  c.lineTo(-8.5, 1.108);
  c.lineTo(13.57, 14.408);
  c.lineTo(15.551, 10.8);
  c.lineTo(-6.519, -2.5);
  c.closePath();
  c.fill('nonzero');
  c.stroke();
  c.setTransform(1.33333, 0, 0, 1.33333, x, y);
  c.setTransform(1, 0, 0, 1, x, y);
  c.setTransform(1, 0, 0, 1, x + 8.5, y + 8.475);
  c.setTransform(1, 0, 0, 1, x + 10.025, y);
  c.beginPath();
  c.moveTo(1, 2);
  c.lineTo(3, 0);
  c.lineTo(3, 22);
  c.lineTo(1, 22);
  c.closePath();
  c.fillStyle = color;
  c.fill('nonzero');
  c.strokeStyle = 'transparent';
  c.stroke();
  c.stroke();
  c.setTransform(1, 0, 0, 1, x + 8.5, y + 6.719);
  c.beginPath();
  c.moveTo(13.57, 1);
  c.lineTo(15.551, 0.308);
  c.lineTo(-8.519, 12.477);
  c.lineTo(-7.5, 10.869);
  c.lineTo(13.57, -1.7);
  c.closePath();
  c.fill('nonzero');
};

const drawCross = (
  c,
  { x, y },
  percent,
  color = '#C8C8C8',
  fillColor = '#C8C8C8',
  borderColor = '#000',
  designator = '',
  isSelected,
) => {
  if (isSelected) {
    drawDashedRectAroundShape(c, { x, y });
  }
  x -= 12;
  y -= 12;
  if (designator) {
    const circleCenterY = y + 35;
    const circleCenterX = x - 6;
    const radius = 10;
    c.beginPath();
    c.arc(circleCenterX, circleCenterY, radius, 0, Math.PI * 2);
    c.fillStyle = fillColor;
    c.fill();
    c.font = '13px Roboto';
    c.fillStyle = borderColor;
    c.fillText(`${designator}`, x - 10, y + 39);
  }
  if (percent) {
    c.font = '16px Roboto';
    c.fillStyle = color;
    c.fillText(`${percent}%`, x + 12, y + 40);
  }

  c.setTransform(1.33333, 0, 0, 1.33333, x, y);
  c.setTransform(1, 0, 0, 1, x, y);
  c.setTransform(1, 0, 0, 1, x + 5.9, y + 6);
  c.setTransform(1, 0, 0, 1, x + 8.159, y);
  c.beginPath();
  c.moveTo(0, 12);
  c.bezierCurveTo(0, 5.37258, 1.71967, 0, 3.841, 0);
  c.bezierCurveTo(5.96233, 0, 7.682, 5.37258, 7.682, 12);
  c.bezierCurveTo(7.682, 18.62742, 5.96233, 24, 3.841, 24);
  c.bezierCurveTo(1.71967, 24, 0, 18.62742, 0, 12);
  c.fillStyle = fillColor;
  c.fill('nonzero');
  c.strokeStyle = borderColor;
  c.stroke();
  c.setTransform(1, 0, 0, 1, x + 5.9, y + 2.427);
  c.beginPath();
  c.moveTo(18.1, 10.141);
  c.bezierCurveTo(14.52911, 12.50002, 10.37702, 13.82904, 6.1, 13.982);
  c.bezierCurveTo(1.82298, 13.82904, -2.3291, 12.50002, -5.9, 10.141);
  c.bezierCurveTo(-2.32911, 7.78198, 1.82299, 6.45296, 6.1, 6.3);
  c.bezierCurveTo(10.37702, 6.45296, 14.5291, 7.78198, 18.1, 10.141);
  c.closePath();
  c.fill('nonzero');
  c.stroke();
  c.beginPath();
  c.strokeStyle = 'transparent';
  c.moveTo(2, 13);
  c.bezierCurveTo(4, 7.37258, 3.71967, 6, 4.8, 4);
  c.bezierCurveTo(6.96233, 4, 14.682, 7.37258, 7.682, 17);
  c.closePath();
};

const drawTriangle = (
  c,
  { x, y },
  percent,
  color = '#C8C8C8',
  fillColor = '#C8C8C8',
  borderColor = '#000',
  designator = '',
  isSelected,
) => {
  if (isSelected) {
    drawDashedRectAroundShape(c, { x, y });
  }
  const side = 26;
  const h = side * (Math.sqrt(3) / 2);

  if (designator) {
    const circleCenterY = y + 25;
    const circleCenterX = x - 11;
    const radius = 10;
    c.beginPath();
    c.arc(circleCenterX, circleCenterY, radius, 0, Math.PI * 2);
    c.fillStyle = fillColor;
    c.fill();
    c.font = '13px Roboto';
    c.fillStyle = borderColor;
    c.fillText(`${designator}`, x - 15, y + 29);
  }
  if (percent) {
    c.font = '16px Roboto';
    c.fillStyle = color;
    c.fillText(`${percent}%`, x + 3, y + 30);
  }

  c.beginPath();

  c.moveTo(x, y - h / 2);
  c.lineTo(x - side / 2, y + h / 2);
  c.lineTo(x + side / 2, y + h / 2);
  c.closePath();
  c.fillStyle = fillColor;
  c.fill();
  c.strokeStyle = borderColor;
  c.stroke();
};

const shapeMaps = {
  arrow: drawArrow,
  3: drawFlake,
  1: drawCross,
  2: drawTriangle,
};

const drawHandles = (context, evtDetail, handles, options = {}) => {
  const element = evtDetail.element;
  const defaultColor = toolColors.getToolColor();

  context.strokeStyle = options.color || defaultColor;

  const handleKeys = Object.keys(handles);

  for (let i = 0; i < handleKeys.length; i++) {
    const handleKey = handleKeys[i];
    const handle = handles[handleKey];

    if (handle.drawnIndependently === true) {
      continue;
    }

    if (options.drawHandlesIfActive === true && !handle.active) {
      continue;
    }

    const lineWidth = handle.active ? toolStyle.getActiveWidth() : toolStyle.getToolWidth();
    const fillStyle = options.fill;
    const shape = handle.shape || 'arrow';

    path(
      context,
      {
        lineWidth,
        fillStyle,
      },
      context => {
        const handleCanvasCoords = cornerstone.pixelToCanvas(element, handle);
        shapeMaps[shape](
          context,
          handleCanvasCoords,
          handle.percentage,
          options.color,
          options.fillColor,
          options.borderColor,
          handle.designator,
          options.isSelected,
        );
      },
    );
  }
};

export default drawHandles;
