import React from 'react';
import Button from 'shared/components/Button';
import { QuizIcon } from 'shared/components/Icons';
import { QUIZ_ANSWER_STATUS_OBJ, QUIZ_START_DESC_OBJ, QUIZ_STATUSES } from '../constants';

export const QuizStart = ({ handleOpenStartModal, handleContinue, status, isLessonCompleted }) => {
  const isInProgress = status === QUIZ_STATUSES.InProgress;
  return (
    <div className='quiz-start-block'>
      <div className='inner-transparent-block'>
        <QuizIcon className='big-quiz-icon' />
        <p className='quiz-desc'>
          {isLessonCompleted
            ? QUIZ_START_DESC_OBJ.Completed
            : QUIZ_START_DESC_OBJ[QUIZ_ANSWER_STATUS_OBJ[status]] ||
              'Press start to pass the quiz. It is recommended to read the quiz details on the quiz pre-start modal.'}
        </p>
        <Button
          onClick={isInProgress ? handleContinue : handleOpenStartModal}
          className='btn-blue btn-start-quiz'
        >
          {isInProgress ? 'Continue' : 'Start'}
        </Button>
      </div>
    </div>
  );
};
