import csTools from 'cornerstone-tools';
import * as cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools';
// State
import { getToolState, addToolState, removeToolState } from './stateManagement/toolState.js';
import toolColors from './stateManagement/toolColors.js';
// Drawing
import { getNewContext, draw } from './drawing/index.js';
import drawHandles from './drawing/drawHandles.js';
// Utilities
import { probeCursor } from './cursors/index.js';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');

export default class SelectTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'SelectTool',
      stateUseName: 'GenuineTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: probeCursor,
      configuration: {
        drawHandles: true,
      },
    };

    super(props, defaultProps);
    this.touchPressCallback = this._onDBClickPoint.bind(this);
    this.doubleClickCallback = this._onDBClickPoint.bind(this);
  }

  createEventData({ x, y }) {
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        end: {
          x,
          y,
          highlight: true,
          active: true,
        },
      },
    };
  }

  findNearestAnnotationData = (element, currentPoints) => {
    const toolState = getToolState(element, 'GenuineTool');
    if (!toolState || !toolState.data || !toolState.data.length) {
      return null;
    }

    const canvasCoords = cornerstone.pixelToCanvas(element, currentPoints.image);
    let nearestData = null;
    let minDistance = Infinity;
    const NEAR_THRESHOLD = 30; // Set the near threshold to 30 pixels

    toolState.data.forEach(data => {
      const handleCoords = cornerstone.pixelToCanvas(element, data.handles.end);
      const distance = cornerstoneMath.point.distance(handleCoords, canvasCoords);
      if (distance < minDistance && distance <= NEAR_THRESHOLD) {
        // Check if within threshold
        minDistance = distance;
        nearestData = data;
      }
    });

    return nearestData; // This will return null if no data is within NEAR_THRESHOLD
  };

  createNewMeasurement(eventData) {
    // this._selectPoint(eventData);
  }

  // TEMPORARY COMMENTED

  // _selectPoint(eventData) {
  //   const { element } = eventData;

  //   if (
  //     this._configuration.isClickWithinDialog(eventData) ||
  //     this._configuration.isClickWithinTempArea(eventData) ||
  //     this._configuration.isClickWithinAssociationsSwitcherArea(eventData)
  //   ) {
  //     return;
  //   }

  //   document.querySelectorAll('.viewport-element').forEach(el => {
  //     const selectTool = cornerstoneTools.getToolForElement(el, 'SelectTool');
  //     if (selectTool && typeof selectTool.clearSelection === 'function') {
  //       selectTool.clearSelection(el);
  //     }
  //   });

  //   const toolData = getToolState(element, 'GenuineTool');
  //   const nearestData = this.findNearestAnnotationData(element, eventData.currentPoints);
  //   if (!toolData) return;
  //   if (nearestData) {
  //     if (nearestData.handles.end.isActive) {
  //       nearestData.isActive = false;
  //       nearestData.handles.end.isActive = false;
  //     } else {
  //       nearestData.isActive = true;
  //       nearestData.handles.end.isActive = true;
  //     }

  //     for (let i = 0; i < toolData.data.length; i++) {
  //       if (toolData.data[i]?.uuid !== nearestData?.uuid) {
  //         toolData.data[i].handles.end.isActive = false;
  //       }
  //     }
  //   } else {
  //     for (let i = 0; i < toolData.data.length; i++) {
  //       toolData.data[i].handles.end.isActive = false;
  //     }
  //   }
  //   // Force Cornerstone to re-render the image to reflect the updated tool state
  //   cornerstone.updateImage(eventData.element);
  //   this._configuration?.handleGenuineToolStateChange(
  //     element,
  //     eventData.currentPoints,
  //     this._configuration?.index,
  //   );
  // }

  _onDBClickPoint = async evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const { element, currentPoints } = evt.detail;
    const nearestData = this.findNearestAnnotationData(element, currentPoints);

    if (nearestData) {
      this._configuration.openOverlay(nearestData);
    }
  };

  clearSelection(element) {
    const toolData = getToolState(element, 'GenuineTool');
    if (toolData) {
      for (let i = 0; i < toolData.data.length; i++) {
        toolData.data[i].handles.end.isActive = false;
      }
    }
    cornerstone.updateImage(element);
  }

  pointNearTool(element, data, coords) {
    const hasEndHandle = data && data.handles && data.handles.end;
    const validParameters = hasEndHandle;
    if (!validParameters || data.visible === false) {
      return false;
    }
    const probeCoords = cornerstone.pixelToCanvas(element, data.handles.end);
    return cornerstoneMath.point.distance(probeCoords, coords) < 30;
  }

  updateCachedStats(image, element, data) {
    const { onArrowMove } = this.initialConfiguration;
    clearTimeout(this.updateEvent);

    this.updateEvent = setTimeout(() => {
      if (onArrowMove) onArrowMove(image, data);
    }, 500);
  }

  createInitialData(handles) {
    const data = [];
    handles.forEach(item => {
      data.push(this.createEventData(item));
    });
    return this.createEventData(handles[0]);
  }

  addedToolInitialData(target) {
    const { data } = this.initialConfiguration;
    if (this.addedInitialData || !data || !data.length) return;
    data.forEach(item => {
      addToolState(target, this.stateUseName, this.createEventData(item));
    });
    this.addedInitialData = true;
  }

  renderToolData(evt) {
    this.addedToolInitialData(evt.currentTarget);
    const eventData = evt.detail;
    const { handleRadius } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.stateUseName);

    if (!toolData) {
      return;
    }

    const context = getNewContext(eventData.canvasContext.canvas);

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        const color = toolColors.getColorIfActive(data);
        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, {
            handleRadius,
            color,
          });
        }
      });
    }
  }

  _onClickArrow(evt) {
    const { onArrowRemove } = this.initialConfiguration;
    const eventData = evt.detail;
    const { element, currentPoints } = eventData;

    const coords = currentPoints.canvas;
    const toolData = getToolState(element, this.stateUseName);

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      if (this.pointNearTool(element, data, coords)) {
        removeToolState(element, this.stateUseName, data);
        if (onArrowRemove) onArrowRemove(eventData.image, data);
        cornerstone.updateImage(element);

        evt.stopImmediatePropagation();
        evt.preventDefault();
        evt.stopPropagation();

        return;
      }
    }
  }
}
