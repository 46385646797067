import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { getCoordinates } from './utils';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import Loading from 'shared/components/Loading';

const getCirclePostions = (pos, imgEl) => {
  if (!pos || !imgEl || !imgEl.current) return {};
  const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imgEl.current;
  const widthScale = pos.x / naturalWidth;
  const heightScale = pos.y / naturalHeight;
  const res = {
    left: widthScale * clientWidth,
    top: heightScale * clientHeight,
  };
  return res;
};

const HeatmapModal = ({ caseId, lessonId, userLessonId, updateStep, isDisabled, isOpened }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [positions, setPositions] = useState();
  const [fetch, setFetching] = useState();
  const [dicom, setDicom] = useState();
  const imgEl = useRef();

  const selectText = positions
    ? 'Thank you, your selection is submitted.'
    : 'Please select by one mouse click a most probable calcification you may notice.';

  const onMouseDownOnImage = async e => {
    if (positions) return;
    try {
      const cords = getCoordinates(e, imgEl.current);
      const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imgEl.current;
      const widthScale = cords.x / clientWidth;
      const heightScale = cords.y / clientHeight;
      const pos = {
        x: widthScale * naturalWidth,
        y: heightScale * naturalHeight,
      };
      const { caseId, dicomId, imageId } = dicom;
      await Api.post(`/dicom/saveheatmapdata`, { ...pos, userLessonId, caseId, dicomId, imageId });
      setPositions(pos);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const getHeatMapImage = async () => {
    try {
      setFetching(true);
      const { data } = await Api.get(`/cases/getheatmaps/${lessonId}/${caseId}`);
      const dicom = data.data && data.data[0];
      if (!dicom) return;
      const points = await Api.get(`/dicom/lessonheatmapdata/${userLessonId}/${caseId}`);
      const point = points.data.data && points.data.data[0];
      if (!isOpened) await updateStep(11);
      setDicom(dicom);
      if (point) {
        setTimeout(setPositions, 500, point);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getHeatMapImage();
    //eslint-disable-next-line
  }, []);

  if (isDisabled)
    return (
      <div className='p-4 text-center text-white w-100'>
        You must first review all steps of the hanging protocol as indicated below
      </div>
    );

  if (fetch) return <Loading classView='w-100 mt-5' />;

  if (!dicom || isOpened) {
    return (
      <div className='p-4 text-center text-white w-100'>
        This step is not required for this case
      </div>
    );
  }

  return (
    <div className='modal-custom modal__fullscreen modal__fluid modal-heatmap'>
      <div className='control-area control-area px-2 justify-content-center d-flex flex-column'>
        <h5 className='mb-0'>Calcification Selection</h5>
      </div>
      <div className='inner-content heatmap-content'>
        <h4>{selectText}</h4>
        <div className={`image-area text-center ${!positions ? 'has-cursor' : ''}`}>
          <img
            src={dicom && dicom.url}
            width='500'
            alt='img'
            ref={imgEl}
            onMouseDown={onMouseDownOnImage}
          />
          {positions && <div className='circle' style={getCirclePostions(positions, imgEl)} />}
        </div>
      </div>
    </div>
  );
};

export default HeatmapModal;
