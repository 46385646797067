import { WithMouseFollowOrb, useMouseFollowOrb } from 'HOC/WithMouseFollowOrb';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useQuery from 'shared/hooks/useQuery';

const LeftLayout = props => {
  const { description, entityForwardPageKey = 'register' } = props;
  const { urlPath } = useParams();
  const { spec, entity } = useQuery();
  const canvasRef = useMouseFollowOrb();
  const [entityFontSize, setEntityFontSize] = useState(84);

  const charsCount = entity?.length || 1;

  useLayoutEffect(() => {
    const calculateFontSize = () => {
      if (entity) {
        const entityTitleElm = document.getElementById('entity_title');
        const entityTitleWidth = entityTitleElm.offsetWidth;
        const newFontSize = (entityTitleWidth / charsCount) * 1.8;
        setEntityFontSize(newFontSize);
      }
    };

    calculateFontSize();

    window.addEventListener('resize', calculateFontSize);

    return () => window.removeEventListener('resize', calculateFontSize);
  }, []);

  return (
    <div className='product-signin-left-layout-container'>
      <div className='background'>
        <canvas className='canvas-elm' ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      </div>
      <div id='left-side' className='content with-entity'>
        {entity ? (
          <>
            <p className='entity-select-forward'>
              <Link
                to={{
                  pathname: `/product/${urlPath}/entity-select`,
                  search: `?spec=${spec}&entity=${entity}`,
                  state: { entityForwardPageKey },
                }}
              >{`Not a ${entity} User? >`}</Link>
            </p>
            <div className='welcome-sec'>
              <p className='welcome'>Welcome</p>
              <p
                id='entity_title'
                style={{ fontSize: `${entityFontSize}px` }}
                className='left-sec-title'
              >
                {entity}
              </p>
              <p className='user'>User</p>
            </div>
            {description && <p className='description'>{description}</p>}
          </>
        ) : (
          <div className='d-flex flex-column h-100 justify-content-center'>
            <p className='left-sec-title welcome'>Welcome</p>
            <p className='description'>{description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WithMouseFollowOrb(LeftLayout);
