import React, { useEffect, useRef } from 'react';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import Hammer from 'hammerjs';
import Loading from 'shared/components/Loading';

cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

const CanvasOverlay = ({ onClose, children, fullScreen, activeCase, activeStep, isLoading }) => {
  const overlayElement = useRef(null);

  const initOverlay = async () => {
    try {
      await cornerstone.enable(overlayElement.current);
    } catch (err) {
      console.error('Error initializing the overlay:', err);
    }
  };

  useEffect(() => {
    initOverlay();

    return () => {
      if (overlayElement.current) {
        cornerstone.disable(overlayElement.current);
      }
    };
  }, []);

  return (
    <div
      className={`viewer-area d-flex canvas-overlay ${fullScreen ? 'fullscreen' : ''}`}
      key={`${activeCase}_${activeStep}`}
    >
      {isLoading && (
        <div className='loading-overlay'>
          <Loading />
        </div>
      )}
      <div className='overlay-close-container'>
        <button className='overlay-close-button' onClick={onClose}>
          Return
        </button>
      </div>
      {children}
    </div>
  );
};

export default CanvasOverlay;
