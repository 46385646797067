import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { loginUser } from 'app/Auth/actions';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import LeftLayout from 'app/Product/components/LeftLayout';
import Button from 'shared/components/Button';
import RightLayout from 'app/Product/components/RightLayout';
import useQuery from 'shared/hooks/useQuery';
import ButtonLine from 'shared/components/ButtonLine';
import { TOKEN_RESPONSE_CODES } from 'app/Auth/routes/Register/configs';
import CodeVerificationInputs from 'app/Auth/components/CodeVerificationInputs';

const VerifyEmail = ({ match, history, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { urlPath } = match.params;
  const queryParams = useQuery();
  const { description, registeringUserEmail, resetUserEmail } = location?.state || {};

  const { entity, spec = '' } = queryParams;

  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(''));
  const [error, setError] = useState();
  const [fetch, setFetch] = useState();
  const [isResendFetch, setIsResendFetch] = useState();
  const [isCodeExpired, setIsCodeExpired] = useState(false);

  const allInputsFilled = verificationCode.every(code => code !== '');

  const verifCode = verificationCode.join('');

  const qParams = `${entity ? `entity=${entity}` : ''}${
    spec ? `${entity ? '&' : ''}spec=${spec}` : ''
  }`;

  const leftLayoutDetails = {
    entity: entity,
    entityForwardPageKey: 'verify',
    description:
      description ||
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever ",
  };

  const rightLayoutDetails = {
    footerForwardUrl: `/product/${urlPath}/register?${qParams}`,
    question: 'Don’t have an account yet?',
    buttonText: 'Sign Up',
  };

  const handleCodeChange = (idx, val) => {
    const code = [...verificationCode];
    code[idx] = val;
    setVerificationCode(code);
  };

  const handlePaste = pastedArray => {
    setVerificationCode(pastedArray);
  };

  const handleResendCode = async () => {
    try {
      setIsResendFetch(true);
      if (registeringUserEmail) {
        await Api.post('/auth/resend-confirmation-code', {
          email: registeringUserEmail,
        });
        enqueueSnackbar('Successfully sent!', { variant: 'success' });
      }
      if (resetUserEmail) {
        await Api.post('/auth/resend-reset-code', {
          email: resetUserEmail,
        });
        enqueueSnackbar('Successfully sent!', { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
    setIsResendFetch(false);
  };

  const handleSubmit = async () => {
    try {
      setFetch(true);
      if (registeringUserEmail) {
        const res = await Api.post('/auth/confirm-activation/', {
          email: registeringUserEmail,
          code: verifCode,
        });
        await dispatch(loginUser(res.data, history, `/product/${urlPath}?${qParams}`));
      }
      if (resetUserEmail) {
        const res = await Api.post('/auth/confirm-forgot-email', {
          email: resetUserEmail,
          code: verifCode,
        });
        history.push({
          pathname: `/product/${urlPath}/create-password`,
          search: `?${qParams}`,
          state: { resetUserEmail, resetToken: res?.data?.data },
        });
      }
    } catch (err) {
      if (err.response.data.data === TOKEN_RESPONSE_CODES.expiredConfirmationCode) {
        setIsCodeExpired(true);
      }
      setError(getError(err));
    }
    setFetch(false);
  };

  return (
    <div className='product-sign-container d-flex flex-column min-vh-100'>
      <div className='flex-1 d-flex'>
        <LeftLayout {...leftLayoutDetails} />
        <RightLayout {...rightLayoutDetails}>
          <div className='form-block'>
            <div className='form-title-block'>
              <h5 className='desc-title'>Verify Your Email</h5>
              <p className='desc-subtitle'>Please enter the verification code sent to your email</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='d-flex flex-column'>
                <CodeVerificationInputs
                  onEnter={handleSubmit}
                  onCodeChange={handleCodeChange}
                  onPaste={handlePaste}
                />
                {error && <div className='error-text'>{error}</div>}
                {isCodeExpired && <div className='error-text'>The code is expired.</div>}
                <ButtonLine
                  disabled={isResendFetch}
                  hideArrow
                  onClick={handleResendCode}
                  className='forgot-pass-link'
                >
                  Resend Code
                </ButtonLine>
              </div>
              <div className='form-btns-container'>
                <Button
                  onClick={() => history.goBack()}
                  className='btn-outline-blue form-button w-100'
                >
                  Back
                </Button>
                <Button
                  onClick={handleSubmit}
                  className='btn-blue-dark-redesigned form-button w-100'
                  type='submit'
                  disabled={!allInputsFilled || fetch}
                  disabledClassName='btn-disabled-gray'
                >
                  Verify
                </Button>
              </div>
            </form>
          </div>
        </RightLayout>
      </div>
    </div>
  );
};

export default VerifyEmail;
