/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Switch from 'react-switch';

const ToggleLabel = ({ text }) => (
  <div className='custom-switch-label'>
    <span className='text'>{text}</span>
  </div>
);

const Toggle = props => (
  <div
    className='d-flex align-items-center'
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }}
  >
    <ToggleLabel text={`${props.checked ? 'Enabled' : 'Disabled'}`} />
    <Switch
      {...props}
      uncheckedIcon={<span />}
      checkedIcon={<span />}
      height={16}
      width={30}
      onColor='#5aa83b'
      offColor='#fff'
      offHandleColor='#EFF4FB'
      onHandleColor='#eff4fb'
    />
  </div>
);

export default Toggle;
