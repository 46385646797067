/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';

import { getDefaultType } from '../../../configs';
import CanvasView from '../../CanvasView';
import ControlArea from '../../ControlArea';
import { answerTools } from '../configs';
import { createArrayWithIncrementingNumbers } from 'utils/createHelpers';
import { LeftArrow, RightArrow } from 'shared/components/Icons';
import { IconCase, IconDicomSmall } from 'shared/components/Icons';

import step1Icon from 'assets/answers/step1.png';
import step2Icon from 'assets/answers/step2.png';
import step3Icon from 'assets/answers/step3.png';

const mock = [step1Icon, step2Icon, step3Icon];

const AnswerViewer = ({ data, mergedSteps, activeStep, setActiveStep }) => {
  const [activeTool, setActiveTool] = useState({});
  const [fullScreen, setFullScreen] = useState(!!document.fullscreenElement);
  const [isScrollAtStart, setIsScrollAtStart] = useState(true);
  const [isScrollAtEnd, setIsScrollAtEnd] = useState(false);

  const scrollContainerRef = useRef(null);

  const stepsArr = createArrayWithIncrementingNumbers(mergedSteps?.length);

  const isListOverThree = stepsArr?.length > 3;

  const fullScreenChange = () => {
    document.addEventListener('fullscreenchange', e => {
      setFullScreen(!!document.fullscreenElement);
    });
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft - 400,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollContainerRef.current.scrollLeft + 400,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    fullScreenChange();
    //eslint-disable-next-line
  }, []);

  if (!mergedSteps || !mergedSteps[activeStep]) {
    return (
      <div className='text-white p-5 text-center w-100'>
        No answers frame provided for this case
      </div>
    );
  }

  const activeStepData = mergedSteps[activeStep];

  const activeStepIsImage = activeStepData && activeStepData.stepType === 'image';
  const stepViews = mergedSteps[activeStep].views;
  const stepViewsData = Object.keys(stepViews);
  const viewsCount = Object.values(stepViews).length;

  const viewSizesForced = () => {
    return {
      width: (window.innerWidth - (fullScreen ? 548 : 240 + 530)) / viewsCount,
      height: window.innerHeight - 290,
    };
  };

  const getImage = index => {
    try {
      const step = mergedSteps[index];
      const { key, dataType, imageIndex = 0 } = step.views[Object.keys(step.views)[0]];
      const dicomTypeData = {
        tomo: data[`${key}_TOMO`],
        '2D': data[`${key}_2D`],
        '3DQ': data[`${key}_3DQ`],
      };

      const control = getDefaultType(dataType, dicomTypeData);
      const dicomData = dicomTypeData[control];
      return step.stepType === 'image' ? step.views.image : dicomData.images[imageIndex].url;
    } catch (err) {
      return mock[index];
    }
  };

  const getType = index => {
    try {
      const step = mergedSteps[index];
      return step.stepType;
    } catch (err) {
      return mock[index];
    }
  };

  const handleScroll = event => {
    const { scrollWidth, scrollLeft, clientWidth } = event.target;
    const isAtStart = scrollLeft === 0;
    const isAtEnd = scrollLeft + clientWidth === scrollWidth;
    setIsScrollAtStart(isAtStart);
    setIsScrollAtEnd(isAtEnd);
  };

  return (
    <div className='flex-fill'>
      <div className={`viewer d-flex flex-column flex-fill pr-3 max_width_vw-700`}>
        <ControlArea
          fullScreen={fullScreen}
          activeTool={activeTool}
          setActiveTool={tool => setActiveTool(tool)}
          tools={answerTools}
          disabled={activeStepIsImage}
        />
        <div className={`viewer-area d-flex ${fullScreen ? 'fullscreen' : ''}`}>
          {stepViewsData.map((key, index) => {
            const item = stepViews[key];
            if (activeStepIsImage) {
              return (
                <div
                  key={item.key + index}
                  style={{ height: viewSizesForced().height }}
                  className='text-center'
                >
                  <img src={item} alt='img' style={{ maxHeight: '100%' }} />;
                </div>
              );
            }
            return (
              <div key={key}>
                <CanvasView
                  key={item.key + index}
                  dataTomo={data[`${item.key}_TOMO`]}
                  data2D={data[`${item.key}_2D`]}
                  data3DQ={data[`${item.key}_3DQ`]}
                  step={item}
                  activeTool={activeTool}
                  setActiveTool={tool => setActiveTool(tool)}
                  fullScreen={fullScreen}
                  viewsCount={viewsCount}
                  viewSizesForced={viewSizesForced}
                  currentImageIndex={item.imageIndex}
                />
              </div>
            );
          })}
        </div>
        <div className={`answer-viewer-steps scrollable`}>
          <div
            ref={scrollContainerRef}
            onScroll={handleScroll}
            className={`d-flex image-list justify-content-between scollable_image_list`}
          >
            {isListOverThree && (
              <div
                onClick={scrollLeft}
                className={`list_arrow_left ${isScrollAtStart && 'disabled'}`}
              >
                <LeftArrow />
              </div>
            )}
            {stepsArr.map(index => {
              return (
                <div
                  key={index}
                  onClick={() => setActiveStep(index)}
                  className={`position-relative pointer flex-fill ${
                    activeStep === index ? 'active' : ''
                  }`}
                >
                  <div className='position-absolute steps_arrows_block hide_scrollbar'>
                    {
                      <div className='position-absolute birads_step_icon_abs_block no_bg'>
                        {getType(index) === 'case' ? (
                          <IconDicomSmall color={activeStep === index ? '#008DA8' : '#fff'} />
                        ) : (
                          <IconCase color={activeStep === index ? '#008DA8' : '#fff'} />
                        )}
                      </div>
                    }
                  </div>
                  <img className='list_image' src={getImage(index)} alt='img' role='presentation' />
                </div>
              );
            })}
            {isListOverThree && (
              <div
                onClick={scrollRight}
                className={`list_arrow_right ${isScrollAtEnd && 'disabled'}`}
              >
                <RightArrow />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerViewer;
