import React from 'react';
import { Slider } from '@material-ui/core';

const ZoomSlider = ({
  zoomLevel,
  handleZoomChange,
  min,
  max,
  step,
  ariaLabel,
  className,
  hasLabel = false,
}) => {
  return (
    <div className='zoom-actions'>
      <Slider
        value={zoomLevel}
        min={min}
        max={max}
        step={step}
        onChange={handleZoomChange}
        aria-labelledby={ariaLabel}
        className={className}
      />
      {hasLabel && <div className='zoom-slider-label'>{zoomLevel}</div>}
    </div>
  );
};

export default ZoomSlider;
