/* eslint-disable jsx-a11y/no-static-element-interactions */
import { WithMouseFollowOrb, useMouseFollowOrb } from 'HOC/WithMouseFollowOrb';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { IconForward } from 'shared/components/Icons';

const LinkTitleSection = ({ externalLinks }) => {
  const canvasRef = useMouseFollowOrb();
  const filteredExternalLinks =
    !!externalLinks &&
    externalLinks.filter(link => link.title && link.description && link.productLink);
  if (!filteredExternalLinks?.length) return null;

  const goWithLink = (url, e) => {
    e.preventDefault();
    if (!url) return;
    confirmAlert({
      message:
        'A new browser tab will now be opened. You can close it at any time and come back to this tab to continue.',
      buttons: [
        {
          label: 'Continue',
          onClick: () => window.open(url, '_blank'),
        },
        {
          label: 'Close',
        },
      ],
    });
  };

  return (
    <div className='link-title-sec'>
      {filteredExternalLinks.map((link, idx) => {
        const forwardUrl = link.productLink.startsWith('https://')
          ? link.productLink
          : `https://${link.productLink}`;
        return (
          <a
            href={forwardUrl}
            onClick={e => {
              e.preventDefault();
              goWithLink(forwardUrl, e);
            }}
            target='_blank'
            rel='noopener noreferrer'
            key={idx}
            className='content'
          >
            <h3>{link.title}</h3>
            <p className='regular-text-sm desc'>{link.description}</p>
            <IconForward className='forward-icon' />
          </a>
        );
      })}
    </div>
  );
};

export default WithMouseFollowOrb(LinkTitleSection);
