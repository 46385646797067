import React from 'react';
import StarRating from 'shared/components/StarRating';

const FeedRating = ({ index, data, onChange, answers, isDisabled }) => {
  return (
    <li className='d-flex align-items-center'>
      <div className='col-6 pl-0 pt-1'>
        <label className='m-0'>{data.question}</label>
      </div>
      <StarRating
        onClick={value => onChange({ id: index, value, feedbackItemId: data?.id })}
        rate={answers[index] || answers[(data?.id)]}
        isDisabled={isDisabled}
      />
    </li>
  );
};

export default FeedRating;
