import React from 'react';

const TextArea = ({ label, uiType, ...props }) => {
  return (
    <div className={`redesigned-textarea-container ${uiType}`}>
      <label>{label}</label>
      <textarea {...props} />
    </div>
  );
};

export default TextArea;
