const findPosition = oElement => {
  if (typeof oElement.offsetParent != 'undefined') {
    for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
      posX += oElement.offsetLeft;
      posY += oElement.offsetTop;
    }
    return [posX, posY];
  } else {
    return [oElement.x, oElement.y];
  }
};

export const getCoordinates = (e, el) => {
  let PosX = 0;
  let PosY = 0;
  let ImgPos;
  ImgPos = findPosition(el);
  if (!e) e = window.event;
  if (e.pageX || e.pageY) {
    PosX = e.pageX;
    PosY = e.pageY;
  } else if (e.clientX || e.clientY) {
    PosX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
    PosY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
  }
  PosX -= ImgPos[0];
  PosY -= ImgPos[1];
  return { x: PosX, y: PosY };
};
