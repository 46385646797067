import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import Input from 'shared/components/Input';
import Select from 'shared/components/Select';
import TextArea from 'shared/components/TextArea';
import Button from 'shared/components/Button';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import AvatarsGroup from 'assets/product/avatars_group.png';

const categories = [{ title: 'Dosimetry', id: 'Dosimetry' }, { title: 'Oncology', id: 'Oncology' }];
const initialForm = {
  name: '',
  surname: '',
  email: '',
  subject: null,
  text: '',
};

const SendQuestionSection = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSending, setIsSending] = useState();
  const [form, setForm] = useState(initialForm);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSending(true);
    try {
      const body = {
        name: form.name + ' ' + form.surname,
        email: form.email,
        subject: form.subject,
        text: form.text,
      };
      const res = await Api.post('/common/sendemail', body);
      enqueueSnackbar(res?.data?.message || 'Successfully sent!', { variant: 'success' });
      setForm(initialForm);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div id='question-submit-section' className='question-submit-sec'>
      <div className='top-pic-block'>
        <img alt='group' src={AvatarsGroup} />
      </div>
      <div className='title-row'>
        <h3 className='section-title-dark-gray sm'>Still have questions?</h3>
        <p className='section-desc-gray sm mb-0'>
          Can’t find the answer you’re looking for? Please chat to our friendly team.
        </p>
      </div>
      <form onSubmit={handleSubmit} className='form-block'>
        <div className='inputs-row'>
          <Input
            onChange={handleChange}
            label='First Name'
            name='name'
            placeholder='First Name'
            value={form.name}
            required
          />
          <Input
            onChange={handleChange}
            label='Last name'
            placeholder='Last name'
            name='surname'
            value={form.surname}
            required
          />
        </div>
        <Input
          onChange={handleChange}
          label='Email'
          placeholder='your@company.com'
          name='email'
          type='email'
          required
          value={form.email}
        />
        <Select
          onChange={handleChange}
          name='subject'
          topLabel='Category:'
          label='General Enquiry'
          items={categories}
          displayProperty='title'
          className='redesigned-select-container outlined-gray'
          notRequired
        />
        <TextArea
          onChange={handleChange}
          uiType='outlined-gray'
          label='Short Description'
          placeholder='Enter Description'
          name='text'
          required
          value={form.text}
        />
        <Button disabled={isSending} className='btn-purple-filled w-100' type='submit'>
          Send message
        </Button>
      </form>
    </div>
  );
};

export default SendQuestionSection;
