import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Player, BigPlayButton } from 'video-react';
import { IconPlayFill, IconLock, CircleFillIcon } from 'shared/components/Icons';
import { Api } from 'utils/connectors';
import { onCourseUpdate, getCourses } from 'app/Main/routes/Courses/actions';
import { bindDuration, isMobileDevice } from 'utils/appHelpers';
import { LESSON_STATUSES } from 'configs/constants';

let activeTime = 0;
let lastSendTime = 0;
let checkTime = 0;
let videoFinish = false;

const TypeVideo = ({ lesson, course, getCourses, action }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activePart, setActivePart] = useState(lesson.episodes[0]);
  const player = useRef(null);
  const videoSettings = {
    src: lesson.url || lesson.contentUrl,
    fluid: false,
    preload: 'auto',
    height: 465,
  };

  const epsiodeTimes = lesson.episodes.map(item => item.time);

  const navigateToNextLesson = () => {
    const currentLessonIndex = course.lessons.findIndex(l => l.id === lesson.id);
    const nextLesson = course.lessons[currentLessonIndex + 1];
    if (nextLesson) {
      history.push(`/courses/${course.id}/${nextLesson.type}/${nextLesson.id}`);
    }
  };

  const updateLessonsTimeAndEpisode = episodeId => {
    const tempCourse = { ...course };
    const lessonIndex = tempCourse.lessons.findIndex(item => item.id === lesson.id);
    const tempLesson = tempCourse.lessons[lessonIndex];
    const episodeIndex = tempLesson.episodes.findIndex(item => item.id === episodeId);
    const tempEpisode = tempLesson.episodes[episodeIndex];
    tempLesson.completed = activeTime;
    tempEpisode.status = 1;
    if (!videoFinish) dispatch(onCourseUpdate(tempCourse));
  };

  const autoSelectEpisode = ({ currentTime, seeking }) => {
    const videoTime = Math.ceil(currentTime);
    if (!videoTime || videoTime === checkTime || seeking) return;
    let activeKey = 0;
    checkTime = videoTime;
    epsiodeTimes.forEach((item, key) => {
      if (checkTime >= item) activeKey = key;
    });
    updateLessonsTimeAndEpisode(lesson.episodes[activeKey].id);
    setActivePart(lesson.episodes[activeKey]);
  };

  const sendLessonTime = async (time, videoFinished, isSkipEpisode) => {
    const completed = time > lesson.duration ? lesson.duration : time;
    const body = { lessonId: lesson.id, completed, courseId: course.id };
    if (videoFinished) {
      await Api.post('/courses/updateuserlesson', body);
      if (isSkipEpisode) {
        const tempCourse = { ...course };
        const lessonIndex = tempCourse.lessons.findIndex(item => item.id === lesson.id);
        tempCourse.lessons[lessonIndex].status = LESSON_STATUSES.completed;
        const nextLesson = tempCourse.lessons[lessonIndex + 1];
        if (nextLesson) {
          nextLesson.status = LESSON_STATUSES.inProgress;
          dispatch(onCourseUpdate(tempCourse));
        }
      } else {
        await getCourses();
        navigateToNextLesson();
      }
    } else {
      Api.post('/courses/updateuserlesson', body);
    }
  };

  const updateLessonTime = time => {
    const sendTime = Math.ceil(time);
    if (sendTime % 5 === 0 && sendTime > activeTime && sendTime !== lastSendTime) {
      lastSendTime = sendTime;
      sendLessonTime(sendTime);
    }
  };

  const handleStateChange = async nextState => {
    autoSelectEpisode(nextState);
    if (nextState.currentTime > activeTime) {
      if (!nextState.seeking) {
        activeTime = Math.max(activeTime, nextState.currentTime);
        updateLessonTime(activeTime);
        if (Math.ceil(activeTime) === Math.ceil(nextState.duration) && !videoFinish) {
          videoFinish = true;
          await sendLessonTime(Math.ceil(nextState.duration), true);
          navigateToNextLesson();
        }
      } else {
        player.current.seek(activeTime);
      }
    }
  };

  const handlePartSelect = episode => {
    if (episode.status !== 1) return;
    player.current.seek(episode.time);
    setActivePart(episode);
  };

  const lessonStartLog = () => {
    Api.post('/courses/lesson/start', {
      courseId: course.id,
      lessonId: lesson.id,
    });
  };

  useEffect(() => {
    activeTime = lesson.completed || 0;
    const subs = player.current.subscribeToStateChange(handleStateChange);
    const pCurrent = player.current;
    if (action && action === 'auto') {
      pCurrent.play();
    }
    return function cleanup() {
      pCurrent.pause();
      subs();
    };
    //eslint-disable-next-line
  }, [lesson]);

  useEffect(() => {
    activeTime = lesson.completed || 0;
    lastSendTime = 0;
    checkTime = 0;
    videoFinish = false;
  }, [lesson]);

  useEffect(() => {
    lessonStartLog();
    //eslint-disable-next-line
  }, []);

  const episodes = lesson.episodes ? lesson.episodes.filter(i => i.duration) : [];

  return (
    <>
      <div className='video-area flex-1 d-flex justify-content-center'>
        <Player
          className={`${course?.isMarketingMaterial && isMobileDevice() && 'custom-video-player'}`}
          {...videoSettings}
          ref={player}
        >
          <BigPlayButton position='center' />
        </Player>
      </div>
      {course?.isMarketingMaterial && isMobileDevice() && (
        <div className='mobile-marketing-lesson-name-container'>
          <div className='name-block'>
            <CircleFillIcon />
            <div className='text-block'>
              <h2 className='title'>{lesson.title}</h2>
              <p className='description'>{lesson.info}</p>
            </div>
          </div>
        </div>
      )}
      {!(course.isMarketingMaterial && isMobileDevice()) && (
        <div className='course-parts d-flex flex-column'>
          <p className='course-title'>{lesson.name} - Chapters</p>
          <div className='course-parts-lists'>
            {!!episodes &&
              episodes.map((item, index) => {
                const active =
                  item.status === LESSON_STATUSES.inProgress ||
                  item.status === LESSON_STATUSES.completed;
                const Icon = active ? IconPlayFill : IconLock;
                const selected = activePart && activePart.name === item.name ? 'active' : '';
                const disabled = !active ? 'disabled' : '';
                return (
                  <div
                    onClick={() => handlePartSelect(item)}
                    role='button'
                    tabIndex='-1'
                    key={item.name + index}
                    className={`part-item d-flex align-items-center px-3 ${disabled} ${selected}`}
                  >
                    <div className='mr-2'>
                      <Icon />
                    </div>
                    <div>
                      <p className='name'>{item.name}</p>
                      <p className='time'>{bindDuration(item.time)}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default connect(
  null,
  { getCourses },
)(TypeVideo);
