/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useState } from 'react';
import { Api } from 'utils/connectors';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Loading from 'shared/components/Loading';
import Slider from 'react-slick';
import moment from 'moment';
import { getError } from 'utils/appHelpers';
import ResponsiveImage from 'shared/ResponsiveImage';
import { IMAGE_RESIZE_WIDTHS } from 'configs/constants';

const DashBoxEvent = ({ onOpenUrl }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [events, setEvents] = useState();

  const sliderSettings = {
    arrows: false,
    dots: false,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    className: 'home-events-slide',
    autoplay: true,
  };

  const openEventPage = event => {
    // Temporary closed the click for 1st event
    if (event.id === 1) {
      return;
    }
    onOpenUrl(`${window.location.origin}/events/${event.id}`);
  };

  const getEvents = async () => {
    try {
      const { data } = await Api.get('/events/events');
      setEvents(data.data.results);
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  if (!events) return <Loading classView='mt-5' />;

  return (
    <Slider {...sliderSettings}>
      {events.map((event, i) => {
        if (i > 0) return null;
        const isOnGoing =
          moment(event.startDate).diff(new Date()) <= 0 &&
          moment(event.endDate).diff(new Date()) > 0;
        const isExpired = moment(event.endDate).diff(new Date()) <= 0;
        const label = isExpired ? 'Archived' : isOnGoing ? 'Ongoing' : 'Upcoming';
        return (
          <div className='d-flex' key='id' onClick={openEventPage.bind(null, event)}>
            <div className='col-12 p-0 slider-area'>
              <Slider {...sliderSettings}>
                {event.banners.map(item => {
                  return (
                    <div key={item.id}>
                      <ResponsiveImage
                        sizes={IMAGE_RESIZE_WIDTHS.eventBanner}
                        width='618'
                        imgUrl={item.bannerUrl}
                        alt='img'
                      />
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className='col-4 d-flex flex-column justify-content-between event-info-block'>
              <div className='ib-title'>{event.subject}</div>
              <div>
                <h3>
                  Hologic Webinars <br />
                  <p>{label}</p>
                </h3>
                {!isExpired && (
                  <p>
                    {moment(event.startDate).format('MMMM DD')} -{' '}
                    {moment(event.endDate).format('MM/DD/YYYY')}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default DashBoxEvent;
