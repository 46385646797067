import csTools from 'cornerstone-tools';
import * as cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
// State
import { getToolState, addToolState, removeToolState } from './stateManagement/toolState.js';
// Drawing
import { getNewContext, draw } from './drawing/index.js';
import drawHandles from './drawing/drawHandles.js';
// Utilities
import { probeCursor } from './cursors/index.js';

const BaseAnnotationTool = csTools.importInternal('base/BaseAnnotationTool');

export default class GenuineTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'GenuineTool',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      svgCursor: probeCursor,
      configuration: {
        drawHandles: true,
      },
    };

    super(props, defaultProps);
    this.touchPressCallback = this._onDBClickPoint.bind(this);
    this.doubleClickCallback = this._onDBClickPoint.bind(this);
  }

  activeCallback(element, data) {
    this.shape = data.shape;
  }

  disabledCallback(element) {}

  createEventData({ x, y, shape, percentage, designator, id }) {
    return {
      visible: true,
      active: true,
      color: undefined,
      invalidated: true,
      handles: {
        end: {
          percentage: percentage,
          shape: shape || this.shape,
          x,
          y,
          highlight: true,
          active: true,
          designator,
          id,
        },
      },
    };
  }

  createNewMeasurement(eventData) {
    // const { onArrowAdd } = this.initialConfiguration;
    const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;
    if (!goodEventData) {
      return {};
    }

    const currentDataLength = getToolState(eventData.element, this.name)?.data.length;

    setTimeout(() => {
      const toolData = getToolState(eventData.element, this.name);
      if (!toolData || toolData.data?.length === currentDataLength) return;
      const percentage = prompt('Probability (Enter %):');
      this._onUpdatePoint(eventData, percentage, !percentage);
    }, 400);

    // if (onArrowAdd) onArrowAdd(eventData.image, eventData);
    return this.createEventData({ ...eventData.currentPoints.image, percentage: null });
  }

  pointNearTool(element, data, coords) {
    const hasEndHandle = data && data.handles && data.handles.end;
    const validParameters = hasEndHandle;
    if (!validParameters || data.visible === false) {
      return false;
    }
    const probeCoords = cornerstone.pixelToCanvas(element, data.handles.end);
    return cornerstoneMath.point.distance(probeCoords, coords) < 30;
  }

  updateCachedStats(image, element, data) {
    const { onArrowMove } = this.initialConfiguration;
    clearTimeout(this.updateEvent);

    this.updateEvent = setTimeout(() => {
      if (onArrowMove) onArrowMove(image, data);
    }, 500);
  }

  createInitialData(handles) {
    const data = [];
    handles.forEach(item => {
      data.push(this.createEventData(item));
    });
    return this.createEventData(handles[0]);
  }

  addedToolInitialData(target) {
    const { data } = this.initialConfiguration;
    if (this.addedInitialData || !data || !data.length) return;
    data.forEach(item => {
      addToolState(target, this.name, this.createEventData(item));
    });
    this.addedInitialData = true;
  }

  renderToolData(evt) {
    this.addedToolInitialData(evt.currentTarget);
    const eventData = evt.detail;
    const { handleRadius } = this.configuration;
    const toolData = getToolState(evt.currentTarget, this.name);

    if (!toolData) {
      return;
    }

    const context = getNewContext(eventData.canvasContext.canvas);

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      const color = '#C8C8C8';
      const borderColor = '#000';
      const fillColor = '#C8C8C8';

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        if (this.configuration.drawHandles) {
          drawHandles(context, eventData, data.handles, {
            handleRadius,
            color,
            fill: fillColor,
            borderColor,
            isSelected: data?.handles?.end?.isActive,
          });
        }
      });
    }
  }

  _onDBClickPoint(evt) {
    const percentage = prompt('Update Probability (Enter %):');
    const eventData = evt.detail;

    this._onUpdatePoint(eventData, percentage, !percentage);

    evt.stopImmediatePropagation();
    evt.preventDefault();
    evt.stopPropagation();

    return;
  }

  _onUpdatePoint(eventData, percentage, isRemove) {
    const { onArrowMove, onArrowRemove } = this.initialConfiguration;
    const { element, currentPoints } = eventData;

    const coords = currentPoints.canvas;
    const toolData = getToolState(element, this.name);

    // Now check to see if there is a handle we can move
    if (!toolData) {
      return;
    }

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];
      if (this.pointNearTool(element, data, coords)) {
        if (isRemove) {
          removeToolState(element, this.name, data);
          if (onArrowRemove) onArrowRemove(eventData.image, data);
        } else {
          data.handles.end.percentage = percentage;
          if (onArrowMove) onArrowMove(eventData.image, data);
        }

        cornerstone.updateImage(element);

        return;
      }
    }
  }
}
