import React from 'react';
import { Link } from 'react-router-dom';
import placeholder from 'assets/case_placeholder.jpg';
import {
  CircleFillIcon,
  IconPlayFill,
  VideoPlayCircleNotAllowedIcon,
} from 'shared/components/Icons';
import { hasAccess } from 'utils/permissionHelper';
import { getEventUrl, isMobileDevice } from 'utils/appHelpers';

const TypeCases = ({ lesson, course }) => {
  const path = hasAccess('event_user') ? getEventUrl() : '';
  const isLinkActive = !(course?.isMarketingMaterial && isMobileDevice());

  return (
    <>
      <div className='case-part text-center'>
        {isLinkActive ? (
          <Link to={`${path}/courses/${course.id}/cases/${lesson.id}/play`} className='play-btn'>
            <IconPlayFill />
          </Link>
        ) : (
          <VideoPlayCircleNotAllowedIcon className='absolute-centered-video-play' />
        )}
        <img height='100%' src={lesson.image || placeholder} alt='placeholder' />
      </div>
      {!isLinkActive && (
        <div className='mobile-marketing-lesson-name-container'>
          <div className='name-block'>
            <CircleFillIcon />
            <div className='text-block'>
              <h2 className='title'>{lesson.title}</h2>
              <p className='description'>{lesson.info}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TypeCases;
