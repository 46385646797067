/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LinearProgress } from '@material-ui/core';
import { COURSE_TYPES, DEFAULT_IMG_URLS } from 'configs/constants';
import Loading from 'shared/components/Loading';
import { getDate, getError } from 'utils/appHelpers';
import { SearchInput } from 'shared/components/SearchInput';
import ReactPaginate from 'react-paginate';
import { Api } from 'utils/connectors';
import CoursesProgressCircle from './CoursesProgressCircles';
import Button from 'shared/components/Button';
import FolderOpenImg from 'assets/product/folder-open.png';
import NotFoundImage from 'assets/product/not-found.png';
import Select from 'shared/components/Select';
import ResponsiveImage from 'shared/ResponsiveImage';

export const SPECIALTIES = [
  { name: 'Surgery', value: 'Surgery' },
  { name: 'Radiology', value: 'Radiology' },
  { name: 'Fellowship in Radiology', value: 'Fellowship in Radiology' },
  { name: 'Oncology', value: 'Oncology' },
  { name: 'Radiation Oncology', value: 'Radiation Oncology' },
  { name: 'Medical Physics', value: 'Medical Physics' },
  { name: 'Internal Medicine', value: 'Internal Medicine' },
  { name: 'Dosimetry', value: 'Dosimetry' },
  { name: 'Other', value: 'Other' },
  { name: 'All Specialties', value: 'all' },
];

const PRODUCT_COURSE_TAGS = [{ id: 1, name: 'Required' }, { id: 2, name: 'Not Required' }];

const getFirstLessonPath = course => {
  const firstLesson = course?.courseLessons?.[0]?.lesson;
  return `/courses/${course.id}/${COURSE_TYPES[(firstLesson?.contentType)]}/${firstLesson?.id}`;
};

const NO_PAGE_DETAILS = {
  noPage: {
    title: <h5 className='section-title'>This product doesn't have courses for your specialty</h5>,
    description: (
      <>
        <p className='desc'>
          Please <span className='bold'>return later</span> to check.
        </p>
        <p className='desc'>
          If you feel you appear here because of some problem then{' '}
          <span className='bold'>contact your Entity Manager.</span>
        </p>
      </>
    ),
  },
  noAccess: {
    title: <h5 className='section-title'>You don’t have access to this Product page</h5>,
    description: (
      <>
        <p className='desc'>
          For <span className='bold'>accessing</span> this program you should be part of an Entity.
        </p>
        <p className='desc'>
          Ask your <span className='bold'>Organization Manager</span> to add you as a{' '}
          <span className='bold'>member of the Entity</span> before accessing this page.
        </p>
      </>
    ),
  },
};

const SpecialistCourses = props => {
  const { currentPage, userHasEntity, productId, userSpecialty } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [activeSearchTags, setActiveSearchTags] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [courses, setCourses] = useState(null);
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [requiredCourses, setRequiredCourses] = useState([]);
  const [selectedSpecialtyForCourses, setSelectedSpecialtyForCourses] = useState(userSpecialty);

  const isUserSpecialtyChosen = selectedSpecialtyForCourses === userSpecialty;

  const specialtiesBesidesUsers = SPECIALTIES.filter(
    spec => spec?.name?.toLowerCase() !== userSpecialty?.toLowerCase(),
  );

  const noPage = !currentPage?.id;
  const noAccess = !userHasEntity;
  const noPageDetailsKey = noAccess ? 'noAccess' : 'noPage';
  const params = { page, search };
  const userCoursesForProgresses = requiredCourses ? requiredCourses.map(course => course) : [];

  const getCourses = async (tags, specialties) => {
    try {
      setIsFetching(true);
      const reqParams = { ...params };

      if (tags[0]) {
        reqParams.required = tags[0] === 1;
      }

      if (specialties?.length > 0) {
        reqParams.specialty = specialties.join(',');
        reqParams.all_users = specialties?.[0] !== userSpecialty;
      }

      const { data } = await Api.get(`/product/user-product/${productId}`, { params: reqParams });

      if (data?.data) {
        setCourses(data.data.courses.results || []);
        setPageCount(data.data.courses.pageCount);
        setPage(data.data.courses.currentPage);
        if (Array.isArray(data?.data?.required)) {
          setRequiredCourses(
            data.data.required.map(reqCourse => ({
              ...reqCourse,
              progress:
                data.data.courses.results.find(course => course.id === reqCourse.id)?.userCourse
                  ?.progress || 0,
            })),
          );
        }
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const handleGetUserSpecialtyCourses = () => {
    const all = specialtiesBesidesUsers[specialtiesBesidesUsers.length - 1];
    setSelectedSpecialtyForCourses([all]);
    setSelectedSpecialtyForCourses(userSpecialty);
    getCourses(activeSearchTags, [userSpecialty]);
  };

  const handleGetOtherSpecialtiesCourses = () => {
    const updatingSpecialties = specialtiesBesidesUsers.map(item => item.value).slice(0, -1);
    const all = specialtiesBesidesUsers[updatingSpecialties.length - 1];
    setSelectedSpecialtyForCourses(all);
    getCourses(activeSearchTags, updatingSpecialties);
  };

  const handleChangeSpecialty = ({ target }) => {
    let updatingSpecialties = [target.value];
    setSelectedSpecialtyForCourses(target.value);
    if (target.value === 'all' || !target.value) {
      updatingSpecialties = specialtiesBesidesUsers.map(item => item.value).slice(0, -1);
    }
    getCourses(activeSearchTags, updatingSpecialties);
  };

  const handleToggleSelectedTags = id => {
    if (activeSearchTags.includes(id)) {
      setActiveSearchTags([]);
    } else {
      setActiveSearchTags([id]);
    }
  };

  const handleSearch = e => setSearch(e.target.value);

  const handlePageClick = selectedItem => {
    setPage(selectedItem.selected + 1);
  };

  const handleNeedHelpClick = () => {
    const section = document.getElementById('question-submit-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (productId) getCourses(activeSearchTags, [selectedSpecialtyForCourses]);
  }, [productId, activeSearchTags, page, debouncedSearch, currentPage]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  return (
    <>
      {(noPage || noAccess) && (
        <div className='no-access-block'>
          <div className='text-block'>
            {NO_PAGE_DETAILS[noPageDetailsKey].title}
            <div className='line' />
            {NO_PAGE_DETAILS[noPageDetailsKey].description}
            <Button onClick={handleNeedHelpClick} className='btn-purple-filled btn-need-help'>
              Need Support?
            </Button>
          </div>
          <div className='img-block'>
            <img alt='not found' src={NotFoundImage} />
          </div>
        </div>
      )}
      <>
        <div className='required-courses-section'>
          <div className='title-row'>
            <h2 className='section-title'>Required Courses</h2>
            <p className='regular-text'>
              Depending on your age, the Well Woman exam may cover different health topics. Let’s
              take a look at what you can expect.
            </p>
          </div>
          {isUserSpecialtyChosen && !isFetching && (
            <CoursesProgressCircle coursesForProgresses={userCoursesForProgresses} />
          )}
          <div className='specialist-courses-sec'>
            <div className='top-row'>
              <div className='left-block'>
                <div
                  onClick={handleGetUserSpecialtyCourses}
                  className={`course-specialty-select-item ${selectedSpecialtyForCourses ===
                    userSpecialty && 'active'}`}
                >
                  My Courses
                </div>
                <div
                  onClick={handleGetOtherSpecialtiesCourses}
                  className={`course-specialty-select-item ${selectedSpecialtyForCourses !==
                    userSpecialty && 'active'}`}
                >
                  Other Specialties
                </div>
              </div>
              <div className='search-block'>
                <div className='search-types-block'>
                  {PRODUCT_COURSE_TAGS.map((tag, idx) => (
                    <div
                      key={idx}
                      onClick={() => handleToggleSelectedTags(tag.id)}
                      className={`search-type-item ${activeSearchTags.includes(tag.id) &&
                        'active'}`}
                    >
                      {tag?.name}
                    </div>
                  ))}
                  <Select
                    hasIconLeftLine
                    hasCustomRightIcon
                    className='redesigned-select-purple-filled'
                    items={specialtiesBesidesUsers}
                    onChange={handleChangeSpecialty}
                    useProperty='value'
                    displayProperty='name'
                    label='Choose...'
                  />
                </div>
                <SearchInput
                  onEnter={() => getCourses(activeSearchTags)}
                  onChange={handleSearch}
                  value={search}
                  placeholder='Search in materials'
                />
              </div>
            </div>
            <div className='product-courses-block'>
              {!courses || isFetching ? (
                <Loading classView='mt-5 m-auto' />
              ) : courses.length ? (
                courses.map((course, index) => (
                  <Link
                    onClick={() => Api.get(`/courses/check-to-enroll/${course.id}`)}
                    to={getFirstLessonPath(course)}
                    key={index}
                    className='landing-course-item'
                  >
                    <div className='img_block'>
                      <ResponsiveImage
                        className='course-image'
                        alt='course banner'
                        imgUrl={course.imageUrl || DEFAULT_IMG_URLS.course}
                      />
                      {course.userCourse?.progress !== undefined && isUserSpecialtyChosen && (
                        <div className='linear-progress-block'>
                          <LinearProgress
                            variant='determinate'
                            value={course.userCourse?.progress || 0}
                            className='progress-item'
                          />
                        </div>
                      )}
                    </div>
                    <div className='title-block'>
                      <div className='course-title'>{course.title}</div>
                      <div className='create-date'> {getDate(course.createdAt)}</div>
                    </div>
                    <div className='course-description'>{course.info}</div>
                  </Link>
                ))
              ) : (
                <div className='no-courses-block'>
                  <img className='no-result-img' alt='no-result' src={FolderOpenImg} />
                  <p className='desc'>There is no courses to be shown</p>
                  <p className='desc-2'>
                    Try checking other specialties or change the used filters
                  </p>
                </div>
              )}
            </div>
            <ReactPaginate
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              containerClassName={'landing-pagination'}
              activeClassName={'active'}
              forcePage={page - 1}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default SpecialistCourses;
