import React from 'react';
import { NavLink } from 'react-router-dom';

const SubMenuSubscriptions = ({ path }) => (
  <ul className='sub-menu authors mt-2'>
    <li>
      <NavLink exact={true} to={`${path}/subscriptions`} activeClassName='active'>
        My Subscriptions
      </NavLink>
    </li>
    <li>
      <NavLink exact={true} to={`${path}/subscriptions/all`} activeClassName='active'>
        Available Plans
      </NavLink>
    </li>
  </ul>
);

export default SubMenuSubscriptions;
