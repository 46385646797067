import React, { useEffect } from 'react';
import Switcher from './Switcher';
import { IconAbout } from 'shared/components/Icons';
import checkIcon from 'assets/arrows/3p.svg';
import StepLeftIcon from 'assets/arrows/stepLeft.svg';
import StepRightIcon from 'assets/arrows/stepRight.svg';

const StepsArea = ({
  cases,
  steps,
  completedSteps,
  activeCase,
  activeStep,
  changeActiveCase,
  changeActiveStep,
  setActiveStep,
  onHelpModalOpen,
}) => {
  const isLastCase = activeCase === cases.length - 1;
  const isLastStep = activeStep === steps.length - 1;
  const highlightNextCase = isLastStep && !isLastCase;

  const handleStepChange = bool => {
    setActiveStep(current => {
      const disabledPrev = current - 1 < 0;
      const disabledNext = Number(current) + 2 > steps.length;
      if ((bool && !disabledNext) || (!bool && !disabledPrev)) {
        changeActiveStep(current - (bool ? -1 : 1));
      }
      if (bool && disabledNext && !isLastCase) {
        handleCaseChange(true);
      }
      return current;
    });
  };

  const handleCaseChange = bool => {
    changeActiveCase(activeCase - (bool ? -1 : 1));
    changeActiveStep(0);
  };

  const disabledPrev = () => {
    return activeStep - 1 < 0;
  };

  // const goToFeedback = () => {
  //   history.push(getFeedbackUrl(reduxCourse));
  // };

  const handleUserKeyPress = event => {
    const { keyCode } = event;
    if (keyCode === 37) handleStepChange(false);
    if (keyCode === 39) handleStepChange(true);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className='steps-area px-2 align-items-center justify-content-between d-flex'>
      <div className='help-block d-flex justify-content-center align-items-center'>
        <button className='btn btn-sm' onClick={() => onHelpModalOpen(true)}>
          <IconAbout className='mr-3' />
          Help
        </button>
      </div>
      <div className='view-steps d-flex switcher'>
        <button
          className='btn'
          onClick={handleStepChange.bind(null, false)}
          disabled={disabledPrev()}
        >
          <img src={StepLeftIcon} alt='icon' className='sw-icon' />
        </button>
        {steps.map((item, index) => {
          const isActive = completedSteps.includes(index);
          const isExplanator = item.name === 'explanator';
          // const isHeatmap = item.name === 'heatmap';
          const isNeedToDone =
            activeStep >= 9 &&
            completedSteps.length < 10 &&
            !isActive &&
            activeStep !== index &&
            !isExplanator;
          return (
            <button
              className={`btn text-white btn-sm position-relative ${
                activeStep === index ? 'active' : ''
              } ${isNeedToDone ? 'border-danger' : ''}`}
              key={item.name}
              onClick={() => changeActiveStep(index)}
            >
              {isActive && <img className='step-check-icon' src={checkIcon} alt='check' />}
              {item.icon && (
                <img
                  height='30'
                  src={activeStep === index ? item.iconActive : item.icon}
                  alt={item.name}
                />
              )}
            </button>
          );
        })}
        {/* <button className='btn text-white btn-sm position-relative' onClick={() => goToFeedback()}>
          <IconFeedback />
        </button> */}
        <button
          className='btn'
          onClick={handleStepChange.bind(null, true)}
          disabled={Number(activeStep) + 2 > steps.length}
        >
          <img src={StepRightIcon} alt='icon' className='sw-icon' />
        </button>
      </div>
      <Switcher
        name='Case'
        previews={handleCaseChange.bind(null, false)}
        next={handleCaseChange.bind(null, true)}
        current={activeCase}
        allCount={cases.length}
        highlightNext={highlightNextCase}
      />
    </div>
  );
};

export default StepsArea;
