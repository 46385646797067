import Main from 'app/Main';
import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import EventLanding from './routes/EventLanding';
import EventLogin from './routes/EventLogin';
import EventRegister from './routes/EventRegister';
import { WithTitleRoute, hasAccess } from 'utils/permissionHelper';
import EventEmailVerification from './routes/EventEmailVerification';

const Events = ({ match }) => {
  const isAuthUser = useSelector(state => state.isAuthenticated);
  const isVisitorUser = hasAccess('visitor_user');
  const isAuth = isAuthUser && !isVisitorUser;
  const { path } = match;

  return (
    <div className='events'>
      <Switch>
        {!isAuth && <WithTitleRoute path={`${path}/:name/login`} component={EventLogin} />}
        {!isAuth && <WithTitleRoute path={`${path}/:name/register`} component={EventRegister} />}
        {!isAuth && (
          <WithTitleRoute
            path={`${path}/:name/email-verification`}
            component={EventEmailVerification}
          />
        )}
        {isAuth && <WithTitleRoute path={`${path}/:eventName/dashboard`} component={Main} />}
        <WithTitleRoute path={`${path}/:name?`} component={EventLanding} />
        <Redirect from='*' to={`${path}/`} />
      </Switch>
    </div>
  );
};

export default Events;
