/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import ResponsiveImage from 'shared/ResponsiveImage';
import { IconCirclePlay, IconLock } from 'shared/components/Icons';

const EventCME = ({ event }) => {
  const openItemUrl = url => {
    window.open(url, '_blank');
  };

  return (
    <div className='event-cme' id='cmeSection'>
      <div className='container'>
        <h1 className='text-right large-title'>
          <span>CME</span>
          Courses
        </h1>
        <div className='event-cme-list d-flex justify-content-between'>
          {event.cmEs &&
            event.cmEs.map(item => {
              const Icon = false ? IconLock : IconCirclePlay;
              return (
                <div className='cme-block d-flex flex-column' key={item.id}>
                  <div className='text-center bg-secondary img-area'>
                    <div
                      className='icon-area pointer d-flex justify-content-center align-items-center'
                      onClick={openItemUrl.bind(null, item.externalUrl)}
                    >
                      <Icon />
                    </div>
                    <ResponsiveImage imgUrl={item.imageUrl} height='190' alt='o' />
                  </div>
                  <div className='info-area flex-1 d-flex flex-column justify-content-between'>
                    <p className='title pointer' onClick={openItemUrl.bind(null, item.externalUrl)}>
                      {item.title}
                    </p>
                    <p className='info'>{item.description}</p>
                    <a href={item.externalUrl} target='_blank' rel='noopener noreferrer'>
                      Open
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
        <p className='cme-text'>
          World Class CME is accredited by the Accreditation Council for Continuing Medical
          Education for Physicians and designates this activity for a maximum of 1 AMA PRA Category
          1 Credit™. Attendees should claim only the credit commensurate with the extent of their
          participation in the activity. This activity is supported by an educational grant from
          Hologic.
        </p>
      </div>
    </div>
  );
};

export default EventCME;
