import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import Button from 'shared/components/Button';
import { Api } from 'utils/connectors';
import { getError } from 'utils/appHelpers';
import { loginUser } from '../../../Auth/actions';

import topLogo from 'assets/ACE-logo-blue.svg';
import CodeVerificationInputs from 'app/Auth/components/CodeVerificationInputs';
import { TOKEN_RESPONSE_CODES } from 'app/Auth/routes/Register/configs';
import EventHeader from '../EventLanding/components/EventHeader';

const EventEmailVerification = ({
  loginUser,
  history,
  location,
  match,
  title = 'Confirmation Code',
  subTitle = 'Please enter the code which we have sent to your email.',
  className = '',
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { name } = match.params;
  const [verificationCode, setVerificationCode] = useState(Array(6).fill(''));
  const [user, setUser] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState();
  const [isCodeExpired, setIsCodeExpired] = useState(false);

  const regCode = match.params.code;
  const registeringUserEmail = location?.state?.email;
  const isForgotPassword = location?.state?.isForgotPassword;

  const allInputsFilled = verificationCode.every(code => code !== '');

  const getUserdata = async code => {
    try {
      const { data } = await Api.get(`/auth/getinviteuser/${code}`);
      if (data.data.invitation.email) {
        setUser({
          fname: data.data.invitation.firstName,
          lname: data.data.invitation.lastName,
          email: data.data.invitation.email,
        });
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
      history.push('/login');
    }
  };

  const handleCodeChange = (idx, val) => {
    const code = [...verificationCode];
    code[idx] = val;
    setVerificationCode(code);
  };

  const handlePaste = pastedArray => {
    setVerificationCode(pastedArray);
  };

  const handleResendCode = async () => {
    try {
      await Api.post('/auth/resend-confirmation-code', {
        email: user?.email || registeringUserEmail,
      });
      enqueueSnackbar('Successfully sent!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const handleSubmit = async () => {
    try {
      setIsFetching(true);
      if (isForgotPassword) {
        const res = await Api.post('/auth/confirm-forgot-email', {
          email: user?.email || registeringUserEmail,
          code: verificationCode?.join(''),
        });
        if (res.status === 200) {
          history.push({ pathname: `/auth/reset/${res.data.data}` });
        }
      } else {
        const res = await Api.post('/auth/confirm-activation/', {
          email: user?.email || registeringUserEmail,
          code: verificationCode?.join(''),
        });
        await loginUser(res.data, history, `/events/${name}`);
      }
    } catch (err) {
      if (err.response.data.data === TOKEN_RESPONSE_CODES.expiredConfirmationCode) {
        setIsCodeExpired(true);
      }
      setError(getError(err));
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (regCode) getUserdata(regCode);
  }, [regCode]);

  return (
    <div className='event-view d-flex flex-column min-vh-100'>
      <EventHeader name={name} />
      <div className='email_verify_container align-items-center large d-flex flex-column justify-content-center py-5 position-relative gap_40'>
        <div>
          <Link to='/login'>
            <img src={topLogo} className='mt-5' width='100%' height='55' alt='topLogo' />
          </Link>
        </div>
        <div className='align-items-center d-flex flex-column'>
          <div className={`${className || ''}`}>
            <h1 className='title'>{title}</h1>
            <p className='sub_title'>{subTitle}</p>
          </div>
          <CodeVerificationInputs
            onEnter={handleSubmit}
            onCodeChange={handleCodeChange}
            onPaste={handlePaste}
          />
          {error && <div className='error-text'>{error}</div>}
          {isCodeExpired && (
            <div className='error-text'>{'Click "Resend Code" below to get the new one.'}</div>
          )}
          <div className='text-center mt-3 w-100 pl-4 pr-4'>
            <Button
              className='btn btn-blue-dark w-100'
              to={'/login'}
              onClick={handleSubmit}
              disabled={!allInputsFilled || isFetching}
            >
              Submit
            </Button>
          </div>
          <div className='text-center mt-3'>
            <Button className='btn btn-no-outline resend_btn' onClick={handleResendCode}>
              Resend Code
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  null,
  { loginUser },
)(EventEmailVerification);
