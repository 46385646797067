import step1Icon from 'assets/stepsnew/1n.svg';
import step1IconActive from 'assets/stepsnew/1s.svg';
import step1IconPassed from 'assets/stepsnew/1p.svg';
import step1IconPActive from 'assets/stepsnew/1ps.svg';

import step2Icon from 'assets/stepsnew/2n.svg';
import step2IconActive from 'assets/stepsnew/2s.svg';
import step2IconPassed from 'assets/stepsnew/2p.svg';
import step2IconPActive from 'assets/stepsnew/2ps.svg';

import step3Icon from 'assets/stepsnew/3n.svg';
import step3IconActive from 'assets/stepsnew/3s.svg';
import step3IconPassed from 'assets/stepsnew/3p.svg';
import step3IconPActive from 'assets/stepsnew/3ps.svg';

import step4Icon from 'assets/stepsnew/4n.svg';
import step4IconActive from 'assets/stepsnew/4s.svg';
import step4IconPassed from 'assets/stepsnew/4p.svg';
import step4IconPActive from 'assets/stepsnew/4ps.svg';

import step5Icon from 'assets/stepsnew/5n.svg';
import step5IconActive from 'assets/stepsnew/5s.svg';
import step5IconPassed from 'assets/stepsnew/5p.svg';
import step5IconPActive from 'assets/stepsnew/5ps.svg';

import step6Icon from 'assets/stepsnew/6n.svg';
import step6IconActive from 'assets/stepsnew/6s.svg';
import step6IconPassed from 'assets/stepsnew/6p.svg';
import step6IconPActive from 'assets/stepsnew/6ps.svg';

import step7Icon from 'assets/stepsnew/7n.svg';
import step7IconActive from 'assets/stepsnew/7s.svg';
import step7IconPassed from 'assets/stepsnew/7p.svg';
import step7IconPActive from 'assets/stepsnew/7ps.svg';

import step8Icon from 'assets/stepsnew/8n.svg';
import step8IconActive from 'assets/stepsnew/8s.svg';
import step8IconPassed from 'assets/stepsnew/8p.svg';
import step8IconPActive from 'assets/stepsnew/8ps.svg';

import step9Icon from 'assets/stepsnew/9n.svg';
import step9IconActive from 'assets/stepsnew/9s.svg';
import step9IconPassed from 'assets/stepsnew/9p.svg';
import step9IconPActive from 'assets/stepsnew/9ps.svg';

import step11Icon from 'assets/stepsnew/11n.svg';
import step11IconActive from 'assets/stepsnew/11s.svg';
import step11IconPassed from 'assets/stepsnew/11p.svg';
import step11IconPActive from 'assets/stepsnew/11ps.svg';

// import step10Icon from 'assets/steps/11p.svg';
// import step10IconActive from 'assets/steps/11a.svg';
import step12Icon from 'assets/stepsnew/12n.svg';
import step12IconActive from 'assets/stepsnew/12s.svg';
import step12IconPassed from 'assets/stepsnew/12p.svg';
import step12IconPActive from 'assets/stepsnew/12ps.svg';
import tool14Icon from 'assets/tools/14p.svg';
import tool14IconActive from 'assets/tools/14a.svg';
import tool15Icon from 'assets/tools/15p.svg';

import heatmapIcon from 'assets/stepsnew/heatmapn.svg';
import heatmapIconActive from 'assets/stepsnew/heatmaps.svg';
import heatmapIconPassed from 'assets/stepsnew/heatmaps.svg';
import heatmapIconPActive from 'assets/stepsnew/heatmaps.svg';

import tool1Icon from 'assets/tools/1p.svg';
import tool1IconActive from 'assets/tools/1a.svg';
import tool2Icon from 'assets/tools/2p.svg';
import tool2IconActive from 'assets/tools/2a.svg';
// import tool3Icon from 'assets/tools/3p.svg';
// import tool3IconActive from 'assets/tools/3a.svg';
import tool4Icon from 'assets/tools/4p.svg';
import tool4IconActive from 'assets/tools/4a.svg';
import tool5Icon from 'assets/tools/5p.svg';
import tool5IconActive from 'assets/tools/5a.svg';
import tool6Icon from 'assets/tools/6p.svg';
import tool6IconActive from 'assets/tools/6a.svg';
import tool7Icon from 'assets/tools/7p.svg';
import tool7IconActive from 'assets/tools/7a.svg';
import tool8Icon from 'assets/tools/8p.svg';
import tool8IconActive from 'assets/tools/8a.svg';
import tool9Icon from 'assets/tools/9p.svg';
import tool9IconActive from 'assets/tools/9a.svg';
import tool10Icon from 'assets/tools/10p.svg';
import tool10IconActive from 'assets/tools/10p.svg';

import { IconGenuineCross, IconGenuineFlake, IconGenuineTriangle } from 'shared/components/Icons';

export const genuieShapes = [
  { icon: IconGenuineTriangle, shape: 2 },
  { icon: IconGenuineCross, shape: 1 },
  { icon: IconGenuineFlake, shape: 3 },
];

export const steps = [
  {
    id: 1,
    name: '2D CC/MLO Views',
    icon: step1Icon,
    iconActive: step1IconActive,
    iconPassed: step1IconPassed,
    iconPassedActive: step1IconPActive,
    views: {
      RCC: {
        number: 1,
        active: true,
        position: 'right',
        thumbpos: 'left',
        key: 'RCC',
        has_switcher: true,
        dataType: '2D',
      },
      LCC: {
        number: 2,
        active: true,
        position: 'left',
        thumbpos: 'right',
        key: 'LCC',
        has_switcher: true,
        dataType: '2D',
      },
      RMLO: {
        number: 3,
        active: true,
        position: 'right',
        thumbpos: 'left',
        key: 'RMLO',
        has_switcher: true,
        dataType: '2D',
      },
      LMLO: {
        number: 4,
        active: true,
        position: 'left',
        thumbpos: 'right',
        key: 'LMLO',
        has_switcher: true,
        dataType: '2D',
      },
    },
  },
  {
    id: 2,
    name: '2D CC Views',
    icon: step2Icon,
    iconActive: step2IconActive,
    iconPassed: step2IconPassed,
    iconPassedActive: step2IconPActive,
    views: {
      RCC: {
        number: 1,
        active: true,
        position: 'right',
        thumbpos: 'left',
        has_switcher: true,
        dataType: '2D',
        key: 'RCC',
      },
      LCC: {
        number: 2,
        active: true,
        position: 'left',
        thumbpos: 'right',
        has_switcher: true,
        dataType: '2D',
        key: 'LCC',
      },
    },
  },
  {
    id: 3,
    name: '2D MLO Views',
    icon: step3Icon,
    iconActive: step3IconActive,
    iconPassed: step3IconPassed,
    iconPassedActive: step3IconPActive,
    views: {
      RMLO: {
        number: 1,
        active: true,
        key: 'RMLO',
        position: 'right',
        thumbpos: 'left',
        has_switcher: true,
        dataType: '2D',
      },
      LMLO: {
        number: 2,
        active: true,
        key: 'LMLO',
        position: 'left',
        thumbpos: 'right',
        has_switcher: true,
        dataType: '2D',
      },
    },
  },
  {
    id: 4,
    name: '2D/3D RCC Views',
    icon: step4Icon,
    iconActive: step4IconActive,
    iconPassed: step4IconPassed,
    iconPassedActive: step4IconPActive,
    views: {
      RCC: {
        number: 1,
        active: true,
        key: 'RCC',
        position: 'right',
        thumbpos: 'left',
        has_switcher: true,
        dataType: '2D',
      },
      RCC_L: {
        number: 2,
        active: true,
        key: 'RCC',
        layers: true,
        position: 'right',
        thumbpos: 'left',
        has_switcher: true,
        dataType: '3DQ',
      },
    },
  },
  {
    id: 5,
    name: '2D/3D RMLO Views',
    icon: step5Icon,
    iconActive: step5IconActive,
    iconPassed: step5IconPassed,
    iconPassedActive: step5IconPActive,
    views: {
      RMLO: {
        number: 1,
        active: true,
        key: 'RMLO',
        position: 'right',
        thumbpos: 'left',
        has_switcher: true,
        dataType: '2D',
      },
      RMLO_L: {
        number: 2,
        active: true,
        key: 'RMLO',
        layers: true,
        position: 'right',
        thumbpos: 'left',
        has_switcher: true,
        dataType: '3DQ',
      },
    },
  },
  {
    id: 6,
    name: '2D/3D LCC Views',
    icon: step6Icon,
    iconActive: step6IconActive,
    iconPassed: step6IconPassed,
    iconPassedActive: step6IconPActive,
    views: {
      LCC: {
        number: 1,
        active: true,
        key: 'LCC',
        position: 'left',
        thumbpos: 'right',
        has_switcher: true,
        dataType: '2D',
      },
      LCC_L: {
        number: 2,
        active: true,
        key: 'LCC',
        layers: true,
        position: 'left',
        thumbpos: 'right',
        has_switcher: true,
        dataType: '3DQ',
      },
    },
  },
  {
    id: 7,
    name: '2D/3D LMLO Views',
    icon: step7Icon,
    iconActive: step7IconActive,
    iconPassed: step7IconPassed,
    iconPassedActive: step7IconPActive,
    views: {
      LMLO: {
        number: 1,
        active: true,
        key: 'LMLO',
        position: 'left',
        thumbpos: 'right',
        has_switcher: true,
        dataType: '2D',
      },
      LMLO_L: {
        number: 2,
        active: true,
        key: 'LMLO',
        layers: true,
        position: 'left',
        thumbpos: 'right',
        has_switcher: true,
        dataType: '3DQ',
      },
    },
  },
  {
    id: 8,
    name: '2D/3D stacked R CC/MLO, Toggle (T) between modes',
    icon: step8Icon,
    iconActive: step8IconActive,
    iconPassed: step8IconPassed,
    iconPassedActive: step8IconPActive,
    views: {
      RCC: {
        number: 1,
        active: true,
        key: 'RCC',
        layers: true,
        has_switcher: true,
        dataType: '2D',
        position: 'right',
        thumbpos: 'left',
      },
      RMLO: {
        number: 2,
        active: true,
        key: 'RMLO',
        layers: true,
        has_switcher: true,
        dataType: '2D',
        position: 'right',
        thumbpos: 'left',
      },
    },
  },
  {
    id: 9,
    name: '2D/3D stacked L CC/MLO, Toggle (T) between modes',
    icon: step9Icon,
    iconActive: step9IconActive,
    iconPassed: step9IconPassed,
    iconPassedActive: step9IconPActive,
    views: {
      LMLO: {
        number: 1,
        active: true,
        key: 'LMLO',
        layers: true,
        has_switcher: true,
        dataType: '2D',
        position: 'left',
        thumbpos: 'right',
      },
      LCC: {
        number: 2,
        active: true,
        key: 'LCC',
        layers: true,
        has_switcher: true,
        dataType: '2D',
        position: 'left',
        thumbpos: 'right',
      },
    },
  },
  {
    id: 11,
    name: 'question',
    icon: step11Icon,
    iconActive: step11IconActive,
    iconPassed: step11IconPassed,
    iconPassedActive: step11IconPActive,
    type: 'modal',
    views: {},
    disabled_no_active: 9,
  },
  {
    id: 12,
    name: 'heatmap',
    icon: heatmapIcon,
    iconActive: heatmapIconActive,
    iconPassed: heatmapIconPassed,
    iconPassedActive: heatmapIconPActive,
    type: 'modal',
    views: {},
    disabled_no_active: 10,
  },
  {
    id: 13,
    name: 'explanator',
    icon: step12Icon,
    iconActive: step12IconActive,
    iconPassed: step12IconPassed,
    iconPassedActive: step12IconPActive,
    type: 'modal',
    views: {},
    disabled_no_active: 10,
  },
];

export const leftOverlayViews = {
  LCC: {
    number: 2,
    active: true,
    position: 'left',
    thumbpos: 'right',
    key: 'LCC',
    has_switcher: true,
    dataType: '2D',
  },
  LMLO: {
    number: 4,
    active: true,
    position: 'left',
    thumbpos: 'right',
    key: 'LMLO',
    has_switcher: true,
    dataType: '2D',
  },
};

export const rightOverlayViews = {
  RCC: {
    number: 1,
    active: true,
    position: 'right',
    thumbpos: 'left',
    key: 'RCC',
    has_switcher: true,
    dataType: '2D',
  },
  RMLO: {
    number: 3,
    active: true,
    position: 'right',
    thumbpos: 'left',
    key: 'RMLO',
    has_switcher: true,
    dataType: '2D',
  },
};

export const tools = [
  // {
  //   name: 'Designators',
  //   title: 'Show/Hide Designator List',
  //   icon: tool14Icon,
  //   iconActive: tool14IconActive,
  //   type: 'associations',
  //   cursor: 'associations',
  //   height: 32,
  //   canStayActive: true,
  // },
  {
    type: 'separator',
  },
  {
    name: 'FT',
    title: 'Show/Hide Finding Tool',
    icon: tool1Icon,
    iconActive: tool1IconActive,
    type: 'findingToggle',
    cursor: 'default',
    height: 23,
    onTimeAction: true,
    canStayActive: true,
    noResetFindings: true,
  },
  {
    name: 'F',
    title: 'Activate Finding Tool',
    icon: tool2Icon,
    iconActive: tool2IconActive,
    type: 'finding',
    cursor: 'arrow',
    height: 23,
    onTimeAction: false,
  },
  {
    name: 'FR',
    title: 'Reset Finding Tool',
    icon: tool10Icon,
    iconActive: tool10IconActive,
    type: 'findingReset',
    cursor: 'default',
    height: 23,
    onTimeAction: true,
  },
  {
    type: 'separator',
  },
  {
    name: 'R',
    title: 'Reset Viewers',
    icon: tool4Icon,
    iconActive: tool4IconActive,
    type: 'reset',
    cursor: 'default',
    onTimeAction: true,
  },
  {
    name: 'P',
    title: 'Activate Panning Tool',
    icon: tool5Icon,
    iconActive: tool5IconActive,
    type: 'pan',
    cursor: 'pan',
    onTimeAction: false,
  },
  {
    name: 'I Z',
    title: 'Activate Interactive Zoom',
    icon: tool6Icon,
    iconActive: tool6IconActive,
    type: 'zoomInteractive',
    cursor: 'izoom',
    onTimeAction: false,
  },
  {
    name: 'Z 1:1',
    title: 'Activate 1:1 Tool',
    icon: tool7Icon,
    iconActive: tool7IconActive,
    type: 'zoom1_1',
    cursor: 'fullzoom',
    onTimeAction: false,
  },
  {
    name: 'M',
    title: 'Activate Magnifier Tool',
    icon: tool8Icon,
    iconActive: tool8IconActive,
    type: 'zoomMagnifier',
    cursor: 'mzoom',
    onTimeAction: false,
  },
  {
    name: 'W/L',
    title:
      'Activate Window/Level Tool (W; for presets move mouse over viewer and press numeric keys 1, 2, 3, ...)',
    icon: tool9Icon,
    iconActive: tool9IconActive,
    type: 'windowLevel',
    cursor: 'wlevel',
    onTimeAction: false,
  },
];

export const getToolByType = type => {
  return tools.find(item => item.type === type);
};

export const getDefaultType = (type, datas) => {
  if (type === '3DQ' && !datas['3DQ'] && datas['tomo']) return 'tomo';
  return type;
};
