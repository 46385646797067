/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useState } from 'react';
import ReactModal from 'react-modal';
import { onOpenExternalUrl } from 'utils/appHelpers';
import { getFromStore, initCookieState, saveToStore } from 'utils/storeHelpers';
import CookieSettingsModal from './components/CookieSettingsModal';

const CookiePopup = () => {
  const storeState = getFromStore('cookie');
  const runState = storeState ? JSON.parse(storeState) : { ...initCookieState };
  const [open, toggleModal] = useState(false);
  const [state, setState] = useState(runState);

  const saveAndAccept = () => {
    const newState = { ...state, cookieAccepted: true };
    toggleModal(false);
    setState(newState);
    saveToStore('cookie', JSON.stringify(newState));
  };

  if (state.cookieAccepted) return null;

  return (
    <>
      <div className='cookie-popup d-flex'>
        <div className='info'>
          We use cookies on our website to give you the most relevant experience by remembering
          preferences and repeat visits. By clicking “Accept” you consent to store on your device
          all the technologies described in our{' '}
          <a
            href='https://www.hologic.com/privacy-policy'
            onClick={onOpenExternalUrl.bind(null, 'https://www.hologic.com/privacy-policy')}
            target='_blank'
          >
            Cookie Policy
          </a>
          . However, you may visit “Cookie Settings” to provide a more controlled consent.”
        </div>
        <div className='cookie-btns col-3 pr-0 text-right'>
          <button className='btn p-0 cookie-settings' onClick={() => toggleModal(true)}>
            Cookie settings
          </button>
          <button onClick={saveAndAccept} className='btn btn-success'>
            ACCEPT
          </button>
        </div>
      </div>
      <ReactModal isOpen={open} className='custom-modal'>
        <CookieSettingsModal
          onClose={() => toggleModal(false)}
          state={state}
          setState={setState}
          saveAndAccept={saveAndAccept}
        />
      </ReactModal>
    </>
  );
};

export default CookiePopup;
