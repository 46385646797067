/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { InputSearchIcon } from 'shared/components/Icons';

export const SearchInput = ({ placeholder = 'Search', className, onChange, onEnter, value }) => {
  return (
    <div className='search-input-container'>
      <input
        onKeyDown={e => {
          if (e.key === 'Enter') onEnter();
        }}
        onChange={onChange}
        value={value}
        type='text'
        className={`search-input ${className}`}
        placeholder={placeholder}
        aria-label={placeholder}
      />
      <div onClick={onEnter}>
        <InputSearchIcon className='search-icon' />
      </div>
    </div>
  );
};
