/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ReactDOM from 'react-dom';
import Button from 'shared/components/Button';
import Logo from 'assets/logos/ACE-logo_final-White.png';
import {
  BatteryIcon,
  DurationIcon,
  ModalCloseIcon,
  NumberIcon,
  PassThresholdIcon,
  TerminateLeaveIcon,
  TimeLimitIcon,
} from 'shared/components/Icons';

export const QuizStartModal = ({
  isOpen,
  onClose,
  onStart,
  lesson,
  passedDuration = 0,
  currentAttempt,
}) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={e => e.stopPropagation()}>
        <div className='modal-body'>
          <img src={Logo} alt='logo' />
          <Button onClick={onClose} className='btn-transparent close-button p-0'>
            <ModalCloseIcon />
          </Button>
          <p className='main-desc'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla pharetra aliquet lectus
            malesuada pulvinar.{' '}
          </p>
          <div className='content'>
            <div className='first-block'>
              <h6 className='title'>Before you begin</h6>
              <p className='description'>
                Each Answer will be submitted by clicking next. You can't go back to change your
                answers. There are answers for which individual time limit and if you not submit
                before limit ends the answer will be considered as wrong.
              </p>
            </div>
            <div className='second-block'>
              <h6 className='title'>Overview</h6>
              <p className='description'>
                The details about the quiz duration, time limit etc. are shown below.
              </p>
              <div className='details-block'>
                <div className='left-block'>
                  <div className='row'>
                    <div className='name-part'>
                      <NumberIcon />
                      <span>Number Of Questions</span>
                    </div>
                    <span>{lesson.steps?.length}</span>
                  </div>
                  <div className='row'>
                    <div className='name-part'>
                      <BatteryIcon />
                      <span>Number Of attempts</span>
                    </div>
                    <span>
                      {currentAttempt}/{lesson.attempts}
                    </span>
                  </div>
                  <div className='row'>
                    <div className='name-part'>
                      <PassThresholdIcon />
                      <span>Pass Threshold</span>
                    </div>
                    <span>{lesson.passingThreshold}%</span>
                  </div>
                </div>
                <div className='vertical-border' />
                <div className='right-block'>
                  <div className='row'>
                    <div className='name-part'>
                      <DurationIcon />
                      <span>Duration</span>
                    </div>
                    <span>
                      {lesson.timeLimit
                        ? `~${(lesson.timeLimit - passedDuration / 60).toFixed(0)} min`
                        : 'No Limit'}
                    </span>
                  </div>
                  <div className='row'>
                    <div className='name-part'>
                      <TimeLimitIcon />
                      <span>Time Limit</span>
                    </div>
                    <span>{lesson.timeLimit ? `${lesson.timeLimit} min` : 'No Limit'}</span>
                  </div>
                  <div className='row'>
                    <div className='name-part'>
                      <TerminateLeaveIcon />
                      <span>Terminate on Leave</span>
                    </div>
                    <span>{lesson.terminateOnLeave ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='btns-container'>
            <Button className='btn-blue btn-cancel' onClick={onClose}>
              Cancel
            </Button>
            <Button
              className='btn-blue btn-start'
              onClick={() => {
                onStart();
                onClose();
              }}
            >
              Start
            </Button>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root'),
  );
};
