import React from 'react';
import AuthBlock from './AuthBlock';
import Button from 'shared/components/Button';

const InfoComponent = ({ className = '', title, subtitles, actionText, actionHandler }) => {
  return (
    <div className='container align-items-center d-flex flex-column'>
      <div className={`auth-success-block ${className || ''}`}>
        <h1 className='title'>{title}</h1>
        {subtitles &&
          subtitles.map((subtitle, idx) => (
            <p key={idx} className='sub-title'>
              {subtitle}
            </p>
          ))}
      </div>
      <div className='action_block text-center pt-4'>
        <Button className='btn-blue-dark info_action_btn' onClick={actionHandler}>
          {actionText}
        </Button>
      </div>
    </div>
  );
};

const InformativeMessageComponent = ({
  match,
  history,
  title,
  subtitles,
  actionText,
  actionHandler,
}) => {
  const Info = {
    title,
    subtitles,
    actionText,
    actionHandler,
  };

  const authOptions = {
    links: { signin: true },
  };

  return (
    <AuthBlock
      large={true}
      noFooterInfo={true}
      {...authOptions}
      successBlock={<InfoComponent {...Info} />}
    />
  );
};

export default InformativeMessageComponent;
