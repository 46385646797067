import React from 'react';
import { Link } from 'react-router-dom';
import ButtonLine from 'shared/components/ButtonLine';
import blueLogo from 'assets/ACE-logo-blue.svg';
import AuthPage from '../components/AuthPage';

const UserLicense = ({ history }) => {
  return (
    <AuthPage>
      <div className='align-items-center auth-block large d-flex py-5 position-relative'>
        <div className='text-center'>
          <Link to='/login'>
            <img src={blueLogo} alt='logo' height='75' className='opc-3' />
          </Link>
        </div>
        <div className='reg-block d-flex flex-column justify-content-between mt-5'>
          <div className='head-part d-flex align-items-center px-4'>
            <p className='m-0'>End-User Software License Agreement</p>
          </div>
          <div className='content-part px-4 py-3 flex-fill'>
            <p>Last updated: August 20, 2020</p>
            Please read this End-User Software License Agreement (hereinafter the “Agreement”)
            carefully before clicking the “I Agree” button, downloading or using the software
            (hereinafter the “Licensed Software”).
            <br />
            <br />
            By clicking the “I Agree” button, downloading or using the Licensed Software, You are
            agreeing to be bound by the terms and conditions of this Agreement. If You are accepting
            these terms of behalf of another person or company or other legal entity, You represent
            and warrant that you have full authority to bind that person, company or legal entity to
            these terms.
            <br />
            <br />
            If You do not agree to the terms of this Agreement, do not click on the “I Agree” button
            and You cannot download or use the Licensed Software.
            <br />
            <br />
            “You” or “Your” means the person or entity who is being licensed to use the Licensed
            Software in association with any sales or service agreement (“Usage Agreement”). “We”,
            “Our” and “Us” means Hologic, Inc.
            <br />
            <br />
            1. License Grant. We grant You a revocable, non-exclusive, non-transferable, limited
            right to access and use the Licensed Software on a device owned and controlled by You,
            and to access and use the Licensed Software strictly in accordance with the terms and
            conditions of this Agreement, the Usage Agreement and any service agreement, sales
            agreement or any other agreement with You, (collectively, “Related Agreements”).
            <br />
            <br />
            2. Restrictions on Use. You shall use the Licensed Software strictly in accordance with
            the terms of the Related Agreements and shall not: (a) decompile, reverse engineer,
            disassemble, attempt to derive the source code of, maliciously penetrate any system or
            network which runs or stores, or decrypt the Licensed Software; (b) make any
            modification, automation, conversion, adaptation, improvement, enhancement, translation
            or derivative work to or from the Licensed Software; (c) remove, circumvent or create,
            or use any workaround to any copy protection or security feature relating to the
            Licensed Software; (d) violate any applicable laws, rules or regulations in connection
            with Your access or use of the Licensed Software; (e) remove, alter, or obscure any
            proprietary notice (including any notice of copyright or trademark) of Ours or Our
            affiliates, partners, suppliers or licensors; (f) use the Licensed Software for any
            revenue generating endeavor, commercial enterprise, or other purpose for which it is not
            designed or intended; (g) install, use or permit the Licensed Software to be used only
            on the originally designated Equipment for the Licensed Software; (h) make the Licensed
            Software available over a network or other environment permitting access or use by
            multiple devices or users at the same time; (i) use the Licensed Software for creating a
            product, service or software that is, directly or indirectly, competitive with or in any
            way a substitute for any product, services or software offered by Us; (j) use the
            Licensed Software to send automated queries to any website or to send any unsolicited
            commercial e-mail; (k) connect the Licensed Software to any online service or other
            applications, software or services not provided or permitted by Us; (l) use any
            proprietary information or interfaces of Ours or other intellectual property of Ours in
            the design, development, manufacture, licensing or distribution of any applications,
            accessories or devices for use with the Licensed Software; (m) own title, or transfer
            title to the Licensed Software to any third party; (n) distribute, or sublicense or
            otherwise provide copies or any rights in relation to the Licensed Software to any third
            party; or (o) pledge, hypothecate, alienate or otherwise encumber the Licensed Software
            to any third party. <br />
            <br /> 3. Consent to Use of Content. You agree that We may collect and use device data
            and related information, including but not limited to technical information about Your
            devices, products, system and application software, and peripherals, that is gathered to
            facilitate the provision of software updates, product support and other services to You
            related to the Licensed Software (collectively, “Data”). You may also have agreed to
            provide information, feedback, de-identified images, and other content in connection
            with Your use of the Licensed Software (together with the Data, collectively,
            “Content”). You agree that We may use the Content to improve the products and services
            or to provide products and services to You, and You hereby grant Us a non-exclusive,
            worldwide, royalty-free, fully paid-up, transferable and sub- licensable license in and
            to the Content, including all intellectual property rights therein, for Us to use,
            modify and create derivative works of the same in connection with or related to any
            business purposes. You represent and warrant to Us that (i) you have the necessary
            rights to grant the licenses and rights in the Content, and (ii) the Content and Our use
            thereof as permitted in this Agreement will not infringe, violate or misappropriate any
            third party right. Content will not include any patient health information (“PHI”). For
            the avoidance of doubt, &quot;Protected Health Information&quot; or &quot;PHI&quot;
            shall mean individually identifiable health information regardless of the form in which
            it is maintained or transmitted. We may, at our sole discretion, aggregate and
            de-identify any and all PHI obtained under this Agreement and use all such de-identified
            data in accordance with the de-identification requirements at 45 CFR 164.514(b). With
            respect to de-identification, the aggregated and de-identified data We produce or use
            either: (i) will not include any identifiers listed in 45 CFR 164.514(b)(2)(i), or (ii)
            will have been determined by a person with appropriate knowledge of and experience with
            generally accepted statistical and scientific principles and methods for rendering
            information not individually identifiable and applying such principles and methods, that
            the risk is very small that the aggregated and de-identified data generated under this
            Agreement could be used, alone or in combination with other reasonably available
            information, by an anticipated recipient, to identify an individual who is a subject of
            the information, thereby forming a &quot;statistically de-identified data set&quot; and
            rendering the information not PHI under HIPAA. De-identified information does not
            constitute PHI and is not subject to the terms of this Agreement.
            <br />
            <br /> 4. Intellectual Property Rights. You acknowledge and agree that the Licensed
            Software and Our documentation are provided under license, and not sold, to You. You do
            not acquire any ownership interest in the Licensed Software or Our documentation under
            this Agreement, or any other rights thereto, other than to use the Licensed Software in
            accordance with Our documentation, subject to all of the terms, conditions and
            restrictions under this Agreement and any Usage Agreement. We reserve and shall retain
            all right, title and interest in and to the Licensed Software and all intellectual
            property rights arising out of or relating to the Licensed Software, except as expressly
            granted to You in this Agreement. You shall safeguard the Licensed Software from
            infringement, misappropriation, theft, misuse or unauthorized access. You shall promptly
            notify Us if You become aware of any infringement of Our intellectual property rights in
            the Licensed Software and fully cooperate with Us in any legal action taken by Us to
            enforce Our intellectual property rights. Further, We will be free to use any ideas,
            concepts, know-how or techniques contained in such Content for any purpose whatsoever,
            including, without limitation, developing, making, marketing, distributing and selling
            products and services incorporating such Content. We will have no obligation to
            consider, use, return or preserve any of the Content you provide to Us. Any Content You
            provide to Us may or may not be treated confidentially by Us, and We will have no
            obligation or liability to You for the use or disclosure of any of the Content. You
            should not to expect any compensation of any kind from Us with respect to the Content
            You provided. <br />
            <br />
            5. Limited Warranty and Disclaimer.
            <br />
            a) Limited Warranty. We warrant that, for a period of ninety (90) days following the
            date the Licensed Software is made available to You for Your use, the Licensed Software
            will perform substantially in accordance with Our documentation (the “Limited
            Warranty”).
            <br /> b) Exclusive Remedy. In case of any breach of the above Limited Warranty, as your
            exclusive remedy and Our entire obligation and liability, We will: (i) repair or replace
            the Licensed Software; or (ii) if such repair or replacement would in Our opinion be
            commercially unreasonable, upon Our receipt of Your written representation and promise
            that you have removed all instances of the Licensed Software and will not use the
            Licensed Software, refund the price paid by You for the applicable Licensed Software.{' '}
            <br />
            c) Exclusion of Warranty. THE ABOVE LIMITED WARRANTY WILL NOT APPLY IF: (i) THE LICENSED
            SOFTWARE IS NOT USED IN ACCORDANCE WITH THIS AGREEMENT OR OUR DOCUMENTATION; (ii) THE
            LICENSED SOFTWARE OR ANY PART THEREOF HAS BEEN MODIFIED BY YOU OR ANY ENTITY OTHER THAN
            US; OR (iii) A MALFUNCTION IN THE LICENSED SOFTWARE HAS NOT BEEN CAUSED BY ANY EQUIPMENT
            OR SOFTWARE NOT APPROVED OR SUPPLIED BY US. <br />
            d) Disclaimer. EXCEPT FOR THE LIMITED WARRANTY SET FORTH ABOVE, THE LICENSED SOFTWARE IS
            PROVIDED “AS IS” AND WE MAKE NO REPRESENTATIONS OR WARRANTIES, AND WE DISCLAIM ALL
            REPRESENTATIONS, WARRANTIES AND CONDITIONS, ORAL OR WRITTEN, EXPRESS OR IMPLIED, ARISING
            FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE IN TRADE, OR OTHERWISE,
            INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, FITNESS
            FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR SYSTEMS INTEGRATION. WITHOUT
            LIMITING THE FOREGOING, WE MAKE NO WARRANTY, REPRESENTATION, OR GUARANTEE THAT THE
            OPERATION OF THE LICENSED SOFTWARE WILL BE FAILSAFE, UNINTERRUPTED, OR FREE FROM ERRORS
            OR DEFECTS. <br />
            <br />
            6. Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL WE BE
            LIABLE FOR PERSONAL INJURY, OR ANY INCIDENTAL, SPECIAL, INDIRECT OR CONSEQUENTIAL
            DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF
            DATA, BUSINESS INTERRUPTION OR ANY COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR
            RELATED TO YOUR USE OR INABILITY TO USE THE LICENSED SOFTWARE, HOWEVER CAUSED,
            REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
            OTHERWISE) AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
            JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF
            INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU. IN NO
            EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES EXCEED THE LICENSED SOFTWARE FEE
            PAID BY YOU FOR THE APPLICABLE TERM. NOTHING IN THIS AGREEMENT LIMITS OR EXCLUDES ANY
            LIABILITY THAT CANNOT BE LIMITED OR EXCLUDED UNDER APPLICABLE LAW.
            <br />
            <br />
            7. Intellectual Property Indemnity.
            <br /> a) Indemnity. We will indemnify, and at our election, defend, You against claims
            asserted against You in a suit or action if: (i) the claim is for direct patent
            infringement or direct copyright infringement, or for misappropriation of third party
            trade secrets; (ii) the claim is asserted against the Licensed Software, alone and not
            in combination with any third party software or systems.
            <br />
            b) Exclusions. Notwithstanding anything else in this Agreement, We have no obligation to
            indemnify or defend you for claims asserted, in whole or in part, if (i) the Licensed
            Software is not used in accordance with this Agreement or Our documentation; (ii) the
            Licensed Software or any part thereof has been modified by You or any entity other than
            Us; or (iii) a malfunction in the Licensed Software has been caused by any equipment or
            software not approved or supplied by Us. <br />
            c) Conditions. As a condition of Our obligations under this Section 7, You must provide
            Us with: (i) prompt written notice of the claim and Your agreement to give Us sole
            control over the defense and settlement of the claim; and Your full and timely
            cooperation. We will not be responsible for any costs, expenses or compromises that You
            make or incur without Our written consent.
            <br /> d) Remedies. We may, at Our sole discretion and expense: (i) procure for you the
            right to continue using the Licensed Software; (ii) replace the Licensed Software with
            non-infringing Licensed Software; (iii) modify the Licensed Software so that it becomes
            non-infringing; or (iv) upon Your return of the Licensed Software to Us, and/or removal
            of the Licensed Software from your systems, refund the residual value of the Licensed
            Software fee paid by You for the infringing Licensed Software, depreciated using a
            straight-line method of depreciation over a three (3) year period from the date of
            delivery of the Licensed Software to You. This indemnity section states Our entire
            obligation and Your exclusive remedy for claims of patent or copyright infringement, or
            trade secret misappropriation, made in whole or in part against the Licensed Software.
            <br />
            <br /> 8. Export. You may not use or otherwise export or re-export the Licensed Software
            except as authorized by United States law and the laws of the jurisdiction in which the
            Licensed Software was obtained. In particular, but without limitation, the Licensed
            Software may not be exported or re-exported: (i) into any U.S. embargoed countries; or
            (ii) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals
            or the U.S. Department of Commerce Denied Person’s List or Entity List. By using the
            Licensed Software, You represent and warrant that You are not located in any such
            country or on any such list and that You will not use the Licensed Software for any
            purposes prohibited by United States law. <br />
            <br />
            9. Term. The term of this Agreement will commence on the date of Your agreement to these
            terms and shall continue for the same term as the Usage Agreement. Hologic may terminate
            this Agreement at any time for any reason, including to the extent that any Usage
            Agreement has been terminated or expired. <br />
            <br />
            10. General. a) Governing Law. Jurisdiction. Upon acceptance of these terms, this
            Agreement is governed by the laws of the Commonwealth of Massachusetts, without regard
            to any conflict of law principles to the contrary. You hereby irrevocably consent to
            jurisdiction of the state and federal courts located in Boston, Massachusetts with
            respect to any proceeding regarding this Agreement or the Licensed Software. The 1980 UN
            Convention for the International Sale of Goods or any successor thereto does not apply
            to this Agreement. You will not prosecute any action, suit, proceeding or claim arising
            under or by reason of this Agreement or the Licensed Software except in such courts.
            <br /> b) Severability. If any provision of this Agreement is held by a court of
            competent jurisdiction to be invalid or unenforceable, the remainder of this Agreement
            will remain in full force and effect, and the remaining provisions will be amended to
            achieve as closely as possible the effect of the original term and all other provisions
            of this Agreement will continue in full force.
            <br /> c) Assignment. You may not assign or otherwise transfer the Licensed Software or
            this Agreement, or assign, sub-license or otherwise transfer to any other person or
            entity any of Your rights under this Agreement without Our prior written consent, and
            any attempted assignment without such consent will be void. <br />
            d) Notices. All &quot;notices&quot; provided by You under this Agreement shall be in
            writing sent to the contact information associated with the user account to access the
            Licensed Software or other means providing proof of delivery. <br />
            e) Revisions to this Agreement. We may at any time revise the terms of this Agreement by
            updating these terms and by providing notice to You of that change upon You accessing
            the Licensed Software at the time of said change and agreeing to the revised terms.{' '}
            <br />
            <br />
            11. Entire Agreement. This Agreement together with the Related Agreements sets forth Our
            entire agreement with respect to Your use of the Licensed Software and supersedes all
            prior and contemporaneous understandings and agreements with respect to the Licensed
            Software and Your use thereof whether written or oral. In the event of a conflict, the
            Usage Agreement shall supersede and control over the terms of this End-User Software
            License Agreement.
            <br />
            <br />
            12. General Disclaimers. This is a general information tool for medical professionals
            and is not a complete representation of the product(s)’ Instruction for Use (IFU) or
            Package Insert, and it is the medical professionals’ responsibility to read and follow
            the IFU or Package Insert. The information provided may suggest a particular technique
            or protocol however it is the sole responsibility of the medical professional to
            determine which technique or protocol is appropriate. At all times, clinicians remain
            responsible for utilizing sound patient evaluation and selection practices, and for
            complying with applicable local, state, and federal rules and regulations regarding
            accreditation, anesthesia, reimbursement, and all other aspects of in- office
            procedures. In no event shall Hologic be liable for damages of any kind resulting from
            your use of the information presented. This information is intended for medical
            professionals in the U.S. and other markets and is not intended as a product
            solicitation or promotion where such activities are prohibited. Because Hologic
            materials are distributed through websites, eBroadcasts, and tradeshows, it is not
            always possible to control where such materials appear. For specific information on what
            products are available for sale in a particular country, please contact your local
            Hologic representative or write to womenshealth@hologic.com <br />
            <br /> 13. Medical Use Disclaimer. This training program is not intended for clinical or
            diagnostic use. <br />
            <br />
            14. Faculty Statement Disclaimer. Views and opinions expressed herein by third parties
            are theirs alone and do not necessarily reflect those of Hologic. 15. Copyright.
            Copyright© 2020, Hologic. Inc. Reproduction, display or copying of this information
            without express written permission is strictly prohibited. All materials, images, and
            content are intellectual property of Hologic and may not be copied, reproduced,
            distributed, or displayed without permission.
          </div>
          <div className='bottom-part d-flex align-items-center justify-content-end px-4'>
            <ButtonLine onClick={() => history.goBack()} className='p-0'>
              Done
            </ButtonLine>
          </div>
        </div>
      </div>
    </AuthPage>
  );
};

export default UserLicense;
