import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { EyeIcon } from 'shared/components/Icons';
import InputGroup from 'shared/components/InputGroup';
import { getError } from 'utils/appHelpers';
import { Api } from 'utils/connectors';
import LeftLayout from 'app/Product/components/LeftLayout';
import Button from 'shared/components/Button';
import RightLayout from 'app/Product/components/RightLayout';
import useQuery from 'shared/hooks/useQuery';
import { useSnackbar } from 'notistack';

const ProductCreatePassword = ({ match, history, location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { urlPath } = match.params;
  const queryParams = useQuery();
  const { description, resetToken, resetUserEmail } = location?.state || {};

  const { entity, spec = '' } = queryParams;

  const [error, setError] = useState();
  const [fetch, setFetch] = useState();

  const [form, setForm] = useState({
    password: '',
    rpassword: '',
    resetToken,
    email: resetUserEmail,
  });

  const leftLayoutDetails = {
    entity: entity,
    entityForwardPageKey: 'reset-password',
    description:
      description ||
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever ",
  };

  const rightLayoutDetails = {
    footerForwardUrl: `/product/${urlPath}/register?entity=${entity}&spec=${spec}`,
    question: 'Don’t have an account yet?',
    buttonText: 'Sign Up',
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFetch(true);
    try {
      await Api.post('/auth/reset-password', form);
      enqueueSnackbar('Password has been successfully reset', { variant: 'success' });
      history.push({
        pathname: `/product/${urlPath}/login`,
        search: `?entity=${entity}&spec=${spec}`,
        state: { currentEmail: form.email, currentPassword: form.password },
      });
    } catch (err) {
      setError(getError(err));
      setFetch(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const passwordMatch = form.password === form.rpassword;

  return (
    <div className='product-sign-container d-flex flex-column min-vh-100'>
      <div className='flex-1 d-flex'>
        <LeftLayout {...leftLayoutDetails} />
        <RightLayout {...rightLayoutDetails}>
          <div className='form-block'>
            <h5 className='desc-title'>Please Sign In to Continue</h5>
            <form onSubmit={handleSubmit}>
              <div className='inputs-block'>
                <InputGroup
                  type='password'
                  name='password'
                  onChange={handleChange}
                  className='focus-bottom-border'
                  placeholder='Password'
                  value={form.password}
                  eyeIcon={<EyeIcon />}
                  required
                />
                <InputGroup
                  type='password'
                  name='rpassword'
                  onChange={handleChange}
                  className='focus-bottom-border'
                  placeholder='Confirm password'
                  value={form.rpassword}
                  eyeIcon={<EyeIcon />}
                  required
                />
                {error && <div className='error-text'>{error}</div>}
                {!passwordMatch && form.rpassword && (
                  <div className='error-text'>Passwords mismatch</div>
                )}
              </div>
              <Button
                className='btn-blue-dark-redesigned form-button w-100'
                type='submit'
                disabled={!form.password || !form.rpassword || fetch}
                disabledClassName='btn-disabled-gray'
              >
                Save
              </Button>
            </form>
          </div>
        </RightLayout>
      </div>
    </div>
  );
};

export default ProductCreatePassword;
