import React from 'react';
import { Link } from 'react-router-dom';

const SuccessInfo = ({ title, subTitle, className, signinUrl, subTitleClassName }) => {
  return (
    <div className='align-items-center d-flex flex-column'>
      <div className={`auth-success-block ${className || ''}`}>
        <h1 className='title'>{title}</h1>
        <p className={`sub-title ${subTitleClassName}`}>{subTitle}</p>
      </div>
      <div className='text-center mt-3'>
        <Link className='link' to={signinUrl || '/login'}>
          Sign In
        </Link>
      </div>
    </div>
  );
};

export default SuccessInfo;
